import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { background, Box, Button, Divider, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { HiBan } from "react-icons/hi";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import { Navigate, Link as LinkWrap } from "react-router-dom";
import { ClassificationCategories, IHITLFilters, useHitlFilters } from "..";
import { ComponentLoading } from "../../../components/loading/ComponentLoading";
import { GET_PRECISION_CATEGORIES_OPTIONS, GET_REVIEWED_URL, RESOLVE_URL_CATEGORY } from "../../../graphql/operations/campaignOperations/operations";
import { GET_URL_CLASSIFICATION, UrlClassificationType } from "../../../rest/rest";
import { MultipleChoiceSection } from "./MultipleChoiceSection";
import { TitleLink } from "./TitleLink";
import { Confirm } from "../../adminCampaignPage/boxes/Confirm";
import { ToastNotificationBox } from "../../../components/toastNotificationBox/ToastNotificationBox";
import {
    useToast,
    UseToastOptions,
} from "@chakra-ui/react";


export const UrlReview = () => {

    const hitlFilters = useHitlFilters();
    const [chosenClassification, setChosenClassification] = useState<Record<string, string[]>>({});
    const [rejectedClassification, setRejectedClassification] = useState<Record<string, string[]>>({});
    const [showReviewedButton, setShowReviewedButton] = useState(false);
    const [, setForceUpdate] = useState(false);


    const toast = useToast();
    const toastId = "url-review-toast";


    const { data, error, loading } = useQuery(
        GET_URL_CLASSIFICATION,
        {
            skip: !hitlFilters.selectedUrl,
            context: {clientName: "hitlEndpoint"},
            variables: {
                redisKey: hitlFilters.selectedUrl,
            },
            fetchPolicy: "cache-and-network",
            onCompleted: () => {
                getReviewedUrl({ variables: { url: data.urlClassification.pageUrl } })
            }
        }
    );


    const [ getReviewedUrl, {data: dataReviewed} ] = useLazyQuery(
        GET_REVIEWED_URL,
        {
            onCompleted: () => {
                if (dataReviewed) {
                    setShowReviewedButton(true);

                }
            },
            onError: () => {
                setShowReviewedButton(false);

            }
        }
    )



    const { data: dataPrecision, error: errorPrecision, loading: loadingPrecision } = useQuery(
        GET_PRECISION_CATEGORIES_OPTIONS,
        {
            fetchPolicy: "cache-and-network",
        }
    );

    // May not need to refetch and just go the next entry in urlList
    const [resolveUrlCategory, {loading: loadingMutation}] = useMutation(RESOLVE_URL_CATEGORY, {
        onCompleted: () => {
            goToNextUrl(hitlFilters);
        }
        // refetchQueries: [GET_URL_CLASSIFICATION]
    });

    if (loading || loadingPrecision) {
        return <ComponentLoading text={"Loading URLs..."}/>
    };

    if (error || errorPrecision) {
        return <>Error</>
    };

    if (!data) {
        return <Navigate to={"/human-in-the-loop"} />

    }

    const classificationResults: UrlClassificationType = data.urlClassification;
    const mainClassifications: [string, string[]][] = [];
    const entityClassifications: [string, string[]][] = [];

    const classificationEntries = Object.entries(classificationResults.results);

    // Modify entries so that all results are in the correct format,
    // especially illuma_sent as it is originally a string
    const modifiedEntries: [string, string[]][] = classificationEntries.map((entry) => {

        // if (entry[0] === "illuma_sent") {
        //     const newEntry: [string, string[]] = [entry[0], ["negative", "neutral", "positive"]]
        //     return newEntry
        // }

        if (entry[1] === null) {
            const newEntry: [string, string[]] = [entry[0], []];
            return newEntry
        }

        if (Array.isArray(entry[1])) {
            const newEntry: [string, string[]] =  [entry[0], entry[1]]
            return newEntry
        } else {
            const newEntry: [string, string[]] = [entry[0], [entry[1]]]
            return newEntry
        }
    })

    // Split entity classifications and main classifications
    modifiedEntries.forEach((classification) => {
        if (classification[0].includes("ents")) {
            entityClassifications.push(classification)
        } else {
            mainClassifications.push(classification)
        }
    })

    // Order classifications

    const orderedMainClassifications = mainClassifications.sort(compare);
    const orderedEntityClassifications = entityClassifications.sort(compare);


    return (
        <Box>
            <Flex
                bgColor={"white"}
                borderRadius={"lg"}
                p={4}
                mb={10}
                direction={"column"}
            >
                <TitleLink
                    url={classificationResults.pageUrl}
                    // Only show note when more than one category
                    showNote={true}
                    correctionButton={showReviewedButton}
                />
                <Flex
                    flexWrap={["nowrap", null, "wrap", null, null]}
                    flexDirection={["column", null, "row", null, null]}
                >
                    {orderedMainClassifications.map((classification, ix) => {

                        return (
                            <MultipleChoiceSection
                                key={ix}
                                index={ix}
                                objectKey={classification[0]}
                                objectValue={classification[1]}
                                setChosenCategories={setChosenClassification}
                                setRejectedCategories={setRejectedClassification}
                                setForceUpdate={setForceUpdate}
                                chosenClassification={chosenClassification}
                                dataPrecision={dataPrecision}
                            />
                        )

                    })}
                    {
                        orderedEntityClassifications.length > 0 &&
                            <>
                                <React.Fragment>
                                    <Divider borderColor={"muted"}/>
                                    <Text
                                        width={"100%"}
                                        fontSize={"18px"}
                                        my={2}
                                        ml={6}
                                        fontWeight={"semibold"}
                                    >
                                        {formatSubSectionHeader("ENTITIES")}
                                    </Text>
                                    {
                                        orderedEntityClassifications.map((entityClassification, iy) => {
                                            return (
                                                <MultipleChoiceSection
                                                    key={iy}
                                                    index={iy}
                                                    objectKey={entityClassification[0]}//`${subCategory[0]}_${category}`}
                                                    objectValue={entityClassification[1]}
                                                    setChosenCategories={setChosenClassification}
                                                    setRejectedCategories={setRejectedClassification}
                                                    setForceUpdate={setForceUpdate}
                                                    chosenClassification={chosenClassification}
                                                    dataPrecision={dataPrecision}
                                                />
                                            )
                                        })
                                    }
                                </React.Fragment>
                            </>
                    }
                </Flex>
            </Flex>
            <Flex
                bg={"white"}
                justifyContent={["flex-end", null, "center", null, null]}
                py={4}
                position={"fixed"}
                bottom={0}
                left={0}
                width={"100%"}
                pr={5}
            >
                <HStack spacing={4}>
                    <Button
                        leftIcon={<Icon as={RiCheckFill} w={5} h={5}/>}
                        bgColor={"submitGreen"}
                        _hover={{
                            bgColor: "submitGreen",
                            opacity: 0.7
                        }}
                        isDisabled={
                                (Object.keys(removeEmpty(chosenClassification)).length === 0 &&
                                Object.keys(removeEmpty(rejectedClassification)).length === 0) ? true : false
                        }
                        isLoading={loadingMutation}
                        onClick={() => {
                            resolveUrlCategory({
                                variables: {
                                    url: classificationResults.pageUrl,
                                    precisionIabV3: JSON.stringify(classificationResults.results.precision_iab_v3),
                                    reviewedClassification: Object.keys(removeEmpty(chosenClassification)).length === 0 ?
                                        null : JSON.stringify(removeEmpty(chosenClassification)),
                                    rejectedClassification: Object.keys(removeEmpty(rejectedClassification)).length === 0 ?
                                        null : JSON.stringify(removeEmpty(rejectedClassification))
                                }

                            })
                            .then(() => {
                                toast({
                                    id: toastId,
                                    status: "success",
                                    duration: 3000,
                                    isClosable: true,
                                    position: 'bottom-right',

                                    render: () => {
                                        return (
                                            <ToastNotificationBox
                                                content="Changes submitted"
                                                borderLeftColor={"submitGreen"}
                                                backgroundColor={"lightGreen"}
                                                borderColor={"lightGreen"}
                                                borderLeftWidth={4}
                                                borderRadius={0}
                                            />
                                        )
                                    }
                                })

                            })
                            .catch((err) => err);
                            setShowReviewedButton(false);
                        }}
                    >
                        Submit
                    </Button>
                    <LinkWrap to={".."}>
                        <Button
                            leftIcon={<Icon as={RiCloseFill} w={5} h={5}/>}
                            bgColor={"cancelRed"}
                            _hover={{
                                bgColor: "cancelRed",
                                opacity: 0.7
                            }}
                        >
                            Cancel
                        </Button>
                    </LinkWrap>
                    <Button
                        leftIcon={<Icon as={HiBan} w={5} h={5}/>}
                        bgColor={"#EAF0F2"}
                        onClick={() => {
                            goToNextUrl(hitlFilters);
                            // refetch();
                            setChosenClassification({});
                            setShowReviewedButton(false);
                        }}
                    >
                        Skip
                    </Button>
                </HStack>
            </Flex>
        </Box>
    )
}

const removeEmpty = (obj: Record<string, string[]>) => {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v.length != 0));
}

// Function used to order classification
const compare = ( a: [string, string[]], b: [string, string[]] ) => {

    if ( a[1].length > b[1].length ){
      return -1;
    }
    if ( a[1].length < b[1].length ){
      return 1;
    }
    return 0;
}

const formatSubSectionHeader = (header: string) => {
    let formattedHeader = header;

    switch (header) {
        case ClassificationCategories.entities:
            formattedHeader = "ENTITIES";
            break;
    }

    return formattedHeader;
}

const goToNextUrl = (hitlFilters: IHITLFilters) => {

    if (hitlFilters.selectedIndex === hitlFilters.urlList.length - 1) {
        hitlFilters.setSelectedIndex(0);
        hitlFilters.setSelectedUrl(hitlFilters.urlList[0]);
    } else {
        hitlFilters.setSelectedIndex(hitlFilters.selectedIndex + 1);
        hitlFilters.setSelectedUrl(hitlFilters.urlList[hitlFilters.selectedIndex + 1]);
    }

}
