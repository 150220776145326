import { useState } from "react";
import {
  Box,
  BoxProps,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { SelectField } from "../../components/selectField/SelectField";
import { CampaignStatusChoices } from "../../graphql/generated";
import {
  campaignSearchFilterVar,
  CampaignStatusFilterType,
  campaignStatusFilterVar,
  userDetailsVar,
} from "../../lib/cache";
import { useReactiveVar } from "@apollo/client";
import { MdClear } from "react-icons/md";
import { ActionLabel } from "../../components/actionLabel/ActionLabel";

export const CampaignHomeSearchFilter = (props: BoxProps) => {
  const currentUser = useReactiveVar(userDetailsVar);
  const statusFilter = useReactiveVar(campaignStatusFilterVar);
  const searchFilter = useReactiveVar(campaignSearchFilterVar);
  const [searchState, setSearchState] = useState(searchFilter);
  const statusFilterOptions: {
    label: string;
    value: CampaignStatusFilterType;
  }[] = [
    { label: "View all", value: "ALL" },
    ...Object.entries(CampaignStatusChoices).map(([key, value]) => ({
      label: key,
      value: value,
    })),
  ];
  const handleStatusFilterChange = ({
    value,
  }: {
    value: CampaignStatusFilterType;
  }) => {
    campaignStatusFilterVar(value);
  };
  const currentFilter = statusFilterOptions.find(
    ({ value }) => value === statusFilter
  );

  return (
    <Flex justifyContent={[null, null, "space-between", null, null]}
          flexDirection={["column", null, "row", null, null]}
          {...props}>
      <Box minW={"35%"}>
        <InputGroup>
          <InputLeftElement pointerEvents={"none"}>
            <Icon
              as={FiSearch}
              w={[3, null, null, 4, null]}
              h={[3, null, null, 4, null]}
            />
          </InputLeftElement>
          <Input
            bg={"white"}
            fontSize={["sm", null, null, "md", null]}
            placeholder={
              currentUser && currentUser.isStaff
                ? "Search campaigns"
                : "Search your campaigns"
            }
            value={searchState}
            _placeholder={{ color: "text.dark" }}
            onChange={(e) => {
              setSearchState(e.target.value);
              campaignSearchFilterVar(e.target.value);
            }}
            // onKeyPress={(e) => {
            //   if (e.key === "Enter") {
            //     campaignSearchFilterVar(searchState);
            //   }
            // }}
          />
          <InputRightElement>
            <Collapse in={!!searchState}>
              <IconButton
                aria-label={"clear-search-query"}
                variant={"unstyled"}
                pl={2}
                onClick={() => {
                  campaignSearchFilterVar("");
                  setSearchState("");
                }}
                icon={
                  <Icon
                    as={MdClear}
                    w={[3, null, null, 4, null]}
                    h={[3, null, null, 4, null]}
                  />
                }
              />
            </Collapse>
          </InputRightElement>
        </InputGroup>
      </Box>
      <ActionLabel label={"filter by"} mt={[2, null, 0, null, null]}>
        <Box
          minW={36}
          w={"100%"}
          // sx={{
          //   ".select-control": {
          //     borderColor: "white",
          //     "&:hover": {
          //       boxShadow: (theme) => `0 0 0 3px ${theme.colors.primary.light}`,
          //     },
          //   },
          // }}
        >
          <SelectField
            styles={{
              valueContainer: (base) => ({
                ...base,
                backgroundColor: "white",
              }),
              indicatorsContainer: (base) => ({
                ...base,
                backgroundColor: "white",
              }),
              menu: (base) => ({
                ...base,
                // marginTop: "0px",
                width: "100%",
                // borderWidth: "1px",
              }),
            }}
            isMulti={false}
            value={currentFilter}
            options={statusFilterOptions}
            onChange={handleStatusFilterChange}
          />
        </Box>
      </ActionLabel>
    </Flex>
  );
};
