/** The campaign summary page route has changed, however there are links
 * that still point to the old summary route. This component
 * serves to redirect to the new route
 */

import { Navigate, useLocation } from "react-router-dom";

export const CampaignSummaryHandleDeprecation = () => {
  const { search } = useLocation();
  const campaignId = new URLSearchParams(search).get("id");

  if (!campaignId) {
    return <Navigate to={"/404"} />;
  } else {
    return <Navigate to={`/campaigns/${campaignId}`} />;
  }
};
