import { SectionTitle } from "../../SectionTitle";
import { useReactiveVar } from "@apollo/client";
import { insightsMetricVar } from "../../../../lib/cache";
import {
  InsightMetricsEnum,
  SubCategoryInsightNode,
} from "../../../../graphql/generated";
import { MetricKey } from "../../../../../types";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { PieTooltipProps, ResponsivePie } from "@nivo/pie";
import { FaCircle } from "react-icons/fa";
import { NoData } from "../../NoData";
import { getUrlCountForMetric } from "./getUrlCountForMetric";
import { getMetricDisplay } from "../../../../utils/displayGetters";
import { HiddenLogo } from "../../HiddenLogo";
import { DownloadAsImage } from "../../DownloadAsImage";
import { useRef } from "react";

interface Props {
  subCategories: SubCategoryInsightNode[];
}

export const PieChart = ({ subCategories }: Props) => {
  const currentMetric = useReactiveVar(insightsMetricVar);
  const [pieData, other, total] = structurePieChartData(
    subCategories,
    currentMetric.toLowerCase() as MetricKey
  );

  const otherTotal = pieData.find((d) => d.id === "Other");
  const pieChartRef = useRef(null);
  return (
    <Box bg={"white"} borderRadius={"lg"} mt={8}>
      <DownloadAsImage reference={pieChartRef} name={"PieChart"}/>
      <Box ref={pieChartRef} pb={4}>
        <HiddenLogo chartName="PieChart"/>
        <SectionTitle
            title={`Subcategory distribution by ${getMetricDisplay(currentMetric)}`}
            pl={2}
          />
        <Box bg={"white"} borderRadius={"lg"} pr={5} pb={5}>
          {total ? (
            <>
              {/*<Flex justify={"flex-end"} p={5}>*/}
              {/*  DL*/}
              {/*</Flex>*/}
              <Grid templateColumns={"repeat(3, 1fr)"}>
                <GridItem colSpan={other.length > 1 ? 2 : 3}>
                  <Box h={"30vh"}>
                    <ResponsivePie
                      data={pieData}
                      colors={({ data: { color } }) => {
                        return color;
                      }}
                      enableArcLabels={false}
                      innerRadius={0.5}
                      activeOuterRadiusOffset={8}
                      arcLinkLabelsColor={{ from: "color" }}
                      arcLinkLabelsThickness={3}
                      padAngle={1.5}
                      cornerRadius={3}
                      tooltip={CustomPieToolTip}
                      theme={{
                        fontSize: 16,
                        labels: {
                          text: {
                            fontWeight: "bold",
                          },
                        },
                      }}
                      margin={{
                        top: 40,
                        left: 100,
                        right: 100,
                        bottom: 40,
                      }}
                    />
                  </Box>
                </GridItem>
                {other.length > 1 && (
                  <GridItem colSpan={1}>
                    <OtherCatsDisplay
                      other={other}
                      otherTotal={otherTotal}
                      currentMetric={currentMetric}
                    />
                  </GridItem>
                )}
              </Grid>
            </>
          ) : (
            <NoData
              message={`No ${getMetricDisplay(
                currentMetric
              )} for this date range.`}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const OtherCatsDisplay = ({
  other,
  otherTotal,
  currentMetric,
}: {
  other: PieChartDataType[];
  otherTotal?: PieChartDataType;
  currentMetric: string;
}) => {
  return (
    <Box px={3}>
      {otherTotal && (
        <Grid
          templateColumns={"repeat(8, 1fr)"}
          templateRows={"repeat(2, 1fr)"}
          fontWeight={"bold"}
          alignItems={"center"}
          gap={4}
        >
          <GridItem colSpan={4} alignItems={"center"} d={"flex"} rowStart={2}>
            <Icon as={FaCircle} w={7} h={7} fill={otherTotal.color} mr={2} />
            <Text fontSize={"14px"}>{otherTotal.id}</Text>
          </GridItem>
          <GridItem
            colSpan={2}
            rowStart={1}
            colStart={5}
            d={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Text
              fontSize={["8px", null, null, "11px", null]}
              color={"text.light"}
            >
              Distribution
            </Text>
          </GridItem>
          <GridItem colSpan={2} textAlign={"center"} rowStart={2}>
            <Text fontSize={["14px"]} overflowWrap={"break-word"}>{`${(
              otherTotal.value * 100
            ).toFixed(2)} %`}</Text>
          </GridItem>
          {currentMetric !== InsightMetricsEnum.Ctr && (
            <GridItem
              colSpan={2}
              rowStart={1}
              colStart={7}
              d={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text
                fontSize={["8px", null, null, "11px", null]}
                color={"text.light"}
              >
                Webpages
              </Text>
            </GridItem>
          )}
          {currentMetric !== InsightMetricsEnum.Ctr && (
            <GridItem colSpan={2} textAlign={"center"} rowStart={2}>
              <Text fontSize={["14px"]}>{otherTotal.urls}</Text>
            </GridItem>
          )}
        </Grid>
      )}
      <Box py={2}>
        {other.map(({ id, value, urls }, ix) => (
          <Grid
            templateColumns={"repeat(8, 1fr)"}
            key={id}
            borderBottomColor={"muted"}
            borderTopWidth={ix !== 0 ? "1px" : 0}
            py={2}
          >
            <GridItem colSpan={4}>
              <Text fontSize={"12px"}>{id}</Text>
            </GridItem>
            <GridItem colSpan={2} textAlign={"center"}>
              <Text fontSize={"12px"}>{`${(value * 100).toFixed(2)}%`}</Text>
            </GridItem>
            {currentMetric !== InsightMetricsEnum.Ctr && (
              <GridItem colSpan={2} textAlign={"center"}>
                <Text fontSize={"12px"}>{urls}</Text>
              </GridItem>
            )}
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

const CustomPieToolTip = ({
  datum: {
    label,
    value,
    data: { urls },
  },
}: PieTooltipProps<any>) => {
  return (
    <Flex
      borderRadius={"md"}
      position={"absolute"}
      top={"-50px"}
      flexDirection={"column"}
      bg={"text.dark"}
      color={"white"}
      p={3}
    >
      <Text whiteSpace={"nowrap"} fontSize={"13px"}>
        {label}
      </Text>
      <Text fontSize={"14px"} fontWeight={"bold"}>
        {(100 * value).toFixed(2)}%
      </Text>
      {urls > 0 && (
        <Text whiteSpace={"nowrap"} fontSize={"9px"}>
          {`${urls} ${urls > 1 ? "webpages" : "webpage"}`}
        </Text>
      )}
    </Flex>
  );
};

type PieChartDataType = {
  id: string;
  value: number;
  color: string;
  urls: number;
};
const structurePieChartData = (
  subCategories: SubCategoryInsightNode[],
  metric: MetricKey = "impressions"
): [PieChartDataType[], PieChartDataType[], number] => {
  // Get the maximum value
  const total = subCategories.reduce(
    (accumulator, node) => accumulator + node.insightValues[metric],
    0
  );
  const other: PieChartDataType[] = [];
  const data: PieChartDataType[] = [];
  let colorArray = getColorArray();

  subCategories.forEach((node) => {
    if (!colorArray.length) {
      colorArray = getColorArray();
    }
    if (node.insightValues[metric] > 0) {
      node.insightValues[metric] / total < 0.1
        ? other.push({
          id: node.subCategory,
          value: node.insightValues[metric] / total,
          color: "#123",
          urls: getUrlCountForMetric(metric, node.insightValues),
        })
        : data.push({
          id: node.subCategory,
          value: node.insightValues[metric] / total,
          color: colorArray.pop() ?? "#123",
          urls: getUrlCountForMetric(metric, node.insightValues),
        });
    }
  });
  const otherColor: string =
    colorArray.pop() || getColorArray().pop() || "#123";
  if (other.length > 1) {
    data.push({
      id: "Other",
      value: other.reduce((accumulator, { value }) => accumulator + value, 0),
      color: otherColor,
      urls: other.reduce((accumulator, { urls }) => accumulator + urls, 0),
    });
  } else if (other.length === 1) {
    data.push({ ...other[0], color: otherColor });
  }
  const otherSorted = other.concat().sort(sortOther()).reverse();
  return [data, otherSorted, total];
};

const getColorArray = () => {
  const chakraTheme = useTheme();
  return [
    chakraTheme.colors.dataColors.l2 as string,
    chakraTheme.colors.dataColors.l4 as string,
    chakraTheme.colors.secondary as string,
    chakraTheme.colors.accent as string,
    chakraTheme.colors.dataColors.l6 as string,
  ];
};

const sortOther = () => {
  return (a: PieChartDataType, b: PieChartDataType) =>
    a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
};
