import { Box, Checkbox, Stack, Text } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useReducer, useState } from "react";
import { FixedSizeList } from "react-window";

export interface CheckboxOptionType {
    id: number,
    label: string,
    parents: string[],
    children: string[],
    isChecked?: boolean,
    isIndeterminate?: boolean,
}

interface CheckboxV3Props {
    options: CheckboxOptionType[]
    setSelectedOptions: Dispatch<SetStateAction<CheckboxOptionType[]>>
    removeOptions: CheckboxOptionType[]
    setRemoveOptions: Dispatch<SetStateAction<CheckboxOptionType[]>>
    searchInput?: string
    handleChildren?: boolean // Automatically handle child options when parent is changed
    handleParents?: boolean // Automatically handle parent options when children are changed
}

export const CheckboxTreeV3 = ({
    options,
    setSelectedOptions,
    removeOptions,
    setRemoveOptions,
    searchInput,
    handleChildren=true,
    handleParents=true,
}: CheckboxV3Props) => {

    const [completeListState, setCompleteListState] = useState<CheckboxOptionType[]>(options);
    const [refinedListState, setRefinedListState] = useState<CheckboxOptionType[]>([]);
    const [toggle, setToggle] = useState(false);

    // Filter out all options which are checked
    useEffect(() => {
        setSelectedOptions(completeListState.filter((checkboxOption) => checkboxOption.isChecked === true));
    }, [toggle])

    // Using search input to filter only relevant options
    useEffect(() => {
        setRefinedListState(completeListState);

        if (searchInput) {
            const refined = completeListState.filter((checkboxItem) => {
                const pathLabel = constructPathLabel(checkboxItem, completeListState);

                if (pathLabel.toLowerCase().includes(searchInput.toLowerCase())) {
                    return true;
                }
            });
            setRefinedListState(refined);
        }
    }, [searchInput])

    // Handling removing options when remove tag is selected or clear button
    useEffect(() => {
        // const newCompleteList = completeListState;
        const tempReplacerOptions = completeListState.map((option) => Object.assign({}, option));
        let removeReplacer: CheckboxOptionType[] = [];

        removeOptions.forEach((option) => {
            const removeOptionIndex = getIndex(option.id.toString(), completeListState);
            // newCompleteList[removeOptionIndex].isChecked = false;
            removeReplacer = handleOptionChecked(
                false,
                tempReplacerOptions,
                option,
                removeOptionIndex,
                completeListState,
                handleChildren,
                handleParents
            )
            // Causes issues when many items at once
            // setRemoveOptions(removeOptions.filter(removeOption => removeOption.id !== option.id));
        });
        if (removeReplacer.length) {
            setCompleteListState(removeReplacer);
            setRemoveOptions([]);
        }
        setToggle(prev => !prev)
    }, [removeOptions])

    return(
        <>
            <FixedSizeList
                height={refinedListState.length === 0 ? 0 : 340}
                itemCount={refinedListState.length}
                itemSize={55}
                width={"100%"}
            >
                {({ index, style }) => {
                    const [, forceUpdate] = useReducer(x => x + 1, 0);

                    const option = refinedListState[index];
                    const optionIndex = getIndex(option.id.toString(), completeListState);

                    const tempOptions = completeListState.map((option) => Object.assign({}, option));

                    // Preparing label
                    const formattedLabel = constructPathLabel(option, completeListState); // formatLabel(label);
                    const labelBeginning = formattedLabel.substring(0, formattedLabel.indexOf(":") + 1);
                    const labelEnding = formattedLabel.substring(formattedLabel.indexOf(":") + 1);

                    return (
                        <Box
                            style={style}
                            key={index}
                        >
                            <Stack
                                spacing={0}
                                direction={"row"}
                                _hover={{
                                    backgroundColor: "primary.light"
                                }}
                            >
                                <Checkbox
                                    width={"100%"}
                                    p={2}
                                    isIndeterminate={completeListState[optionIndex]?.isChecked ? false : completeListState[optionIndex]?.isIndeterminate}
                                    isChecked={completeListState[optionIndex]?.isChecked}
                                    onChange={(e) => {
                                        const replacer = handleOptionChecked(
                                            e.target.checked,
                                            tempOptions,
                                            option,
                                            optionIndex,
                                            completeListState,
                                            handleChildren,
                                            handleParents
                                        )

                                        setCompleteListState(replacer);
                                        forceUpdate();
                                        setToggle(prev => !prev);

                                    }}
                                >
                                    {labelBeginning && <Text as={"span"} color={"muted"}>{labelBeginning}</Text>}
                                    <Text as={"span"}>{labelEnding}</Text>
                                </Checkbox>
                            </Stack>
                        </Box>
                    )
                }}
            </FixedSizeList>
        </>
    )
}

const formatLabel = (label: string) => {
    const position = label.lastIndexOf(">");
    if (position === -1) {
        return label;
    }
    return label.substring(0, position - 1) + ":" + label.substring(position + 1);
}

const constructPathLabel = (checkboxItem: CheckboxOptionType, fullList: CheckboxOptionType[]) => {
    let pathLabel = "";

    if (checkboxItem.parents) {
        checkboxItem.parents.forEach((parentId) => {
            const parentIndex = getIndex(parentId, fullList);
            pathLabel = fullList[parentIndex].label + " > " + pathLabel;
        })
    }

    pathLabel += checkboxItem.label;

    return formatLabel(pathLabel);
}

const getIndex = (id: string, completeList: CheckboxOptionType[]) => {
    return completeList.findIndex(listOption => listOption.id.toString() === id);
}

const handleOptionChecked = (
    isChecked: boolean,
    tempOptions: CheckboxOptionType[],
    option: CheckboxOptionType,
    optionIndex: number,
    completeListState: CheckboxOptionType[],
    handleChildren: boolean,
    handleParents: boolean,
) => {
    // Handles current option
    // newOption["isChecked"] = isChecked; // e.target.checked;
    tempOptions[optionIndex].isChecked = isChecked;
    if (!isChecked) {
        tempOptions[optionIndex].isIndeterminate = isChecked;
    }

    // Handles all child options
    if (option.children.length > 0 && handleChildren) {
        option.children.forEach((childId) => {
            const childIndex = getIndex(childId, completeListState);
            tempOptions[childIndex] = {...tempOptions[childIndex], isChecked: isChecked};
        })
    }

    // Handles parent options
    if (option.parents.length > 0 && handleParents) {

        const parentIds = option.parents.slice().reverse();
        parentIds.forEach((parentId) => {
            const siblingChecks: boolean[] = [];
            const parentIndex = getIndex(parentId, completeListState);

            const siblingIds = completeListState[parentIndex].children;
            const siblingIndexes = siblingIds.map((siblingId) => getIndex(siblingId, completeListState));

            siblingIndexes.forEach((siblingIndex) => siblingChecks.push(
                tempOptions[siblingIndex] && tempOptions[siblingIndex].isChecked === true ? true : false
            ));

            // When all siblings are all the same (checked or not checked) parent follows
            // When siblings differ (only some checked) parent is half ticked
            if (siblingChecks.every(check => check === siblingChecks[0])) {
                tempOptions[parentIndex].isIndeterminate = false;
                tempOptions[parentIndex].isChecked = siblingChecks[0];
            } else {
                tempOptions[parentIndex].isIndeterminate = true;
                tempOptions[parentIndex].isChecked = false;
            }
        })
    }

    return tempOptions;
}
