import { createElement, useCallback, useMemo, MouseEvent, useState, useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { insightsMetricVar } from "../../../../lib/cache";
import { SectionTitle } from "../../SectionTitle";
import { DateInsightNode, DateRangeType } from "../../../../graphql/generated";
import { getDateRange } from "../../../../utils/getDateRange";
import { Box, useTheme, Text } from "@chakra-ui/react";
import { BarItemProps, BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { CustomChartDateTick } from "../../CustomChartDateTick";
import {
  formatNumberDisplay,
  getMetricDisplay,
} from "../../../../utils/displayGetters";
import { animated } from "react-spring";
import { MetricKey } from "../../../../../types";
import { NoData } from "../../NoData";
import { useTooltip } from "@nivo/tooltip";
import { format } from "date-fns";
import { getUrlCountForMetric } from "./getUrlCountForMetric";
import { useMetricSuffix } from "../../hooks";
import { HiddenLogo } from "../../HiddenLogo";
import { DownloadAsImage } from "../../DownloadAsImage";

interface Props {
  dates: DateInsightNode[];
  dateRange: DateRangeType;
}

export const BarChart = ({ dates, dateRange }: Props) => {
  const currentMetric = useReactiveVar(insightsMetricVar);
  const chakraTheme = useTheme();
  const [barChartData, hasDataToRender] = structureBarChartData(
    dates,
    dateRange,
    currentMetric.toLowerCase() as MetricKey
  );
  const suffix = useMetricSuffix();
  const barChartRef = useRef(null);

  return (
    <Box bg={"white"} borderRadius={"lg"}>
      <DownloadAsImage reference={barChartRef} name={"BarChart"} />
      <Box ref={barChartRef} pb={8}>
        <HiddenLogo chartName="BarChart" />
        <SectionTitle
          title={`Daily ${getMetricDisplay(currentMetric)} summary`}
          pl={2}
        />
        <Box bg={"white"} borderRadius={"lg"}>
          {hasDataToRender ? (
            <>
              {/*<Flex justify={"flex-end"} p={5}>*/}
              {/*  DL*/}
              {/*</Flex>*/}
              <Box h={"30vh"}>
                <ResponsiveBar
                  valueFormat={(value) => formatNumberDisplay(value, suffix)}
                  barComponent={CustomBarComponent}
                  axisBottom={{
                    renderTick: CustomChartDateTick,
                    legend: "date",
                    legendPosition: "middle",
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    legend: `${getMetricDisplay(currentMetric)}`,
                    legendPosition: "middle",
                    legendOffset: -60,
                    tickSize: 0,
                    format: (value: number) => formatNumberDisplay(value, suffix),
                  }}
                  tooltip={CustomBarToolTip}
                  data={barChartData}
                  indexBy={"date"}
                  keys={["metric"]}
                  padding={0.3}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 80,
                    bottom: 60,
                  }}
                  enableLabel={false}
                  theme={{
                    axis: {
                      legend: {
                        text: {
                          fontSize: 16,
                          fill: chakraTheme.colors.text.light,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </>
          ) : (
            <NoData
              message={`No ${getMetricDisplay(
                currentMetric
              )} for this date range.`}
            />
          )}
        </Box>
      </Box>
    </ Box>
  );
};

const CustomBarComponent = ({
  bar,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  style,
}: BarItemProps<any>) => {
  const chakraTheme = useTheme();
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  const { x, y, width, height, data } = bar;
  const [onBar, setOnBar] = useState(false);
  const renderTooltip = useMemo(
    () => () => createElement(tooltip, { ...bar, ...data }),
    [tooltip, bar, data]
  );
  const handleToolTip = useCallback(
    (e: MouseEvent<SVGRectElement>) => showTooltipFromEvent(renderTooltip(), e),
    [showTooltipFromEvent, renderTooltip]
  );
  const handleMouseEnter = useCallback(
    (e: MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(data, e);
      setOnBar(true);
      showTooltipFromEvent(renderTooltip(), e);
    },
    [data, onMouseEnter, showTooltipFromEvent, renderTooltip]
  );
  const handleMouseLeave = useCallback(
    (e: MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(data, e);
      setOnBar(false);
      hideTooltip();
    },
    [data, hideTooltip, onMouseLeave]
  );

  return (
    <>
      <defs>
        <clipPath id={`round-corner${x}`}>
          <rect x={x} y={y} width={width} height={height + 5} rx="5" ry="5" />
        </clipPath>
      </defs>
      <animated.rect
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleToolTip}
        onMouseLeave={handleMouseLeave}
        style={{
          transition: "fill 0.25s ease-in-out",
        }}
        width={style.width as any}
        height={style.height as any}
        x={x}
        y={y}
        clipPath={`url(#round-corner${x})`}
        fill={
          onBar
            ? chakraTheme.colors.dataColors.l6
            : chakraTheme.colors.dataColors.l3
        }
      />
    </>
  );
};
const CustomBarToolTip = ({
  data: { urls },
  formattedValue,
  indexValue,
}: BarTooltipProps<any>) => {
  return (
    <Box
      borderRadius={"md"}
      position={"absolute"}
      top={"-50px"}
      flexDirection={"column"}
      bg={"text.dark"}
      color={"white"}
      p={3}
    >
      <Text fontSize={"9px"} whiteSpace={"nowrap"}>
        {format(new Date(indexValue), "dd MMM yyyy")}
      </Text>
      <Text fontSize={"14px"} fontWeight={"bold"}>
        {formattedValue}
      </Text>
      {urls && urls > 0 && (
        <Text fontSize={"9px"} fontWeight={"bold"} whiteSpace={"nowrap"}>
          {`${urls} ${urls > 1 ? "webpages" : "webpage"}`}
        </Text>
      )}
    </Box>
  );
};
type BarChartDataType = {
  date: string;
  metric: number;
  urls: number;
};
const structureBarChartData = (
  dates: DateInsightNode[],
  dateRange: DateRangeType,
  currentMetric: MetricKey
): [BarChartDataType[], boolean] => {
  const { startDate, endDate } = dateRange;
  // Sometimes the data skips dates, so we generate our own serial dates for the
  // date range
  const dateValues = getDateRange(startDate, endDate);
  let hasDataToRender = false;
  const data: BarChartDataType[] = dateValues.map((dateValue) => {
    const dateNode = dates.find(({ date }) => date === dateValue);
    if (dateNode) {
      if (dateNode.insightValues[currentMetric] > 0) hasDataToRender = true;
      return {
        date: dateValue,
        metric: dateNode.insightValues[currentMetric],
        urls: getUrlCountForMetric(currentMetric, dateNode.insightValues),
      };
    } else {
      return { date: dateValue, metric: 0, urls: 0 };
    }
  });
  return [data, hasDataToRender];
};
