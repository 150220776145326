import { useMutation } from "@apollo/client";
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react"
import { Field, FieldProps, Form, Formik } from "formik";
import { useRef } from "react";
import { useCampaignInfo } from ".";
import { DatePicker } from "../../components/datePicker/DatePicker"
import { FormFieldLabel } from "../../components/formFieldLabel/FormFieldLabel";
import { FormInputField } from "../../components/formInputField/FormInputField";
import { UPDATE_CAMPAIGN } from "../../graphql/operations/campaignOperations/operations";

enum modalOptionsEnum {
    endDate = "End Date",
    estimatedIllumaBudget = "Estimated Illuma Budget",
    estimatedIllumaImpressions = "Estimated Illuma Impressions",
    averageCpm = "Average CPM"
}

interface EditModalProps {
    isOpen: boolean,
    onClose: () => void,
    modalOption: string,
}

export const EditModal = ({isOpen, onClose, modalOption}: EditModalProps) => {

    const { campaign: { id, startDate } } = useCampaignInfo();
    const initialRef = useRef(null);

    const [updateCampaign, { loading }] = useMutation(UPDATE_CAMPAIGN, {
        //refetchQueries: [GET_CAMPAIGN_INFO],
      });

    const validateField = (value: string) => {
        let error;
        switch (modalOption) {
            case modalOptionsEnum.estimatedIllumaBudget:
            case modalOptionsEnum.averageCpm:
                !/^[$£€][+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$/.test(value) && (error = "Incorrect format");
                break;
            case modalOptionsEnum.estimatedIllumaImpressions:
                !/^([1-9][0-9]{0,8}|100000000)$/.test(value) && (error = "Incorrect format")
                break;
            default:
                return;
        }
        return error;
    }

    const prepareMutationVariable = (values: {[key: string]: string}, modalOption: string) => {
        switch (modalOption) {
            case modalOptionsEnum.endDate:
                return { endDate: values[modalOption] }
            case modalOptionsEnum.estimatedIllumaBudget:
                return { estimatedIllumaBudget: values[modalOption] }
            case modalOptionsEnum.estimatedIllumaImpressions:
                return { estimatedIllumaImpressions: Number(values[modalOption]) }
            case modalOptionsEnum.averageCpm:
                return { averageCpm: values[modalOption] }
        }
    }

    const placeholderText: {[key in modalOptionsEnum]: string} = {
        [modalOptionsEnum.endDate]: "Choose",
        [modalOptionsEnum.estimatedIllumaBudget]: "e.g. $5000",
        [modalOptionsEnum.estimatedIllumaImpressions]: "e.g. 5000",
        [modalOptionsEnum.averageCpm]: "e.g. $5, £2.50 etc"
    }

    // Options are End Date, Estimated Illuma Budget, Estimated Illuma Impressions, Average CPM
    return (
        <Formik
            initialValues={{}}
            onSubmit={(values: {[key: string]: string}, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    updateCampaign({
                        variables: {
                            id: id,
                            updateFields: prepareMutationVariable(values, modalOption)
                        }
                    });
                    setSubmitting(false);
                    resetForm();
                    onClose();
                }, 400);
            }}
        >
        {(formik) => {
            return (
                <Modal
                    isOpen={isOpen}
                    initialFocusRef={initialRef}
                    onClose={() => {
                        onClose();
                        formik.resetForm();
                    }}
                >
                    <ModalOverlay />
                    <ModalContent>
                            <ModalHeader>Change {modalOption}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <Text>Are you sure you want to change the {modalOption}?</Text>
                                    {modalOption === "End Date" &&
                                        <>
                                            <Field name={modalOption}>
                                                {({ field, form }: FieldProps) => {
                                                    return (
                                                        <FormFieldLabel
                                                            label={modalOption}
                                                            isRequired={false}
                                                            isInvalid={
                                                                !!(form.errors[modalOption] && !!form.touched[modalOption])
                                                            }
                                                            errorMessage={form.errors[modalOption]?.toString()}
                                                        >
                                                            <DatePicker
                                                                inputProps={{
                                                                    field,
                                                                    form,
                                                                }}
                                                                placeholder={placeholderText[modalOption]}
                                                                dateLimits={{ start: new Date(startDate) }}
                                                            />
                                                        </FormFieldLabel>
                                                    );
                                                }}
                                            </Field>
                                        </>
                                    }

                                    {(modalOption === "Average CPM"
                                        || modalOption === "Estimated Illuma Budget"
                                        || modalOption === "Estimated Illuma Impressions") &&
                                        <>
                                            <Field
                                                name={modalOption}
                                                validate={validateField}
                                            >
                                                {({ field, form }: FieldProps) => (
                                                    <FormInputField
                                                        inputFieldProps={field}
                                                        fieldType={"text"}
                                                        isRequired={false}
                                                        label={modalOption}
                                                        isInvalid={!!(form.errors[modalOption] && !!form.touched[modalOption])}
                                                        placeholder={placeholderText[modalOption]}
                                                        errorMessage={form.errors[modalOption]?.toString()}
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    }
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    variant={"primary"}
                                    ref={initialRef}
                                    mr={3}
                                    onClick={() => {
                                        onClose();
                                        formik.resetForm();
                                    }}
                                >
                                    Close
                                </Button>
                                <Button
                                    type={"submit"}
                                    variant={"primary"}
                                    disabled={!(formik.isValid && formik.dirty)}
                                    isLoading={formik.isSubmitting || loading}
                                    onClick={() => {
                                        formik.submitForm();
                                    }}
                                >
                                    Save
                                </Button>
                            </ModalFooter>
                    </ModalContent>
                </Modal>
            )
        }}
        </Formik>
    )
}
