import { useState } from "react";
import {
  Form,
  Formik,
  Field,
  FieldProps,
  FormikValues,
  FormikHelpers,
} from "formik";
import { LoginFormDataClass } from "./loginFormSchema";
import { FormInputField } from "../../../components/formInputField/FormInputField";
import {
  HStack,
  Button,
  Center,
  Checkbox,
  Collapse,
  Icon,
  Text,
  Box,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { AccountsErrorCodes } from "../../../graphql/generated";
import { Link } from "react-router-dom";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export interface LoginFormProps {
  onSubmit: (
    email: string,
    password: string,
    remember?: boolean
  ) => Promise<any>;
}

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const navigate = useNavigate();
  const handleSubmit = (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    // SignIn hook sets the reactive var if sign in is successful.
    const { email, password, remember } = values;
    onSubmit(email, password, remember)
      .then(() => {
        return navigate("/", { replace: true });
      })
      .catch((err) => {
        if (
          err.graphQLErrors[0]?.extensions.code ===
          AccountsErrorCodes.InvalidCredentials
        ) {
          setLoginErrorMessage(
            "The email address or password you entered does not match our records. Remember, your password is case sensitive."
          );
        } else {
          setLoginErrorMessage("There seems to be an issue. Please try again.");
        }
        actions.setSubmitting(false);
        actions.resetForm();
      });
  };
  const loginFormData = new LoginFormDataClass(["inputFormat"]);
  const validationSchema = loginFormData.getYupSchema();
  const initialValues = loginFormData.getDefaultValues();
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(
    null
  );
  const { email, password, remember } = loginFormData.fields;
  return (
    <>
      <Box my={6}>
        <Collapse in={!!loginErrorMessage} animateOpacity={true}>
          <HStack
            color={"error"}
            bg={"rgb(252, 234, 234)"}
            borderRadius={"lg"}
            p={5}
            spacing={6}
          >
            <Icon as={BsExclamationTriangleFill} w={10} h={10} />
            <Text fontSize={"13px"} fontWeight={"bold"}>
              {loginErrorMessage}
            </Text>
          </HStack>
        </Collapse>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            {Object.entries({ email, password }).map(([entry, values], ix) => (
              <Field name={entry} key={`${entry}-${ix}`}>
                {({ field, form }: FieldProps) => (
                  <FormInputField
                    mt={2}
                    fieldType={values.inputFormat as "email" | "password"}
                    isInvalid={!!(form.errors[entry] && form.touched[entry])}
                    label={values.label}
                    placeholder={values.placeholder}
                    isRequired={values.isRequired}
                    inputFieldProps={field}
                    errorMessage={form.errors[entry]?.toString()}
                  />
                )}
              </Field>
            ))}
            <Flex justify={"flex-end"}>
              <Button
                as={Link}
                to={"/password-reset"}
                fontWeight={"medium"}
                fontSize={"12px"}
                textAlign={"right"}
                variant={"link"}
              >
                Forgot your password?
              </Button>
            </Flex>
            <VStack align={"flex-start"}>
              {/* OLD METHOD - form did not reset correctly on error
              <Field
                name={"remember"}
                as={Checkbox}
                mt={4}
                defaultChecked={remember.default}
              >
                <Text fontSize={"14px"}>{remember.label}</Text>
              </Field>
              <Field
                name={"disclaimer"}
                as={Checkbox}
                mt={4}
                defaultChecked={disclaimer.default}
              >
                <Text fontSize={"14px"}>
                  By checking this box, you are agreeing to our{" "}
                  <Button
                    as={Link}
                    to={"/terms-of-service"}
                    fontWeight={"medium"}
                    fontSize={"inherit"}
                    variant={"link"}
                  >
                    terms of service.
                  </Button>
                </Text>
              </Field> */}
              <Checkbox
                name={"remember"}
                isChecked={formik.values.remember}
                onChange={formik.handleChange}
              >
                <Text fontSize={"14px"}>{remember.label}</Text>
              </Checkbox>
              <Checkbox
                name={"disclaimer"}
                isChecked={formik.values.disclaimer}
                onChange={formik.handleChange}
              >
                <Text fontSize={"14px"}>
                  By checking this box, you are agreeing to our{" "}
                  <Button
                    as={Link}
                    to={"/terms-of-service"}
                    fontWeight={"medium"}
                    fontSize={"inherit"}
                    variant={"link"}
                  >
                    terms of service.
                  </Button>
                </Text>
              </Checkbox>
            </VStack>

            <Center>
              <Button
                type={"submit"}
                data-testid={"submit-btn"}
                variant={"primary"}
                mt={8}
                mx={"auto"}
                isLoading={formik.isSubmitting}
                disabled={!formik.dirty || !formik.isValid}
              >
                Login
              </Button>
            </Center>
          </Form>
        )}
      </Formik>
    </>
  );
};
