import { CampaignHomeHeader } from "./CampaignHomeHeader";
import { CampaignHomeSearchFilter } from "./CampaignHomeSearchFilter";
import { CampaignHomeBody } from "./CampaignHomeBody";

export const CampaignHomePage = () => {

  return (
    <>
      <CampaignHomeHeader />
      <CampaignHomeSearchFilter />
      <CampaignHomeBody />
    </>
  );
};
