import { useReactiveVar } from "@apollo/client";
import { Navigate } from "react-router-dom";
import { ComponentLoading } from "../../components/loading/ComponentLoading";
import { userDetailsVar } from "../../lib/cache";
import { SegmentHomeBody } from "./SegmentHomeBody"
import { SegmentHomeHeader } from "./SegmentHomeHeader"
import { SegmentHomeSearchFilter } from "./SegmentHomeSearchFilter"

export const SegmentHomePage = () => {

    const currentUser = useReactiveVar(userDetailsVar);

    if (!currentUser) {
        return <ComponentLoading text={"Loading page..."} />;
    }

    if (!(currentUser?.isStaff || currentUser?.agency.hitlPermission?.isPermitted)) {
        return <Navigate to={"/"} />

    }

    return (
        <>
            <SegmentHomeHeader />
            <SegmentHomeSearchFilter />
            <SegmentHomeBody />
        </>
    )
}
