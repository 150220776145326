import { CampaignForm } from "./campaignForm";
import { Box } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { GET_PRECISION_CATEGORIES_OPTIONS } from "../../graphql/operations/campaignOperations/operations";
import { PrecisionCategoriesOptionsType } from "../../graphql/generated";
import { createContext, useContext } from "react";
import { Navigate } from "react-router-dom";
import { ComponentLoading } from "../../components/loading/ComponentLoading";

interface IPrecisionCategories {
  precisionCategoriesOptions: PrecisionCategoriesOptionsType[]
}

const precisionCategoriesContext = createContext({} as IPrecisionCategories)

export const usePrecisionCategoriesOptions = () => {
  return useContext(precisionCategoriesContext)
}

export const CampaignSetupPage = () => {

  const { data, error, loading } = useQuery(
    GET_PRECISION_CATEGORIES_OPTIONS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  if (loading) {
    return <ComponentLoading text={"Loading campaign form..."} />;
  }

  if (error) {
    return <>ERROR</>;
  }

  const precisionCategoriesOptions: PrecisionCategoriesOptionsType[] = data.precisionCategoriesOptions;

  if (!precisionCategoriesOptions) {
    return <Navigate to={"/404"} />;
  }

  return (
    <precisionCategoriesContext.Provider value={{precisionCategoriesOptions: precisionCategoriesOptions}}>
      <Box mb={20}>
        <CampaignForm />
      </Box>
    </precisionCategoriesContext.Provider>
  )
};
