import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Form, Formik, Field, FieldProps } from "formik";
import { FormSection } from "../../../components/containers/formSection/FormSection";
import { BasicDetails } from "./campaignDetails/BasicDetails";
import { DSPDetails } from "./campaignDetails/DSPDetails";
import { CampaignFormDataClass } from "./campaignFormSchema";
import {
  CampaignCreateInput,
  DspChoices,
  ProductCategoryChoices,
} from "../../../graphql/generated";
import { Checklist } from "./campaignChecklist/Checklist";
import { ListFiles, ListFilesType } from "./campaignChecklist/ListFiles";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { CREATE_CAMPAIGN } from "../../../graphql/operations/campaignOperations/operations";
import { useMutation, useReactiveVar } from "@apollo/client";
import { userDetailsVar } from "../../../lib/cache";
import { FormSubSectionHeader } from "../../../components/formSubSectionHeader/FormSubSectionHeader";
import { FormInputField } from "../../../components/formInputField/FormInputField";
import { BsExclamationTriangleFill } from "react-icons/bs";


export const CampaignForm = () => {
  const [dspState, setDspState] = useState<string>(DspChoices.Xandr);
  const navigate = useNavigate();
  const [createCampaign] = useMutation(CREATE_CAMPAIGN);
  const currentUser = useReactiveVar(userDetailsVar);
  const [campaignFormData, setCampaignFormData] = useState(
    new CampaignFormDataClass(dspState, currentUser?.isStaff)
  );
  const [listFiles, setListFiles] = useState<ListFilesType>({} as ListFilesType);
  const { campaignOwnerEmail } = campaignFormData.getUserDetailsFields();

  useEffect(() => {
    setCampaignFormData(
      new CampaignFormDataClass(dspState, currentUser?.isStaff)
    );
  }, [dspState, currentUser]);
  return (
    <>
      <Formik
        initialValues={campaignFormData.getDefaultValues()}
        validationSchema={campaignFormData.getYupSchema()}
        onSubmit={(values, { setSubmitting, setErrors, setStatus }) => {
          // ToDo: Check for inclusion and exclusion list uploads before submitting
          const campaignDetails: CampaignCreateInput =
            prepareCampaignFormInput(values);
          createCampaign({ variables: { campaignDetails, ...listFiles } })
            .then((res) => {
              navigate(`/campaign-summary?id=${res.data.campaignCreate.id}`);
            })
            .catch((err) => {
              err.graphQLErrors.forEach(
                (e: {
                  message: string;
                  extensions?: Record<string, string>;
                }) => {
                  if (e.extensions) {
                    setErrors(e.extensions);
                    setStatus("Your form contains errors.");
                  } else {
                    setStatus(e.message);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }
                  setSubmitting(false);
                }
              );
            });
          setStatus("");
        }}
      >
        {(formik) => {
          return (
            <>
              <Collapse in={!!formik.status} animateOpacity={true}>
                <Center>
                  <HStack
                    color={"error"}
                    bg={"rgb(252, 234, 234)"}
                    borderRadius={"lg"}
                    p={5}
                    spacing={6}
                    mb={4}
                  >
                    <Icon as={BsExclamationTriangleFill} w={10} h={10} />
                    <Text fontSize={"16px"} fontWeight={"bold"}>
                      {formik.status}
                    </Text>
                  </HStack>
                </Center>
              </Collapse>

              <Collapse in={currentUser?.isStaff} unmountOnExit={true}>
                <Box
                  p={8}
                  bg={"white"}
                  borderColor={"accent"}
                  borderWidth={2}
                  borderRadius={"md"}
                  mb={3}
                  maxW={["100%", null, null, "50%", null]}
                >
                  <FormSubSectionHeader
                    label={"User Details"}
                    toolTip={"Who does this campaign belong to?"}
                  />
                  <Field name={"campaignOwnerEmail"}>
                    {({ field, form }: FieldProps) => (
                      <FormInputField
                        mt={0}
                        label={campaignOwnerEmail.label}
                        fieldType={
                          campaignOwnerEmail.inputFormat as
                          | "email"
                          | "text"
                          | "password"
                        }
                        isInvalid={
                          !!(
                            form.errors["campaignOwnerEmail"] &&
                            form.touched["campaignOwnerEmail"]
                          )
                        }
                        isRequired={campaignOwnerEmail.isRequired}
                        placeholder={campaignOwnerEmail.placeholder}
                        inputFieldProps={field}
                        errorMessage={form.errors[
                          "campaignOwnerEmail"
                        ]?.toString()}
                      />
                    )}
                  </Field>
                </Box>
              </Collapse>
              <FormSection title={"Campaign details"} index={1}>
                <Form>
                  <Stack
                    spacing={5}
                    alignItems={"start"}
                    direction={["column", null, null, "row", null]}
                  >
                    <BasicDetails
                      fields={campaignFormData.getBasicDetails(dspState)}
                      formik={formik}
                      currentDsp={dspState}
                      setDspState={setDspState}
                      minW={"50%"}
                      w={"100%"}
                      defaultValues={campaignFormData.getDefaultValues()}
                    />
                    <DSPDetails
                      key={"dspDetails"}
                      currentDsp={dspState}
                      formik={formik}
                      fields={campaignFormData.getDspDetails(dspState)}
                      minW={"50%"}
                      w={"100%"}
                    />
                  </Stack>
                </Form>
              </FormSection>
              <FormSection title={"Campaign checklist"} index={2} mt={8}>
                <Form>
                  <Stack
                    spacing={5}
                    alignItems={"start"}
                    direction={["column", null, null, "row", null]}
                  >
                    <Checklist
                      fields={campaignFormData.getCampaignChecklist()}
                      helpLink={campaignFormData.getHelpLink(dspState)}
                      minW={"50%"}
                      w={"100%"}
                    />
                    <ListFiles
                      minW={"50%"}
                      w={"100%"}
                      listFiles={listFiles}
                      setListFiles={setListFiles}
                    />
                  </Stack>
                </Form>
              </FormSection>
              <Flex
                bg={"text.dark"}
                justifyContent={["flex-end", null, null, null, "center"]}
                py={3}
                position={"fixed"}
                bottom={0}
                left={0}
                width={"100%"}
                pr={5}
              >
                {formik.dirty && !formik.isSubmitting ? (
                  <Popover placement={"top-start"}>
                    <PopoverTrigger>
                      <Button variant={"secondary"} mr={3}>
                        Cancel
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      bg={"text.dark"}
                      color={"white"}
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      <PopoverCloseButton />
                      <PopoverHeader fontWeight={"bold"} fontSize={"18px"}>
                        Form Partially Filled
                      </PopoverHeader>
                      <PopoverBody>
                        Are you sure you want to discard the data entered so
                        far?
                      </PopoverBody>
                      <PopoverFooter d={"flex"} justifyContent={"flex-end"}>
                        <Button
                          onClick={() => navigate("/")}
                          variant={"primary"}
                          bg={"red.500"}
                          color={"white"}
                          size={"sm"}
                        >
                          Yes
                        </Button>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                ) : (
                  <Button
                    as={RouterLink}
                    mr={3}
                    to={"/campaigns"}
                    variant={"secondary"}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  type={"submit"}
                  variant={"primary"}
                  disabled={!(formik.isValid && formik.dirty)}
                  isLoading={formik.isSubmitting}
                  onClick={() => formik.submitForm()}
                >
                  Save
                </Button>
              </Flex>
            </>
          );
        }}
      </Formik>
    </>
  );
};

function prepareCampaignFormInput(data: any) {
  /**
   * The server expects the campaign create mutation to be in a particular format.
   * This function removes invalid client side parameters, and formats the following
   * fields to match teh server expectations.
   *
   * kpis, remove the dummy kpi.
   * illumaProduct, format to match list expected by server
   * productSelected, macroApplied, reportsCreated, which just check that
   * the campaign form has been filled properly
   */
  const {
    illumaCore,
    illumaCompass,
    illumaSmart,
    illumaSmartCtv,
    illumaPrecision,
    illumaPrecisionCtv,
    kpis,
    dsp,
    dspMemberId,
    estimatedIllumaImpressions,
    ...dataToSubmit
  } = data;

  dataToSubmit.productCategories = [];

  if (illumaCore) {
    dataToSubmit.productCategories.push({
      productCategory: ProductCategoryChoices.Reacts,
      insightCategories: [],
    });
  }

  if (illumaCompass) {
    dataToSubmit.productCategories.push({
      productCategory: ProductCategoryChoices.Compass,
      insightCategories: [],
    });
  }

  if (illumaSmart && illumaSmart.length) {
    dataToSubmit.productCategories.push({
      productCategory: ProductCategoryChoices.Smart,
      insightCategories: illumaSmart,
    });
  }

  if (illumaSmartCtv && illumaSmartCtv.length) {
    dataToSubmit.productCategories.push({
      productCategory: ProductCategoryChoices.SmartCtv,
      insightCategories: illumaSmartCtv,
    });
  }

  if (illumaPrecision && illumaPrecision.length) {
    dataToSubmit.productCategories.push({
      productCategory: ProductCategoryChoices.Precision,
      precisionCategories: illumaPrecision
    });
  }

  if (illumaPrecisionCtv && illumaPrecisionCtv.length) {
    dataToSubmit.productCategories.push({
      productCategory: ProductCategoryChoices.PrecisionCtv,
      precisionCategories: illumaPrecisionCtv
    });
  }

  dataToSubmit.kpis = kpis.slice(0, kpis.length - 1);
  dataToSubmit.kpis[0].isPrimary = true;

  dataToSubmit.dsp = {
    dspName: dsp,
    dspMemberId: dspMemberId,
  };

  dataToSubmit.estimatedIllumaImpressions = parseInt(
    estimatedIllumaImpressions
  );

  // Creative IDs have to be casted to list if creative ids in dsp specific
  dataToSubmit.dspSpecific &&
    dataToSubmit.dspSpecific.creativeIds &&
    (dataToSubmit.dspSpecific.creativeIds =
      dataToSubmit.dspSpecific.creativeIds.split(","));

  // Properties that are not required should be deleted.
  // These properties are just in the form to ensure that required fields have been included.
  // delete dataToSubmit.macroApplied
  delete dataToSubmit.reportsCreated
  delete dataToSubmit.productSelected

  return dataToSubmit;
}
