import { format } from "date-fns";
import { animated } from "react-spring";

/**
 * The heatmap and bar chart have date ticks that require the same type of
 * formatting. This custom component is used for rendering the date axis.
 */

export const CustomChartDateTick = ({
  value,
  lineX,
  lineY,
  textBaseline,
  textAnchor,
  tickIndex,
  animatedProps,
}: Record<string, any>) => {
  return (
    <animated.g
      transform={animatedProps.transform as any}
      key={value}
      opacity={animatedProps.opacity as any}
    >
      <line
        x1={0}
        x2={lineX}
        y1={0}
        y2={lineY}
        strokeWidth={"1px"}
        stroke={"#4D657E"}
      />
      <animated.text
        dominantBaseline={textBaseline}
        transform={animatedProps.textTransform as any}
        textAnchor={textAnchor}
        fontSize={"8px"}
        color={"black"}
      >
        {`${formatDateLabel(value, tickIndex)}`}
      </animated.text>
    </animated.g>
  );
};

const formatDateLabel = (dateString: string, index: number) => {
  const date = new Date(dateString);
  if (date.getDate() === 1 || index === 0) {
    return format(date, "MMM dd");
  } else {
    return format(date, "dd");
  }
};
