import { BoxProps, Grid, Text, HStack, Icon, IconButton, GridItem } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { BsTrash } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

interface Props {
  isAudited: boolean;
  creativeId: string;
  includeBorder: boolean;
  setCreativeId: Dispatch<SetStateAction<string>>;
  onOpen: () => void;
}

export const CreativeIdField = ({
  creativeId,
  isAudited,
  includeBorder,
  setCreativeId,
  onOpen,
  ...rest
}: Props & BoxProps) => {

  return (
    <>
      <Grid
        alignItems={"start"}
        px={10}
        py={2}
        templateColumns="repeat(8, 1fr)"
        gap={6}
        mt={4}
        borderBottomColor={"muted"}
        borderBottomWidth={includeBorder ? "1px" : "0px"}
        {...rest}
      >
        <GridItem colSpan={5}>
          <HStack align={"flex-start"}>
            <Text color={"primary.darker"} fontWeight={"bold"} fontSize={"sm"}>
              ID
            </Text>
            <Text>{creativeId}</Text>
          </HStack>
        </GridItem>
        <GridItem colEnd={9}>
          <HStack align={"flex-start"}>
            <Text color={"primary.darker"} fontWeight={"bold"} fontSize={"sm"}>
              Audited
            </Text>
            <Icon
              width={6}
              height={6}
              as={isAudited ? FaCheckCircle : MdCancel}
              color={isAudited ? "green.300" : "error"}
            />
            <IconButton
              height={6}
              aria-label={"remove"}
              icon={<BsTrash />}
              onClick={() => {
                setCreativeId(creativeId)
                onOpen()
              }}
              backgroundColor={"white"}
              _hover={{bg: "white"}}
              _active={{bg: "white"}}
            />
          </HStack>
        </GridItem>
      </Grid>
    </>
  );
};
