import { Icon } from "@chakra-ui/react";

export const InsightsIcon = (props: Record<string, any>) => {
  return (
    <Icon fillRule={"nonzero"} viewBox="0 0 1001 1001" {...props}>
      <g transform="matrix(1,0,0,1,-124.2,-25)">
        <g transform="matrix(4.16667,0,0,4.16667,-2.62918,-124.096)">
          <g transform="matrix(1,0,0,1,29.904,6.191)">
            <path
              d="M120.649,102.62C94.652,102.62 73.502,123.77 73.502,149.768C73.502,175.764 94.652,196.914 120.649,196.914C146.644,196.914 167.794,175.764 167.794,149.768C167.794,123.77 146.644,102.62 120.649,102.62ZM120.649,206.985C89.098,206.985 63.431,181.319 63.431,149.768C63.431,118.218 89.098,92.55 120.649,92.55C152.198,92.55 177.865,118.218 177.865,149.768C177.865,181.319 152.198,206.985 120.649,206.985Z"
              style={{ fill: "rgb(242,148,47)" }}
            />
          </g>
          <g transform="matrix(1,0,0,1,29.904,6.191)">
            <path
              d="M105.965,101.565C98.124,82.855 85.651,66.136 69.893,53.213L76.281,45.424C93.303,59.387 106.781,77.453 115.254,97.671L105.965,101.565Z"
              style={{ fill: "rgb(242,148,47)" }}
            />
          </g>
          <g transform="matrix(1,0,0,1,29.904,6.191)">
            <path
              d="M120.835,205.468L99.548,196.341C105.865,181.609 109.068,165.939 109.068,149.768C109.068,133.929 105.992,118.562 99.929,104.095L121.29,95.141C128.548,112.46 132.229,130.839 132.229,149.768C132.229,169.096 128.396,187.837 120.835,205.468Z"
              style={{ fill: "rgb(242,148,47)" }}
            />
          </g>
          <g transform="matrix(1,0,0,1,29.904,6.191)">
            <path
              d="M77.304,253.265L70.842,245.54C85.869,232.966 97.875,216.844 105.564,198.92L114.819,202.889C106.513,222.261 93.538,239.681 77.304,253.265Z"
              style={{ fill: "rgb(242,148,47)" }}
            />
          </g>
          <g transform="matrix(1,0,0,1,29.904,6.191)">
            <path
              d="M120.631,49.734C65.516,49.734 20.677,94.573 20.677,149.687C20.677,204.802 65.516,249.641 120.631,249.641C175.746,249.641 220.585,204.802 220.585,149.687C220.585,94.573 175.746,49.734 120.631,49.734ZM120.631,269.783C54.41,269.783 0.535,215.908 0.535,149.687C0.535,83.467 54.41,29.592 120.631,29.592C186.852,29.592 240.727,83.467 240.727,149.687C240.727,215.908 186.852,269.783 120.631,269.783Z"
              style={{ fill: "rgb(78,191,220)" }}
            />
          </g>
        </g>
      </g>
    </Icon>
  );
};
