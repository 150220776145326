import { Flex } from "@chakra-ui/react";
import { InsightsIcon } from "../../assets/icons/InsightsIcon";

export const FullSizeIconLoading = () => {
  return (
    <Flex
      w={"100vw"}
      h={"100vh"}
      align={"center"}
      justify={"center"}
      bg={"#EAF0F2"}
    >
      <InsightsIcon width={"8%"} height={"8%"} />
    </Flex>
  );
};
