import { Box, HStack, Icon, Text, useTheme } from "@chakra-ui/react";
import {
  CircleProps,
  ComputedDatum,
  LabelProps,
  ResponsiveCirclePacking,
} from "@nivo/circle-packing";
import { Link, useParams } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_CATEGORY_INSIGHTS } from "../../../../graphql/operations/campaignOperations/operations";
import {
  CategoryInsightEdge,
  InsightsValuesType,
} from "../../../../graphql/generated";
import { insightsDatesVar, insightsMetricVar } from "../../../../lib/cache";
import { animated } from "react-spring";
import { ComponentLoading } from "../../../../components/loading/ComponentLoading";
import { NoData } from "../../NoData";
import { MetricKey } from "../../../../../types";
import { formatNumberDisplay } from "../../../../utils/displayGetters";
import { useMetricSuffix } from "../../hooks";
import { useRef } from "react";
import { DownloadAsImage } from "../../DownloadAsImage";
import { HiddenLogo } from "../../HiddenLogo";

export const BubbleChart = () => {
  const { id: campaignId } = useParams();
  const currentMetric = useReactiveVar(insightsMetricVar).toLowerCase();
  const chakraTheme = useTheme();
  const dateRange = useReactiveVar(insightsDatesVar);
  const { data, loading, error } = useQuery(GET_CATEGORY_INSIGHTS, {
    variables: {
      campaignId,
      dateRange,
    },
  });
  const bubbleChartRef = useRef(null);

  if (loading) {
    return (
      <ComponentLoading text={"Generating bubble chart..."} bg={"background"} />
    );
  }

  if (!data) {
    return <NoData />;
  }

  if (error) {
    return <div>Error</div>;
  }
  const edges: CategoryInsightEdge[] = data.insightsByCategoryList.edges;
  const { dataColors } = chakraTheme.colors;
  const [bubbleChartData, hasChildrenToRender] = structureBubbleChartData(
    edges,
    currentMetric as MetricKey
  );
  const labelSkipRadius = 50;
  return hasChildrenToRender ? (
    <>
      <DownloadAsImage reference={bubbleChartRef} name={"BubbleChart"} />
      <Box ref={bubbleChartRef} pb={8}>
        <HiddenLogo chartName="BubbleChart"/>
        <Box  h={["60vh", null, null, "70vh", null]} >
          <ResponsiveCirclePacking
            data={bubbleChartData}
            id={"name"}
            value={"value"}
            enableLabels
            leavesOnly={true}
            labelsSkipRadius={labelSkipRadius}
            motionConfig={"gentle"}
            padding={6}
            tooltip={(props) => (
              <BubbleTooltip
                {...props}
                labelSkipRadius={labelSkipRadius}
                dataColors={dataColors}
              />
            )}
            labelComponent={BubbleLabel}
            labelTextColor={"white"}
            theme={{
              fontSize: 16,
            }}
            circleComponent={(props) => (
              <BubbleCircle
                {...props}
                labelSkipRadius={labelSkipRadius}
                dataColors={dataColors}
              />
            )}
          />
        </Box>
      </Box>
    </>
  ) : (
    <NoData />
  );
};

type BubbleElementProps = {
  labelSkipRadius: number;
  dataColors: Record<string, string>;
};

const BubbleTooltip = ({
  id,
  value,
  radius,
  labelSkipRadius,
  dataColors,
}: ComputedDatum<any> & BubbleElementProps) => {
  const color = radius < labelSkipRadius ? dataColors.l2 : dataColors.l4;
  const suffix = useMetricSuffix();

  return (
    <HStack bg={"text.dark"} color={"white"} px={2} py={1} fontSize={"12px"}>
      <Icon viewBox={"0 0 10 10"}>
        <rect width={10} height={10} fill={color} />
      </Icon>
      <Text>{id}</Text>
      <Text fontWeight={"bold"}>{formatNumberDisplay(value, suffix)}</Text>
    </HStack>
  );
};

const BubbleCircle = ({
  node,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  style,
  labelSkipRadius,
  dataColors,
}: CircleProps<any> & BubbleElementProps) => {
  const fill = node.radius < labelSkipRadius ? dataColors.l2 : dataColors.l4;
  return (
    <Link to={`../details?cat=${node.data.name}`}>
      <animated.circle
        onMouseEnter={(e) => onMouseEnter && onMouseEnter(node, e)}
        onMouseMove={(e) => onMouseMove && onMouseMove(node, e)}
        onMouseLeave={(e) => onMouseLeave && onMouseLeave(node, e)}
        cx={style.x as any}
        cy={style.y as any}
        r={style.radius as any}
        opacity={style.opacity as any}
        fill={fill}
      />
    </Link>
  );
};

const BubbleLabel = ({
  node: { id, x, y }, //radius
  label,
  //style,
}: LabelProps<any>) => {
  const labelDisplay = (label as string).split(" ");

  return(
    <g
      id={id}
      transform={`translate(${x},${y})`}
      pointerEvents={"none"}
    >
      <text
        textAnchor={"middle"}
        dominantBaseline={labelDisplay.length % 2 !== 0 ? "central" : "hanging"}
        fill={"white"}
        // font required for safari image download
        fontFamily={"Archivo, sans-serif"}
      >
        {labelDisplay.length > 1 ? (
          <>
            {labelDisplay.map((display, ix) => (
              <tspan
                key={ix}
                x={"0"}
                dy={ix > 0 ? "1.2em" : `-${1.2 * Math.floor(labelDisplay.length / 2)}em`}
              >
                {display}
              </tspan>
            ))}
          </>
        ) : (
          <>{label}</>
        )}
      </text>
    </g>
  );}

  // This method does not work with the download image for all browsers
  // return (
  // <animated.foreignObject
  //   id={id}
  //   alignmentBaseline={"middle"}
  //   opacity={style.opacity as any}
  //   pointerEvents={"none"}
  //   x={x - radius}
  //   y={y - radius}
  //   width={radius * 2}
  //   height={radius * 2}
  //   textAnchor={"middle"}
  //   fill={"white"}
  //   dominantBaseline={"central"}
  // >
  //   <Flex
  //     align={"center"}
  //     w={"100%"}
  //     h={"100%"}
  //     justify={"center"}
  //     color={"white"}
  //   >
  //     <Text align={"center"}>{label}</Text>
  //   </Flex>
  // </animated.foreignObject>
  // );}

  // OR
  // This method works for all browsers except safari when downloading image
  //  return labelDisplay.length > 1 ? (
  //    <>
  //      {labelDisplay.map((display, ix) => (
  //       <animated.text
  //         key={`${id}-${ix}`}
  //         x={style.x as any}
  //         y={style.y as any}
  //         textAnchor={"middle"}
  //         fill={"white"}
  //         dominantBaseline={labelDisplay.length % 2 > 0 ? "central" : "hanging"}
  //         opacity={style.opacity as any}
  //         style={{ transform: `translateY(${20 * ix - 20}px)` }}
  //         pointerEvents={"none"}
  //       >
  //         {display}
  //       </animated.text>
  //     ))}
  //    </>
  //  ) : (
  //    <animated.text
  //      key={`${id}-2`}
  //      x={style.x as any}
  //      y={style.y as any}
  //      fill={"white"}
  //      textAnchor={"middle"}
  //      dominantBaseline={"central"}
  //      opacity={style.opacity as any}
  //      pointerEvents={"none"}
  //    >
  //      {label}
  //    </animated.text>);}

const structureBubbleChartData = (
  edges: CategoryInsightEdge[],
  currentMetric: keyof Omit<InsightsValuesType, "__typename">
): [Record<string, any>, boolean] => {
  let hasChildrenToRender = false;
  const bubbleChartData = {
    name: "campaign",
    children: edges.map(
      ({ node: { category, insightValues } }: CategoryInsightEdge) => {
        const value = insightValues[currentMetric];
        if (value > 0) hasChildrenToRender = true;
        return {
          name: category,
          value,
          color: "red",
        };
      }
    ),
  };

  return [bubbleChartData, hasChildrenToRender];
};
