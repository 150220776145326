import { Box, Center, Spacer, Text, VStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom";

interface MenuTileProps {
    pageName: string
    icon: React.ReactNode
    pageUrl: string
    isAdmin?: boolean
}

export const MenuTile = ({
    pageName,
    icon,
    pageUrl,
    isAdmin=false
}: MenuTileProps) => {

    const navigate = useNavigate();

    return (
        <Box
            backgroundColor={"white"}
            borderColor={isAdmin ? "accent" : "primary.darker"}
            borderWidth={2}
            height={"14em"}
            width={"14em"}
            borderRadius={"lg"}
            as={"button"}
            onClick={() => {
                navigate(pageUrl);
            }}
            p={4}
            _hover={{
                opacity: 0.7
            }}
        >
            <VStack height={"100%"}>
                <Box height={"100%"} width={"100%"}>
                    <Center height={"100%"}>
                        {icon}
                    </Center>
                </Box>
                <Spacer />
                <Box width={"100%"}>
                    <Text fontWeight={"semibold"}>
                        {pageName}
                    </Text>
                </Box>
            </VStack>
        </Box>
    )
}
