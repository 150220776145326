import { Flex, Text } from "@chakra-ui/react"

interface HighlightsProps {
    highlightsList: {
        label: string
        value: number | string
        color: string
    }[]
}

export const Highlights = ({highlightsList}: HighlightsProps) => {

    return (
        <Flex flexDirection={"initial"} flexWrap={"wrap"} pl={10}>
            {highlightsList.map(({label, value, color}, ix) => {
                return (
                    <Flex key={ix} flexDirection={"column"} pr={10} py={4}>
                        <Text color={color} fontWeight={"bold"} fontSize={"4xl"}>
                            {value}
                        </Text>
                        <Text>{label}</Text>
                    </Flex>
                )
            })}
        </Flex>
    )
}
