import { Dispatch, useEffect, useState } from "react";
import {
  Field,
  FieldArray,
  FieldProps,
  FormikProps,
  FormikValues,
} from "formik";
import {
  Box,
  BoxProps,
  Wrap,
  RadioGroup,
  Radio,
  Button,
  Stack,
  Collapse,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { IoMdRemove } from "react-icons/io";
import { GrAdd } from "react-icons/gr";
import { FormInputField } from "../../../../components/formInputField/FormInputField";
import { getDSPName, getKPIDisplay } from "../../../../utils/displayGetters";
import { IFormDataClassField } from "../../../../utils/formsSchema/FormDataClass";
import { CampaignKpiTypeChoices } from "../../../../graphql/generated";
import { FormSubSectionHeader } from "../../../../components/formSubSectionHeader/FormSubSectionHeader";
import { SelectField } from "../../../../components/selectField/SelectField";
import { FormFieldLabel } from "../../../../components/formFieldLabel/FormFieldLabel";

interface DSPDetailsProps {
  currentDsp: string;
  formik: FormikProps<FormikValues>;
  fields: Record<string, IFormDataClassField> & {
    dspSpecific: IFormDataClassField & {
      properties: Record<string, IFormDataClassField>;
    };
    kpis: IFormDataClassField & {
      default: Record<string, any>[];
      items: {
        properties: Record<string, IFormDataClassField> & {
          dspSpecificKpiParams?: IFormDataClassField & {
            properties: Record<string, IFormDataClassField>;
          };
        };
      };
    };
  };
}

export const DSPDetails = ({
  formik,
  fields,
  currentDsp,
  ...boxProps
}: DSPDetailsProps & BoxProps) => {
  const { dspSpecific, kpis } = fields;
  const { kpiType, kpiTarget, dspSpecificKpiParams } = kpis.items.properties;
  const { values, errors, touched } = formik;
  const defaultKpisToShow = kpis.default.length - 1;
  const hasDspSpecific = Object.keys(dspSpecific.properties).length > 0;
  const [kpisToShow, setKpisToShow] = useState(defaultKpisToShow);

  useEffect(() => {
    setKpisToShow(defaultKpisToShow);
  }, [currentDsp]);

  return (
    <Box {...boxProps}>
      <Collapse in={hasDspSpecific} unmountOnExit={true}>
        <Box
          p={8}
          bg={"white"}
          borderRadius={"md"}
          borderBottomRadius={"none"}
          borderBottom={"1px solid"}
          borderBottomColor={"muted"}
        >
          <FormSubSectionHeader label={`${getDSPName(currentDsp)} Campaign`} />
          <Field as={Box} name={"dspSpecific"}>
            <Wrap spacing={6}>
              {Object.entries(dspSpecific.properties).map(
                ([name, { isRequired, label, placeholder }], ix) => {
                  return (
                    <Field name={`dspSpecific.${name}`} key={`${label}${ix}`}>
                      {({ form, field }: FieldProps) => {
                        const fieldError: any = form.errors.dspSpecific;
                        const fieldTouched: any = form.touched.dspSpecific;
                        let isInvalidTouched = false,
                          isInvalidError = false;

                        if (
                          fieldTouched !== undefined &&
                          fieldError !== undefined
                        ) {
                          isInvalidTouched = !!fieldTouched[name];
                          isInvalidError = !!fieldError[name];
                        }
                        return (
                          <FormInputField
                            maxW={["100%", null, "44%", null, null]}
                            isRequired={isRequired}
                            inputFieldProps={field}
                            fieldType={"text"}
                            placeholder={placeholder}
                            label={label}
                            isInvalid={isInvalidTouched && isInvalidError}
                            errorMessage={
                              form.errors?.dspSpecific &&
                              (form.errors.dspSpecific as Record<string, any>)[
                              name
                              ]
                            }
                          />
                        );
                      }}
                    </Field>
                  );
                }
              )}
            </Wrap>
          </Field>
        </Box>
      </Collapse>
      <Box
        p={8}
        bg={"white"}
        borderBottomRadius={"md"}
        borderTopRadius={hasDspSpecific ? 0 : "md"}
      >
        <FormSubSectionHeader
          label={"Primary KPI"}
          toolTip={"Share the KPIs and targets for the illuma campaigns"}
        />
        <FieldArray name={"kpis"}>
          {({ remove, insert }) => (
            <Box>
              {values.kpis.length > 0 &&
                values.kpis.map((kpi: any, kpiIndex: number) => (
                  <Collapse key={`kpis-${kpiIndex}`} in={kpiIndex < kpisToShow}>
                    <Box mt={6 + (kpiIndex > 0 ? 4 : 0)}>
                      <Field name={`kpis.${kpiIndex}.kpiType`}>
                        {({ field, form }: FieldProps) => {
                          const { value, onChange, ...rest } = field;
                          return (
                            <FormFieldLabel
                              label={kpiType.label}
                              isInvalid={
                                !!(
                                  form.errors.kpis &&
                                  (form.errors.kpis as any)[kpiIndex] &&
                                  (form.errors.kpis as any)[kpiIndex].kpiType &&
                                  form.touched.kpis &&
                                  (form.touched.kpis as any)[kpiIndex] &&
                                  (form.touched.kpis as any)[kpiIndex].kpiType
                                )
                              }
                              isRequired={
                                kpis.items.properties.kpiType.isRequired
                              }
                            >
                              <KPITypeSelector
                                {...rest}
                                value={value}
                                form={form}
                                field={field}
                                onChange={onChange}
                                kpiIndex={kpiIndex}
                              />
                            </FormFieldLabel>
                          );
                        }}
                      </Field>

                      <Stack
                        mt={6}
                        direction={["column", null, "row", null, null]}
                        spacing={6}
                        align={"flex-end"}
                      >
                        <Field
                          name={`kpis.${kpiIndex}.kpiTarget`}
                          key={`kpiTarget${kpiIndex}$`}
                        >
                          {({ field }: FieldProps) => {
                            return (
                              <FormInputField
                                isInvalid={
                                  !!(
                                    errors.kpis &&
                                    (errors.kpis as any[])[kpiIndex] &&
                                    (errors.kpis as any[])[kpiIndex]
                                      .kpiTarget &&
                                    touched.kpis &&
                                    (touched.kpis as any[])[kpiIndex] &&
                                    (touched.kpis as any[])[kpiIndex].kpiTarget
                                  )
                                }
                                inputFieldProps={field}
                                fieldType={"text"}
                                placeholder={kpiTarget.placeholder}
                                isRequired={kpiTarget.isRequired}
                                label={kpiTarget.label}
                              />
                            );
                          }}
                        </Field>
                        {Object.entries(
                          dspSpecificKpiParams?.properties as Record<
                            string,
                            IFormDataClassField
                          >
                        ).map(([k, f], ix) => (
                          <Field
                            name={`kpis.${kpiIndex}.dspSpecificKpiParams.${k}`}
                            key={`${k}${kpiIndex}${ix}`}
                          >
                            {({ field }: FieldProps) => {
                              return (
                                <FormInputField
                                  isInvalid={
                                    !!(
                                      errors.kpis &&
                                      (errors.kpis as any[])[kpiIndex] &&
                                      (errors.kpis as any[])[kpiIndex][
                                      "dspSpecificKpiParams"
                                      ] &&
                                      (errors.kpis as any[])[kpiIndex][
                                      "dspSpecificKpiParams"
                                      ][k] &&
                                      touched.kpis &&
                                      (touched.kpis as any[])[kpiIndex] &&
                                      (touched.kpis as any[])[kpiIndex][
                                      "dspSpecificKpiParams"
                                      ] &&
                                      (touched.kpis as any[])[kpiIndex][
                                      "dspSpecificKpiParams"
                                      ][k]
                                    )
                                  }
                                  inputFieldProps={field}
                                  fieldType={"text"}
                                  placeholder={f.placeholder}
                                  isRequired={f.isRequired}
                                  // ml={ix * 5}
                                  label={f.label}
                                />
                              );
                            }}
                          </Field>
                        ))}
                      </Stack>
                    </Box>
                  </Collapse>
                ))}
              <HStack
                align={"center"}
                spacing={kpisToShow < 3 ? 4 : 0}
                mt={10}
                justifyContent={"flex-end"}
              >
                <Box
                  maxW={kpisToShow > defaultKpisToShow ? "200px" : "0"}
                  overflow={"hidden"}
                  transition={"max-width 0.25s ease-in-out"}
                >
                  <Button
                    leftIcon={<Icon as={IoMdRemove} />}
                    _hover={{
                      color: "error",
                      borderColor: "error",
                    }}
                    variant={"secondary"}
                    color={"text.dark"}
                    borderColor={"text.dark"}
                    onClick={() => {
                      if (values.kpis.length > defaultKpisToShow) {
                        remove(values.kpis.length - 2);
                        setKpisToShow((prev: number) =>
                          prev - 1 < defaultKpisToShow
                            ? defaultKpisToShow
                            : prev - 1
                        );
                      }
                    }}
                  >
                    Remove KPI
                  </Button>
                </Box>
                <Box
                  maxW={kpisToShow < 3 ? "200px" : "0"}
                  overflow={"hidden"}
                  transition={"max-width 0.2s ease-in"}
                >
                  <Button
                    leftIcon={<GrAdd />}
                    variant={"primary"}
                    onClick={() => {
                      insert(values.kpis.length - 1, {
                        kpiType: CampaignKpiTypeChoices.Ctr,
                        kpiTarget: "",
                        isPrimary: false,
                        dspSpecificKpiParams: dspSpecificKpiParams
                          ? Object.keys(dspSpecificKpiParams.properties).reduce(
                            (accumulator, k) => ({ ...accumulator, [k]: "" }),
                            {}
                          )
                          : {},
                      });
                      setKpisToShow((prev: number) => prev + 1);
                    }}
                  >
                    Add KPI
                  </Button>
                </Box>
              </HStack>
            </Box>
          )}
        </FieldArray>
      </Box>
    </Box>
  );
};

type KPITypeSelectorProps = {
  value: string;
  onChange: Dispatch<any>;
  kpiIndex: number;
};
const KPITypeSelector = ({
  value,
  onChange,
  kpiIndex,
  form,
  field,
  ...rest
}: KPITypeSelectorProps &
  Record<string, unknown> &
  Pick<FieldProps, "form" | "field">) => {
  const [showOther, setShowOther] = useState(false);

  const [rgValue, setRgValue] = useState(value);

  const onOtherChange = (option: { label: string; value: string }) => {
    form.setFieldValue(field.name, option.value);
  };

  const onOtherMenuClose = () => {
    form.setFieldTouched(field.name, true, true);
  };

  const mainKpis = [
    CampaignKpiTypeChoices.Ctr,
    CampaignKpiTypeChoices.Cpa,
    CampaignKpiTypeChoices.Vcr,
  ];

  const otherKpis = Object.entries(CampaignKpiTypeChoices)
    .filter(([, value]) => !mainKpis.includes(value))
    .map(([, value]) => ({
      label: getKPIDisplay(value),
      value: value,
    }));

  return (
    <RadioGroup {...rest} value={rgValue}>
      <Stack
        spacing={[2, 10, null, 2, 10]}
        direction={["column", "row", null, null, null]}
      >
        {[...mainKpis, "Other"].map((kpiType) => {
          return (
            <Radio
              key={`kpiType-${kpiIndex}${kpiType}`}
              value={kpiType}
              onChange={(e) => {
                if (e.target.value === "Other") {
                  setShowOther(true);
                } else {
                  setShowOther(false);
                }
                setRgValue(e.target.value);
                onChange(e);
              }}
            >
              {getKPIDisplay(kpiType)}
            </Radio>
          );
        })}
        <Box flexGrow={1}>
          <Collapse in={showOther} animateOpacity={true}>
            <SelectField
              placeholder={"Other"}
              onChange={onOtherChange}
              onMenuClose={onOtherMenuClose}
              isMulti={false}
              options={otherKpis}
            />
          </Collapse>
        </Box>
      </Stack>
    </RadioGroup>
  );
};
