import { useLazyQuery } from "@apollo/client";
import {
    Box,
    BoxProps,
    Button,
    Flex,
    HStack,
    Spacer,
    Text,
    IconButton,
    Icon,
    Spinner,
    Progress,
    useDisclosure,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Checkbox,
    RadioGroup,
    Radio,
    Stack,
    Collapse,
} from "@chakra-ui/react"
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { BsDownload } from "react-icons/bs";
import { ActionLabel } from "../../../components/actionLabel/ActionLabel"
import { SectionBox } from "../../../components/sectionBox/SectionBox"
import { SelectField } from "../../../components/selectField/SelectField"
import { DspChoices, FullActualRevenueTypeEdge, FullForecastRevenueTypeEdge, OfficeChoices } from "../../../graphql/generated";
import { GET_FULL_ACTUAL_REVENUE_PAGINATED, GET_FULL_FORECAST_REVENUE_PAGINATED, GET_FULL_SALESFORCE_REVENUE_PAGINATED } from "../../../graphql/operations/campaignOperations/operations";
import { revenueDspVar, revenueOfficeVar } from "../../../lib/cache";
import { getDSPName, formatTextDisplay } from "../../../utils/displayGetters";
import { YearSelect } from "./YearSelect";

interface ChartBoxProps extends BoxProps {
    chartBoxHeader?: string
    includeCombineOffice?: boolean
    dspFilter?: {
        dspSelect: dspFilterOptionType
        setDspSelect: Dispatch<SetStateAction<dspFilterOptionType>>
    }
    officeFilter?: {
        officeSelect: officeFilterOptionType,
        setOfficeSelect: Dispatch<SetStateAction<officeFilterOptionType>>
    }
    yearFilter?: {
        year: number,
        setYear: Dispatch<SetStateAction<number>>
    }
    isDownloadable?: boolean
    forecastCheckbox?: {
        showPastForecast: boolean
        setShowPastForecast: Dispatch<SetStateAction<boolean>>
    }
    managedCheckbox?: {
        showManaged: boolean
        setShowManaged: Dispatch<SetStateAction<boolean>>
    }
    salesforceCheckbox?: {
        showSalesforce: boolean
        setShowSalesforce: Dispatch<SetStateAction<boolean>>
    }
}

export interface officeFilterOptionType {
    label: string,
    value: OfficeChoices | "ALL" | "COMBINED"
}

export interface dspFilterOptionType {
    label: string,
    value: DspChoices | "ALL"
}

export const ChartBox = ({
    chartBoxHeader,
    includeCombineOffice = false,
    dspFilter,
    officeFilter,
    yearFilter,
    isDownloadable = false,
    forecastCheckbox,
    managedCheckbox,
    salesforceCheckbox,
    children,
    ...rest
}: ChartBoxProps) => {
    const [downloadQuery, setDownloadQuery] = useState("ACTUAL")
    const [isDownload, setIsDownload] = useState(false);
    const [offset, setOffset] = useState(0);
    const [progress, setProgress] = useState(0);
    const [compiledData, setCompiledData] = useState<FullActualRevenueTypeEdge[] | FullForecastRevenueTypeEdge[]>([]);
    const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const { isOpen, onOpen, onClose } = useDisclosure()

    let query = GET_FULL_ACTUAL_REVENUE_PAGINATED;
    switch (downloadQuery) {
        case "ACTUAL":
            query = GET_FULL_ACTUAL_REVENUE_PAGINATED;
            break;
        case "FORECAST":
            query = GET_FULL_FORECAST_REVENUE_PAGINATED;
            break;
        case "SALESFORCE":
            query = GET_FULL_SALESFORCE_REVENUE_PAGINATED;
            break;
    };

    const [
        getDownloadDataPaginated,
        { data: dataPaginated },
    ] = useLazyQuery(query, {
        variables: {

            ...(downloadQuery === "ACTUAL" || downloadQuery === "FORECAST") &&
                {dspFilter: dspFilter?.dspSelect.value === "ALL" ?
                undefined : dspFilter?.dspSelect.value},
            ...(downloadQuery === "ACTUAL" || downloadQuery === "FORECAST") &&
                {officeFilter : (officeFilter?.officeSelect.value === "ALL" || officeFilter?.officeSelect.value === "COMBINED") ?
                undefined : officeFilter?.officeSelect.value},
            ...(downloadQuery === "SALESFORCE") &&
                {regionFilter: (officeFilter?.officeSelect.value === "ALL" || officeFilter?.officeSelect.value === "COMBINED") ?
                undefined : officeFilter?.officeSelect.value},
            yearFilter: yearFilter?.year,
            first: 100,
            offset: offset
        },
        fetchPolicy: "cache-and-network",
        onCompleted: () => {
            setProgress((offset/dataPaginated.fullRevenue.totalCount) * 100);
            // When download is cancelled
            if (!isDownload) {
                setOffset(0);
                setCompiledData([]);
                return;
            }

            if (dataPaginated.fullRevenue.totalCount !== 0 && offset < dataPaginated.fullRevenue.totalCount) {
                compiledData.push(...dataPaginated.fullRevenue.edges);
                setOffset(offset + 100);
                getDownloadDataPaginated();
            } else {
                csvLink.current?.link.click();
                setIsDownload(false);
                onClose();
            }
        }
    });

    const officeSelectOptions: officeFilterOptionType[] = [
        {label: "All Offices", value: "ALL"},
        // {label: "Combined", value: "COMBINED"},
        ...Object.entries(OfficeChoices).map(([, value]) => ({
            label: value,
            value: value,
        })),
    ];
    const dspSelectOptions: dspFilterOptionType[] = [
        {label: "All DSPs", value: "ALL"},
        ...Object.entries(DspChoices).map(([, value]) => ({
            label: getDSPName(value),
            value: value,
        })),
    ];

    // adds Combined as a option
    if (includeCombineOffice) {
        officeSelectOptions.splice(1, 0, {label: "Combined", value: "COMBINED"})
    }

    return (
        <SectionBox p={5} m={0} {...rest}>
            <HStack pt={2} pb={6} mx={4} spacing={6}>
                <Text px={4} fontWeight={"bold"}>
                    {chartBoxHeader}
                </Text>
                <Spacer />
                {dspFilter &&
                    <ActionLabel label={"DSP Filter:"}>
                        <Box minW={140}>
                            <SelectField
                                value={dspFilter.dspSelect}
                                isMulti={false}
                                options={dspSelectOptions}
                                onChange={(option) => {
                                    dspFilter.setDspSelect(option)
                                    revenueDspVar(option)
                                }}
                            />
                        </Box>
                    </ActionLabel>
                }
                {officeFilter &&
                    <ActionLabel label={"Office Filter:"}>
                        <Box minW={140}>
                            <SelectField
                                value={officeFilter.officeSelect}
                                isMulti={false}
                                options={officeSelectOptions}
                                onChange={(option: officeFilterOptionType) => {
                                    officeFilter.setOfficeSelect(option)
                                    revenueOfficeVar(option.value === "COMBINED" ?
                                        {label: "All Offices", value: "ALL"} : option
                                    )
                                }}
                            />
                        </Box>
                    </ActionLabel>
                }
            </HStack>
            <HStack pt={2} pb={4} mx={4} spacing={6}>
                <Spacer />
                {salesforceCheckbox &&
                    <ActionLabel label={"Show Salesforce:"}>
                        <Checkbox
                            isChecked={salesforceCheckbox.showSalesforce}
                            onChange={() => {
                                salesforceCheckbox.setShowSalesforce(!salesforceCheckbox.showSalesforce);
                            }}
                        />
                    </ActionLabel>
                }
                {managedCheckbox &&
                    <ActionLabel label={"Split Managed:"}>
                        <Checkbox
                            isChecked={managedCheckbox.showManaged}
                            onChange={() => {
                                managedCheckbox.setShowManaged(!managedCheckbox.showManaged);
                            }}
                        />
                    </ActionLabel>
                }
                {forecastCheckbox &&
                    <ActionLabel label={"Past Forecasts:"}>
                        <Checkbox
                            isChecked={forecastCheckbox.showPastForecast}
                            onChange={() => {
                                forecastCheckbox.setShowPastForecast(!forecastCheckbox.showPastForecast);
                            }}
                        />
                    </ActionLabel>
                }
            </HStack>
            {children}
            <Flex
                flexDir={"row"}
            >
                {yearFilter &&
                    <Box marginLeft={"44%"}>
                        <YearSelect
                            year={yearFilter.year}
                            setYear={yearFilter.setYear}
                        />
                    </Box>
                }
                {isDownloadable &&
                    <IconButton
                        ml={"auto"}
                        mr={4}
                        bg={"white"}
                        aria-label={"Download data"}
                        variant={"unStyled"}
                        icon={downloadIcon(isDownload)}
                        onClick={() => {
                            onOpen()
                        }}
                    />
                }
                {dataPaginated &&
                    <CSVLink
                        data={compiledData}
                        filename={
                            generateFilename(
                                downloadQuery,
                                yearFilter?.year,
                                dspFilter?.dspSelect,
                                officeFilter?.officeSelect
                            )
                        }
                        ref={csvLink}
                        headers={generateCsvHeaders(downloadQuery)}
                    />
                }
            </Flex>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={!isDownload}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>
                    {isDownload ? "Generating CSV..." : "Generate CSV"}
                </ModalHeader>
                <ModalBody>
                    <Text mb={2}>Options:</Text>
                    <RadioGroup
                        onChange={setDownloadQuery}
                        value={downloadQuery}
                        isDisabled={isDownload}
                        mb={4}
                    >
                        <Stack ml={4} spacing={2}>
                            <Radio value={"ACTUAL"}>Actual Revenue</Radio>
                            <Radio value={"FORECAST"}>Forecast Revenue</Radio>
                            <Radio value={"SALESFORCE"}>Salesforce Revenue</Radio>
                        </Stack>
                    </RadioGroup>
                    <Collapse in={isDownload}>
                        <Progress value={progress} variant={"primary"} />
                        <Text py={2}>Please wait. This may take a while.</Text>
                    </Collapse>
                </ModalBody>

                <ModalFooter>
                    <Button
                        mr={4}
                        variant={"tabActive"}
                        onClick={() => {
                            setIsDownload(false);
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        isDisabled={isDownload}
                        variant={"tabActive"}
                        onClick={() => {
                            setIsDownload(true);
                            getDownloadDataPaginated();
                        }}
                    >
                        Download
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </SectionBox>
    )
}

const generateFilename = (
    query: string,
    year: number | undefined,
    dspSelect: dspFilterOptionType | undefined,
    officeSelect: officeFilterOptionType | undefined,
) => {
    let filename = formatTextDisplay(query) + "_Revenue";
    if (year) {
        filename += `_${year.toString()}`;
    }
    if (dspSelect?.value && dspSelect.value !== "ALL" && query !== "SALESFORCE") {
        filename += `_${dspSelect.value}`;
    }
    if (officeSelect?.value && officeSelect.value !== "ALL" && officeSelect.value !== "COMBINED") {
        filename += `_${officeSelect.value}`;
    }
    return filename;
}

const downloadIcon = (isDownload: boolean): JSX.Element => {
    if (isDownload) {
        return (
            <Spinner
                size={"sm"}
                speed={"0.75s"}
            />
        )
    }
    return (
        <Icon as={BsDownload} w={5} h={5} />
    )
}

const generateCsvHeaders = (query: string) => {
    const headers = [{label: "Revenue (USD)", key: "node.revenue"}];

    // Different options have different data formats
    if (query === "ACTUAL" || query === "FORECAST") {
        headers.unshift(
            {label: "Campaign ID", key: "node.campaign.id"},
            {label: "Campaign Name", key: "node.campaign.name"},
            {label: "Brand", key: "node.campaign.brand.name"},
            {label: "DSP", key: "node.campaign.dspInfo.dspName"},
            {label: "Office", key: "node.campaign.office"},
            {label: "Sales Person", key: "node.campaign.salesPerson.lastName"},
            {label: "Account Manager", key: "node.campaign.accountManager.lastName"},
        );
    }
    if (query === "ACTUAL") {
        headers.unshift({label: "Date", key: "node.date"});
    }
    if (query === "FORECAST") {
        headers.unshift(
            {label: "Year", key: "node.year"},
            {label: "Month", key: "node.month"}
        );
    }
    if (query === "SALESFORCE") {
        headers.unshift(
            {label: "Date", key: "node.date"},
            {label: "Opportunity Name", key: "node.salesforceCampaign.opportunityName"},
            {label: "Opportunity Owner", key: "node.salesforceCampaign.opportunityOwner"},
            {label: "Region", key: "node.salesforceCampaign.region"},
            {label: "Account Name", key: "node.salesforceCampaign.accountName"},
            {label: "Stage", key: "node.salesforceCampaign.stage"},
        );
    }

    return headers;
}
