import { GET_DATE_INSIGHTS } from "../../../../graphql/operations/campaignOperations/operations";
import { useQuery, useReactiveVar } from "@apollo/client";
import { insightsDatesVar, insightsMetricVar } from "../../../../lib/cache";
import { useParams } from "react-router-dom";
import { CategoryInsightNode, DateInsightEdge } from "../../../../graphql/generated";
import { ComponentLoading } from "../../../../components/loading/ComponentLoading";
import { NoData } from "../../NoData";
import { useLayoutEffect, useState } from "react";
import {
    InsightsValuesType,
    InsightCategoryChoices
} from "../../../../graphql/generated";
import { MetricKey } from "../../../../../types";
import { Box, Button, HStack, Icon, Spacer, useRadioGroup } from "@chakra-ui/react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { HiRewind } from "react-icons/hi";
import { BarChartRaceRadio } from "./BarChartRaceRadio";


export const BarChartRace = () => {
    const [replayCheck, setReplayCheck] = useState(false);
    const [isCumulative, setIsCumulative] = useState(false);

    //Radio setup
    const radioOptions: {label: string, value: string}[] = [
        {label: "Cumulative", value: "cumulative"},
        {label: "Non-Cumulative", value: "nonCumulative"}
    ]
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'dataMethod',
        defaultValue: "nonCumulative",
        onChange: (value) => {
          handleDataOption(value);
        },
      })
    const group = getRootProps();

    //get data
    const { id: campaignId } = useParams();
    const dateRange = useReactiveVar(insightsDatesVar);
    const currentMetric = useReactiveVar(insightsMetricVar).toLowerCase() as MetricKey;

    const { data, loading, error } = useQuery(GET_DATE_INSIGHTS, {
        variables: {
            campaignId,
            dateRange,
        },
    });

    useLayoutEffect(() => {

        if (!document.querySelector("#chartdiv")) {
            return;
        }

        const root = am5.Root.new("chartdiv");

        root.numberFormatter.setAll({
            numberFormat: "#a",

            bigNumberPrefixes: [
                { number: 1e3, suffix: "k" },
                { number: 1e6, suffix: "M" },
            ],
            smallNumberPrefixes: []
        });

        //Speed of race
        const stepDuration = 1500;

        // Set themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        const chart: am5xy.XYChart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: true,
            wheelX: "none",
            wheelY: "none",
        }));

        chart.zoomOutButton.set("forceHidden", true);

        // Create axes
        const yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 10,
            inversed: true
        });
        // hide grid
        yRenderer.grid.template.set("visible", false);

        const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            categoryField: "categoryName",
            renderer: yRenderer,
            zoomX: false,
            zoomY: false
        }));

        const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0,
            min: 0,
            strictMinMax: false,
            extraMax: 0.1,
            renderer: am5xy.AxisRendererX.new(root, {})
        }));

        xAxis.set("interpolationDuration", stepDuration / 0.5);
        xAxis.set("interpolationEasing", am5.ease.linear);

        const series = chart.series.push(am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "value",
            categoryYField: "categoryName",
        }));

        // Rounded corners for columns
        series.columns.template.setAll({ cornerRadiusBR: 2, cornerRadiusTR: 2 });

        //Make each column to be of a different color
        series.columns.template.adapters.add("fill", function (fill, target: any) {
            return chart.get("colors")?.getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", function (stroke, target: any) {
            return chart.get("colors")?.getIndex(series.columns.indexOf(target));
        });

        // Add label bullet
        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                sprite: am5.Label.new(root, {
                    text: "{valueXWorking.formatNumber('#.# a')}",
                    fill: root.interfaceColors.get("alternativeText"),
                    centerX: am5.p100,
                    centerY: am5.p50,
                    populateText: true
                })
            });
        });

        const label = chart.plotContainer.children.push(am5.Label.new(root, {
            text: firstDate,
            fontSize: "4em",
            opacity: 0.2,
            x: am5.p100,
            y: am5.p100,
            centerY: am5.p100,
            centerX: am5.p100
        }));

        // Get series item by category
        function getSeriesItem(category: string | undefined) {
            for (let i = 0; i < series.dataItems.length; i++) {
                const dataItem = series.dataItems[i];
                if (dataItem.get("categoryY") == category) {
                    return dataItem;
                }
            }
        }

        // Axis sorting
        function sortCategoryAxis() {
            series.dataItems.sort(function (x, y) {

                return (y.get("valueX") ?? 0) - (x.get("valueX") ?? 0); // descending
            });

            // go through each axis item
            am5.array.each(yAxis.dataItems, function (dataItem) {
                // get corresponding series item
                const seriesDataItem = getSeriesItem(dataItem.get("category"));

                if (seriesDataItem) {
                    // get index of series data item
                    const index = series.dataItems.indexOf(seriesDataItem);
                    // calculate delta position
                    const deltaPosition = (index - dataItem.get("index", 0)) / series.dataItems.length;
                    // set index to be the same as series data item index
                    if (dataItem.get("index") != index) {
                        dataItem.set("index", index);
                        // set deltaPosition instanlty
                        dataItem.set("deltaPosition", -deltaPosition);
                        // animate delta position to 0
                        dataItem.animate({
                            key: "deltaPosition",
                            to: 0,
                            duration: stepDuration / 2,
                            easing: am5.ease.out(am5.ease.cubic)
                        });
                    }
                }
            });
            // sort axis items by index
            // This changes the order instantly, but as deltaPosition is set, they keep in the same places and then animate to true positions.
            yAxis.dataItems.sort(function (x, y) {

                return (x.get("index") ?? 0) - (y.get("index") ?? 0);
            });
        }

        //Contructing dates
        let dayDate = new Date(firstDate);
        //console.log(dayDate.toISOString().split("T")[0] + 1)
        let dayDateString = dayDate.toISOString().split("T")[0];

        // update data with values
        const interval = setInterval(function () {
            dayDate = new Date(dayDate.setDate(dayDate.getDate() + 1));
            dayDateString = dayDate.toISOString().split("T")[0];

            if (dayDate > new Date(lastDate)) {
                clearInterval(interval);
                clearInterval(sortInterval);
            }

            updateData();
        }, stepDuration);

        const sortInterval = setInterval(function () {
            sortCategoryAxis();
        }, 50);


        function setInitialData() {
            //const initialDayData = raceData[dayDateString];
            for (const categoryName in initialData) {

                series.data.push({ categoryName: categoryName, value: initialData[categoryName][currentMetric] });
                yAxis.data.push({ categoryName: categoryName });
            }

        }

        function updateData() {

            if (raceData[dayDateString]) {
                label.set("text", dayDateString);

                am5.array.each(series.dataItems, function (dataItem) {

                    const category = dataItem.get("categoryY") as InsightCategoryChoices;
                    let value: number;
                    if (raceData[dayDateString][category]) {
                        value = raceData[dayDateString][category][currentMetric];
                    } else {
                        value = 0;
                    }

                    dataItem.animate({
                        key: "valueX",
                        to: value,
                        duration: stepDuration,
                        easing: am5.ease.linear
                    });
                    dataItem.animate({
                        key: "valueXWorking",
                        to: value,
                        duration: stepDuration,
                        easing: am5.ease.linear
                    });
                });

                yAxis.zoomToIndexes(0, 10);
            }
        }

        setInitialData();
        setTimeout(function () {
            dayDate = new Date(dayDate.setDate(dayDate.getDate() + 1));;
            dayDateString = dayDate.toISOString().split("T")[0];
            updateData();
        }, 50);

        // Make stuff animate on load
        series.appear(1000);
        chart.appear(1000, 100);
        return () => {
            root.dispose();
        };

    }, [currentMetric, dateRange, data, replayCheck]);

    if (loading) {
        return <ComponentLoading text={"Generating Bar Chart Race..."} bg={"background"} />
    }

    if (!data || data.insightsByDateList.edges.length < 7) {
        return <NoData />;
    }

    if (error) {
        return <div>Error</div>;
    }

    //Changing cumulative option
    const handleDataOption = (input: string) => {
        switch(input) {
            case "nonCumulative":
                setIsCumulative(false);
                break;
            case "cumulative":
                setIsCumulative(true);
                break;
        }
        setReplayCheck(prevCheck => !prevCheck);
    }

    //Deciding whether data is cumulative
    let raceData: BarChartRaceDatum;
    if(!isCumulative || currentMetric === "ctr") {
        raceData = prepareRaceData(data.insightsByDateList.edges);
    } else {
        raceData = prepareCumulativeRaceData(data.insightsByDateList.edges);
    }

    const possibleCategories: string[] = possibleCategoriesCalc(data.insightsByDateList.edges)
    const raceDataArray = Object.keys(raceData);

    //Initialising data
    const initialData: {[category: string]: InsightsValuesType} = initialiseData(possibleCategories);

    //Dates
    const firstDate = raceDataArray[0];
    const lastDate = raceDataArray.slice(-1)[0];

    return (
        <Box>
            <HStack px={2} pt={2}>
                <Button
                    onClick={() => {
                        setReplayCheck(prev => !prev)
                    }}
                    variant={"unStyled"}
                    leftIcon={
                        <Icon
                            as={HiRewind}
                        />
                    }>
                    Replay
                </Button>
                <Spacer />
                <HStack {...group}>
                    {radioOptions.map(({label, value}) => {
                        const radio = getRadioProps({value})
                        return (
                            <BarChartRaceRadio key={value} {...radio}>
                                <Box
                                    display={currentMetric === "ctr" && value === "cumulative" ? "none" : "block"}
                                >
                                    {label}
                                </Box>
                            </BarChartRaceRadio>
                        )
                    })}
                </HStack>
            </HStack>
            <div id="chartdiv" style={{ width: "100%", height: "600px" }}></div>
        </Box>
    )
}

const possibleCategoriesCalc = (edges: DateInsightEdge[]):  string[] => {
    const possibleCategories: string[] = [];

    for (const date of edges) {
        date.node.categories.reduce((acc, cat) => {
            if (!possibleCategories.includes(cat.category)){
                possibleCategories.push(cat.category)
            }
            return possibleCategories;
        }, [])
    }
    return possibleCategories.sort();
}

const initialiseData = (allCategories: string[]) => {
    return allCategories.reduce((accumulator, category: string) => (
        {...accumulator,
        [category]: {
            clicks: 0,
            conversions: 0,
            ctr: 0,
            impressions: 0,
            urlsCountClicks:0,
            urlsCountConversions:0,
            urlsCountImpressions:0}
    }), {})
}

const prepareRaceData = (edges: DateInsightEdge[]): BarChartRaceDatum=> {
    const preparedData = edges.reduce((accumulator, { node }) => {
        return {
            ...accumulator, [node.date]: reduceCategoriesToInsightValues(node.categories)
        }
    }, {})
    return preparedData
}

const reduceCategoriesToInsightValues = (categories: CategoryInsightNode[]) => {
    return categories.reduce((accumulator, cat) => ({...accumulator, [cat.category]: cat.insightValues}), {})
}

const addTwoDateInsights = ( currentDateInsights: InsightsValuesType, previousDateInsights?: InsightsValuesType,) => {
    if (!previousDateInsights){
        return currentDateInsights
    }
    return Object.keys(currentDateInsights).filter((key) => key !== "__typename").reduce((acc, key: MetricKey) => ({
        ...acc, [key]: previousDateInsights[key] + currentDateInsights[key]
    }), {})
}

const prepareCumulativeRaceData = (edges: DateInsightEdge[]): BarChartRaceDatum => {

    const initialCumulativeData = initialiseData(possibleCategoriesCalc(edges));

    const preparedData = edges.reduce((accumulator, {node}: DateInsightEdge) => {
        const currentDateInsights: { [category: string]: InsightsValuesType } = reduceCategoriesToInsightValues(node.categories);
        const previousDateInsights = Object.values(accumulator).slice(-1)[0];

        let cumulativeInsights: { [category: string]: InsightsValuesType } = {}

        if (!previousDateInsights) {
            //For the first date
            cumulativeInsights = Object.entries(initialCumulativeData).reduce((acc, [key, value]) => {
                return {...acc, [key]: addTwoDateInsights( value as InsightsValuesType, currentDateInsights[key] )}
            }, {});

        } else {
            cumulativeInsights = Object.entries(previousDateInsights).reduce((acc, [key, value]) => {
                return {...acc, [key]: addTwoDateInsights( value as InsightsValuesType, currentDateInsights[key] )}
            }, {});
        }

        accumulator[node.date] = cumulativeInsights
        return accumulator
    }, {} as BarChartRaceDatum)

    return preparedData;
}

type BarChartRaceDatum = {
    [key: string]: { [category: string]: InsightsValuesType }
}
