import { InsightMetricsEnum } from "../../graphql/generated";
import {
  Box,
  HStack,
  RadioProps,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import { insightsMetricVar } from "../../lib/cache";
import { useReactiveVar } from "@apollo/client";
import {
  getMetricDisplay,
} from "../../utils/displayGetters";
import { useCampaignAvailableMetrics } from "./hooks";

export const InsightsRadio = () => {
  const defaultInsight = useReactiveVar(insightsMetricVar);
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "insight-type",
    defaultValue: defaultInsight,
    onChange: (value) => {
      insightsMetricVar(value as InsightMetricsEnum);
    },
  });

  const availableMetrics = useCampaignAvailableMetrics();

  const insightOptions: { label: string; value: string }[] =
    availableMetrics.map((metric: string) => ({
      label: getMetricDisplay(metric, true),
      value: metric,
    }));

  const group = getRootProps();
  return (
    <>
      {insightOptions && (
        <HStack {...group} bg={"white"} borderRadius={"md"} p={1}>
          {insightOptions.map(({ label, value }) => {
            const radio = getRadioProps({ value });
            return (
              <InsightsRadioCard key={value} {...radio}>
                {label}
              </InsightsRadioCard>
            );
          })}
        </HStack>
      )}
    </>
  );
};

const InsightsRadioCard = (props: RadioProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box as={"label"}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius={"md"}
        color={"primary.darker"}
        fontWeight={"medium"}
        transition={"background-color 0.25s ease-in-out"}
        _hover={{
          bg: "primary.lighter",
        }}
        _active={{
          bg: "primary.light",
          transform: "scale(0.98)",
        }}
        _checked={{
          bg: "text.dark",
          color: "white",
        }}
        _focus={{
          boxShadow: "none",
        }}
        px={3}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  );
};
