import { useMutation, useReactiveVar } from "@apollo/client";
import {
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    ModalHeader
} from "@chakra-ui/react";
import { Field, FieldProps, Formik } from "formik";
import { useCampaignInfo } from ".";
import { FormInputField } from "../../components/formInputField/FormInputField";
import { GET_CAMPAIGN_INFO, PROTECTED_GET_CAMPAIGN_INFO, RESLOVE_CREATIVE_ID } from "../../graphql/operations/campaignOperations/operations";
import { userDetailsVar } from "../../lib/cache";

interface CreativeIdModalProps {
    isOpen: boolean,
    onClose: () => void,
    creativeId?: string
}

export const CreativeIdModal = ({isOpen, onClose, creativeId}: CreativeIdModalProps) => {

    const currentUser = useReactiveVar(userDetailsVar);
    const { campaign: { id, creativeIds } } = useCampaignInfo();

    const refetch = currentUser?.isStaff
        ? PROTECTED_GET_CAMPAIGN_INFO
        : GET_CAMPAIGN_INFO;

    const [removeCreativeId, { loading }] = useMutation(RESLOVE_CREATIVE_ID, {
        refetchQueries: [refetch],
    });

    if(loading) {
        return (
            <></>
        )
    }

    return (
        <Formik
            initialValues={{creativeId: ""}}
            onSubmit={(values: {creativeId: string}, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    removeCreativeId({
                        variables: {
                            campaignId: id,
                            creativeId: values.creativeId
                        }
                    })
                    setSubmitting(false);
                    resetForm();
                    onClose();
                }, 400);
            }}
        >
        {(formik) => {
            return (
                <Modal
                    isOpen={isOpen}
                    onClose={() => {
                        onClose();
                        formik.resetForm();
                    }}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            {creativeId ? "Remove Creative ID" : "Add Creative ID"}
                        </ModalHeader>
                        <ModalBody>
                            <>
                                {creativeId ? (
                                    <>Are you sure you want to remove the Creative ID: {creativeId}?</>
                                ) : (
                                    <>
                                        <Text>Are you sure you want to add a Creative ID?</Text>
                                        <Field
                                            name={"creativeId"}
                                            validate={(value: string) => {
                                                if(!value) {
                                                    return "Field is required"
                                                } else if (creativeIds.some(({creativeId}) => (creativeId === value))) {
                                                    return "Creative ID already exists"
                                                }
                                            }}
                                        >
                                            {({ field, form }: FieldProps) => (
                                                <FormInputField
                                                    inputFieldProps={field}
                                                    fieldType={"text"}
                                                    isRequired={false}
                                                    label={"Creative ID"}
                                                    isInvalid={!!(form.errors.creativeId && !!form.touched.creativeId)}
                                                    placeholder={"Creative ID"}
                                                    errorMessage={form.errors.creativeId?.toString()}
                                                />
                                            )}
                                        </Field>
                                    </>
                                )}
                            </>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant={"primary"}
                                mr={3}
                                onClick={() => {
                                    onClose();
                                    formik.resetForm();
                                }}
                            >
                                Close
                            </Button>
                            {creativeId ? (
                                <Button
                                    variant={"primary"}
                                    onClick={() => {
                                        removeCreativeId({
                                            variables: {
                                                campaignId: id,
                                                creativeId: creativeId
                                            }
                                        })
                                        onClose()
                                    }}
                                >
                                    Remove
                                </Button>
                            ) : (
                                <Button
                                    type={"submit"}
                                    variant={"primary"}
                                    disabled={!(formik.isValid && formik.dirty)}
                                    isLoading={formik.isSubmitting || loading}
                                    onClick={() => (formik.submitForm())}
                                >
                                    Add
                                </Button>
                            )}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )
        }}
        </Formik>
    )
}
