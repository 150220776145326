import { gql } from "@apollo/client";

export const CAMPAIGN_INFO_DATA_FRAGMENT = gql`
  fragment CampaignInfoData on CampaignType {
    id
    name
    startDate
    endDate
    status
    averageCpm
    dspSpecific
    availableMetrics
    insightsPermitted
    office
    tier
    exclusionLists{
      id
    }
    inclusionLists{
      id
    }
    dspInfo {
      id
      dspName
      dspMemberId
      isDirect
    }
    productCategories {
      id
      productCategory
      insightCategories
      precisionCategories {
        tier1
        tier2
        tier3
        tier4
      }
    }
    creativeType
    markets {
      name
    }
    advertiserName
    kpis {
      id
      kpiTarget
      kpiType
      dspSpecificKpiParams
    }
    estimatedIllumaBudget
    estimatedIllumaImpressions
    segments {
      id
      name
      # isDeal
      segmentType
    }
    creativeIds {
      creativeId
      isAudited
    }
  }
`;

export const PROTECTED_CAMPAIGN_INFO_DATA_FRAGMENT = gql`
  fragment ProtectedCampaignInfoData on CampaignType {
    creator {
      id
      firstName
      lastName
      email
      agency {
        id
        name
      }
    }
    brand {
      id
      name
    }
    accountManager {
      id
      firstName
      lastName
      email
      agency {
        id
        name
      }
    }
    salesPerson {
      id
      firstName
      lastName
      email
      agency {
        id
        name
      }
    }
    productCategories {
      priceList {
        id
        price
        product
        dsp
      }
      price {
        id
        price
        product
        dsp
      }
    }
    isApproved
    checklist {
      questionId
      questionText
      questionGroup
      checkedBy {
        firstName
        lastName
      }
      isResolved
      resolvedAt
    }
  }
`;

export const CAMPAIGN_INSIGHT_VALUES_FRAGMENT = gql`
  fragment CampaignInsightValues on InsightNodeInterface {
    id
    insightValues {
      impressions
      clicks
      conversions
      urlsCountImpressions
      urlsCountClicks
      urlsCountConversions
      ctr
    }
  }
`;

export const CAMPAIGN_AVAILABLE_METRICS = gql`
  fragment AvailableMetrics on CampaignType {
    availableMetrics
  }
`;
