import { useTheme } from "@chakra-ui/react";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartType,
    ChartTypeRegistry,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    TooltipItem,
  } from 'chart.js';
import { draw } from "patternomaly";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MonthlyRevenueType, OfficeChoices, TargetRevenueType } from "../../../../graphql/generated";
import { getMonthsArray } from "../../../../utils/getMonths";
import { officeFilterOptionType } from "../ChartBox";
import { RevenueDataType } from ".";

interface AnnualRevenueBarChartProps {
  actualData: RevenueDataType,
  actualManagedData: RevenueDataType,
  forecastData: RevenueDataType,
  forecastManagedData: RevenueDataType,
  noIdActualData: RevenueDataType,
  noIdForecastData: RevenueDataType,
  noOfficeActualData: RevenueDataType,
  noOfficeForecastData: RevenueDataType,
  salesforceData: RevenueDataType,
  dataTarget: {targetRevenue: TargetRevenueType}
  year: number
  officeSelect: officeFilterOptionType
  showPastForecast: boolean
  showManaged: boolean
  showSalesforce: boolean
}

export const AnnualRevenueBarChart = ({
  actualData,
  actualManagedData,
  forecastData,
  forecastManagedData,
  noIdActualData,
  noIdForecastData,
  noOfficeActualData,
  noOfficeForecastData,
  salesforceData,
  dataTarget,
  year,
  officeSelect,
  showPastForecast,
  showManaged,
  showSalesforce
}: AnnualRevenueBarChartProps) => {

  const chakraTheme = useTheme();
  ChartJS.register(
    ChartDataLabels,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    interaction: {
      intersect: true,
      mode: "x" as const,
    },
    plugins: {
      datalabels: {
        display: false
      },
      tooltip: {
        filter: (context: TooltipItem<"bar">) => {
          // Removing values of 0 from tooltip
          if (!context.raw) {
            return false;
          } else {
            return true
          }
        },
        callbacks: {
          footer: (context: TooltipItem<"bar" | "line">[]) => {
            let total = 0
            context.forEach((item) => {
              // Not including target value in total
              if (item.dataset.type === "bar") {
                if (typeof item.raw === "number") {
                  total += item.raw
                }
              }
            });
            // No footer when only one value
            if (context.length <= 1) {
              return "";
            }
            return `Total: ${total.toLocaleString()}`;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const prepareDatasets = () => {
    const datasets: {
      type: keyof ChartTypeRegistry;
      label: string;
      data: number[];
      borderColor: any;
      backgroundColor: any;
      borderWidth: number;
    }[] = [];
    const currentYear = new Date().getFullYear();

    // ALL OFFICES OPTION
    if (officeSelect.value === "ALL") {
      // Total Actual and Non-Managed
      pushBarData(datasets, showManaged ? "Actual Self-Service" : "Actual", actualData.resultTotal.data?.monthlyAggregatedRevenue, year,
        chakraTheme.colors.distinctDataColors.orange, "Stack 0");
      // Total Managed
      showManaged && pushBarData(datasets, "Actual Managed", actualManagedData.resultTotal.data?.monthlyAggregatedRevenue, year,
        chakraTheme.colors.distinctDataColors.maroon, "Stack 0");
      // FORECAST
      if (year >= currentYear || showPastForecast) {
        // Total forecast and Non-Managed
        pushBarData(datasets, showManaged ? "Forecast Self-Service" : "Forecast", forecastData.resultTotal.data?.monthlyAggregatedRevenue, year,
          draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.orange),
          "Stack 2", true, showPastForecast);
        // Total Non-Managed forecast
        showManaged && pushBarData(datasets, "Forecast Managed", forecastManagedData.resultTotal.data?.monthlyAggregatedRevenue, year,
          draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.maroon),
          "Stack 2", true, showPastForecast);
      }
    }

    // When Office Select is NOT "ALL", how revenue is split is shown
    if (officeSelect.value !== "ALL") {

      // US
      if (officeSelect.value === "COMBINED" || officeSelect.value === OfficeChoices.Us) {
        // Actual Total US revenue and Non-Managed
        pushBarData(datasets, showManaged ? "Actual Self-service US" : "Actual US", actualData.resultUs.data?.monthlyAggregatedRevenue, year,
          chakraTheme.colors.distinctDataColors.red, "Stack 0");
        // Actual Managed US revenue
        showManaged && pushBarData(datasets, "Actual Managed US", actualManagedData.resultUs.data?.monthlyAggregatedRevenue, year,
          chakraTheme.colors.distinctDataColors.maroon, "Stack 0");
        // US FORECAST
        if (year >= currentYear || showPastForecast) {
          // US forecast, Total and Non-Managed
          pushBarData(datasets, showManaged ? "Forecast Self-service US" : "Forecast US", forecastData.resultUs.data?.monthlyAggregatedRevenue, year,
            draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.red),
            "Stack 2", true, showPastForecast);
          // US Non-Managed forecast
          showManaged && pushBarData(datasets, "Forecast Managed US", forecastManagedData.resultUs.data?.monthlyAggregatedRevenue, year,
            draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.maroon),
            "Stack 2", true, showPastForecast);
        }
      }

      // ROW
      if (officeSelect.value === "COMBINED" || officeSelect.value === OfficeChoices.Row) {
        // Actual Total ROW revenue and Non-Managed
        pushBarData(datasets, showManaged ? "Actual Self-service ROW" : "Actual ROW", actualData.resultRow.data?.monthlyAggregatedRevenue, year,
          chakraTheme.colors.distinctDataColors.blue, "Stack 0");
        // Actual Managed ROW revenue
        showManaged && pushBarData(datasets, "Actual Managed ROW", actualManagedData.resultRow.data?.monthlyAggregatedRevenue, year,
          chakraTheme.colors.distinctDataColors.navy, "Stack 0");
        // ROW FORECAST
        if (year >= currentYear || showPastForecast) {
          // ROW forecast, Total and Non-Managed
          pushBarData(datasets, showManaged ? "Forecast Self-service ROW" : "Forecast ROW", forecastData.resultRow.data?.monthlyAggregatedRevenue, year,
            draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.blue),
            "Stack 2", true, showPastForecast);
          // Managed ROW forecast
          showManaged && pushBarData(datasets, "Forecast Managed ROW", forecastManagedData.resultRow.data?.monthlyAggregatedRevenue, year,
            draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.navy),
            "Stack 2", true, showPastForecast);
        }
      }

      if (officeSelect.value === "COMBINED") {
        // Actual No ID
        pushBarData(datasets, "Unidentifiable Actual", noIdActualData.resultTotal.data?.monthlyAggregatedRevenue, year,
          chakraTheme.colors.distinctDataColors.green, "Stack 0");
        if (year >= currentYear || showPastForecast) {
          // Forecast No ID
          pushBarData(datasets, "Unidentifiable Forecast", noIdForecastData.resultTotal.data?.monthlyAggregatedRevenue, year,
            draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.green),
            "Stack 2", true, showPastForecast);
        }
        // Actual No Office
        pushBarData(datasets, "No Office Actual", noOfficeActualData.resultTotal.data?.monthlyAggregatedRevenue, year,
          chakraTheme.colors.distinctDataColors.gold, "Stack 0");
        if (year >= currentYear || showPastForecast) {
          // Forecast No Office
          pushBarData(datasets, "No Office Forecast", noOfficeForecastData.resultTotal.data?.monthlyAggregatedRevenue, year,
            draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.gold),
            "Stack 2", true, showPastForecast);
        }
      }
    }
    // SALESFORCE
    if (showSalesforce && (year >= currentYear || showPastForecast)) {
      // Salesforce All
      officeSelect.value === "ALL" &&
        showSalesforce && pushBarData(datasets, "Salesforce", salesforceData.resultTotal.data?.monthlyAggregatedRevenue, year,
          draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.olive),
          "Stack 2", true, showPastForecast);
      // US Salesforce forecast
      (officeSelect.value === "COMBINED" || officeSelect.value === OfficeChoices.Us) &&
        showSalesforce && pushBarData(datasets, "Salesforce US", salesforceData.resultUs.data?.monthlyAggregatedRevenue, year,
          draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.olive),
          "Stack 2", true, showPastForecast);
      // ROW Salesforce forecast
      (officeSelect.value === "COMBINED" || officeSelect.value === OfficeChoices.Row) &&
        showSalesforce && pushBarData(datasets, "Salesforce ROW", salesforceData.resultRow.data?.monthlyAggregatedRevenue, year,
          draw("diagonal-right-left", chakraTheme.colors.distinctDataColors.beige),
          "Stack 2", true, showPastForecast);
    }
    // Target line
    if (dataTarget.targetRevenue) {
      datasets.push({
        type: "line" as ChartType,
        label: "Target",
        data: sortTargetRevenue(dataTarget.targetRevenue, officeSelect.value),
        borderColor: chakraTheme.colors.distinctDataColors.purple,
        backgroundColor: chakraTheme.colors.distinctDataColors.purple,
        borderWidth: 2,
      })
    };

    return datasets;
  }

  const chartData = {
    labels: getMonthsArray(),
    datasets: prepareDatasets()
  };

  return (
    <Chart
      type={"bar"}
      options={options}
      data={chartData}
      height={140}
    />
  )
}

const prepareMonthlyData = (
  data: MonthlyRevenueType[] | undefined,
  year: number,
  isForecast: boolean,
  showPastForecast: boolean
) => {
  if (!data) {
    return 0;
  }

  return data.map((monthData, ix) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    // Only showing forecast for current and future months
    if (isForecast) {
      if ((year === currentYear && currentMonth > ix) && !showPastForecast) {
        return 0
      }
    }
    return monthData.revenue
  })
}

const sortTargetRevenue = (targetRevenueData: TargetRevenueType, office: OfficeChoices | "ALL" | "COMBINED") => {
  const months = getMonthsArray()
  type ObjectKey = keyof typeof targetRevenueData;

  if(!targetRevenueData) {
    return []
  }

  return months.map((month) => {
    if (office === OfficeChoices.Us) {
      return Number(targetRevenueData[(month.toLowerCase() + "Us") as ObjectKey])
    }
    if (office === OfficeChoices.Row) {
      return Number(targetRevenueData[(month.toLowerCase() + "Row") as ObjectKey])
    }
    return Number(targetRevenueData[(month.toLowerCase() + "Us") as ObjectKey]) +
      Number(targetRevenueData[(month.toLowerCase() + "Row") as ObjectKey]);
  });
}

const pushBarData = (
  datasets: any[],
  label: string,
  data: MonthlyRevenueType[] | undefined,
  year: number,
  color: string | CanvasPattern,
  stack: string,
  isForecast = false,
  showPastForecast = false,
) => {
  return datasets.push({
    type: "bar" as ChartType,
    label: label,
    data:  prepareMonthlyData(data, year, isForecast, showPastForecast),
    backgroundColor: color,
    stack: stack,
  });
}
