import {IconButton, useToast} from "@chakra-ui/react";
import { MdContentCopy } from "react-icons/md";
import { FC } from "react";
import { ToastNotificationBox } from "../../components/toastNotificationBox/ToastNotificationBox";

export const CopyButton: FC<{ textCopied: string; title: string }> = ({
    textCopied,
    title,
  }) => {
    const toast = useToast();
    const toastId = `${title}-text-copied-toast`;
    return (
      <IconButton
        aria-label={"copy text to clipboard"}
        icon={<MdContentCopy />}
        variant={"unstyled"}
        display={"flex"}
        justifyContent={"center"}
        _hover={{
          color: "primary.dark",
        }}
        onClick={() => {
          navigator.clipboard.writeText(textCopied).then(() => {
            // Notify text copied
            toast({
              id: toastId,
              status: "info",
              duration: 3000,
              render: () => {
                return (
                  <ToastNotificationBox
                    content={`${title} copied to clipboard.`}
                  />
                );
              },
            });
          });
        }}
      />
    );
  };
