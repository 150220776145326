import { Box, BoxProps } from "@chakra-ui/react";
import { DetailField } from "../../components/detailField/DetailField";
import { getKPIDisplay, unCamelcase } from "../../utils/displayGetters";
import { useCampaignInfo } from "./index";
import { SectionBox } from "../../components/sectionBox/SectionBox";

export const KPIDetailsSummary = (props: BoxProps) => {
  const { campaign } = useCampaignInfo();
  return (
    <SectionBox sectionHeader={"KPIs"} {...props}>
      {campaign.kpis?.map((kpi) => (
        <Box key={kpi.id + kpi.kpiType}>
          <DetailField
            label={"Target"}
            value={`${kpi.kpiTarget} ${getKPIDisplay(kpi.kpiType)}`}
          />
          {kpi.dspSpecificKpiParams && (
            <>
              {Object.entries(JSON.parse(kpi.dspSpecificKpiParams))
                .filter(([, value]) => !!value)
                .map(([key, value]) => (
                  <DetailField
                    key={key}
                    label={unCamelcase(key)}
                    value={value}
                  />
                ))}
            </>
          )}
        </Box>
      ))}
    </SectionBox>
  );
};
