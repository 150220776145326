import { useMutation } from "@apollo/client";
import {
    Button,
    Collapse,
    Flex,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    useToast
} from "@chakra-ui/react";
import { useCampaignInfo } from "..";
import { ToastNotificationBox } from "../../../components/toastNotificationBox/ToastNotificationBox";
import { CampaignTierChoices, OfficeChoices } from "../../../graphql/generated";
import { PROTECTED_GET_CAMPAIGN_INFO, UPDATE_CAMPAIGN } from "../../../graphql/operations/campaignOperations/operations";

interface SavePopoverProps {
    officeSelect: OfficeChoices,
    tierSelect: CampaignTierChoices,
    brandSelect: {label: string, value: string} | undefined,
    accountManagerSelect: Record<string, string>,
    salesPersonSelect: Record<string, string>,
    productPrices: Record<string, string>,
    isPriceSelected: boolean,
    isDirect: boolean,
}

export const SavePopover = ({
    officeSelect,
    tierSelect,
    brandSelect,
    accountManagerSelect,
    salesPersonSelect,
    productPrices,
    isPriceSelected,
    isDirect
}: SavePopoverProps) => {

    const { campaign: {accountManager, brand, dspInfo, id, office, salesPerson, tier} } = useCampaignInfo();
    const toast = useToast();
    const toastId = "campaign-details-toast";

    const [updateCampaign, { loading }] = useMutation(UPDATE_CAMPAIGN, {
        refetchQueries: [PROTECTED_GET_CAMPAIGN_INFO],
    });

    return (
        <Flex justifyContent={"flex-end"} mt={3}>
                <Collapse
                    in={
                        accountManagerSelect?.value !== accountManager?.id ||
                        salesPersonSelect?.value !== salesPerson?.id ||
                        officeSelect !== office ||
                        tierSelect !== tier ||
                        brandSelect?.label !== brand?.name ||
                        isPriceSelected ||
                        isDirect !== dspInfo.isDirect
                    }
                >
                <Popover placement={"left-start"}>
                    {({ onClose }) => (
                    <>
                        <PopoverTrigger>
                            <Button variant={"primary"} isLoading={loading}>
                                Save
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent
                            bg={"text.dark"}
                            color={"white"}
                            _focus={{
                                boxShadow: "none",
                            }}
                        >
                            <PopoverCloseButton />
                            <PopoverHeader fontWeight={"bold"} fontSize={"18px"}>
                                Change details?
                            </PopoverHeader>
                            <PopoverBody>Are you sure?</PopoverBody>
                            <PopoverFooter d={"flex"} justifyContent={"flex-end"}>
                                <Button
                                    onClick={() => {
                                        updateCampaign({
                                            variables: {
                                                id: id,
                                                updateFields: {
                                                    // Only pass values changed values, this makes
                                                    // sure slack notification only shows the
                                                    // field that has changed
                                                    accountManager: accountManagerSelect?.value !== accountManager?.id ?
                                                        accountManagerSelect.value : null,
                                                    salesPerson: salesPersonSelect?.value !== salesPerson?.id ?
                                                        salesPersonSelect.value : null,
                                                    office: officeSelect !== office ? officeSelect : null,
                                                    brand: brandSelect?.label !== brand?.name ? brandSelect?.label : null,
                                                    tier: tierSelect !== tier ? tierSelect : null,
                                                    productPrice: isPriceSelected ?
                                                        Object.entries(productPrices).map(
                                                            ([productId, priceId]) => {
                                                                return {
                                                                    productId,
                                                                    priceId,
                                                                };
                                                            }
                                                        ) : null,
                                                    dspInfo: {isDirect: isDirect !== dspInfo.isDirect ? isDirect : null}
                                                },
                                            },
                                        })
                                        .then(() => {
                                            toast({
                                                id: toastId,
                                                status: "info",
                                                duration: 3000,
                                                render: () => {
                                                    return (
                                                        <ToastNotificationBox
                                                            content={"Campaign Details Set!"}
                                                        />
                                                    );
                                                },
                                            });
                                        })
                                        .catch((err) => err);
                                        onClose();
                                    }}
                                    variant={"primary"}
                                    bg={"red.500"}
                                    color={"white"}
                                    size={"sm"}
                                >
                                    Yes
                                </Button>
                            </PopoverFooter>
                        </PopoverContent>
                    </>
                    )}
                </Popover>
                </Collapse>
            </Flex>
    )
}
