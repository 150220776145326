import { Icon } from "@chakra-ui/react";

export const ImpressionsIcon = (props: Record<string, any>) => (
  <Icon viewBox="0 0 16 11" {...props}>
    <g transform="translate(42.74 32)">
      <g transform="translate(-43 -32)">
        <path
          className="a"
          d="M-27.069-26.785A9.409,9.409,0,0,0-35-32c-3.5,0-6.545,2.512-7.931,5.215a.625.625,0,0,0,0,.57A9.409,9.409,0,0,0-35-21c3.939,0,6.842-3.091,7.931-5.215a.625.625,0,0,0,0-.57ZM-35-22.25c-2.837,0-5.218-1.885-6.655-4.25,1.421-2.339,3.795-4.25,6.655-4.25s5.226,1.9,6.655,4.25c-1.444,2.376-3.834,4.25-6.655,4.25Zm0-7.32a3.074,3.074,0,0,0-3.07,3.07A3.074,3.074,0,0,0-35-23.43a3.074,3.074,0,0,0,3.07-3.07A3.074,3.074,0,0,0-35-29.57Zm0,4.891a1.822,1.822,0,0,1-1.82-1.82A1.822,1.822,0,0,1-35-28.32a1.822,1.822,0,0,1,1.82,1.82A1.822,1.822,0,0,1-35-24.68Zm0,0"
          transform="translate(43.261 32)"
        />
      </g>
    </g>
  </Icon>
);
