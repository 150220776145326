import { Box, Checkbox, HStack, Spacer, Text } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MdCheck, MdClose } from "react-icons/md";

interface CategoryChoicesProps {
    position: number
    category: string
    sectionChoices: string[]
    setSectionChoices: Dispatch<SetStateAction<string[]>>
    rejectedSectionChoices: string[]
    setRejectedSectionChoices: Dispatch<SetStateAction<string[]>>
}

export const CategoryChoices = ({
    position,
    category,
    sectionChoices,
    setSectionChoices,
    rejectedSectionChoices,
    setRejectedSectionChoices,
}: CategoryChoicesProps) => {

    const [isChecked, setIsChecked] = useState(false);
    const [isCrossChecked, setIsCrossChecked] = useState(false);

    useEffect(() => {
        if (isChecked) {
            setIsCrossChecked(false);
            const newSectionChoices: string[] = [...sectionChoices];
            newSectionChoices.push(category);
            setSectionChoices(newSectionChoices);

        } else {

            const categoryIndex = sectionChoices.indexOf(category);
            if (categoryIndex !== -1) {
                const newSectionChoices = [...sectionChoices];
                newSectionChoices.splice(categoryIndex, 1);
                setSectionChoices(newSectionChoices);
            }

        }
    }, [isChecked])

    useEffect(() => {
        if (isCrossChecked) {

            setIsChecked(false);
            const newSectionChoices: string[] = [...rejectedSectionChoices];
            newSectionChoices.push(category);
            setRejectedSectionChoices(newSectionChoices);

        } else {

            const categoryIndex = rejectedSectionChoices.indexOf(category);
            if (categoryIndex !== -1) {
                const newSectionChoices = [...rejectedSectionChoices];
                newSectionChoices.splice(categoryIndex, 1);
                setRejectedSectionChoices(newSectionChoices);
            }

        }
    }, [isCrossChecked])

    return (
        <>
            <Box
                m={2}
                px={4}
                py={2}
                borderColor={"#D4DCE4"}
                borderWidth={1}
                borderRadius={"lg"}
                _hover={{
                    backgroundColor: "#EAF0F2",
                }}
            >
                <HStack spacing={6}>
                    <Text>{position}</Text>
                    <Checkbox
                        size={"lg"}
                        icon={<CheckboxIcon as={MdCheck} />}
                        isChecked={isChecked}
                        onChange={() => setIsChecked(prev => !prev)}
                    />
                    <Text ml={2} maxWidth={"100%"}>
                        {formatCategoryChoice(category)}
                    </Text>
                    <Spacer />
                    <Checkbox
                        size={"lg"}
                        icon={<CheckboxIcon as={MdClose} />}
                        isChecked={isCrossChecked}
                        onChange={() => setIsCrossChecked(prev => !prev)}
                    />
                </HStack>
            </Box>
        </>
    )
}

// This is used to remove the dom element errors that appear in the console
export const CheckboxIcon = ({
    isChecked,
    isIndeterminate,
    as: Element,
    ...rest
}: {
    isChecked?: boolean;
    isIndeterminate?: boolean;
    as: React.ElementType;
}) => {
    return <Element {...rest} />;
}

const formatCategoryChoice = (category: string) => {

    let formattedChoice = "";

    // Remove anything before ">"
    const indexOfLastTier = category.lastIndexOf(">");
    formattedChoice = category.slice(indexOfLastTier + 1);

    // Capitilising first letter
    formattedChoice = formattedChoice.charAt(0).toUpperCase() + formattedChoice.slice(1);

    // Remove category weighting
    const categoryEndIndex = formattedChoice.indexOf("(");
    if (categoryEndIndex === -1) {
        return formattedChoice
    }
    return formattedChoice.slice(0, categoryEndIndex);
}

// {addOther &&
//     <Box
//         m={2}
//         px={4}
//         py={2}
//         borderColor={"#D4DCE4"}
//         borderWidth={1}
//         borderRadius={"lg"}
//         cursor={"pointer"}
//         onClick={() => {
//             if (category === chosenCategory) {
//                 setChosenCategory("");
//             } else {
//                 setChosenCategory(category);
//             }
//         }}
//         _hover={{
//             backgroundColor: "#EAF0F2",
//         }}
//     >
//         <HStack spacing={6}>
//             <Text>{position}</Text>
//             <Checkbox
//                 pointerEvents={"none"}
//                 isChecked={category === chosenCategory}
//             >
//                 <Text ml={2} maxWidth={"100%"}>
//                     Other
//                 </Text>
//             </Checkbox>
//             {/* <Input /> */}
//         </HStack>
//     </Box>
// }
