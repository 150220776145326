import { Button, Text, VStack } from "@chakra-ui/react";
import { FormInputField } from "../components/formInputField/FormInputField";
import * as Yup from "yup";
import { Form, Formik, Field, FieldProps } from "formik";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../graphql/operations/userOperations";
import { useNavigate } from "react-router-dom";

export const ResetPassword = () => {
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{
        userEmail: "",
      }}
      validationSchema={Yup.object({
        userEmail: Yup.string()
          .email("Please enter a valid email address")
          .required("Please enter your email address"),
      }).defined()}
      onSubmit={(values) => {
        resetPassword({
          variables: values,
        })
          .then(() => {
            navigate("done");
          })
          .catch((err) => err);
      }}
    >
      <Form>
        <VStack
          spacing={5}
          minW={["100%", null, null, "30rem", null]}
          maxW={"20rem"}
          mx={"auto"}
          bg={"white"}
          p={6}
          borderRadius={"lg"}
        >
          <Text fontWeight={"bold"}>Reset your password</Text>
          <Field name={"userEmail"}>
            {({ form, field }: FieldProps) => (
              <FormInputField
                fieldType={"email"}
                label={"Email Address"}
                placeholder={"Enter your email address"}
                isInvalid={!!(form.errors.userEmail && form.touched.userEmail)}
                isRequired={true}
                inputFieldProps={field}
                errorMessage={form.errors.userEmail?.toString()}
              />
            )}
          </Field>
          <Button variant={"primary"} type={"submit"} isLoading={loading}>
            Reset Password
          </Button>
        </VStack>
      </Form>
    </Formik>
  );
};
