import { Box, Flex, Spacer, Text } from "@chakra-ui/react"
import { Dispatch, SetStateAction } from "react"
import { SelectField } from "../../../components/selectField/SelectField"
import { UserType } from "../../../graphql/generated"

interface PersonSelectProps {
    dataAccount: UserType[]
    dataSales: UserType[]
    dataSenior: UserType[]
    accountManager: UserType
    setAccountManagerSelect: Dispatch<SetStateAction<Record<string, string>>>
    salesPerson: UserType
    setSalesPersonSelect: Dispatch<SetStateAction<Record<string, string>>>
}

export const PersonSelect = ({
    dataAccount,
    dataSales,
    dataSenior,
    accountManager,
    setAccountManagerSelect,
    salesPerson,
    setSalesPersonSelect
}: PersonSelectProps) => {

    const options = [
        ...getRoleOptions(dataAccount),
        ...getRoleOptions(dataSales),
        ...getRoleOptions(dataSenior)
    ]

    return (
        <Flex justifyContent={"center"}>
            <Box width={"48%"}>
                <Text my={5}>Account Manager</Text>
                <Box ml={4}>
                    <SelectField
                        placeholder={"Set Account Manager"}
                        onChange={(option) => {
                            setAccountManagerSelect(option)
                        }}
                        defaultValue={accountManager ?
                            {label: `${accountManager.lastName}, ${accountManager.firstName}`, value: accountManager.id} :
                            undefined
                        }
                        isMulti={false}
                        options={options}
                        hideSelectedOptions={true}
                    />
                </Box>
            </Box>
            <Spacer />
            <Box width={"48%"}>
                <Text my={5}>Sales Person</Text>
                <Box ml={4}>
                    <SelectField
                        placeholder={"Set Sales Person"}
                        onChange={(option) => {
                            setSalesPersonSelect(option)
                        }}
                        defaultValue={salesPerson ?
                            {label: `${salesPerson.lastName}, ${salesPerson.firstName}`, value: salesPerson.id} :
                            undefined
                        }
                        isMulti={false}
                        options={options}
                        hideSelectedOptions={true}
                    />
                </Box>
            </Box>
        </Flex>
    )
}

const getRoleOptions = (
    users: UserType[]
): {label: string, value: string}[] => {
    return users.map((user) =>
            ({label: `${user.lastName}, ${user.firstName}`, value: user.id})
    );
}
