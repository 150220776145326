import { useEffect, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { userDetailsVar } from "../../lib/cache";
import { AccountsUserRoleChoices, UserType } from "../../graphql/generated";
import { GrAdd } from "react-icons/gr";
import { GoDashboard } from "react-icons/go";
import { IoIosArrowRoundBack } from "react-icons/io";

export const CampaignHomeHeader = (props: BoxProps) => {
  const [userDisplay, setUserDisplay] = useState<UserType | null>(null);
  const currentUser = useReactiveVar(userDetailsVar);
  const navigate = useNavigate();

  useEffect(() => {
    setUserDisplay(currentUser);
  }, [currentUser]);

  if (!userDisplay) {
    return <></>
  }

  return (
    <Flex
      justifyContent={"space-between"}
      align={"center"}
      mb={[3, null, null, 5, null]}
      minW={"100%"}
      {...props}
    >
      <HStack>
        {(userDisplay?.isStaff || userDisplay?.agency?.hitlPermission?.isPermitted) &&
          <Box
            as={"button"}
            onClick={() => navigate("/")}
            mr={2}
          >
            <HStack
              spacing={1}
              _hover={{
                stroke: "primary.darker",
                color: "primary.darker",
              }}
              align={"center"}
            >
              <Icon
                as={IoIosArrowRoundBack}
                width={6}
                height={6}
                strokeWidth={10}
              />
              <Text fontSize={"sm"}>
                Back
                {/* {currentUser?.isStaff ? "Back to Campaigns" : "Back to Your Campaigns"} */}
              </Text>
            </HStack>
          </Box>
        }
        <Text fontWeight={"medium"} fontSize={["2xl", null, null, "3xl", null]}>
          {userDisplay?.isStaff ? "Campaigns" : "Your Campaigns"}
        </Text>
      </HStack>
      <HStack>
        {// ToDo: This check will eventually not be required when more is
        // added to admin dahsboard
        (currentUser?.role === AccountsUserRoleChoices.Tech ||
          currentUser?.role === AccountsUserRoleChoices.SeniorManagement ||
          currentUser?.role === AccountsUserRoleChoices.Sales
          ) &&
          <Link to={"admin-dashboard"}>
            <IconButton
              aria-label={"admin-dashboard"}
              icon={<GoDashboard />}
              variant={"primary"}
              color={"white"}
              backgroundColor={"accent"}
              display={["flex", null, "none", null, null]}
            />
            <Button
              variant={"primary"}
              color={"white"}
              backgroundColor={"accent"}
              size={"sm"}
              leftIcon={<Icon as={GoDashboard} w={5} h={5}/>}
              fontSize={["xs", null, null, "md", null]}
              display={["none", null, "flex", null, null]}
              hidden={!currentUser?.isStaff}
              _hover={{
                bg: "accentLight"
              }}
            >
              Admin Dashboard
            </Button>
          </Link>
        }
        <Link to={"new"}>
          <IconButton
            aria-label={"link top new campaign"}
            icon={<GrAdd />}
            variant={"primary"}
            display={["flex", null, "none", null, null]}
          />
          <Button
            variant={"primary"}
            size={"sm"}
            leftIcon={<Icon as={GrAdd} />}
            fontSize={["xs", null, null, "md", null]}
            display={["none", null, "flex", null, null]}
          >
            New Campaign
          </Button>
        </Link>
      </HStack>
    </Flex>
  );
};
