import { Box, Checkbox, Collapse, HStack, Text } from "@chakra-ui/react";
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MdCheck } from "react-icons/md";
import { ClassificationCategories } from "..";
import { MultiTextInput } from "../../../components/multiTextInput/MultiTextInput";
import { SelectField } from "../../../components/selectField/SelectField";
import { PrecisionCategoriesOptionsType } from "../../../graphql/generated";
import { CategoryChoices, CheckboxIcon } from "./CategoryChoices";

interface MultipleChoiceSectionProps {
    index: number,
    objectKey: string,
    objectValue: string[]
    setChosenCategories: Dispatch<SetStateAction<Record<string, string[]>>>
    setRejectedCategories: Dispatch<SetStateAction<Record<string, string[]>>>
    setForceUpdate: Dispatch<SetStateAction<boolean>>
    chosenClassification: Record<string, string[]>
    dataPrecision: {precisionCategoriesOptions: PrecisionCategoriesOptionsType[]}
}

export const MultipleChoiceSection = ({
    index,
    objectKey,
    objectValue,
    setChosenCategories,
    setRejectedCategories,
    setForceUpdate,
    chosenClassification,
    dataPrecision,
}: MultipleChoiceSectionProps) => {


    const [sectionChoices, setSectionChoices] = useState<string[]>([]);
    const [rejectedSectionChoices, setRejectedSectionChoices] = useState<string[]>([]);
    const [isOtherChecked, setIsOtherChecked] = useState(false);
    const [selectedDropdown, setSelectedDropdown] = useState<{label: string, value: string}[]>([]);
    const [collatedTextInputs, setCollatedTextInputs] = useState<string[]>([])

    // Limiting to top three
    let topThreeChoices = objectValue.slice(0,3);
    if (objectKey.includes("illuma_ents")) {
        topThreeChoices = objectValue
    }

    useEffect(() => {
        // Collating then assigning options
        setChosenCategories((prev) => {
            let formattedSelectedDropdown = [];

            formattedSelectedDropdown = selectedDropdown.map((option) => option.value)

            prev[objectKey] = [...sectionChoices, ...formattedSelectedDropdown, ...collatedTextInputs];
            return prev
        });

        setForceUpdate(prev => !prev);
    }, [sectionChoices, selectedDropdown, collatedTextInputs])

    useEffect(() => {
        // Assigning rejected options
        setRejectedCategories((prev) => {
            prev[objectKey] = [...rejectedSectionChoices];
            return prev
        });

        setForceUpdate(prev => !prev);
    }, [rejectedSectionChoices])

    useEffect(() => {
        // Clearing "Other" checkbox and dropdown when skipping
        if (_.isEmpty(chosenClassification)) {
            setSelectedDropdown([]);
            setIsOtherChecked(false);
        }

    }, [chosenClassification])

    // if (loading) {
    //     return <></>
    // }

    // if (error) {
    //     return <></>
    // }

    const dropdownOptions: {label: string, value: string}[] = [];

    // Assigning dropdown options
    switch(objectKey) {
        case ClassificationCategories.precision: {

            const precisionCategories: PrecisionCategoriesOptionsType[] = dataPrecision.precisionCategoriesOptions;
            const listOptions = tierFilter(precisionCategories, 4).map((option) =>
                ({label: option.label, value: option.value})
            );
            dropdownOptions.push(...listOptions);

            break;
        }
        case ClassificationCategories.brandSafety: {

            const precisionCategories: PrecisionCategoriesOptionsType[] = dataPrecision.precisionCategoriesOptions;
            const sensitiveOptions = filterSensitiveCategories(precisionCategories)
            const listOptions = tierFilter(sensitiveOptions, 2).map((option) =>
                ({label: option.label, value: option.value})
            );
            dropdownOptions.push(...listOptions)

            break;
        }
        case ClassificationCategories.emotion:
            dropdownOptions.push(...prepareOptions(emotionCategoryOptions))
            break;
        case ClassificationCategories.sentiment: {
            const allSentimentOptions = prepareOptions(sentimentCategoryOptions);
            const filteredOptions = allSentimentOptions.filter((option) => {
                if (option.value !== objectValue[0]) {
                    return option
                }
            })
            dropdownOptions.push(...filteredOptions);
            break;
        }
    }
    // Sorting into alphabetical order
    dropdownOptions.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <Box
            key={index}
            width={["100%", null, "50%", null, null]}
            px={4}
            pb={4}
        >
            <Text ml={2} fontWeight={"semibold"}>{formatSectionHeader(objectKey)}</Text>
            {topThreeChoices.map((choice, ix) => (
                <CategoryChoices
                    key={ix}
                    position={ix + 1}
                    category={choice}
                    sectionChoices={sectionChoices}
                    setSectionChoices={setSectionChoices}
                    rejectedSectionChoices={rejectedSectionChoices}
                    setRejectedSectionChoices={setRejectedSectionChoices}
                />
            ))}
                    <Box
                        m={2}
                        px={4}
                        py={2}
                        borderColor={"#D4DCE4"}
                        borderWidth={1}
                        borderRadius={"lg"}
                        cursor={"pointer"}
                        onClick={() => {
                            setIsOtherChecked(prev => !prev);
                        }}
                        _hover={{
                            backgroundColor: "#EAF0F2",
                        }}
                    >
                        <HStack spacing={6}>
                            <Text>{topThreeChoices.length + 1}</Text>
                            <Checkbox
                                size={"lg"}
                                icon={<CheckboxIcon as={MdCheck}/>}
                                pointerEvents={"none"}
                                isChecked={isOtherChecked}
                            />
                            <Text ml={2} maxWidth={"100%"}>
                                Other
                            </Text>
                        </HStack>
                        {
                            (objectKey === ClassificationCategories.precision ||
                            objectKey === ClassificationCategories.emotion ||
                            objectKey === ClassificationCategories.brandSafety ||
                            objectKey === ClassificationCategories.sentiment) ?
                                <Collapse
                                    in={isOtherChecked}
                                    onAnimationComplete={() => {
                                        setSelectedDropdown([]);
                                    }}
                                >
                                    <Box
                                        mt={2}
                                        pl={8}
                                        onClick={(e) => {
                                            // Stop checking box when clicking dropdown
                                            e.stopPropagation()
                                        }}
                                    >
                                        <SelectField
                                            placeholder={`Select ${formatSectionHeader(objectKey).toLowerCase()} category`}
                                            onChange={(option) => {
                                                if (Array.isArray(option)) {
                                                    setSelectedDropdown(option)
                                                } else {
                                                    setSelectedDropdown([option])
                                                }
                                            }}
                                            isMulti={objectKey === ClassificationCategories.sentiment ? false : true}
                                            options={dropdownOptions}
                                            value={selectedDropdown}
                                            menuPlacement={"auto"}
                                            shortenPrecisionLabel={true}
                                        />
                                    </Box>
                                </Collapse>
                                :
                                <Collapse
                                    in={isOtherChecked}
                                    onAnimationComplete={() => {
                                        setCollatedTextInputs([]);
                                    }}
                                >
                                    <Box
                                        mt={2}
                                        pl={8}
                                        onClick={(e) => {
                                            // Stop checking box when clicking dropdown
                                            e.stopPropagation()
                                        }}
                                    >
                                        <MultiTextInput
                                            selectedInputs={collatedTextInputs}
                                            setSelectedInputs={setCollatedTextInputs}
                                            placeholder={`Input ${formatSectionHeader(objectKey).toLowerCase()}`}
                                        />
                                    </Box>
                                </Collapse>
                        }
                    </Box>
        </Box>
    )

}

export const formatSectionHeader = (header: string) => {
    let formattedHeader = header

    if (formattedHeader.includes(ClassificationCategories.entities)) {
        // Removing illuma_ent from header
        const indexOfParent = header.search(ClassificationCategories.entities);
        formattedHeader = formattedHeader.slice(indexOfParent + ClassificationCategories.entities.length);
    }

    switch (formattedHeader) {
        case ClassificationCategories.precision:
            formattedHeader = "IAB";
            break;
        case ClassificationCategories.emotion:
            formattedHeader = "EMOTION";
            break;
        case ClassificationCategories.sentiment:
            formattedHeader = "SENTIMENT";
            break;
        case ClassificationCategories.brandSafety:
            formattedHeader = "BRAND SAFETY"
            break;
    }

    formattedHeader = formattedHeader.replaceAll("_", " ");
    formattedHeader = formattedHeader.toLocaleUpperCase();

    return formattedHeader
}

const prepareOptions = (listOptions: string[]) => {

    const dropdownOptions = listOptions.map((option) => (
        {
            label: option.charAt(0).toUpperCase() + option.slice(1),
            value: option
        }
    ))

    return dropdownOptions;
}

// Filter how many tiers are included in dropdown
const tierFilter = (
    precisionCategories: PrecisionCategoriesOptionsType[],
    tierLimit: number
) => {
    const filteredCategories = precisionCategories.filter((category) => category.parents.length < tierLimit);

    const tieredCategories: {label: string, value: string}[] = filteredCategories.map((category) => {

        let newLabel = category.label;
        let newValue = category.label;

        category.parents.forEach((parentIndex) => {
            const parentCategory = filteredCategories.find((parentCategory) => parentCategory.id === Number(parentIndex));
            newLabel = parentCategory?.label + " > " + newLabel;
            // Value doesn't have spaces between > to keep consistency
            newValue = parentCategory?.label + ">" + newValue
        })

        return {
            label: newLabel,
            value: newValue
        }
    })

    return tieredCategories
}

// Dropdown for brand safety has only sensitive categories for options
const filterSensitiveCategories = (precisionCategories: PrecisionCategoriesOptionsType[]) => {

    const sensitiveTopicsCategory = precisionCategories.find((category) => category.label === "Sensitive Topics");
    const sensitiveTopicsIds: number[] = [];

    if (sensitiveTopicsCategory) {
        // Sensitive Topics category
        sensitiveTopicsIds.push(sensitiveTopicsCategory.id);
        // Children of Sensitive Topics category
        const sensitiveTopicsChildrenIds = sensitiveTopicsCategory.children.map((id) => Number(id));
        sensitiveTopicsIds.push(...sensitiveTopicsChildrenIds);
    }

    return precisionCategories.filter((category) => sensitiveTopicsIds.includes(category.id));
}

// Get parent categories to show complete path to category
// const getParentCategories = (
//     precisionCategories:PrecisionCategoriesOptionsType[],
//     chosenCategoryValue: string
// ): string => {

//     const chosenCategory = precisionCategories.find((category) => category.id === Number(chosenCategoryValue));
//     let pathLabel = "";

//     if (chosenCategory) {
//         pathLabel = chosenCategory.label;
//         chosenCategory.parents.forEach((parentId) => {
//             const parentIndex = precisionCategories.findIndex(category => category.id.toString() === parentId);
//             pathLabel = precisionCategories[parentIndex].label + ">" + pathLabel;
//         })
//     }

//     return pathLabel
// }

const emotionCategoryOptions = [
    "informed",
    "self confident",
    "amused",
    "shocked",
    "optimistic",
    "in the mood to spend",
    "adventurous",
    "indulgent",
    "inspired",
    "love",
    "happiness",
    "surprise",
    "anger",
    "sadness",
]

const sentimentCategoryOptions = [
    "negative",
    "neutral",
    "positive"
]


    // useEffect(() => {
    //     // Collating selected choices for parent
    //     setChosenCategories((prev) => {
    //         prev[objectKey] = selectedChoice;
    //         return prev
    //     });

    //     setForceUpdate(prev => !prev);

    // }, [selectedChoice])

    // useEffect(() => {
    //     // Assigning dropdown to selected choice
    //     if (isOtherChecked) {
    //         // Check to see if dropdown is using precision categories as options
    //         if (data) {
    //             // setSelectedChoice(
    //             //     getParentCategories(data.precisionCategoriesOptions, selectedDropdown.value)
    //             // );
    //         } else {
    //             // setSelectedChoice(selectedDropdown.value);
    //         }
    //     }
    // }, [selectedDropdown])

    // Assigning "other" text input to selected choice when text is inputted
    // useEffect(() => {
    //     if (isOtherChecked) {
    //         setSelectedChoice(textInput);
    //     }
    // }, [textInput])

    // Dont show if less than three
    // if (topThreeChoices.length < 3) {
    //     return <></>
    // }
