import * as React from "react";
import {
  Box,
  BoxProps,
  Flex,
  HStack,
  Text
} from "@chakra-ui/react";
import { IllumaLogo } from "../../assets/icons/IllumaLogo";
import { Link } from "react-router-dom";
import { AccountArea } from "./AccountArea";

export const Header = ({
  pageTitle = "Insights",
  children,
  ...rest
}: BoxProps & { pageTitle?: string }) => {
  return (
    <Box mb={8}{...rest}>
      <HeaderRowContainer>
        <Flex justifyContent={"space-between"}>
          <HStack>
            <Link to={"/"}>
              <IllumaLogo w={32} h={8} />
            </Link>
            <Text fontWeight={"medium"} color={"muted"} fontSize={"18px"} h={5}>
              {pageTitle}
            </Text>
          </HStack>
          <HStack>
            {/* CampaignSelectDisplay used to be here */}
            <AccountArea />
          </HStack>
        </Flex>
      </HeaderRowContainer>
      {children && <HeaderRowContainer>{children}</HeaderRowContainer>}
    </Box>
  );
};

const HeaderRowContainer: React.FC = ({ children }) => (
  <Box bg={"white"} px={[2, null, null, null, 0]}>
    <Box maxW={"80rem"} mx={"auto"}>
      {children}
    </Box>
  </Box>
);
