import { CategoryRankBox } from "./CategoryRankBox";
import {
  CategoryInsightNode,
} from "../../../graphql/generated";
import { useParams } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_CATEGORY_RANKINGS } from "../../../graphql/operations/campaignOperations/operations";
import { insightsDatesVar, insightsMetricVar } from "../../../lib/cache";
import { ComponentLoading } from "../../../components/loading/ComponentLoading";
import { MetricKey } from "../../../../types";

export const Rankings = () => {
  const { id: campaignId } = useParams();
  const insightsMetric = useReactiveVar(insightsMetricVar);
  const dateRange = useReactiveVar(insightsDatesVar);

  const { data, loading, error } = useQuery(GET_CATEGORY_RANKINGS, {
    variables: {
      campaignId,
      insightsMetric: insightsMetric,
      dateRange,
    },
  });

  if (loading) {
    return (
      <ComponentLoading
        text={"Generating category rankings..."}
        justifyContent={"left"}
      />
    );
  }

  if (error) {
    console.log(error);
    return <div>Big error</div>;
  }

  const { top, bottom } = data.insightsCategoryRankings;
  return top.length && bottom.length ? (
    <>
      <CategoryRankBox
        categories={top.map(
          ({
            category,
            insightValues: {
              [insightsMetric.toLowerCase() as MetricKey]: metric,
            },
          }: CategoryInsightNode) => ({
            category,
            metric,
          })
        )}
        title={"Top Three Categories"}
        rankingType={"top"}
      />
      <CategoryRankBox
        categories={bottom.map(
          ({
            category,
            insightValues: {
              [insightsMetric.toLowerCase() as MetricKey]: metric,
            },
          }: CategoryInsightNode) => ({
            category,
            metric,
          })
        )}
        title={"Bottom Three Categories"}
        mt={4}
        rankingType={"bottom"}
      />
    </>
  ) : (
    <div />
  );
};
