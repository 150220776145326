import { Flex, Text } from "@chakra-ui/react";
import { InsightsRadio } from "../../InsightsRadio";
import { useReactiveVar } from "@apollo/client";
import { currentCategoryVar } from "../../../../lib/cache";

export const ChartsHeader = () => {
  const { category } = useReactiveVar(currentCategoryVar);
  return (
    <Flex justify={"space-between"} mb={2}>
      <Text fontSize={"32px"} fontWeight={"bold"}>
        {category}
      </Text>
      <InsightsRadio />
    </Flex>
  );
};
