import { useQuery } from "@apollo/client";
import { Box, Divider, Flex, HStack, Spacer, Text, useTheme } from "@chakra-ui/react";
import React from "react";
import { Navigate } from "react-router-dom";
import { useHitlFilters } from "..";
import { ComponentLoading } from "../../../components/loading/ComponentLoading";
import { ReviewedUrlType } from "../../../graphql/generated";
import { GET_REVIEWED_URL } from "../../../graphql/operations/campaignOperations/operations";
import { GET_URL_CLASSIFICATION, UrlClassificationType } from "../../../rest/rest";
import { formatSectionHeader } from "../urlReview/MultipleChoiceSection";
import { TitleLink } from "../urlReview/TitleLink";


export const PastReview = () => {

    const hitlFilters = useHitlFilters();
    // const navigate = useNavigate();

    // if (!hitlFilters.selectedUrl) {
    //     navigate("/human-in-the-loop");
    // }

    const { data, loading, error } = useQuery(
        GET_REVIEWED_URL,
        {
            skip: !hitlFilters.selectedUrl,
            variables: {
                url: hitlFilters.selectedUrl,
            },
            fetchPolicy: "cache-and-network",
        }
    );

    const { data: dataOriginal, loading: loadingOriginal, error: errorOriginal } = useQuery(
        GET_URL_CLASSIFICATION,
        {
            skip: !hitlFilters.selectedUrl,
            context: {clientName: "hitlEndpoint"},
            variables: {
                redisKey: hitlFilters.selectedUrl,
            },
            fetchPolicy: "cache-and-network",
        }
    );

    if (loading || loadingOriginal) {
        return <ComponentLoading text={"Loading URLs..."}/>
    };

    if (error || errorOriginal) {
        return <>Error</>
    };

    if (!data || !dataOriginal) {
        return <Navigate to={"/human-in-the-loop"} />

    }

    const reviewedUrl: ReviewedUrlType = data.reviewedUrl

    const createdAt = new Date(reviewedUrl.createdAt);
    const createdAtDate = createdAt.toLocaleDateString("en-GB");
    const createdAtTime = createdAt.toLocaleTimeString([], {hour: "2-digit", minute:"2-digit"});

    const originalClassification: UrlClassificationType = dataOriginal.urlClassification;

    return (
        <Box>
            <Flex
                bgColor={"white"}
                borderRadius={"lg"}
                p={4}
                mb={10}
                direction={"column"}
            >
                <TitleLink
                    showNote={false}
                    url={hitlFilters.selectedUrl}
                    reviewButton={true}
                />
                <Box mx={4}>
                    <HStack>
                        <Text as={"span"} fontWeight={"bold"}>
                            {`Reviewed by:`}
                        </Text>
                        <Text as={"span"}>
                            {`${reviewedUrl.reviewedBy.firstName} ${reviewedUrl.reviewedBy.lastName}`}
                        </Text>
                        <Spacer />
                        <Text as={"span"} fontWeight={"bold"}>
                            {`Reviewed on:`}
                        </Text>
                        <Text as={"span"}>
                            {`${createdAtDate}-${createdAtTime}`}
                        </Text>
                    </HStack>
                    <Divider borderColor={"muted"}/>
                    {reviewedUrl.reviewedClassification &&
                        <ClassificationsGroup
                            reviewedUrl={reviewedUrl}
                            originalUrl={originalClassification}
                            classicationType={"Accepted"}
                        />
                    }
                    {reviewedUrl.rejectedClassification &&
                        <ClassificationsGroup
                            reviewedUrl={reviewedUrl}
                            originalUrl={originalClassification}
                            classicationType={"Rejected"}
                        />
                    }
                    <ClassificationsGroup
                        reviewedUrl={reviewedUrl}
                        originalUrl={originalClassification}
                        classicationType={"Original"}
                    />
                </Box>
            </Flex>
        </Box>
    )
}

interface ClassificationsGroupType {
    reviewedUrl: ReviewedUrlType,
    originalUrl: UrlClassificationType,
    classicationType: "Accepted" | "Rejected" | "Original"
}

const ClassificationsGroup = ({
    reviewedUrl,
    originalUrl,
    classicationType
}: ClassificationsGroupType) => {

    const chakraTheme = useTheme();
    let fontColor = "#000000";

    let classificationCategories: Record<string, any> = {};



    switch (classicationType) {
        case "Accepted":
            fontColor = chakraTheme.colors.submitGreen;
            classificationCategories = JSON.parse(reviewedUrl.reviewedClassification);
            break;
        case "Rejected":
            fontColor = chakraTheme.colors.cancelRed;
            classificationCategories = JSON.parse(reviewedUrl.rejectedClassification);
            break;
        case "Original":
            classificationCategories = originalUrl.results;
            break;
    }

    return (
        <Box mt={4}>
            <Text
                width={"100%"}
                fontSize={"18px"}
                fontWeight={"bold"}
                color={fontColor}
            >
                {`${classicationType} Classifications`}
            </Text>
            {
                Object.entries(classificationCategories).map((entries, ix) => {

                    const classificationKey = entries[0];
                    const classifcationValues = [];

                    if (Array.isArray(entries[1])) {
                        classifcationValues.push(...entries[1])
                    }
                    if (typeof entries[1] === 'string' || entries[1] instanceof String) {
                        classifcationValues.push(entries[1]);
                    }

                    if (classifcationValues.length === 0) {
                        return
                    }

                    return (
                        <HStack key={ix} mt={2}>
                            <Text>
                                <b>{`${formatSectionHeader(classificationKey)}: `}</b>
                                {classifcationValues.map((value, iy) => {
                                    return (
                                        <React.Fragment key={iy}>
                                            {capitiliseFirstLetter(value)}
                                            {classifcationValues.length !== iy+1 && " | "}
                                        </React.Fragment>
                                    )
                                })}
                            </Text>
                        </HStack>
                    )
                })
            }
            <Divider borderColor={"muted"}/>
        </Box>
    )

}

const capitiliseFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
