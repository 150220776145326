import {
  CampaignCreativeTypeChoices,
  CampaignKpiTypeChoices,
  DspChoices,
  InsightCategoryChoices,
} from "../../../graphql/generated";
import {
  FormDataClass,
  IFormDataClassField,
} from "../../../utils/formsSchema/FormDataClass";
import { DataTypes } from "json-schema-yup-transformer/dist/schema";
import { getCountries } from "../../../utils/getCountries";

// Attempts at using `regenerate-unicode-properties` lib to generate all the
// currencies has been made. Didn't work. These three manually for now.
const currencies = "\u0024 \u00A3 \u20AC"; // $ £ €
const currencyPattern: string =
  "^[" + currencies + "]" + "[+]?([0-9]+(?:[.][0-9]*)?|.[0-9]+)$";
// Comma separated values without spaces
const listPattern = "^\\w+(,\\w+)*$";
// At least two characters without spaces in front or behind
const blockValuePattern = "^[^-\\s][a-zA-Z0-9]+$";

export class CampaignFormDataClass extends FormDataClass {
  constructor(defaultDsp: string = DspChoices.Xandr, staffCreator = false) {
    super();
    this.fields = {
      ...this.getBasicDetails(defaultDsp),
      ...this.getDspDetails(defaultDsp),
      ...this.getCampaignChecklist(),
      ...(staffCreator ? this.getUserDetailsFields() : {}),
    };
  }

  getUserDetailsFields() {
    return {
      campaignOwnerEmail: {
        type: DataTypes.STRING,
        format: "email",
        inputFormat: "email",
        label: "Campaign Owner Email Address",
        placeholder: "Type the campaign owners email address",
        isRequired: true,
        default: "",
        errors: {
          required: "This field is required.",
          format: {
            email: "Please enter a valid email address.",
          },
        },
      },
    };
  }

  getBasicDetails(dsp: string) {
    return {
      name: {
        type: DataTypes.STRING,
        label: "Campaign Name",
        placeholder: "Type the name of the campaign",
        isRequired: true,
        default: "",
        errors: {
          required: "The campaign name is required.",
        },
      },
      productSelected: {
        type: DataTypes.BOOLEAN,
        const: true,
        label: "Product Category",
        isRequired: true,
        default: true,
        errors: {
          const: "Please select your illuma product(s).",
        },
      },
      illumaCore: {
        type: DataTypes.BOOLEAN,
        default: true,
        label: "illuma Reacts",
        isRequired: false,
      },
      illumaCompass: {
        type: DataTypes.BOOLEAN,
        default: false,
        label: "illuma Compass",
        isRequired: false,
      },
      illumaSmart: {
        type: [DataTypes.ARRAY, DataTypes.NULL],
        items: {
          type: DataTypes.STRING,
          enum: Object.values(InsightCategoryChoices),
        },
        isRequired: true,
        label: "illuma Precision (Web)",
        placeholder: "Type or select precision categories",
        default: null,
        minItems: 1,
        errors: {
          required: "Please select at least one precision category.",
        },
      },
      illumaSmartCtv: {
        type: [DataTypes.ARRAY, DataTypes.NULL],
        items: {
          type: DataTypes.STRING,
          enum: Object.values(InsightCategoryChoices),
        },
        isRequired: true,
        label: "illuma Precision (CTV)",
        placeholder: "Type or select precision categories",
        default: null,
        minItems: 1,
        errors: {
          required: "Please select at least one precision category.",
        },
      },
      illumaPrecision: {
        type: [DataTypes.ARRAY, DataTypes.NULL],
        items: {
          type: DataTypes.NUMBER,
          // enum: Object.values(InsightCategoryChoices),
        },
        isRequired: true,
        label: "illuma Precision (Web)",
        placeholder: "Type or select precision categories",
        default: null,
        minItems: 1,
        errors: {
          required: "Please select at least one precision category.",
        },
      },
      illumaPrecisionCtv: {
        type: [DataTypes.ARRAY, DataTypes.NULL],
        items: {
          type: DataTypes.NUMBER,
          // enum: Object.values(InsightCategoryChoices),
        },
        isRequired: true,
        label: "illuma Precision (CTV)",
        placeholder: "Type or select precision categories",
        default: null,
        minItems: 1,
        errors: {
          required: "Please select at least one precision category.",
        },
      },
      dsp: {
        type: DataTypes.STRING,
        enum: Object.values(DspChoices),
        label: "DSP",
        isRequired: true,
        default: dsp,
      },
      markets: {
        type: DataTypes.ARRAY,
        items: {
          type: DataTypes.STRING,
          enum: getCountries("values"),
        },
        placeholder: "Type or select markets",
        label: "Markets",
        default: [],
        isRequired: true,
        minItems: 1,
        errors: {
          required: "Please select at least one market.",
        },
      },
      creativeType: {
        type: DataTypes.STRING,
        enum: Object.values(CampaignCreativeTypeChoices),
        placeholder: "Select the campaign creative type",
        label: "Creative Type",
        isRequired: true,
        default: "",
        errors: {
          required: "Please select the creative type.",
        },
      },
      startDate: {
        type: DataTypes.STRING,
        format: "date",
        label: "Start Date",
        isRequired: true,
        default: "",
        placeholder: "Choose",
        errors: {
          required: "Please specify a start date for the campaign.",
        },
      },
      endDate: {
        type: [DataTypes.STRING, DataTypes.NULL],
        format: "date",
        label: "End Date",
        isRequired: false,
        default: null,
        placeholder: "Choose",
      },
      dspMemberId: {
        type: DataTypes.STRING,
        label: "Member/Seat ID",
        placeholder: "e.g. 001122",
        isRequired: true,
        default: "",
        pattern: blockValuePattern,
        errors: {
          required: "The DSP member ID is required.",
          pattern: "The DSP member ID is invalid.",
        },
      },
      advertiserName: {
        type: DataTypes.STRING,
        label: "Advertiser",
        isRequired: true,
        default: "",
        placeholder: "Type advertiser name",
        errors: {
          required: "The advertiser name is required.",
        },
      },
      isManaged: {
        type: DataTypes.BOOLEAN,
        label: "Managed Service Campaign",
        isRequired: false,
        default: false,
      },
    };
  }

  getDspDetails(dsp: string) {
    return {
      dspSpecific: {
        type: DataTypes.OBJECT,
        label: `${dsp} DSP`,
        isRequired: false,
        properties: this.getDspSpecificFields(dsp),
        default: Object.entries(this.getDspSpecificFields(dsp)).reduce(
          (accumulator, [key, values]) => ({
            ...accumulator,
            [key]: values.default,
          }),
          {}
        ),
        required: this._getRequiredFields(this.getDspSpecificFields(dsp)),
      },
      kpis: {
        type: DataTypes.ARRAY,
        label: "Primary KPI",
        isRequired: true,
        minItems: 1,
        default: [
          {
            kpiType: CampaignKpiTypeChoices.Ctr,
            kpiTarget: "",
            isPrimary: "true",
            dspSpecificKpiParams: Object.entries(
              this.getDspSpecificKpiProperties(dsp)
            ).reduce(
              (accumulator, [key, values]) => ({
                ...accumulator,
                [key]: values.default,
              }),
              {}
            ),
          },
          {
            kpiType: CampaignKpiTypeChoices.Ctr,
            kpiTarget: "11211",
            isPrimary: false,
            dspSpecificKpiParams: Object.entries(
              this.getDspSpecificKpiProperties(dsp)
            ).reduce(
              (accumulator, [key]) => ({
                ...accumulator,
                [key]: "11211",
              }),
              {}
            ),
          },
        ],
        items: {
          type: DataTypes.OBJECT,
          properties: {
            kpiType: {
              type: DataTypes.STRING,
              enum: Object.values(CampaignKpiTypeChoices),
              label: "KPI Type",
              isRequired: true,
            },
            isPrimary: {
              type: DataTypes.BOOLEAN,
              isRequired: true,
              label: "Primary KPI",
            },
            kpiTarget: {
              type: DataTypes.STRING,
              isRequired: true,
              label: "KPI Target",
              placeholder: "e.g. 0.12% (CTR), $2.5 (CPA) etc.",
            },
            dspSpecificKpiParams: {
              type: DataTypes.OBJECT,
              label: `${dsp} Specific KPI Parameters`,
              isRequired: false,
              properties: this.getDspSpecificKpiProperties(dsp),
              required: this._getRequiredFields(
                this.getDspSpecificKpiProperties(dsp)
              ),
            },
          },
          required: ["kpiType", "kpiTarget"],
        },
      },
    };
  }

  getDspSpecificKpiProperties(
    dsp: string
  ): Record<string, IFormDataClassField> {

    const specificKpiProperties: Record<string, IFormDataClassField> = {
      kpiPixelId: {
        type: [DataTypes.STRING, DataTypes.NULL],
        isRequired: false,
        label: "KPI Pixel ID",
        placeholder: "KPI Pixel ID",
        default: "",
        pattern: blockValuePattern,
      },
    }

    switch (dsp) {
      // case DspChoices.Xandr:
      //   return {
      //     kpiPixelId: {
      //       type: [DataTypes.STRING, DataTypes.NULL],
      //       isRequired: false,
      //       label: "KPI Pixel ID",
      //       placeholder: "KPI Pixel ID",
      //       default: "",
      //       pattern: blockValuePattern,
      //     },
      //   };
      case DspChoices.Dv360:
        specificKpiProperties["floodLightActivityId"] = {
            type: [DataTypes.STRING, DataTypes.NULL],
            isRequired: false,
            label: "Floodlight Activity ID",
            placeholder: "Floodlight Activity ID",
            default: "",
            pattern: blockValuePattern,
        };
      // default:
      //   return {} as Record<string, IFormDataClassField>;
    };

    return specificKpiProperties;
  }

  getCampaignChecklist() {
    return {
      /* Temporarily removing macro applied cause it is only required
       * by Xandr and more thought has to be given to the logic */
      // macroApplied: {
      //   type: DataTypes.BOOLEAN,
      //   const: true,
      //   label: "Macro Applied",
      //   isRequired: true,
      //   default: false,
      // },
      reportsCreated: {
        type: DataTypes.BOOLEAN,
        const: true,
        label: "Reports Created",
        isRequired: true,
        default: false,
      },
      estimatedIllumaBudget: {
        type: DataTypes.STRING,
        isRequired: true,
        pattern: currencyPattern,
        label: "Estimated illuma Budget",
        default: "",
        placeholder: "e.g. $5000",
        errors: {
          required: "Enter the estimated illuma budget.",
        },
      },
      estimatedIllumaImpressions: {
        type: DataTypes.STRING,
        isRequired: true,
        //Match 1 - 99,999,999 or 100,000,000 (1 one hundred million impressions)
        pattern: "^([1-9][0-9]{0,8}|100000000)$",
        label: "Estimated illuma Impressions",
        default: "",
        placeholder: "e.g. 5000",
        errors: {
          required: "Enter the estimated illuma impressions.",
        },
      },
      averageCpm: {
        type: DataTypes.STRING,
        isRequired: true,
        pattern: currencyPattern,
        label: "Average CPM",
        default: "",
        placeholder: "e.g. $5, £2.50 etc",
        errors: {
          required: "Enter the average CPM.",
        },
      },
    };
  }

  /** The label and link to support campaign creation */
  getHelpLink(dsp: string) {
    switch (dsp) {
      case DspChoices.Xandr:
        return {
          label: "Contact Xandr Support",
          link: "https://help.xandr.com/s/login/",
        };
      default:
        return {
          label: "Download Report Guide",
          link: `/report_guides/${dsp}.pdf`,
        };
    }
  }

  getDspSpecificFields(dsp: string) {
    switch (dsp) {
      case DspChoices.Xandr:
        return {
          advertiserId: {
            type: DataTypes.STRING,
            label: "Advertiser ID",
            placeholder: "e.g. 001122",
            isRequired: true,
            default: "",
            errors: {
              required: "The advertiser ID is required.",
            },
          },
          insertionOrderId: {
            type: [DataTypes.STRING, DataTypes.NULL],
            label: "Insertion Order IDs",
            placeholder: "Separated with commas (no spaces)",
            isRequired: false,
            pattern: listPattern,
            default: "",
            errors: {
              pattern: "Comma separated, No spaces.",
            },
          },
          illumaLineItemSplitId: {
            type: DataTypes.STRING,
            label: "illuma Line Item/Split ID",
            placeholder: "e.g. 001122",
            isRequired: true,
            default: "",
          },
          otherLineItemSplitId: {
            type: [DataTypes.STRING, DataTypes.NULL],
            label: "Other Line Item/Split IDs",
            placeholder: "Separated with commas (no spaces)",
            pattern: listPattern,
            isRequired: false,
            default: "",
            errors: {
              pattern: "Comma separated, no spaces.",
            },
          },
        };
      case DspChoices.Ttd:
        return {
          advertiserId: {
            type: DataTypes.STRING,
            label: "Advertiser ID",
            placeholder: "e.g. 001122",
            isRequired: true,
            default: "",
            pattern: blockValuePattern,
          },
          adGroupName: {
            type: [DataTypes.STRING, DataTypes.NULL],
            label: "illuma Ad Group Name",
            placeholder: "illuma Ad Group Name",
            isRequired: false,
            default: "",
          },
          adGroupId: {
            type: [DataTypes.STRING, DataTypes.NULL],
            label: "illuma Ad Group ID",
            placeholder: "illuma Ad Group ID",
            isRequired: true,
            default: "",
          },
          otherAdGroupId: {
            type: [DataTypes.STRING, DataTypes.NULL],
            label: "Other Ad Group IDs",
            placeholder: "Separated with commas (no spaces)",
            pattern: listPattern,
            isRequired: false,
            default: "",
            errors: {
              pattern: "Comma separated, no spaces.",
            },
          },
        };
      case DspChoices.Roku:
        return {
          advertiserId: {
            type: DataTypes.STRING,
            label: "Advertiser ID",
            placeholder: "e.g. 001122",
            isRequired: true,
            default: "",
            pattern: blockValuePattern,
          },
          illumaFlightUid: {
            type: DataTypes.STRING,
            label: "illuma Flight UID",
            placeholder: "illuma Flight UID",
            isRequired: true,
            default: "",
          },
          encodedIllumaFlightUid: {
            type: DataTypes.STRING,
            label: "Encoded illuma Flight UID",
            placeholder: "Encoded illuma Flight UID",
            isRequired: true,
            default: "",
          },
          audienceFlightUid: {
            type: [DataTypes.STRING, DataTypes.NULL],
            label: "Audience Flight UID",
            placeholder: "Audience Flight UID",
            isRequired: false,
            default: "",
          },
          encodedFlightUid: {
            type: DataTypes.STRING,
            label: "Encoded Flight UID",
            placeholder: "Encoded Flight UID",
            isRequired: false,
            default: "",
          },
          creativeIds: {
            type: DataTypes.STRING,
            label: "Creative IDs",
            placeholder: "Separated with commas (no spaces)",
            isRequired: false,
            pattern: "^$|" + listPattern,
            default: "",
          },
        };
      case DspChoices.AmazonDsp:
        return {
          advertiserId: {
            type: DataTypes.STRING,
            label: "Advertiser ID",
            placeholder: "e.g. 001122",
            isRequired: true,
            default: "",
            pattern: blockValuePattern,
          },
          lineItemName: {
            type: [DataTypes.STRING, DataTypes.NULL],
            label: "illuma Line Item Name",
            placeholder: "illuma Line Item Name",
            isRequired: false,
            default: "",
            errors: {
              pattern: "Comma separated, No spaces.",
            },
          },
          illumaLineItemId: {
            type: DataTypes.STRING,
            label: "illuma Line Item ID",
            placeholder: "e.g. 001122",
            isRequired: true,
            default: "",
          },
          otherLineItemId: {
            type: [DataTypes.STRING, DataTypes.NULL],
            label: "Other Line Item IDs",
            placeholder: "Separated with commas (no spaces)",
            pattern: listPattern,
            isRequired: false,
            default: "",
            errors: {
              pattern: "Comma separated, no spaces.",
            },
          },
        };
      default:
        return {} as Record<string, IFormDataClassField>;
    }
  }
}
