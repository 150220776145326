import { BoxProps, Flex } from "@chakra-ui/react";
import { useSegmentInfo } from ".";
import { SectionBox } from "../../components/sectionBox/SectionBox";
import { DetailField } from "../../components/detailField/DetailField";
import { DetailFieldListBox } from "../../components/detailFieldListBox/DetailFieldListBox";
import { formatPrecisionLabel } from "../../utils/displayGetters";

export const DetailsSummary = (props: BoxProps) => {
    const { segment } = useSegmentInfo();

    return (
        <SectionBox sectionHeader={"Details"} {...props}>
            <Flex direction={"column"}>
                <DetailField
                    label={"Segment Name"}
                    value={segment.name}
                />
                <DetailField
                    label={"Start Date"}
                    value={segment.startDate}
                />
                <DetailField
                    label={"End Date"}
                    value={segment.endDate ? segment.endDate : "TBD"}
                />
                {
                    segment.keywordExclusion &&
                        <DetailFieldListBox
                            label={"Keywords"}
                            value={segment.keywordExclusion}
                            useTag={true}
                        />
                }
                {
                    segment.expansionCategories.length > 0 &&
                        <DetailFieldListBox
                            label={"Precision Categories"}
                            value={formatPrecisionLabel(segment.expansionCategories)}
                        />
                }
            </Flex>
        </SectionBox>
    )
}
