export default {
  baseStyle: {
    textDecoration: "underline",
    color: "link",
  },

  variants: {
    buttonLinkPrimary: {
      fontFamily: "action",
      borderRadius: "full",
      textDecoration: "none",
      color: "text.dark",
      bg: "primary.dark",
      fontWeight: "bold",
      py: 2,
      px: 6,
      fontSize: "14px",

      _hover: {
        textDecoration: "none",
        bg: "primary.light",
      },

      _focus: {
        boxShadow: "none",
      },
    },
    buttonLinkSecondary: {
      fontFamily: "action",
      fontSize: "14px",
      borderRadius: "full",
      textDecoration: "none",
      color: "white",
      bg: "transparent",
      borderColor: "white",
      borderWidth: "2px",
      fontWeight: "bold",
      px: 6,
      py: 2,
      textAlign: "center",
      verticalAlign: "middle",

      _hover: {
        textDecoration: "none",
        borderColor: "primary.light",
        color: "primary.light",
      },

      _focus: {
        boxShadow: "none",
      },
    },
  },
};
