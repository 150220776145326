import { gql } from "@apollo/client";

export const GET_URL_LIST = gql`
  query urlList ($search: String, $category: String, $cursor: Int) {
    urlList(search: $search, category: $category, cursor: $cursor) @rest(type: "UrlList", path: "ListURL?{args}") {
      urls
      cursor
    }
  }
`;

export type UrlListType = {
  urls: string[]
  cursor: number
}

export const GET_URL_CLASSIFICATION = gql`
  query urlClassification ($redisKey: String) {
    urlClassification(redis_key: $redisKey) @rest(type: "UrlClassification", path: "SpecificURL?{args}") {
      pageUrl: page_url
      results {
        illuma_sent: cat_sent
        precision_iab_v3: cat_iab
        cat_brandsafety: cat_brandsafety
        illuma_emotion: cat_emotion
        illuma_ents_PERSON: cat_ents_PERSON
        illuma_ents_FAC: cat_ents_FAC
        illuma_ents_LOC: cat_ents_LOC
        illuma_ents_WORK_OF_ART: cat_ents_WORK_OF_ART
        illuma_ents_ORG: cat_ents_ORG
        illuma_ents_GPE: cat_ents_GPE
        illuma_ents_DATE: cat_ents_DATE
        illuma_ents_EVENT: cat_ents_EVENT
      }
    }
  }
`;

export type UrlClassificationType = {
  pageUrl: string
  results: {
    illuma_sent: string
    precision_iab_v3: string[]
    cat_brandsafety: string[]
    illuma_emotion: string[]
    ents_person: string[]
    ents_fac: string[]
    ents_loc: string[]
    ents_work_of_art: string[]
    ents_org: string[]
    ents_gpe: string[]
    ents_date: string[]
    ents_event: string[]
  }
}
