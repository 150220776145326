import { Box, BoxProps, Flex } from "@chakra-ui/react"

export const SidebarV2 = ({children}: BoxProps) => {
    return (
        <Flex flexDirection={"column"} mr={2} h={"70vh"} w={"30%"} >
            <Box
                bg={"white"}
                borderRadius={"lg"}
                overflowY={"auto"}
                sx={{
                    '&::-webkit-scrollbar': {
                        width: '10px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: "inactive",
                        borderRightRadius: "lg"
                    },
                }}
            >
                {children}
            </Box>
        </Flex>
    )
}
