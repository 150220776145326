export default {
  variants: {
    outline: {
      field: {
        fontSize: "14px",
        borderColor: "muted",
        _hover: {
          borderColor: "primary.light",
        },
      },
    },
  },
  defaultProps: {
    focusBorderColor: "primary.dark",
    errorBorderColor: "error",
  },
};
