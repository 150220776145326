import { Box, Collapse } from "@chakra-ui/react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { currentCategoryVar, insightsMetricVar } from "../../../../lib/cache";
import { BarChart } from "./BarChart";
import { PieChart } from "./PieChart";
import { GET_SINGLE_CATEGORY_INSIGHTS_FROM_NODE } from "../../../../graphql/operations/campaignOperations/operations";
import { ComponentLoading } from "../../../../components/loading/ComponentLoading";
import { CategoryInsightNode, InsightMetricsEnum } from "../../../../graphql/generated";

export const DetailsCharts = () => {
  const currentCategory = useReactiveVar(currentCategoryVar);
  const currentMetric = useReactiveVar(insightsMetricVar);
  // Define metrics that permit showing of a pie chart
  const showPieChart = ![InsightMetricsEnum.Ctr].includes(currentMetric)

  const { data, loading, error } = useQuery(
    GET_SINGLE_CATEGORY_INSIGHTS_FROM_NODE,
    {
      variables: {
        id: currentCategory.id,
      },
      skip: !currentCategory.id,
    }
  );

  if (loading || !data) {
    return <ComponentLoading text={"Generating charts..."} />;
  }

  if (error) {
    return <div>Error</div>;
  }
  /** In this particular case, the category connection has the date range, and we
   * cannot cache query for it because the connection has no ID. We need to extract
   * the date range used for the node query from the insight filters.
   */
  const {
    dates,
    subCategories,
    insightFilters: { date__gte: startDate, date__lte: endDate },
  }: CategoryInsightNode = data.node;

  return (
    <>

      <Box borderBottomRadius={"lg"}>
        <BarChart dates={dates} dateRange={{ startDate, endDate }}/>
        <Collapse in={showPieChart}>
          <PieChart subCategories={subCategories} />
        </Collapse>
      </Box>
    </>
  );
};
