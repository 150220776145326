import { BoxProps, HStack, Spinner, Text } from "@chakra-ui/react";

interface Props {
  text?: string;
}

export const ComponentLoading = ({ text, ...rest }: Props & BoxProps) => {
  return (
    <HStack spacing={2} justifyContent={"center"} {...rest}>
      <Text>{text ?? "Loading..."}</Text>
      <Spinner size={"sm"} color={"primary.dark"} />
    </HStack>
  );
};
