import { SectionTitle } from "../../SectionTitle";
import { ActionLabel } from "../../../../components/actionLabel/ActionLabel";
import { CategoryListFilter } from "../CategoryListFilter";
import { Flex } from "@chakra-ui/react";
import { useCampaignAvailableMetrics } from "../../hooks";

export const CategoryListHeader = () => {
  const availableMetrics = useCampaignAvailableMetrics();
  return (
    <Flex justify={"space-between"} mb={2} align={"center"}>
      <SectionTitle
        title={"Categories"}
        fontSize={["14px", null, "16px", "20px", null]}
        mb={0}
      />
      <ActionLabel
        label={"order"}
        textProps={{ display: ["none", null, null, null, "block"] }}
      >
        <CategoryListFilter
          size={"sm"}
          px={[1, null, null, null, 2]}
          availableMetrics={availableMetrics}
        />
      </ActionLabel>
    </Flex>
  );
};
