import { FormDataClass } from "../../../utils/formsSchema/FormDataClass";
import { DataTypes } from "json-schema-yup-transformer/dist/schema";

export class LoginFormDataClass extends FormDataClass {
  config = {
    errors: {
      email: {
        required: "Your email is required.",
      },
      password: {
        required: "Your password is required.",
      },
    },
  };
  fields = {
    email: {
      type: DataTypes.STRING,
      format: "email",
      inputFormat: "email",
      label: "Email Address",
      placeholder: "Type your email address",
      isRequired: true,
      default: "",
      errors: {
        required: "Your email is required.",
        format: {
          email: "Please enter a valid email address.",
        },
      },
    },
    password: {
      type: DataTypes.STRING,
      label: "Password",
      inputFormat: "password",
      placeholder: "Type your password",
      isRequired: true,
      default: "",
      errors: {
        required: "Your password is required.",
      },
    },
    remember: {
      type: DataTypes.BOOLEAN,
      label: "Remember me",
      isRequired: false,
      default: false,
    },
    disclaimer: {
      type: DataTypes.BOOLEAN,
      label: "By checking this box, you are agreeing to our terms of service.",
      isRequired: true,
      const: true,
      errors: {
        const: "Please confirm that you accept the terms of service.",
      },
      default: false,
    }
  };
}
