import { Icon } from "@chakra-ui/react";

export const FilterIcon = (props: Record<string, any>) => {
  return (
    <Icon
      viewBox={"0 0 20.842 17.775"}
      sx={{
        ".a,.b": {
          stroke: "#1a2938",
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: "2px",
        },
        ".b": {
          fill: "#fff",
        },
      }}
      {...props}
    >
      <g transform="translate(-1047.469 106.315)">
        <line
          className="a"
          y2="15.775"
          transform="translate(1050.652 -105.315)"
        />
        <line
          className="a"
          y2="15.775"
          transform="translate(1057.92 -105.315)"
        />
        <line
          className="a"
          y2="15.775"
          transform="translate(1065.188 -105.315)"
        />
        <circle
          className="b"
          cx="2.063"
          cy="2.063"
          r="2.063"
          transform="translate(1048.469 -96.182)"
        />
        <circle
          className="b"
          cx="2.063"
          cy="2.063"
          r="2.063"
          transform="translate(1063.185 -96.182)"
        />
        <circle
          className="b"
          cx="2.063"
          cy="2.063"
          r="2.063"
          transform="translate(1055.797 -101.308)"
        />
      </g>
    </Icon>
  );
};
