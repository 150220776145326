import {
    Box,
    Button,
    Collapse,
    Flex,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Spacer,
    Text,
    Tooltip
} from "@chakra-ui/react";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdClear } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ActionLabel } from "../../components/actionLabel/ActionLabel";
import { SelectField } from "../../components/selectField/SelectField";
import { PrecisionCategoriesOptionsType } from "../../graphql/generated";


interface HeaderProps {
    precisionCategories: PrecisionCategoriesOptionsType[]
    urlSearch: string
    setUrlSearch: React.Dispatch<React.SetStateAction<string>>
    categoryFilter: {label: string, value: string}
    setCategoryFilter: React.Dispatch<React.SetStateAction<{label: string, value: string}>>
}

export const Header = ({
    precisionCategories,
    urlSearch,
    setUrlSearch,
    categoryFilter,
    setCategoryFilter
}: HeaderProps) => {

    const navigate = useNavigate();
    const [localUrlSearch, setLocalUrlSearch] = useState("");
    const [localCategoryFilter, setLocalCategoryFilter] = useState({label: "All Categories", value: "ALL"});

    const tierOnePrecisionCategories: {label: string, value: string}[] = precisionCategories.filter((category) => {
        if (category.parents.length === 0) {
            return true;
        } else {
            return false;
        }
    }).map((category) => ({
        label: category.label,
        value: category.label
    }))

    tierOnePrecisionCategories.unshift({label: "All", value: "ALL"})

    return (
        <Box>
            <Box
                // backgroundColor={"white"}
                // py={10}
                // px={6}
                mb={4}
                mx={"auto"}
                // borderRadius={"lg"}
                width={"80%"}
            >
                <HStack
                    // spacing={1}
                    // align={"center"}
                >
                    <Box
                        as={"button"}
                        onClick={() => history.back()}
                    >
                        <HStack
                            _hover={{
                                stroke: "primary.darker",
                                color: "primary.darker",
                            }}
                        >
                            <Icon
                                as={IoIosArrowRoundBack}
                                width={6}
                                height={6}
                                strokeWidth={10}
                            />
                            <Text fontSize={"sm"}>Back</Text>
                        </HStack>
                    </Box>
                </HStack>
            </Box>
            <Box
                width={"80%"}
                mx={"auto"}
                mb={4}
            >
                <Flex
                    justifyContent={[null, null, "space-between", null, null]}
                    flexDirection={["column", null, "row", null, null]}
                    gridGap={[1, null, 2, null, null]}
                >
                    <Box minW={"40%"}>
                        <Tooltip
                            label={"Search term must contain at least 3 letters"}
                            openDelay={600}
                            p={2}
                        >
                            <InputGroup>
                                <InputLeftElement pointerEvents={"none"}>
                                    <Icon
                                        as={FiSearch}
                                        w={[3, null, null, 4, null]}
                                        h={[3, null, null, 4, null]}
                                    />
                                </InputLeftElement>
                                <Input
                                    bg={"white"}
                                    fontSize={["sm", null, null, "md", null]}
                                    placeholder={"Search URL"}
                                    value={localUrlSearch}
                                    onChange={(e) => {
                                        setLocalUrlSearch(e.target.value);
                                    }}
                                />
                                <InputRightElement>
                                    <Collapse in={!!urlSearch}>
                                    <IconButton
                                        aria-label={"clear-search-query"}
                                        variant={"unstyled"}
                                        pl={2}
                                        onClick={() => {
                                            setLocalUrlSearch("");
                                        }}
                                        icon={
                                            <Icon
                                                as={MdClear}
                                                w={[3, null, null, 4, null]}
                                                h={[3, null, null, 4, null]}
                                            />
                                        }
                                    />
                                    </Collapse>
                                </InputRightElement>
                            </InputGroup>
                        </Tooltip>
                    </Box>
                    <Spacer />
                    <Flex
                        justifyContent={[null, null, "space-between", null, null]}
                        flexDirection={"row"}
                        gridGap={2}
                    >
                        <ActionLabel label={"category filter"} />
                        <Box
                            minW={60}
                            w={"100%"}
                        >
                            <SelectField
                                styles={{
                                    valueContainer: (base) => ({
                                        ...base,
                                        backgroundColor: "white",
                                    }),
                                    indicatorsContainer: (base) => ({
                                        ...base,
                                        backgroundColor: "white",
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        // marginTop: "0px",
                                        width: "100%",
                                        // borderWidth: "1px",
                                    }),
                                }}
                                placeholder={`Select category`}
                                onChange={(option) => {setLocalCategoryFilter(option)}}
                                isMulti={false}
                                options={tierOnePrecisionCategories}
                                value={localCategoryFilter.value ? localCategoryFilter : null}
                            />
                        </Box>
                    </Flex>
                    <Button
                        // Filter is only active when the search or category filter has been changed.
                        // Search term must be more than 4 letters.
                        isDisabled={(
                            (localUrlSearch === urlSearch || (localUrlSearch.length > 0 && localUrlSearch.length < 3)) &&
                            localCategoryFilter.value === categoryFilter.value
                        )}
                        variant={"tabActive"}
                        _hover={{
                            opacity: 0.3
                        }}
                        onClick={() => {
                            setUrlSearch(localUrlSearch);
                            setCategoryFilter(localCategoryFilter);
                            navigate("/human-in-the-loop/url-list");
                        }}
                    >
                        Filter
                    </Button>
                </Flex>
            </Box>
        </Box>
    )
}
