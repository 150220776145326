import { Flex, Icon, Text, Tooltip} from "@chakra-ui/react";
import { AiOutlineQuestion } from "react-icons/ai";
import { TooltipLabel, TooltipLabelTypes } from "../tooltipLabel/TooltipLabel";

interface Props {
  label: string;
  toolTip?: TooltipLabelTypes
}

export const FormSubSectionHeader = ({ label, toolTip }: Props) => {
  return (
    <Flex justifyContent={"space-between"}>
      <Text fontWeight={"medium"} fontSize={"20px"} mb={6}>
        {label}
      </Text>
      {(toolTip) && (
        <Tooltip
          label={<TooltipLabel label={toolTip} />}
          placement={"left-start"}
          bg={"text.dark"}
          p={2}
        >
          <span>
            <Icon
              bg={"text.dark"}
              borderRadius={"full"}
              p={"2px"}
              as={AiOutlineQuestion}
              color={"primary.light"}
            />
          </span>
        </Tooltip>
      )}
    </Flex>
  );
};
