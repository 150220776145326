import {
  Box,
  Icon,
  Switch,
  VStack,
  Stack,
  SimpleGrid,
  BoxProps,
  Link,
} from "@chakra-ui/react";
import { FormSubSectionHeader } from "../../../../components/formSubSectionHeader/FormSubSectionHeader";
import { IFormDataClassField } from "../../../../utils/formsSchema/FormDataClass";
import { FieldProps, Field } from "formik";
import { FormFieldLabel } from "../../../../components/formFieldLabel/FormFieldLabel";
// import { AiOutlineDownload } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { FormInputField } from "../../../../components/formInputField/FormInputField";
// import { DspChoices } from "../../../../graphql/generated"
interface Props {
  fields: Record<string, IFormDataClassField>;
  helpLink: { label: string; link: string };
}

export const Checklist = ({
  fields,
  helpLink,
  ...boxProps
}: Props & BoxProps) => {
  const {
    // macroApplied,
    reportsCreated,
    estimatedIllumaBudget,
    averageCpm,
    estimatedIllumaImpressions,
  } = fields;
  // const { values } = useFormikContext<{ dsp: string }>();
  return (
    <Box p={8} bg={"white"} borderRadius={"md"} {...boxProps}>
      <FormSubSectionHeader label={"Checklist"} />
      <SimpleGrid
        columns={[0, null, null, null, 2]}
        rows={[2, null, null, null, 0]}
        spacing={8}
      >
        <Stack spacing={6} direction={"column"}>
          {/* {
            values.dsp === DspChoices.Xandr && (
              <Box w={"100%"}>
                <Field name={"macroApplied"}>
                  {({ form, field }: FieldProps) => (
                    <FormFieldLabel
                      justifyContent={"space-between"}
                      flexDirection={"row"}
                      label={macroApplied.label}
                      isRequired={macroApplied.isRequired}
                      isInvalid={
                        !!(form.errors.macroApplied && form.touched.macroApplied)
                      }
                      mb={3}
                    >
                      <Switch
                        {...field}
                        defaultChecked={macroApplied.default as boolean}
                      />
                    </FormFieldLabel>
                  )}
                </Field>
                <Link
                  href={`/dsp_macros/${values.dsp}.txt`}
                  variant={"buttonLinkPrimary"}
                  isExternal={true}
                >
                  <Icon as={AiOutlineDownload} width={6} height={6} ml={2} />
                  Download Macro
                </Link>
              </Box>
            )
          } */}

          <Box>
            <Field name={"reportsCreated"}>
              {({ form, field }: FieldProps) => (
                <FormFieldLabel
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  label={reportsCreated.label}
                  isRequired={reportsCreated.isRequired}
                  isInvalid={
                    !!(
                      form.errors.reportsCreated && form.touched.reportsCreated
                    )
                  }
                  forceSpacer={true}
                  mb={3}
                >
                  <Switch
                    {...field}
                    defaultChecked={reportsCreated.default as boolean}
                  />
                </FormFieldLabel>
              )}
            </Field>
            <Link
              href={helpLink.link}
              variant={"buttonLinkPrimary"}
              isExternal={true}
            >
              {helpLink.label}
              <Icon as={BsArrowRight} width={6} height={6} ml={2} />
            </Link>
          </Box>
        </Stack>
        <VStack spacing={6}>
          {Object.entries({
            estimatedIllumaBudget,
            estimatedIllumaImpressions,
            averageCpm,
          }).map(([key, values]) => {
            return (
              <Field name={key} key={key}>
                {({ form, field }: FieldProps) => (
                  <FormInputField
                    inputFieldProps={field}
                    fieldType={"text"}
                    placeholder={values.placeholder}
                    isRequired={values.isRequired}
                    label={values.label}
                    isInvalid={!!(form.errors[key] && form.touched[key])}
                    errorMessage={form.errors[key]?.toString()}
                  />
                )}
              </Field>
            );
          })}
        </VStack>
      </SimpleGrid>
    </Box>
  );
};
