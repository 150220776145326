import { InsightsValuesType } from "../../../../graphql/generated";
import { MetricKey } from "../../../../../types";

export const getUrlCountForMetric = (
  metric: string,
  data: InsightsValuesType
): number => {
  // Convert the metric to all lowercase in case the metric is from the enum
  metric = metric.toLowerCase();
  const urlKey = "urlsCount" + metric[0].toUpperCase()  + metric.slice(1);
  return data[urlKey as MetricKey] ?? 0;
};
