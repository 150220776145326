import { Box, RadioProps, useRadio } from "@chakra-ui/react";


export const BarChartRaceRadio = (props: RadioProps) => {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();
    return (
      <Box as={"label"}>
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderRadius={"md"}
          color={"muted"}
          fontWeight={"medium"}
          transition={"background-color 0.25s ease-in-out"}
          _hover={{
            bg: "primary.lighter",
          }}
          _active={{
            bg: "primary.light",
            transform: "scale(0.98)",
          }}
          _checked={{
            color: "primary.darker",
          }}
          _focus={{
            boxShadow: "none",
          }}
          px={3}
          py={2}
        >
          {props.children}
        </Box>
      </Box>
    );
  };
