import { Grid, GridItem } from "@chakra-ui/react";
import { Rankings } from "./Rankings";
import { OverviewCharts } from "./overviewCharts";

export const CampaignInsightsOverview = () => {
  return (
    <Grid templateColumns={"repeat(8, 1fr)"} gap={4}>
      <GridItem colSpan={2}>
        <Rankings />
      </GridItem>
      <GridItem colSpan={6} mb={20}>
        <OverviewCharts />
      </GridItem>
    </Grid>
  );
};
