import { Box, BoxProps } from "@chakra-ui/react";
import { SectionHeader } from "./SectionHeader";

interface SectionBoxProps extends BoxProps {
  sectionHeader?: string
}

export const SectionBox = ({
  sectionHeader,
  children,
  ...rest
}: SectionBoxProps) => {
  return (
    <Box
      bg={"white"}
      p={10}
      borderRadius={"lg"}
      borderColor={"transparent"}
      borderWidth={2}
      mb={4}
      {...rest}
    >
      {sectionHeader && <SectionHeader title={sectionHeader} />}
      {children}
    </Box>
  );
};
