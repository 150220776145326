import { useQuery, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  CategoryInsightEdge,
  CategoryInsightNode,
} from "../../../../graphql/generated";
import { DefaultHeatMapDatum, HeatMapDatum, HeatMapSerie, ResponsiveHeatMapCanvas } from "@nivo/heatmap";
import { Box, Flex, useTheme, Text, VStack } from "@chakra-ui/react";
import { insightsDatesVar, insightsMetricVar } from "../../../../lib/cache";
import { ComponentLoading } from "../../../../components/loading/ComponentLoading";
import { NoData } from "../../NoData";
import { MetricKey } from "../../../../../types";
import { getDateRange } from "../../../../utils/getDateRange";
import {
  formatNumberDisplay,
} from "../../../../utils/displayGetters";
import { GET_CATEGORY_DATE_INSIGHTS } from "../../../../graphql/operations/campaignOperations/operations";
import { useMetricSuffix } from "../../hooks";
import { useRef } from "react";
import { DownloadAsImage } from "../../DownloadAsImage";
import { HiddenLogo } from "../../HiddenLogo";
import { format } from "date-fns";

export const HeatmapChart = () => {
  const { id: campaignId } = useParams();
  const dateRange = useReactiveVar(insightsDatesVar);
  const { data, loading, error } = useQuery(GET_CATEGORY_DATE_INSIGHTS, {
    variables: {
      campaignId,
      dateRange,
    },
  });
  const insightMetric = useReactiveVar(
    insightsMetricVar
  ).toLowerCase() as MetricKey;
  const heatMapRef = useRef(null);
  const chakraTheme = useTheme();

  if (loading) {
    return (
      <ComponentLoading text={"Generating heatmap..."} bg={"background"} />
    );
  }

  if (error) {
    return <div>Big Error</div>;
  }

  if (!data) {
    return <NoData />;
  }

  const {
    edges,
    dateRange: { startDate, endDate },
  } = data.insightsByCategoryList;

  let maxInsightValueForDateRange = 0;
  const heatmapData: HeatMapSerie<DefaultHeatMapDatum, HeatMapDatum>[] = edges.map(
    ({ node: categoryNode }: CategoryInsightEdge) => {
      const [categoryData, maxValueForCategory] = structureHeatmapData(
        insightMetric,
        categoryNode,
        startDate,
        endDate
      );
      maxInsightValueForDateRange =
        maxInsightValueForDateRange < maxValueForCategory
          ? maxValueForCategory
          : maxInsightValueForDateRange;
      return categoryData;
    }
  );


  return maxInsightValueForDateRange > 0 ? (
    <>
      <DownloadAsImage reference={heatMapRef} name={"Heatmap"} />
      <Box ref={heatMapRef} pb={8}>
        <HiddenLogo chartName="Heatmap" />
        <HeatmapHeader maxValue={maxInsightValueForDateRange} />
        <Box h={["60vh", null, null, "70vh", null]}>
          <ResponsiveHeatMapCanvas
            data={heatmapData}
            pixelRatio={2}
            margin={{ top: 70, right: 50, bottom: 50, left: 150 }}
            axisTop={{
              format: (dateString) => {
                const date = new Date(dateString);
                if(dateString === startDate) {
                  return format(date, "MMM dd")
                } else {
                  return format(date, "dd");
                }
              },
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -90,
              legend: "",
              legendOffset: 36,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            forceSquare={true}
            enableLabels={false}
            inactiveOpacity={0.4}
            colors={({data}) => {
              const colorIndex = data.y !== null && data.y !== undefined ?
                getColorIndexForCell(data.y, maxInsightValueForDateRange) : 0;
              return chakraTheme.colors.dataColors[`l${colorIndex}`];
            }}
            theme={{
              textColor: "#4D657E",
              axis: {
                ticks: {
                  line: {
                    stroke: "#4D657E",
                  },
                },
              },
            }}
          />
        </Box>
      </Box>
    </>
  ) : (
    <NoData />
  );
};

const structureHeatmapData = (
  key: MetricKey,
  { dates, category }: CategoryInsightNode,
  startDate: string,
  endDate: string
): [{id: string, data: HeatMapDatum[]}, number] => {

  const datesArray: {"x": string, "y": number}[] = [];
  let maxVal = 0;
  const rangeOfDates = getDateRange(startDate, endDate);

  rangeOfDates.forEach((currentDate) => {
    const matchedValue = dates.find(node => node.date === currentDate)?.insightValues[key] ?? 0;
    if(matchedValue > maxVal) {
      maxVal = matchedValue;
    }

    datesArray.push({
      "x": currentDate,
      "y": matchedValue
    })
  })

  const heatMap = {
    "id": category,
    "data": datesArray
  }

  return [{ ...heatMap }, maxVal];
};

// Required when using heatmap svg componenet
// const CustomHeatmapCell = ({
//   data: { value },
//   x,
//   y,
//   width,
//   height,
//   maxValue,
// }: {
//   [x: string]: any;
// }) => {
//   const chakraTheme = useTheme();
//   const colorIndex =
//     value !== undefined ? getColorIndexForCell(value, maxValue) : 0;
//   const cellColor = chakraTheme.colors.dataColors[`l${colorIndex}`];
//   return (
//     <>
//     <g transform={`translate(${x - 10}, ${y - 10})`}>
//       <rect
//         height={height}
//         width={width}
//         fill={cellColor ?? chakraTheme.colors.dataColors.l0}
//       />
//     </g>
//     </>
//   );
// };

const getColorIndexForCell = (
  value: number,
  maxValue: number,
  colorLevels = 6
) => {
  /** We have defined 6 color levels for the heatmap cell, so the
   * colors will range from l1 for low values to l6 for the highest values.
   * if new colors are ever added to the dataColors object in the theme,
   * it is important that this logic is edited to reflect that
   */
  return Math.ceil((value / maxValue) * colorLevels);
};

const HeatmapHeader = ({ maxValue }: { maxValue: number }) => {
  // Heatmap legend to show data distribution
  const chakraTheme = useTheme();
  const heatmapColors = Object.values(chakraTheme.colors.dataColors);
  const suffix = useMetricSuffix();
  const legendData = Array(heatmapColors.length)
    .fill(null)
    .map((value, ix) => {
      return (ix / heatmapColors.length) * maxValue;
    });

  return (
    <Flex py={2} pb={6} pl={10}>
      {legendData.map((value, ix) => (
        <VStack key={`heatmap-legend-${ix}`}>
          <Box
            width={"30px"}
            height={"10px"}
            bg={`dataColors.l${ix}`}
          >
          </Box>
          {/* svg heatmap legend */}
          {/* <Icon
            width={"30px"}
            height={"10px"}
            viewBox={"0 0 30 10"}
            color={`dataColors.l${ix}`}
          >
            <rect width={"100%"} height={"100%"} fill={"currentColor"} />
          </Icon> */}
          <Text fontSize={"8px"}>
            {Math.ceil(value)
              ? formatNumberDisplay(Math.ceil(value), suffix)
              : "NA"}
          </Text>
        </VStack>
      ))}
    </Flex>
  );
};
