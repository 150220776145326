import {
  CampaignKpiTypeChoices,
  CampaignStatusChoices,
  DspChoices,
  InsightMetricsEnum,
  PartnershipsPackagePartnerChoices,
  PrecisionCategoriesType,
} from "../graphql/generated";
import { format } from "date-fns";

/**
 * Returns the dsp name to display in the interface. Built to work with DspChoices Enum
 * @param dsp
 */
export const getDSPName = (dsp: string): string => {
  switch (dsp) {
    case DspChoices.AmazonDsp:
      return "Amazon DSP"
    case DspChoices.Ttd:
      return "The Trade Desk";
    case DspChoices.Dv360:
      return "DV360";
    case DspChoices.ZetaDsp:
      return "Zeta DSP";
    case DspChoices.VerizonMedia:
        return "Y! DSP";
    case DspChoices.Centro:
      return "Basis";
    default:
      return formatTextDisplay(dsp);
  }
};

export const getPartnerName = (partner: string | undefined): string => {
  switch (partner) {
    case PartnershipsPackagePartnerChoices.NewsUk:
      return "News UK"
    case PartnershipsPackagePartnerChoices.Pubmatic:
      return "Pubmatic";
    case PartnershipsPackagePartnerChoices.TtdApac:
      return "TTD APAC";
    case PartnershipsPackagePartnerChoices.TtdEmea:
      return "TTD EMEA";
    case PartnershipsPackagePartnerChoices.TtdNamer:
        return "TTD NAMER";
    default:
      if (!partner) {
        return "No partner found"
      }
      return formatTextDisplay(partner);
  }
};

export const getKPIDisplay = (kpi: string): string => {
  switch (kpi) {
    case CampaignKpiTypeChoices.WebsiteLands:
      return formatTextDisplay(kpi);
    case CampaignKpiTypeChoices.Viewability:
      return formatTextDisplay(kpi);
    default:
      return kpi;
  }
};

export const getMetricDisplay = (metric: string, title = false): string => {
  switch (metric) {
    case InsightMetricsEnum.Ctr:
      return "CTR";
    default:
      if (title) {
        return formatTextDisplay(metric);
      } else {
        return metric.toLowerCase();
      }
  }
};

export function formatTextDisplay(value: string): string {
  // First split the value on `_`
  const result = value.split("_");
  if (!result[1]) {
    return result[0][0] + result[0].slice(1).toLowerCase();
  } else {
    return result.map((r) => r[0] + r.slice(1).toLowerCase()).join(" ");
  }
}

export function formatDateDisplay(dateString: string): string {
  return format(new Date(dateString), "dd/MM/yy");
}

export function unCamelcase(value: string): string {
  return value
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, " $1")
    .replace(/^./, (s) => s.toUpperCase())
    .replace(/[[iI]d]*$/, "ID");
}

export const getStatusColor = (status: string) => {
  switch (status) {
    case CampaignStatusChoices.Confirmed:
      return "green.300";
    case CampaignStatusChoices.Active:
      return "primary.dark";
    case CampaignStatusChoices.Archived:
    case CampaignStatusChoices.Complete:
      return "inactive";
    case CampaignStatusChoices.Cancelled:
      return "orange.300";

    default:
      return "primary.light";
  }
};

export const formatNumberDisplay = (value: number | string, suffix = "") => {
  return `${numberDisplaySI(value)}${suffix}`;
};

export const getMetricSuffix = (metric: InsightMetricsEnum): string => {
  switch (metric) {
    case InsightMetricsEnum.Ctr:
      return "%";
    default:
      return "";
  }
};

export const numberDisplaySI = (value: number | string, places = 2): string => {
  if (typeof value === "string") {
    value = parseInt(value);
  }
  const [result, power] = reduceNumber(value);
  switch (power) {
    case 0:
      return `${result}`;

    case 1:
      return `${result.toFixed(places)}k`;

    case 2:
      return `${result.toFixed(places)}M`;

    default:
      return `${value}`;
  }
};

const reduceNumber = (value: number, power = 0): number[] => {
  let result;

  if (Math.abs(value / 1000) < 1) {
    result = [value, power];
  } else {
    result = reduceNumber(value / 1000, power + 1);
  }
  return result;
};

export const formatPrecisionLabel = (precisionCategories: PrecisionCategoriesType[]) => {
  const formattedPrecisonLabel = precisionCategories.map((category) => {
    let label = "";
    label += category.tier1;

    if (category.tier2) {
      // category.tier3 ? label += " > " : label += " : ";
      label += " > " + category.tier2;
    }
    return label
  })

  return formattedPrecisonLabel
}
