import { BoxProps, HStack, Text, TextProps } from "@chakra-ui/react";

export const ActionLabel = ({
                              label,
                              children,
                              textProps,
                              ...rest
                            }: { label: string, textProps?: TextProps } & BoxProps) => {
  return (
    <HStack spacing={4} {...rest}>
      <Text
        whiteSpace={"nowrap"}
        color={"link"}
        fontSize={["8px", null, null, "sm", null]}
        fontWeight={"medium"}
        {...textProps}
      >
        {label.toUpperCase()}
      </Text>
      {children}
    </HStack>
  );
};
