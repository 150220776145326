import { Box, BoxProps, Collapse, SimpleGrid, Switch } from "@chakra-ui/react";
import { FormSubSectionHeader } from "../../../../components/formSubSectionHeader/FormSubSectionHeader";
import { FormFieldLabel } from "../../../../components/formFieldLabel/FormFieldLabel";
import { FileUpload } from "../../../../components/fileUpload/FileUpload";
import { Dispatch, SetStateAction } from "react";
import { useState } from "react"
import { FileWithPath } from "react-dropzone";

export type ListFilesType = {
  inclusionLists: FileWithPath[];
  exclusionLists: FileWithPath[]
}

interface Props {
  listFiles: ListFilesType;
  setListFiles: Dispatch<SetStateAction<ListFilesType>>
}

export const ListFiles = ({
  listFiles,
  setListFiles,
  ...boxProps
}: Props & BoxProps) => {
  const acceptedFileTypes =
    "text/csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const [inclusionListUsed, setInclusionListUsed] = useState<boolean>(false)
  const [exclusionListUsed, setExclusionListUsed] = useState<boolean>(false)

  const setFiles = (name: string, files: FileWithPath[]) => {
    setListFiles(prevFiles => ({ ...prevFiles, [name]: files }))
  }

  return (
    <Box p={8} bg={"white"} borderRadius={"md"} {...boxProps}>
      <FormSubSectionHeader
        label={"Upload Site Lists"}
        toolTip={
          "Share your inclusion and exclusion lists for the illuma campaigns"
        }
      />
      <SimpleGrid columns={2} spacing={6}>
        <Box>
          <FormFieldLabel
            flexDirection={"row"}
            justifyContent={"space-between"}
            label={"Inclusion Lists Used"}
          >
            <Switch
              defaultChecked={inclusionListUsed}
              onChange={(event) => setInclusionListUsed(event.target.checked)}
            />
          </FormFieldLabel>
          <Collapse in={inclusionListUsed}>
            <FormFieldLabel
              label={"Inclusion Lists"}
              subLabel={"Import only .txt or .csv or .xls"}
            >
              <FileUpload
                name={"inclusionLists"}
                acceptedFileTypes={acceptedFileTypes}
                maxFiles={4}
                setFiles={setFiles}
              />
            </FormFieldLabel>
          </Collapse>

        </Box>
        <Box>
          <FormFieldLabel
            flexDirection={"row"}
            justifyContent={"space-between"}
            label={"Exclusion Lists Used"}
          >
            <Switch
              defaultChecked={exclusionListUsed}
              onChange={(event) => setExclusionListUsed(event.target.checked)}
            />
          </FormFieldLabel>
          <Collapse in={exclusionListUsed}>
            <FormFieldLabel
              label={"Exclusion Lists"}
              subLabel={"Import only .txt or .csv or .xls"}
            >
              <FileUpload
                name={"exclusionLists"}
                acceptedFileTypes={acceptedFileTypes}
                maxFiles={4}
                setFiles={setFiles}
              />
            </FormFieldLabel>
          </Collapse>
        </Box>
      </SimpleGrid>
    </Box>
  );
};
