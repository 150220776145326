import {
  Avatar,
  Button, ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/bi";

interface Props {
  items: { label: string; action: () => void }[];
  userName?: string;
  isStaff: boolean;
}

export const AccountMenu = ({
                              items,
                              userName = "Illuma User",
                              isStaff = false,
                              ...rest
                            }: Props & ButtonProps) => {
  return (
    <Menu variant={"accountMenu"} placement={"bottom-end"}>
      <MenuButton
        as={Button}
        rightIcon={<Icon as={BiChevronDown} w={6} h={6} />}
        variant={"accountMenu"}
        {...rest}
      >
        <Avatar
          name={userName}
          color={"white"}
          bg={isStaff ? "accent" : "secondary"}
        />
      </MenuButton>
      <MenuList>
        {items.map(({ label, action }, ix) => {
          return (
            <MenuItem key={`${label}-${ix}`} onClick={action}>
              {label}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
