import {
    Box,
    Flex,
    HStack,
    IconButton,
    Spacer,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    Table as TableType,
    useReactTable
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useEffect, useState } from "react"
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { CustomTable } from "../../../components/customTable/CustomTable";
import { PaginationBar } from "../../../components/paginationBar/PaginationBar";
import { SectionBox } from "../../../components/sectionBox/SectionBox";
import { mockSegmentRotationData } from "../../adminDashboardPage/segmentRotation/mockSegmentRotationData";

interface Segment {
    taskName: string
    platform: string
    segmentId: string
    domains: string
    runAt: string
    postedAt: string
}

export const SpecificSegmentRotation = () => {

    const segmentColumns: ColumnDef<Segment>[] = [
        {
            header: () => {
                return (
                    <Text pl={12}>Segment ID</Text>
                )
            },
            accessorKey: "segmentId",
            cell: ({ row, getValue } ) => {
                return (
                    <HStack>
                        <IconButton
                            aria-label={"Arrow"}
                            icon={ !row.getIsExpanded() ? <RiArrowDropDownLine /> : <RiArrowDropUpLine />}
                            onClick={() => row.toggleExpanded(!row.getIsExpanded())}
                            backgroundColor={"white"}
                            _hover={{bg: "white"}}
                            _active={{bg: "white"}}
                        />
                        <Tooltip
                            label={getValue()}
                            openDelay={600}
                            placement={"bottom-start"}
                            bg={"text.dark"}
                            p={2}
                        >
                            <Text
                            >
                                {getValue()}
                            </Text>
                        </Tooltip>
                    </HStack>
                )
            },
        },
        {
            accessorKey: "platform",
            header: "Platform",
        },
        {
            accessorKey: "postedAt",
            header: "Date",
            cell: ({ getValue }) => {
                const date = format(new Date(getValue()), "do MMM yyyy");
                return (
                    <Text>{date}</Text>
                )
            }
        },
        {
            accessorKey: "postedAt",
            header: "Time",
            cell: ({ getValue }) => {
                const time = format(new Date(getValue()), "kk:mm");
                return (
                    <Text>{time}</Text>
                )
            }

        },
        {
            accessorKey: "domains",
            header: () => null,
        }
    ]

    const [segmentData] = useState<Segment[]>(mockSegmentRotationData.segmentsRotated);
    const [pageIndex, setPageIndex] = useState(0)
    const [pagination, setPagination] = useState({pageIndex: pageIndex, pageSize: 6});

    const segmentTable: TableType<Segment> = useReactTable({
        data: segmentData,
        columns: segmentColumns,
        state: {
            pagination
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    useEffect(() => {
        // Hiding domain column, so is not shown in table but can be used in expanded component
        segmentTable.getColumn("domains").toggleVisibility(false);
    },[])

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: pageIndex
        }))
    }, [pageIndex])

    return (
        <SectionBox p={5} py={3} mb={0}>
            <Flex>
                <Spacer />
                <PaginationBar
                    currentPageIndex={segmentTable.getState().pagination.pageIndex}
                    totalPageCount={segmentTable.getPageCount()}
                    setPage={setPageIndex}
                    mr={5}
                />
            </Flex>
            <Box overflow={"auto"}>
                <CustomTable table={segmentTable} expanded={"domains"}/>
            </Box>
        </SectionBox>
    )
}
