import { useReactiveVar } from "@apollo/client";
import { BoxProps } from "@chakra-ui/react"
import { Navigate } from "react-router-dom";
import { allowedRevenueUsers } from "..";
import { userDetailsVar } from "../../../lib/cache";

export const Revenue = ({children}: BoxProps) => {

    const currentUser = useReactiveVar(userDetailsVar);

    if(currentUser ? !allowedRevenueUsers.includes(currentUser.role) : false) {
        return <Navigate to={"/campaigns"} />
    }

    return (
        <>{children}</>
    )
}
