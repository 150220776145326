import { FC } from "react";
import { BoxProps, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

export const CheckedField: FC<{ label: string; value: boolean } & BoxProps> = ({
  label,
  value,
  ...rest
}) => {
  return (
    <Grid
      templateColumns="repeat(8, 1fr)"
      gap={6}
      mb={4}
      alignItems={"start"}
      {...rest}
    >
      <GridItem colSpan={3} {...rest}>
        <Text
          mr={1}
          color={"primary.darker"}
          fontWeight={"bold"}
          fontSize={"sm"}
        >
          {label}
        </Text>
      </GridItem>
      <GridItem colSpan={5}>
        <Icon
          as={value ? FaCheckCircle : MdCancel}
          width={6}
          height={6}
          color={value ? "green.300" : "error"}
        />
      </GridItem>
    </Grid>
  );
};
