import { ApolloError, DocumentNode, useQuery } from "@apollo/client";
import { useTheme } from "@chakra-ui/react";
import { useState } from "react";
import { ComponentLoading } from "../../../../components/loading/ComponentLoading";
import { SectionBox } from "../../../../components/sectionBox/SectionBox";
import { MonthlyRevenueType, OfficeChoices, RevenueType } from "../../../../graphql/generated";
import { GET_MONTHLY_AGGREGATED_REVENUE, GET_TARGET_REVENUE } from "../../../../graphql/operations/campaignOperations/operations";
import { revenueDspVar, revenueOfficeVar, revenueYearVar } from "../../../../lib/cache";
import { ChartBox, dspFilterOptionType, officeFilterOptionType } from "../ChartBox";
import { Highlights } from "../Highlights";
import { AnnualRevenueBarChart } from './annualRevenueBarChart';

interface QueryResult {
  data: Record<string, MonthlyRevenueType[]> | undefined
  loading: boolean
  error: ApolloError | undefined
}

export interface RevenueDataType {
  resultTotal: QueryResult
  resultUs: QueryResult
  resultRow: QueryResult
}

export const AnnualRevenue = () => {

  const dataColors = useTheme().colors.distinctDataColors;
  const [year, setYear] = useState<number>(revenueYearVar);
  const [officeSelect, setOfficeSelect] = useState(revenueOfficeVar);
  const [dspSelect, setDspSelect] = useState(revenueDspVar);
  const [showPastForecast, setShowPastForecast] = useState(false);
  const [showManaged, setShowManaged] = useState(false);
  const [showSalesforce, setShowSalesforce] = useState(false);

  const { data: dataTarget, loading: loadingTarget, error: errorTarget } = useQuery(GET_TARGET_REVENUE, {
    variables: {
      yearFilter: year,
    },
    fetchPolicy: "cache-and-network",
  });
  const actualData = runQuery(
    GET_MONTHLY_AGGREGATED_REVENUE, //query
    RevenueType.Actual, //revenueType
    dspSelect, //dspSelect
    officeSelect, //officeSelect
    year, //year
    showManaged ? false : undefined //isManaged - Show non-managed campaigns if showManaged is checked
    //isNoId
  );
  const actualManagedData = runQuery(
    GET_MONTHLY_AGGREGATED_REVENUE,
    RevenueType.Actual,
    dspSelect,
    officeSelect,
    year,
    showManaged ? true : undefined // Show managed campaigns if showManaged is checked
  );
  const forecastData = runQuery(
    GET_MONTHLY_AGGREGATED_REVENUE,
    RevenueType.Forecast,
    dspSelect,
    officeSelect,
    year,
    showManaged ? false : undefined
  );
  const forecastManagedData = runQuery(
    GET_MONTHLY_AGGREGATED_REVENUE,
    RevenueType.Forecast,
    dspSelect,
    officeSelect,
    year,
    showManaged ? true : undefined
  );
  const noIdActualData = runQuery(
    GET_MONTHLY_AGGREGATED_REVENUE,
    RevenueType.Actual,
    dspSelect,
    officeSelect,
    year,
    undefined,
    true
  );
  const noIdForecastData = runQuery(
    GET_MONTHLY_AGGREGATED_REVENUE,
    RevenueType.Forecast,
    dspSelect,
    officeSelect,
    year,
    undefined,
    true
  );
  const noOfficeActualData = runQuery(
    GET_MONTHLY_AGGREGATED_REVENUE,
    RevenueType.Actual,
    dspSelect,
    officeSelect,
    year,
    undefined,
    false,
    true
  );
  const noOfficeForecastData = runQuery(
    GET_MONTHLY_AGGREGATED_REVENUE,
    RevenueType.Forecast,
    dspSelect,
    officeSelect,
    year,
    undefined,
    false,
    true
  );
  const salesforceData = runQuery(
    GET_MONTHLY_AGGREGATED_REVENUE,
    RevenueType.Salesforce,
    dspSelect,
    officeSelect,
    year
  );

  // This does not look nice, sorry
  if (loadingTarget ||
      actualData.resultTotal.loading || actualData.resultUs.loading || actualData.resultRow.loading ||
      actualManagedData.resultTotal.loading || actualManagedData.resultUs.loading || actualManagedData.resultRow.loading ||
      forecastData.resultTotal.loading || forecastData.resultUs.loading || forecastData.resultRow.loading ||
      forecastManagedData.resultTotal.loading || forecastManagedData.resultUs.loading || forecastManagedData.resultRow.loading ||
      noIdActualData.resultTotal.loading || noIdActualData.resultUs.loading || noIdActualData.resultRow.loading ||
      noIdForecastData.resultTotal.loading || noIdForecastData.resultUs.loading || noIdForecastData.resultRow.loading ||
      noOfficeActualData.resultTotal.loading || noOfficeActualData.resultUs.loading || noOfficeActualData.resultRow.loading ||
      noOfficeForecastData.resultTotal.loading || noOfficeForecastData.resultUs.loading || noOfficeForecastData.resultRow.loading ||
      salesforceData.resultTotal.loading || salesforceData.resultUs.loading || salesforceData.resultRow.loading
  ) {
    return (
      <SectionBox>
        <ComponentLoading text={"Loading Chart..."} p={5}/>
      </SectionBox>
    )
  }
  if (errorTarget ||
    actualData.resultTotal.error || actualData.resultUs.error || actualData.resultRow.error ||
    actualManagedData.resultTotal.error || actualManagedData.resultUs.error || actualManagedData.resultRow.error ||
    forecastData.resultTotal.error || forecastData.resultUs.error || forecastData.resultRow.error ||
    forecastManagedData.resultTotal.error || forecastManagedData.resultUs.error || forecastManagedData.resultRow.error ||
    noIdActualData.resultTotal.error || noIdActualData.resultUs.error || noIdActualData.resultRow.error ||
    noIdForecastData.resultTotal.error || noIdForecastData.resultUs.error || noIdForecastData.resultRow.error ||
    noOfficeActualData.resultTotal.error || noOfficeActualData.resultUs.error || noOfficeActualData.resultRow.error ||
    noOfficeForecastData.resultTotal.error || noOfficeForecastData.resultUs.error || noOfficeForecastData.resultRow.error ||
    salesforceData.resultTotal.error || salesforceData.resultUs.error || salesforceData.resultRow.error
  ) {
    return (
      <SectionBox>
        There seems to be a problem
      </SectionBox>
    );
  }

  const prepareHighlights = (): {
    label: string;
    value: string | number;
    color: string;
  }[]  => {
    const highlights = [];
    const percentages = getPercentages(
      sumRevenue(actualData.resultUs.data?.monthlyAggregatedRevenue),
      sumRevenue(actualData.resultRow.data?.monthlyAggregatedRevenue),
      sumRevenue(noIdActualData.resultTotal.data?.monthlyAggregatedRevenue),
      sumRevenue(noOfficeActualData.resultTotal.data?.monthlyAggregatedRevenue)
    );
    if (officeSelect.value === "ALL") {
      highlights.push({
        label: `${officeSelect.label} total actual revenue`,
        value: `$${sumRevenue(actualData.resultTotal.data?.monthlyAggregatedRevenue).toLocaleString()}`,
        color: dataColors.orange
      },{
        label: `${officeSelect.label} total forecast revenue`,
        value: `$${sumRevenue(forecastData.resultTotal.data?.monthlyAggregatedRevenue).toLocaleString()}`,
        color: dataColors.orange
      })
    }
    if (officeSelect.value === "COMBINED" || officeSelect.value === OfficeChoices.Us) {
      highlights.push({
        label: "US total actual revenue",
        value: `$${sumRevenue(actualData.resultUs.data?.monthlyAggregatedRevenue).toLocaleString()} (${Math.round(percentages.us)}%)`,
        color: dataColors.red
      },{
        label: "US total forecast revenue",
        value: `$${(sumRevenue(forecastData.resultUs.data?.monthlyAggregatedRevenue)).toLocaleString()}`,
        color: dataColors.red
      })
    }
    if (officeSelect.value === "COMBINED" || officeSelect.value === OfficeChoices.Row) {
      highlights.push({
        label: "ROW total actual revenue",
        value: `$${sumRevenue(actualData.resultRow.data?.monthlyAggregatedRevenue).toLocaleString()} (${Math.round(percentages.row)}%)`,
        color: dataColors.blue
      },{
        label: "ROW total forecast revenue",
        value: `$${(sumRevenue(forecastData.resultRow.data?.monthlyAggregatedRevenue)).toLocaleString()}`,
        color: dataColors.blue
      })
    }
    if (officeSelect.value === "COMBINED") {
      // Only show highlights for no ID or no office when an actual or forecast value exists
      if (
        sumRevenue(noIdActualData.resultTotal.data?.monthlyAggregatedRevenue) ||
        sumRevenue(noIdForecastData.resultTotal.data?.monthlyAggregatedRevenue)
      ) {
        highlights.push({
          label: "Unidentified total actual revenue",
          value: `$${sumRevenue(noIdActualData.resultTotal.data?.monthlyAggregatedRevenue).toLocaleString()} (${Math.round(percentages.noId)}%)`,
          color: dataColors.green
        },{
          label: "Unidentified total forecast revenue",
          value: `$${(sumRevenue(noIdForecastData.resultTotal.data?.monthlyAggregatedRevenue)).toLocaleString()}`,
          color: dataColors.green
        })
      }
      if (
        sumRevenue(noOfficeActualData.resultTotal.data?.monthlyAggregatedRevenue) ||
        sumRevenue(noOfficeForecastData.resultTotal.data?.monthlyAggregatedRevenue)
      ) {
        highlights.push({
          label: "No Office total actual revenue",
          value: `$${sumRevenue(noOfficeActualData.resultTotal.data?.monthlyAggregatedRevenue).toLocaleString()} (${Math.round(percentages.noOffice)}%)`,
          color: dataColors.gold
        },{
          label: "No Office total forecast revenue",
          value: `$${(sumRevenue(noOfficeForecastData.resultTotal.data?.monthlyAggregatedRevenue)).toLocaleString()}`,
          color: dataColors.gold
        })
      }
    }
    if (showSalesforce) {
      officeSelect.value === "ALL" && highlights.push({
        label: "Total Salesforce forecast",
        value: `$${sumRevenue(salesforceData.resultTotal.data?.monthlyAggregatedRevenue).toLocaleString()}`,
        color: dataColors.olive
      });
      (officeSelect.value === "COMBINED" || officeSelect.value === OfficeChoices.Us) && highlights.push({
        label: "US total Salesforce forecast",
        value: `$${sumRevenue(salesforceData.resultUs.data?.monthlyAggregatedRevenue).toLocaleString()}`,
        color: dataColors.olive
      });
      (officeSelect.value === "COMBINED" || officeSelect.value === OfficeChoices.Row) && highlights.push({
        label: "ROW total Salesforce forecast",
        value: `$${sumRevenue(salesforceData.resultRow.data?.monthlyAggregatedRevenue).toLocaleString()}`,
        color: dataColors.beige
      });
    }
    return highlights;
  };

  return (
    <>
      <ChartBox
        chartBoxHeader={"MONTHLY REVENUE BAR CHART"}
        dspFilter={{dspSelect: dspSelect, setDspSelect: setDspSelect}}
        officeFilter={{officeSelect: officeSelect, setOfficeSelect: setOfficeSelect}}
        yearFilter={{year: year, setYear: setYear}}
        isDownloadable={true}
        includeCombineOffice={true}
        forecastCheckbox={{showPastForecast: showPastForecast, setShowPastForecast: setShowPastForecast}}
        managedCheckbox={{showManaged: showManaged, setShowManaged: setShowManaged}}
        salesforceCheckbox={{showSalesforce: showSalesforce, setShowSalesforce: setShowSalesforce}}
      >
        <AnnualRevenueBarChart
          actualData={actualData}
          actualManagedData={actualManagedData}
          forecastData={forecastData}
          forecastManagedData={forecastManagedData}
          noIdActualData={noIdActualData}
          noIdForecastData={noIdForecastData}
          noOfficeActualData={noOfficeActualData}
          noOfficeForecastData={noOfficeForecastData}
          salesforceData={salesforceData}
          dataTarget={dataTarget}
          year={year}
          officeSelect={officeSelect}
          showPastForecast={showPastForecast}
          showManaged={showManaged}
          showSalesforce={showSalesforce}
        />
      </ChartBox>
      <SectionBox mt={4} mb={0}>
        <Highlights highlightsList={prepareHighlights()}/>
      </SectionBox>
    </>
  )
}

const sumRevenue = (monthlyData: MonthlyRevenueType[] | undefined) => {
  let sum = 0;
  monthlyData && monthlyData.forEach((month) => sum += month.revenue);
  return sum;
}

const getPercentages = (
  totalUs: number,
  totalRow: number,
  totalNoId: number,
  totalNoOffice: number
) => {
  // console.log(totalUs, totalRow, totalNoId, totalNoOffice)
  return {
    us: (totalUs/(totalUs + totalRow + totalNoId + totalNoOffice))*100,
    row: (totalRow/(totalUs + totalRow + totalNoId + totalNoOffice))*100,
    noId: (totalNoId/(totalUs + totalRow +  totalNoId + totalNoOffice))*100,
    noOffice: (totalNoOffice/(totalUs + totalRow +  totalNoId + totalNoOffice))*100,
  }
}

const runQuery = (
  query: DocumentNode,
  revenueType: RevenueType,
  dspSelect: dspFilterOptionType,
  officeSelect: officeFilterOptionType,
  year: number,
  isManaged?: boolean,
  isNoId=false,
  isNoOffice=false,
) => {

  const { data: dataTotal, loading: loadingTotal, error: errorTotal } = useQuery(query, {
      skip: officeSelect.value !== "ALL" || isNoId || isNoOffice,
      variables: {
        revenueType: revenueType,
        dspFilter: dspSelect.value === "ALL" ? undefined : dspSelect.value,
        officeFilter: undefined,
        isManaged: isManaged,
        isNoId: isNoId,
        yearFilter: year,
      },
      fetchPolicy: "cache-and-network",
  });
  const { data: dataUs, loading: loadingUs, error: errorUs } = useQuery(query, {
    skip: officeSelect.value === "ALL" || officeSelect.value === OfficeChoices.Row || isNoId || isNoOffice,
    variables: {
      revenueType: revenueType,
      dspFilter: dspSelect.value === "ALL" ? undefined : dspSelect.value,
      officeFilter: OfficeChoices.Us,
      isManaged: isManaged,
      yearFilter: year,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: dataRow, loading: loadingRow, error: errorRow } = useQuery(query, {
    skip: officeSelect.value === "ALL" || officeSelect.value === OfficeChoices.Us || isNoId || isNoOffice,
    variables: {
      revenueType: revenueType,
      dspFilter: dspSelect.value === "ALL" ? undefined : dspSelect.value,
      officeFilter: OfficeChoices.Row,
      isManaged: isManaged,
      yearFilter: year,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: dataNoId, loading: loadingNoId, error: errorNoId } = useQuery(query, {
    skip: !(officeSelect.value === "COMBINED" && isNoId),
    variables: {
      revenueType: revenueType,
      isNoId: isNoId,
      yearFilter: year,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: dataNoOffice, loading: loadingNoOffice, error: errorNoOffice } = useQuery(query, {
    skip: !(officeSelect.value === "COMBINED" && isNoOffice),
    variables: {
      revenueType: revenueType,
      isNoOffice: isNoOffice,
      yearFilter: year,
    },
    fetchPolicy: "cache-and-network",
  });

  const queryResult: RevenueDataType = {
    resultTotal: {data: dataTotal, loading: loadingTotal, error: errorTotal},
    resultUs: {data: dataUs, loading: loadingUs, error: errorUs},
    resultRow: {data: dataRow, loading: loadingRow, error: errorRow}
  };
  // For NoId data the result is assigned to resultTotal
  if (isNoId && !isNoOffice) {
    queryResult.resultTotal = {data: dataNoId, loading: loadingNoId, error: errorNoId}
  }
  if (isNoOffice && !isNoId) {
    queryResult.resultTotal = {data: dataNoOffice, loading: loadingNoOffice, error: errorNoOffice}
  }

  return queryResult
}
