export const mockSegmentRotationData = {
    segmentsRotated: [
        {
            taskName: "Not Campaign Name 1",
            platform: "Xandr",
            segmentId: "28781431",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 2",
            platform: "Xandr",
            segmentId: "28781432",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name very very very very very very long",
            platform: "Xandr",
            segmentId: "28781434",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 4",
            platform: "Xandr",
            segmentId: "28781436",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 5",
            platform: "Xandr",
            segmentId: "28781437",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 6",
            platform: "Xandr",
            segmentId: "28781438",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 7",
            platform: "Xandr",
            segmentId: "28781440",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 8",
            platform: "Xandr",
            segmentId: "28781439",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },

        {
            taskName: "Diff 9",
            platform: "Xandr",
            segmentId: "28781741",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Diff 10",
            platform: "Xandr",
            segmentId: "28781744",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Diff 11",
            platform: "Xandr",
            segmentId: "28781741",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Diff 12",
            platform: "Xandr",
            segmentId: "28781744",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },


        {
            taskName: "Not Campaign Name 1",
            platform: "Xandr",
            segmentId: "28781431",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 2",
            platform: "Xandr",
            segmentId: "28781432",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name very very very very very very long",
            platform: "Xandr",
            segmentId: "28781434",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 4",
            platform: "Xandr",
            segmentId: "28781436",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 5",
            platform: "Xandr",
            segmentId: "28781437",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 6",
            platform: "Xandr",
            segmentId: "28781438",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 7",
            platform: "Xandr",
            segmentId: "28781440",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Not Campaign Name 8",
            platform: "Xandr",
            segmentId: "28781439",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },

        {
            taskName: "Diff 9",
            platform: "Xandr",
            segmentId: "28781741",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Diff 10",
            platform: "Xandr",
            segmentId: "28781744",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Diff 11",
            platform: "Xandr",
            segmentId: "28781741",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
        {
            taskName: "Diff 12",
            platform: "Xandr",
            segmentId: "28781744",
            domains: "hant.se/nyheter,sydsvenskan.se/2022-03-12,folkbladet.nu/2022-03-09,hockeysverige.se,hn.se,op.se/2012-10-19,sttidningen.se,mabra.com/feelgood,kt-kuriren.se,marcusoscarsson.se/just-nu-rysk-general-dodad-av-ukrainas-vapnade-styrkor,vk.se/nara,allas.se/relationer,allehanda.se/2022-03-09,marcusoscarsson.se/ryssland-skrammande-uppgifter-fran-diktaturen-prast-arresterad,hn.se/hallands-affärer,vn.se,svenskdam.se,vibilagare.se/nyheter,babyhjalp.se,hant.se/noje,mitti.se,zeinaskitchen.se,klassiker.nu,folkbladet.nu,vn.se,babyhjalp.se,kt-kuriren.se,mitti.se,svenskdam.se,hockeysverige.se,hn.se,sttidningen.se",
            runAt: "2022-03-14 18:30:41.000",
            postedAt: "2022-03-14 18:30:39.000"
        },
    ]
}
