import { BoxProps, Text } from "@chakra-ui/react";

interface Props {
  title: string;
}

export const SectionTitle = ({ title, ...rest }: Props & BoxProps) => {
  return (
    <Text fontSize={"20px"} fontWeight={"bold"} mb={4} {...rest}>
      {title}
    </Text>
  );
};
