import { Box, Flex } from "@chakra-ui/react";
import { SelectField } from "../../../../components/selectField/SelectField";
import { InsightsRadio } from "../../InsightsRadio";
import { ActionLabel } from "../../../../components/actionLabel/ActionLabel";
import { useReactiveVar } from "@apollo/client";
import { overviewChartVar } from "../../../../lib/cache";

type ChartOptionsType = {
  label: string;
  value: string;
};

interface ChartSelectProps {
  chartView: string;
  chartOptions: ChartOptionsType[];
}

export const OverviewChartsHeader = () => {
  const chartView = useReactiveVar(overviewChartVar);
  const chartOptions: ChartOptionsType[] = [
    { label: "Bubble Chart", value: "BUBBLE" },
    { label: "Heatmap", value: "HEATMAP" },
    { label: "Bar Chart Race", value: "BARCHARTRACE" }
  ];
  return (
    <Flex justifyContent={"space-between"}>
      <InsightsRadio />
      <ChartSelect chartView={chartView} chartOptions={chartOptions} />
    </Flex>
  );
};

const ChartSelect = ({ chartView, chartOptions }: ChartSelectProps) => {
  return (
    <ActionLabel label={"view"}>
      <Box minW={40}>
        <SelectField
          styles={{
            valueContainer: (base) => ({
              ...base,
              backgroundColor: "white",
            }),
            indicatorsContainer: (base) => ({
              ...base,
              backgroundColor: "white",
            }),
            menu: (base) => ({
              ...base,
              width: "100%",
            }),
          }}
          isMulti={false}
          value={chartOptions.find(({ value }) => value === chartView)}
          options={chartOptions}
          onChange={({ value }) => {
            overviewChartVar(value);
          }}
        />
      </Box>
    </ActionLabel>
  );
};
