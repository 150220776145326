import { useReactiveVar } from "@apollo/client";
import {
  Box,
  BoxProps,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  CategoryInsightEdge,
  CategoryInsightNode,
  InsightMetricsEnum,
} from "../../../graphql/generated";
import { ImpressionsIcon } from "../../../assets/icons/ImpressionsIcon";
import { ConversionsIcon } from "../../../assets/icons/ConversionsIcon";
import { MetricKey } from "../../../../types";
import { ClicksIcon } from "../../../assets/icons/ClicksIcon";
import {
  formatNumberDisplay,
  getMetricDisplay,
  getMetricSuffix,
} from "../../../utils/displayGetters";
import { categoryOrderingVar, currentCategoryVar } from "../../../lib/cache";
import { useCampaignAvailableMetrics } from "../hooks";
import { FaRegDotCircle } from "react-icons/fa";

export const CategoryList = ({ edges }: { edges: CategoryInsightEdge[] }) => {
  const { orderBy, ordering } = useReactiveVar(categoryOrderingVar);
  // The default ordering for the sort function below is descending.
  // If ASC is selected, we reverse the array before rendering
  let orderedEdges = edges.concat().sort(sortBy(orderBy));

  if (ordering === "DESC") {
    orderedEdges = orderedEdges.reverse();
  }

  return (
    <>
      <Box bg={"white"} borderRadius={"lg"} overflowY={"scroll"}>
        {orderedEdges.map(({ node }: CategoryInsightEdge, ix: number) => {
          return (
            <CategoryListCard
              key={`${node.category}-${ix}`}
              node={node}
              showBorder={!!ix}
            />
          );
        })}
      </Box>
    </>
  );
};

// Returns the category edges sorted in descending order.
const sortBy = (key: string) => {
  return (a: CategoryInsightEdge, b: CategoryInsightEdge) => {
    if (key === "ALPHABETICALLY") {
      return a.node.category > b.node.category
        ? 1
        : b.node.category > a.node.category
          ? -1
          : 0;
    } else {
      const metricKey: MetricKey = key.toLowerCase() as MetricKey;
      return a.node.insightValues[metricKey] - b.node.insightValues[metricKey];
    }
  };
};

const CategoryListCard = ({
  node: { id, category, insightValues },
  showBorder,
  ...rest
}: {
  node: CategoryInsightNode;
  showBorder: boolean;
} & BoxProps) => {
  const currentCategory = useReactiveVar(currentCategoryVar);
  const isCurrentCategory = currentCategory.id === id;
  const availableMetrics = useCampaignAvailableMetrics();
  return (
    <Box
      borderTopColor={showBorder ? "muted" : "transparent"}
      borderTopWidth={"1px"}
      bg={isCurrentCategory ? "secondary" : "transparent"}
      color={isCurrentCategory ? "white" : "text.dark"}
      fill={isCurrentCategory ? "white" : "#93a5b7"}
      pl={4}
      py={2}
      cursor={"pointer"}
      _hover={{
        bg: "primary.dark",
        color: "text.dark",
        fill: "text.dark",
        "#conversions-icon": { stroke: "text.dark" },
        "#fa-ctr-icon": { color: "text.dark" },
      }}
      onClick={() => {
        currentCategoryVar({ id, category });
      }}
      _active={{
        bg: "primary.darker",
      }}
      sx={{
        "#conversions-icon": {
          stroke: `${isCurrentCategory ? "white" : "#93a5b7"}`,
        },
        "#fa-ctr-icon": {
          color: isCurrentCategory ? "white" : "#93a5b7",
        },
      }}
      {...rest}
    >
      <Text fontSize={"14px"} fontWeight={"bold"}>
        {category}
      </Text>
      <Grid templateColumns={`repeat(${availableMetrics.length}, 1fr)`} gap={4}>
        {availableMetrics.map((metric, ix) => (
          <GridItem colSpan={1} key={`${category}-metric-${ix}`} py={1}>
            <MetricDisplay
              metric={metric as InsightMetricsEnum}
              value={insightValues[metric.toLowerCase() as MetricKey]}
            />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

const MetricDisplay = ({
  metric,
  value,
}: {
  metric: InsightMetricsEnum;
  value: number;
}) => {
  const suffix = getMetricSuffix(metric);
  const getIcon = (key: InsightMetricsEnum, props: Record<string, any>) => {
    switch (key) {
      case InsightMetricsEnum.Impressions:
        return <ImpressionsIcon {...props} />;
      case InsightMetricsEnum.Clicks:
        return <ClicksIcon {...props} />;
      case InsightMetricsEnum.Conversions:
        return (
          <ConversionsIcon
            id={"conversions-icon"}
            stroke={"#93a5b7"}
            {...props}
          />
        );
      case InsightMetricsEnum.Ctr:
        return <Icon as={FaRegDotCircle} {...props} id={"fa-ctr-icon"} />;
    }
  };
  return (
    <Tooltip
      label={getMetricDisplay(metric)}
      placement={"bottom-start"}
      bg={"text.dark"}
    >
      <HStack justifyContent={"flex-start"}>
        {getIcon(metric, {
          w: 4,
          h: 4,
        })}
        <Text>{formatNumberDisplay(value, suffix)}</Text>
      </HStack>
    </Tooltip>
  );
};
