import { useApolloClient, useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  AvailableMetricsFragmentDoc,
  InsightMetricsEnum,
} from "../../graphql/generated";
import { insightsMetricVar } from "../../lib/cache";
import { getMetricSuffix } from "../../utils/displayGetters";

export const useCampaignAvailableMetrics = (): InsightMetricsEnum[] => {
  const client = useApolloClient();
  const { id: campaignId } = useParams();

  const result = client.readFragment({
    id: `CampaignType:${campaignId}`,
    fragment: AvailableMetricsFragmentDoc,
  });
  const metrics = result ? result.availableMetrics : [];
  if (metrics.includes(InsightMetricsEnum.Clicks)) {
    return [ ...metrics, InsightMetricsEnum.Ctr]
  }
  return [...metrics];
};

 export const useMetricSuffix = (): string => {
  const currentMetric = useReactiveVar(insightsMetricVar);
  return getMetricSuffix(currentMetric);
};
