import {
    HStack,
    Tag,
    TagLabel,
    Text,
    Tooltip,
    Grid,
    Box,
    GridItem,
    Button,
    Icon,
    IconButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { formatDateDisplay, getPartnerName } from "../../utils/displayGetters";
import { HiOutlineClipboardList } from "react-icons/hi";
import { PackageType } from "../../graphql/generated";
import { useReactiveVar } from "@apollo/client";
import { userDetailsVar } from "../../lib/cache";

  export const SegmentCard = ({
    name,
    startDate,
    endDate,
    uniqueId,
    active,
    partner
  }: Partial<PackageType>) => {
    const currentUser = useReactiveVar(userDetailsVar);

    return (
      <Tooltip
        label={name}
        placement={"top-start"}
        openDelay={800}
        bg={"text.dark"}
        p={2}
      >
        <Box
          bg={"white"}
          borderRadius={"lg"}
          px={[2, null, null, 6, null]}
          py={[2, null, null, 4, null]}
          transition={"all 0.3s ease-in-out"}
          overflow={"hidden"} // ToDo: Remove this
        >
          <Grid templateColumns={"repeat(8, 1fr)"} w={"100%"}>
            <GridItem colSpan={5} maxW={"95%"}>
              <Link to={`${uniqueId}`}>
                <Text
                  fontWeight={"bold"}
                  fontSize={["md", null, null, "lg", null]}
                  isTruncated
                  _hover={{
                    color: "primary.dark",
                  }}
                  _active={{
                    color: "primary.darker",
                  }}
                >
                  {/* <span>{creativeType}</span> */}
                  {name}
                </Text>
              </Link>
              <HStack spacing={2} gridRow={1}>
                <Text fontSize={["xs", null, null, "md", null]}>
                  {formatDateDisplay(startDate)}
                </Text>
                <span>&ndash;</span>{" "}
                <Text fontSize={["xs", null, null, "md", null]}>
                  {endDate ? formatDateDisplay(endDate) : "TBD"}
                </Text>
                <Tag
                  size={"sm"}
                  bg={active ? "primary.light" : "inactive"}
                  borderRadius={"full"}
                  px={3}
                >
                  <TagLabel>
                    {active ? "Active" : "Inactive"}
                </TagLabel>
                </Tag>
                {currentUser?.isStaff &&
                  <Text color={"muted"} fontSize={"sm"}>{getPartnerName(partner)}</Text>
                }
              </HStack>
            </GridItem>
            <GridItem colSpan={3}>
              <HStack align={"center"} justify={"flex-end"}>
                <Link to={`${uniqueId}`}>
                  <IconButton
                    aria-label={"link to segment summary"}
                    icon={<HiOutlineClipboardList />}
                    variant={"unStyled"}
                    display={["flex", null, "none", null, null]}
                  />
                  <Button
                    variant={"unStyled"}
                    fontSize={["sm", null, null, "md", null]}
                    display={["none", null, "flex", null, null]}
                    leftIcon={
                      <Icon
                        as={HiOutlineClipboardList}
                        w={[4, null, null, 5, null]}
                        h={[4, null, null, 5, null]}
                      />
                    }
                  >
                    Summary
                  </Button>
                </Link>
              </HStack>
            </GridItem>
          </Grid>
        </Box>
      </Tooltip>
    );
  };
