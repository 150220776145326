import * as React from "react";
import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";

export interface FormSectionProps {
  title: string;
  /**
   * If using  a multi part form on a single page, the index determines the
   * number to show on the form
   */
  index?: number;
}

export const FormSection: React.FC<FormSectionProps & BoxProps> = ({
  title,
  index,
  children,
  ...boxProps
}) => {
  const sectionColors = ["secondary", "accent", "primary.dark"];
  return (
    <Flex direction={"column"} {...boxProps}>
      <Flex alignItems={"center"}>
        {index && (
          <Box
            color={sectionColors[index - 1] ?? "text.dark"}
            display={"inline-block"}
            borderRadius={"full"}
            fontSize={"17px"}
            fontWeight={"bold"}
            border={"2px solid"}
            lineHeight={"0px"}
            sx={{
              "& span": {
                display: "inline-block",
                pt: "50%",
                pb: "50%",
                ml: "5px",
                mr: "5px",
                fontWeight: "bold",
                fontSize: "17px",
              },
            }}
          >
            <span>{index}</span>
          </Box>
        )}
        <Text fontSize={"26px"} fontWeight={"bold"} ml={2}>
          {title}
        </Text>
      </Flex>
      <Box mt={3}>{children}</Box>
    </Flex>
  );
};
