export default {
    variants: {
        primary: {
            content: {
                borderColor: "primary.dark",
                borderWidth: 2,
                // _focus: {
                //     borderColor: "red"
                // },
            },
            body: {
                padding: 0
            }
        }
    }
}
