import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  GET_CAMPAIGN_INFO,
  PROTECTED_GET_CAMPAIGN_INFO,
} from "../../graphql/operations/campaignOperations/operations";
import { userDetailsVar } from "../../lib/cache";
// import { CampaignPriceSelector } from "./CampaignPriceSelector";
// import { SendCommunications } from "./SendCommunications";
// import { Actions } from "./Actions";
import { BasicDetailsSummary } from "./BasicDetailsSummary";
// import { OwnerDetails } from "./OwnerDetails";
import { KPIDetailsSummary } from "./KpiDetailsSumamry";
import { DSPDetailsSummary } from "./DspDetailsSummary";
import { ComponentLoading } from "../../components/loading/ComponentLoading";
import { CampaignStatusChoices, CampaignType } from "../../graphql/generated";
import { SegmentsSummary } from "./SegmentsSummary";
// import { SummaryHeader } from "./SummaryHeader";
import { CreativeIdsSummary } from "./CreativeIdsSummary";
import { CampaignStatusDisplay } from "./CampaignStatusDisplay";
import { ContentHeader } from "../../components/contentHeader/ContentHeader";

interface ICampaignSummary {
  campaign: CampaignType;
  shouldPoll: boolean;
}

const campaignContext = createContext({} as ICampaignSummary);

export const useCampaignInfo = () => {
  return useContext(campaignContext);
};

export const CampaignSummaryPage = () => {
  const currentUser = useReactiveVar(userDetailsVar);

  const { id: campaignId } = useParams();

  const [shouldPoll, setShouldPoll] = useState(false);

  const detailQuery = currentUser?.isStaff
    ? PROTECTED_GET_CAMPAIGN_INFO
    : GET_CAMPAIGN_INFO;

  if (!campaignId) {
    return <Navigate to={"/404"} />;
  }

  useEffect(() => {
    if (shouldPoll) {
      startPolling(2000);
    }
    return stopPolling;
  }, [shouldPoll]);

  const { data, error, loading, startPolling, stopPolling } = useQuery(
    detailQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        id: campaignId,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ campaignInfo }) => {
        if (campaignInfo) {
          setShouldPoll(
            campaignInfo.isApproved &&
              !campaignInfo.segments.length &&
              campaignInfo.status === CampaignStatusChoices.Submitted
          );
        }
      },
    }
  );

  if (loading && !data) {
    return <ComponentLoading text={"Loading your campaign information..."} />;
  }

  if (error) {
    return <Navigate to={"/404"} />;
  }
  const campaignInfo: CampaignType = data.campaignInfo;

  if (!campaignInfo) {
    return <Navigate to={"/404"} />;
  }

  return (
    <campaignContext.Provider value={{ campaign: campaignInfo, shouldPoll }}>
      <ContentHeader campaign={campaignInfo}/>
      {/* <SummaryHeader /> */}
      <Grid gap={6} templateColumns={"repeat(8, 1fr)"}>
        <GridItem colSpan={4}>
          <BasicDetailsSummary />
          <KPIDetailsSummary />
          {campaignInfo.dspSpecific && <DSPDetailsSummary />}
        </GridItem>
        <GridItem colSpan={4}>
          {/* <Collapse in={currentUser?.isStaff} unmountOnExit={true}>
            <OwnerDetails />
            <CampaignPriceSelector />
            <Actions />
            <SendCommunications />
          </Collapse> */}
          <Box borderRadius={"lg"} bg={"white"} overflow={"hidden"}>
            <CampaignStatusDisplay />
            <SegmentsSummary />
          </Box>
          <CreativeIdsSummary />
        </GridItem>
      </Grid>
    </campaignContext.Provider>
  );
};
