export default {
  baseStyle: {
    fontSize: "13px",
    color: "text.dark",
    fontWeight: "semibold",
    _invalid: {
      color: "error",
    },
  },
};
