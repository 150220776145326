import { useReactiveVar } from "@apollo/client";
import { Box, Button, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import { GoGraph } from "react-icons/go";
import { HiOutlineClipboardList } from "react-icons/hi";
import { IoIosArrowRoundBack } from "react-icons/io";
import { RiAdminLine } from "react-icons/ri";
import { Link, useLocation, useParams } from "react-router-dom";
import { CampaignType } from "../../graphql/generated";
import { userDetailsVar } from "../../lib/cache";

interface ContentHeaderProps {
    campaign?: CampaignType,
}

export const ContentHeader = ({campaign}: ContentHeaderProps) => {

    const currentUser = useReactiveVar(userDetailsVar);
    //const campaignHasInsights = !!campaign.availableMetrics.length;
    const campaignHasInsights = campaign ? !!campaign.availableMetrics.length : null
    const { id: campaignId } = useParams();
    const pathname = useLocation().pathname;

    return (
        <Flex mb={8} justifyContent={"space-between"}>
          <Box
            as={"button"}
            onClick={() => history.back()}
          >
            <HStack
              spacing={1}
              _hover={{
                stroke: "primary.darker",
                color: "primary.darker",
              }}
              align={"center"}
            >
              <Icon
                as={IoIosArrowRoundBack}
                width={6}
                height={6}
                strokeWidth={10}
              />
              <Text fontSize={"sm"}>
                Back
                {/* {currentUser?.isStaff ? "Back to Campaigns" : "Back to Your Campaigns"} */}
              </Text>
            </HStack>
          </Box>
          <HStack>
          {campaign &&
            <>
                {!pathname.includes("admin-campaign") &&
                    <Link to={`/campaigns/${campaignId}/admin-campaign`}>
                        <Button
                            variant={"darkSolid"}
                            backgroundColor={"accent"}
                            size={"sm"}
                            leftIcon={<Icon as={RiAdminLine} w={5} h={5} />}
                            hidden={!currentUser?.isStaff}
                        >
                            Admin
                        </Button>
                    </Link>
                }
                {pathname.includes("admin-campaign") &&
                    <Link to={`/campaigns/${campaignId}`}>
                        <Button
                            variant={"darkSolid"}
                            backgroundColor={"darkSolid"}
                            size={"sm"}
                            leftIcon={<Icon as={HiOutlineClipboardList} w={5} h={5}/>}
                            hidden={!campaignId}
                        >
                            Summary
                        </Button>
                    </Link>
                }
                <Link to={`/campaigns/${campaignId}/insights/overview`}>
                    <Button
                        variant={"darkSolid"}
                        size={"sm"}
                        leftIcon={<Icon as={GoGraph} w={5} h={5} />}
                        disabled={!campaignHasInsights || !campaign.insightsPermitted && !currentUser?.isStaff}
                        hidden={!campaignId}
                    >
                        Go to Insights
                    </Button>
                </Link>
                </>
            }
          </HStack>
        </Flex>
      );
}
