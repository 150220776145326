import { Icon } from "@chakra-ui/react";

export const CalendarIcon = (props: any) => (
  <Icon {...props}>
    <g transform="translate(-270 121.1)">
      <path
        className="a"
        d="M290.994-119.325h-1.47v-1.165a.612.612,0,0,0-.61-.61.612.612,0,0,0-.61.61v1.165h-5.979v-1.165a.612.612,0,0,0-.61-.61.612.612,0,0,0-.61.61v1.165h-6.04v-1.165a.612.612,0,0,0-.61-.61.612.612,0,0,0-.61.61v1.165h-1.409A2.469,2.469,0,0,0,270-116.854v14.185a2.435,2.435,0,0,0,2.434,2.44h18.553a2.433,2.433,0,0,0,2.434-2.434h0v-14.191A2.46,2.46,0,0,0,290.994-119.325Zm1.214,16.656a1.217,1.217,0,0,1-1.208,1.22H272.434a1.213,1.213,0,0,1-1.214-1.214v-14.191a1.241,1.241,0,0,1,1.214-1.251h1.409v.885a.612.612,0,0,0,.61.61.612.612,0,0,0,.61-.61v-.885h6.04v.885a.612.612,0,0,0,.61.61.612.612,0,0,0,.61-.61v-.885H288.3v.885a.612.612,0,0,0,.61.61.612.612,0,0,0,.61-.61v-.885h1.47a1.241,1.241,0,0,1,1.214,1.251Z"
      />
      <path
        className="a"
        d="M409.878-7H408.81a.612.612,0,0,0-.61.61.612.612,0,0,0,.61.61h1.068a.612.612,0,0,0,.61-.61A.612.612,0,0,0,409.878-7Z"
        transform="translate(-129.768 -107.139)"
      />
      <path
        className="a"
        d="M479.478-7H478.41a.612.612,0,0,0-.61.61.612.612,0,0,0,.61.61h1.068a.612.612,0,0,0,.61-.61.608.608,0,0,0-.61-.61Z"
        transform="translate(-195.122 -107.139)"
      />
      <path
        className="a"
        d="M549.678-7H548.61a.612.612,0,0,0-.61.61.612.612,0,0,0,.61.61h1.068a.612.612,0,0,0,.61-.61.612.612,0,0,0-.61-.61Z"
        transform="translate(-261.039 -107.139)"
      />
      <path
        className="a"
        d="M409.878,62H408.81a.61.61,0,1,0,0,1.22h1.068a.61.61,0,1,0,0-1.22Z"
        transform="translate(-129.768 -171.929)"
      />
      <path
        className="a"
        d="M339.684,62H338.61a.61.61,0,0,0,0,1.22h1.068a.61.61,0,0,0,.006-1.22Z"
        transform="translate(-63.851 -171.929)"
      />
      <path
        className="a"
        d="M479.478,62H478.41a.61.61,0,0,0,0,1.22h1.068a.61.61,0,1,0,0-1.22Z"
        transform="translate(-195.122 -171.929)"
      />
      <path
        className="a"
        d="M549.678,62H548.61a.61.61,0,1,0,0,1.22h1.068a.61.61,0,1,0,0-1.22Z"
        transform="translate(-261.039 -171.929)"
      />
      <path
        className="a"
        d="M409.878,130H408.81a.61.61,0,0,0,0,1.22h1.068a.61.61,0,0,0,0-1.22Z"
        transform="translate(-129.768 -235.78)"
      />
      <path
        className="a"
        d="M339.684,130H338.61a.61.61,0,0,0,0,1.22h1.068a.61.61,0,0,0,.006-1.22Z"
        transform="translate(-63.851 -235.78)"
      />
      <path
        className="a"
        d="M479.478,130H478.41a.61.61,0,0,0,0,1.22h1.068a.61.61,0,0,0,0-1.22Z"
        transform="translate(-195.122 -235.78)"
      />
    </g>
  </Icon>
);
