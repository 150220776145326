export default {
  variants: {
    primary: {
      thumb: {
        bg: "text.dark",
        _checked: {
          bg: "primary.light",
        },
      },
      track: {
        bg: "muted",
        _focus: {
          boxShadow: "none",
        },
        _checked: {
          bg: "primary.dark",
        },
      },
    },
  },

  defaultProps: {
    size: "sm",
    coloScheme: "teal",
    variant: "primary",
  },
};
