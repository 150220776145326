import { BoxProps, Text } from "@chakra-ui/react";

export const SectionHeader = ({
  title,
  ...rest
}: { title: string } & BoxProps) => (
  <Text fontSize={"20px"} fontWeight={"medium"} mb={5} {...rest}>
    {title}
  </Text>
);
