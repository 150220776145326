import {
    BoxProps,
    Button,
    Collapse,
    Flex,
    HStack,
    Icon,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Text,
    useToast,
    Spacer
  } from "@chakra-ui/react";
  import { useState } from "react";
  import { useMutation } from "@apollo/client";
  import {
    PROTECTED_GET_CAMPAIGN_INFO,
    UPDATE_CAMPAIGN,
  } from "../../../graphql/operations/campaignOperations/operations";
  import { IoMdCheckmark } from "react-icons/io";
  import { ToastNotificationBox } from "../../../components/toastNotificationBox/ToastNotificationBox";
  import { useCampaignInfo } from "../index";
  import { SectionBox } from "../../../components/sectionBox/SectionBox";

  export const Actions = (props: BoxProps) => {
    const [updateCampaign, { loading }] = useMutation(UPDATE_CAMPAIGN, {
      refetchQueries: [PROTECTED_GET_CAMPAIGN_INFO],
    });
    const [approvalError, setApprovalError] = useState<string>("");
    const [insightsMessage, setInsightsMessage] = useState<string>("");
    const toast = useToast();
    const toastId = "approval-toast";
    const {
      campaign: { id, isApproved, insightsPermitted },
    } = useCampaignInfo();
    return (
      <SectionBox sectionHeader={"Campaign Actions"} {...props}>
        <Collapse in={!!approvalError}>
          <Text fontSize={"sm"} color={"error"} fontWeight={"bold"}>
            {approvalError}
          </Text>
        </Collapse>
        <Collapse in={!!insightsMessage}>
          <Text fontSize={"sm"} color={"error"} fontWeight={"bold"}>
            {insightsMessage}
          </Text>
        </Collapse>
        <HStack marginBottom={5}>
          <Text
            fontWeight={"bold"}
            fontSize={"18px"}
            color={isApproved ? "green.300" : "error"}
          >
            {isApproved ? "Campaign Approved" : " Campaign Not Approved"}
          </Text>
          <Spacer />
          {!isApproved && (
            <Flex justifyContent={"flex-end"} mt={3}>
              <Popover placement={"left-start"}>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <Button
                        width={150}
                        variant={"primary"}
                        rightIcon={<Icon as={IoMdCheckmark} width={6} height={6} />}
                        isLoading={loading}
                      >
                        Approve
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      bg={"text.dark"}
                      color={"white"}
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      <PopoverCloseButton />
                      <PopoverHeader fontWeight={"bold"} fontSize={"18px"}>
                        Campaign Approval
                      </PopoverHeader>
                      <PopoverBody>
                        Are you sure you want to approve this campaign?
                      </PopoverBody>
                      <PopoverFooter d={"flex"} justifyContent={"flex-end"}>
                        <Button
                          alignContent={"right"}
                          onClick={() => {
                            updateCampaign({
                              variables: {
                                id: id,
                                updateFields: {
                                  approved: true,
                                },
                              },
                            })
                              .then(() => {
                                setApprovalError("");
                                toast({
                                  id: toastId,
                                  status: "info",
                                  duration: 3000,
                                  render: () => {
                                    return (
                                      <ToastNotificationBox
                                        content={"Campaign approved!"}
                                      />
                                    );
                                  },
                                });
                              })
                              .catch((err) =>
                                setApprovalError(err.graphQLErrors[0].message)
                              );
                            onClose();
                          }}
                          variant={"primary"}
                          bg={"red.500"}
                          color={"white"}
                          size={"sm"}
                        >
                          Yes
                        </Button>
                      </PopoverFooter>
                    </PopoverContent>
                  </>
                )}
              </Popover>
            </Flex>
          )}
        </HStack>

        <HStack>
          <Text
            fontWeight={"bold"}
            fontSize={"18px"}
            color={insightsPermitted ? "green.300" : "error"}
          >
            {insightsPermitted ? "Insights Permitted" : "Insights Not Permitted"}
          </Text>
          <Spacer />
          {!insightsPermitted && (
          <Flex justifyContent={"flex-end"} mt={3}>
            <Popover placement={"left-start"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button
                      width={150}
                      variant={"primary"}
                      rightIcon={<Icon as={IoMdCheckmark} width={6} height={6} />}
                      isLoading={loading}
                    >
                      Permit
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    bg={"text.dark"}
                    color={"white"}
                    _focus={{
                      boxShadow: "none",
                    }}
                  >
                    <PopoverCloseButton />
                    <PopoverHeader fontWeight={"bold"} fontSize={"18px"}>
                      Permit Insights
                    </PopoverHeader>
                    <PopoverBody>
                      Are you sure you want to permit insights for this campaign?
                    </PopoverBody>
                    <PopoverFooter d={"flex"} justifyContent={"flex-end"}>
                      <Button
                        onClick={() => {
                          updateCampaign({
                            variables: {
                              id: id,
                              updateFields: {
                                insightsPermitted: true,
                              },
                            },
                          })
                            .then(() => {
                              setInsightsMessage("");
                              toast({
                                id: toastId,
                                status: "info",
                                duration: 3000,
                                render: () => {
                                  return (
                                    <ToastNotificationBox
                                      content={"Insights Permitted!"}
                                    />
                                  );
                                },
                              });
                            })
                            .catch((err) =>
                              setInsightsMessage(err.graphQLErrors[0].message)
                            );
                          onClose();
                        }}
                        variant={"primary"}
                        bg={"red.500"}
                        color={"white"}
                        size={"sm"}
                      >
                        Yes
                      </Button>
                    </PopoverFooter>
                  </PopoverContent>
                </>
              )}
            </Popover>
          </Flex>
        )}
        </HStack>
      </SectionBox>
    );
  };
