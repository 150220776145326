import { Actions } from "./Actions"
import { CampaignDetails } from "./CampaignDetails"

export const Approval = () => {
    return (
        <>
            <CampaignDetails />
            <Actions mb={0}/>
        </>
    )
}
