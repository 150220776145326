import { useQuery, useReactiveVar } from "@apollo/client";
import { Flex } from "@chakra-ui/react"
import { createContext, useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { ContentHeader } from "../../components/contentHeader/ContentHeader";
import { ComponentLoading } from "../../components/loading/ComponentLoading";
import { SidebarGroup } from "../../components/sidebar/SidebarGroup";
import { SidebarItem } from "../../components/sidebar/SidebarItem";
import { SidebarV2 } from "../../components/sidebar/SidebarV2";
import { CampaignChecklistType, CampaignType, QuestionGroupChoices } from "../../graphql/generated";
import { PROTECTED_GET_CAMPAIGN_INFO } from "../../graphql/operations/campaignOperations/operations";
import { userDetailsVar } from "../../lib/cache";
// import { AdminHeader } from "./AdminHeader";

interface ICampaignInfo {
    campaign: CampaignType;
  }

const campaignContext = createContext({} as ICampaignInfo);

export const useCampaignInfo = () => {
  return useContext(campaignContext);
};

export const AdminCampaignPage = () => {

    const { pathname } = useLocation();
    const currentUser = useReactiveVar(userDetailsVar);
    const { id: campaignId } = useParams();

    useEffect(() => {
        //Scrolls to top of flex area when changing sections
        document.getElementById("contentArea")?.scrollTo(0,0)
    }, [pathname]);

    const { data, error, loading } = useQuery(
      PROTECTED_GET_CAMPAIGN_INFO,
      {
        fetchPolicy: "cache-and-network",
        variables: {
          id: campaignId,
        }
      }
    );

    if (loading && !data || currentUser?.isStaff === undefined) {
        return <ComponentLoading text={"Loading campaign information..."} />;
    }

    if(!currentUser?.isStaff) {
        return <Navigate to={"/campaigns"} />
    }

    if (error) {
        return <Navigate to={"/404"} />;
    }

    const campaignInfo: CampaignType = data.campaignInfo;

    if (!campaignInfo) {
        return <Navigate to={"/404"} />;
    }

    const checkIfComplete = (questionGroup: QuestionGroupChoices): boolean => {
      // Filtering for question group
      const filteredChecklist = campaignInfo.checklist.filter((checklistOption: CampaignChecklistType) => (
          checklistOption.questionGroup === questionGroup
      ));
      // When question group does not exist on checklist return false
      if (!filteredChecklist.length) {
          return false;
      }
      // Check all isResolved, return true if all are resolved
      return filteredChecklist.every((checklistOption: CampaignChecklistType) => (
          checklistOption.isResolved === true
      ))
    }

    return (
        <campaignContext.Provider value={{ campaign: campaignInfo }}>
            <ContentHeader campaign={campaignInfo}/>
            {/* <AdminHeader /> */}
            <Flex>
                <SidebarV2>
                    <SidebarItem
                        title={"Overview"}
                        url={"campaign-admin-overview"}
                        removeTopBorder={true}
                    />
                    <SidebarGroup
                        title={"Workflow"}
                    >
                        <SidebarItem
                            isSubItem={true}
                            title={"1. Approve Campaign"}
                            url={"approval"}
                            isComplete={campaignInfo.isApproved}
                        />
                        <SidebarItem
                            isSubItem={true}
                            title={"2. Pre-Campaign Checks"}
                            url={"pre-campaign-checks"}
                            isComplete={checkIfComplete(QuestionGroupChoices.PreCampaignChecks)}
                        />
                        <SidebarItem
                            isSubItem={true}
                            title={"3. Active Campaign"}
                            url={"active-campaign"}
                            isComplete={checkIfComplete(QuestionGroupChoices.ActiveCampaign)}
                        />
                    </SidebarGroup>
                    {/* <SidebarItem
                        title={"Segment Rotation"}
                        url={"specific-segment-rotation"}
                    /> */}
                </SidebarV2>
                <Flex
                    id={"contentArea"}
                    flexDirection={"column"}
                    w={"full"}
                    mx={2}
                    overflowY={"scroll"}
                    h={"75vh"}
                >
                  <Outlet />
                </Flex>
            </Flex>
        </campaignContext.Provider>
    )
}

// HOW OLD SIDEBAR COMPONENT WAS USED
// // Two patterns available for sidebar, individual tab and group tab, example of each below
// const sidebarItems: SidebarOptions[] = [
//     {
//         title: "Overview",
//         url: "campaign-admin-overview",
//         subItems: null
//     },
//     {
//         title: "Workflow",
//         url: null,
//         subItems: [
//             {subTitle: "Approve Campaign", subUrl: "approval", isComplete: campaignInfo.isApproved},
//             {subTitle: "Pre-Campaign Checks", subUrl: "pre-campaign-checks", isComplete: checkIfComplete(QuestionGroupChoices.PreCampaignChecks)},
//             {subTitle: "Active Campaign", subUrl: "active-campaign", isComplete: checkIfComplete(QuestionGroupChoices.ActiveCampaign)},
//         ]
//     },
//     // Keeping hidden until ready
//     // {
//     //     title: "Segment Rotation",
//     //     url: "specific-segment-rotation",
//     //     subItems: null
//     // },
// ]
