import {
    Box,
    Button,
    HStack,
    Icon,
    IconButton,
    Link,
    Spacer,
    Text,
    VStack
} from "@chakra-ui/react"
import { Link as LinkWrap } from "react-router-dom";
import { IoReaderOutline } from "react-icons/io5";
import { BiPencil } from "react-icons/bi";

interface TitleLinkProps {
    url: string,
    showNote: boolean
    correctionButton?: boolean
    reviewButton?: boolean
}

export const TitleLink = ({
    url,
    showNote,
    correctionButton=false,
    reviewButton=false
}: TitleLinkProps) => {

    return (
        <Box mx={4} my={6}>
            <VStack alignItems={"start"}>
            <HStack width={"100%"}>
                <Text fontWeight={"bold"} fontSize={"18px"}>
                    {getSiteNameFromUrl(url).toUpperCase()}
                </Text>
                <Spacer />
                {
                    correctionButton &&
                        <LinkWrap to={"/human-in-the-loop/past-review"}>
                            <IconButton
                                backgroundColor={"primary.dark"}
                                aria-label={"link to correction"}
                                icon={<IoReaderOutline />}
                                variant={"primary"}
                                display={["flex", null, "none", null, null]}
                            />
                            <Button
                                px={4}
                                backgroundColor={"primary.dark"}
                                variant={"primary"}
                                fontSize={["sm", null, null, "md", null]}
                                display={["none", null, "flex", null, null]}
                                leftIcon={
                                    <Icon
                                        as={IoReaderOutline}
                                        w={[4, null, null, 5, null]}
                                        h={[4, null, null, 5, null]}
                                    />
                                }
                                // hidden={!currentUser?.isStaff}
                            >
                                Correction
                            </Button>
                        </LinkWrap>
                }
                {
                    reviewButton &&
                        <LinkWrap to={"/human-in-the-loop/review"}>
                            <IconButton
                                backgroundColor={"primary.dark"}
                                aria-label={"link to url review"}
                                icon={<BiPencil />}
                                variant={"primary"}
                                display={["flex", null, "none", null, null]}
                            />
                            <Button
                                px={4}
                                backgroundColor={"primary.dark"}
                                variant={"primary"}
                                fontSize={["sm", null, null, "md", null]}
                                display={["none", null, "flex", null, null]}
                                leftIcon={
                                    <Icon
                                        as={BiPencil}
                                        w={[4, null, null, 5, null]}
                                        h={[4, null, null, 5, null]}
                                    />
                                }
                                // hidden={!currentUser?.isStaff}
                            >
                                Review
                            </Button>
                        </LinkWrap>
                }
            </HStack>
            <Link
                href={url}
                isExternal
                color={"#91c9d7"}
                textDecoration={"none"}
                _hover={{
                    textDecoration: "none"
                }}
                width={"100%"}
                // isTruncated={true}
                overflowY={"scroll"}
                maxH={100}
            >
                {url}
            </Link>
            <Text hidden={!showNote} color={"muted"}>
                Note: At least one category needs to be selected to submit review.
                Tick checkbox if option is relevant to URL and cross checkbox if option is not relevent to URL.
            </Text>
            </VStack>
        </Box>
    )
}

const getSiteNameFromUrl = (url: string): string => {

    // Slicing the url to get only the site name
    const firstPosition = url.indexOf("//");
    const lastPosition = url.indexOf("/", firstPosition + 2);
    let siteName = url.slice(firstPosition + 2, lastPosition);

    // remove common domains from site name
    const removeFromUrls = [
        "www.", ".com", ".net", ".org", ".co.uk", ".us", ".int",
        ".edu", ".cn", ".de", ".nl", ".ru", ".br", ".xyz", ".live"
    ]
    removeFromUrls.forEach((rem) => siteName = siteName.replace(rem, ""));

    return siteName
}
