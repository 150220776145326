import { Grid, GridItem } from "@chakra-ui/react";
import { CategoryListHeader } from "./CategoryListHeader";
import { ChartsHeader } from "./ChartsHeader";

export const DetailsHeader = () => {
  return (
    <Grid templateColumns={"repeat(8, 1fr)"} gap={4} alignItems={"center"}>
      <GridItem colSpan={2}>
        <CategoryListHeader />
      </GridItem>
      <GridItem colSpan={6}>
        <ChartsHeader />
      </GridItem>
    </Grid>
  );
};
