import { OverviewChartsHeader } from "./OverviewChartsHeader";
import { SectionTitle } from "../../SectionTitle";
import { BubbleChart } from "./BubbleChart";
import { HeatmapChart } from "./HeatmapChart";
import { BarChartRace } from "./BarChartRace";
import { Box } from "@chakra-ui/react";
import { useReactiveVar } from "@apollo/client";
import { overviewChartVar } from "../../../../lib/cache";

export const OverviewCharts = () => {
  const chartView = useReactiveVar(overviewChartVar);

  return (
    <>
      <SectionTitle title={"Campaign Overview"} />
      <OverviewChartsHeader />
      <Box bg={"white"} mt={2} borderRadius={"lg"} >
        {getChartToShow(chartView)}
      </Box>
    </>
  );
};

const getChartToShow = (chartKey: string) => {
  switch (chartKey) {
    case "BUBBLE":
      return <BubbleChart />;

    case "HEATMAP":
      return <HeatmapChart />;

    case "BARCHARTRACE":
      return <BarChartRace />

    default:
      return <div>There should really be a chart here.</div>;
  }
};
