import { BoxProps, Grid, GridItem, HStack, IconButton, Text } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { AiFillEdit } from "react-icons/ai";
import { formatTextDisplay } from "../../utils/displayGetters";
import { CopyButton } from "../copyButton/CopyButton";

export const DetailField = ({
  label,
  value,
  format = false,
  openModal,
  setModalOption,
  isCopyField = false,
  ...rest
}: {
  label: string;
  value?: any;
  format?: boolean;
  openModal?: () => void;
  setModalOption?: Dispatch<SetStateAction<string>>;
  isCopyField?: boolean;
} & BoxProps) => {
  return (
    <Grid
      templateColumns="repeat(8, 1fr)"
      gap={6}
      mb={4}
      alignItems={"start"}
      {...rest}
    >
      <GridItem colSpan={3}>
        <Text
          mr={1}
          color={"primary.darker"}
          fontWeight={"bold"}
          fontSize={"sm"}
        >
          {label}
        </Text>
      </GridItem>
      <GridItem colSpan={5}>
        {value && value.constructor === Array ? (
          value.map((v, ix) => (
            <Text key={v + ix} mr={1}>
              {format ? formatTextDisplay(v) : v}
              <span
                style={{
                  visibility: ix !== value.length - 1 ? "visible" : "hidden",
                }}
              >
                ,
              </span>
            </Text>
          ))
        ) : (
          <HStack spacing={"5"}>
            {isCopyField ?
              <HStack spacing={0} bg={"gray.100"} borderRadius={"md"}>
                <Text
                  fontFamily={"mono"}
                  p={1}
                  fontSize={["sm", null, null, "md", null]}
                  wordBreak={"break-all"}
                >
                  {format ? formatTextDisplay(value) : value}
                </Text>
                <CopyButton textCopied={value} title={"ID"} />
              </HStack>
              :
              <Text wordBreak={"break-all"}>
                {format ? formatTextDisplay(value) : value}
              </Text>
            }
            {
              (openModal && setModalOption) &&
              <IconButton
                aria-label={"Edit"}
                m={0}
                variant={"unStyled"}
                icon={<AiFillEdit />}
                color={"primary.darker"}
                size={"sm"}
                onClick={() => {
                  setModalOption(label)
                  openModal()
                }}
              />
            }
          </ HStack>
        )}
      </GridItem>
    </Grid>
  );
};
