import { useMutation, useReactiveVar } from "@apollo/client";
import { Box, Button, Center, Collapse, Flex, HStack, Icon, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Stack, Text } from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";
import { FormInputField } from "../../../components/formInputField/FormInputField";
import { FormSubSectionHeader } from "../../../components/formSubSectionHeader/FormSubSectionHeader";
import { ComponentLoading } from "../../../components/loading/ComponentLoading";
import { CREATE_PACKAGE } from "../../../graphql/operations/campaignOperations/operations";
import { userDetailsVar } from "../../../lib/cache";
import { KeywordsFile, UploadFileType } from "./KeywordsFile";
import { SegmentDetails } from "./SegmentDetails";
import { SegmentFormDataClass } from "./segmentFormSchema";

export const SegmentForm = () => {

    const currentUser = useReactiveVar(userDetailsVar);
    const navigate = useNavigate();
    const [createPackage] = useMutation(CREATE_PACKAGE);
    const [segmentFormData, setSegmentFormData] = useState(
      new SegmentFormDataClass(currentUser?.isStaff)
    );
    const [keywordsExcFile, setKeywordsExcFile] = useState<UploadFileType>({} as UploadFileType);
    const { packageOwnerEmail } = segmentFormData.getUserDetailsFields();

    useEffect(() => {
        setSegmentFormData(
          new SegmentFormDataClass(currentUser?.isStaff)
        );
    }, [currentUser]);

    if (!currentUser) {
        return <ComponentLoading text={"Loading page..."} />;
    }

    if (!(currentUser?.isStaff || currentUser?.agency.hitlPermission?.isPermitted)) {
        return <Navigate to={"/"} />

    }

    return (
        <>
            <Formik
                initialValues={segmentFormData.getDefaultValues()}
                validationSchema={segmentFormData.getYupSchema()}
                onSubmit={(values, { setSubmitting, setErrors, setStatus }) => {

                    createPackage({
                        variables: {
                            packageDetails: values,
                            keywordExlusionList: keywordsExcFile.keywordsExc[0]
                        }
                    })
                    .then((res) => {
                      navigate(`/segments/${res.data.packageCreate.uniqueId}`);
                    })
                    .catch((err) => {
                      err.graphQLErrors.forEach(
                        (e: {
                          message: string;
                          extensions?: Record<string, string>;
                        }) => {
                          if (e.extensions) {
                            setErrors(e.extensions);
                            setStatus("Your form contains errors.");
                          } else {
                            setStatus(e.message);
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }
                          setSubmitting(false);
                        }
                      );
                    });
                  setStatus("");
                }}
            >
                {(formik) => {
                    return (
                        <>
                            <Collapse in={!!formik.status} animateOpacity={true}>
                                <Center>
                                <HStack
                                    color={"error"}
                                    bg={"rgb(252, 234, 234)"}
                                    borderRadius={"lg"}
                                    p={5}
                                    spacing={6}
                                    mb={4}
                                >
                                    <Icon as={BsExclamationTriangleFill} w={10} h={10} />
                                    <Text fontSize={"16px"} fontWeight={"bold"}>
                                        {formik.status}
                                    </Text>
                                </HStack>
                                </Center>
                            </Collapse>

                        <Collapse in={currentUser?.isStaff} unmountOnExit={true}>
                            <Box
                                p={8}
                                bg={"white"}
                                borderColor={"accent"}
                                borderWidth={2}
                                borderRadius={"md"}
                                mb={3}
                                maxW={["100%", null, null, "50%", null]}
                            >
                                <FormSubSectionHeader
                                    label={"User Details"}
                                    toolTip={"Who does this segment belong to?"}
                                />
                                <Field name={"packageOwnerEmail"}>
                                    {({ field, form }: FieldProps) => (
                                        <FormInputField
                                            mt={0}
                                            label={packageOwnerEmail.label}
                                            fieldType={
                                                packageOwnerEmail.inputFormat as
                                                | "email"
                                                | "text"
                                                | "password"
                                            }
                                            isInvalid={
                                            !!(
                                                form.errors["packageOwnerEmail"] &&
                                                form.touched["packageOwnerEmail"]
                                            )
                                            }
                                            isRequired={packageOwnerEmail.isRequired}
                                            placeholder={packageOwnerEmail.placeholder}
                                            inputFieldProps={field}
                                            errorMessage={form.errors["packageOwnerEmail"]?.toString()}
                                        />
                                    )}
                                </Field>
                            </Box>
                        </Collapse>

                        <Form>
                            <Stack
                                spacing={5}
                                alignItems={"start"}
                                direction={["column", null, null, "row", null]}
                            >
                                <SegmentDetails
                                    fields={segmentFormData.getBasicDetails()}
                                    formik={formik}
                                    minW={"50%"}
                                    w={"100%"}
                                    defaultValues={segmentFormData.getDefaultValues()}
                                />
                                <KeywordsFile
                                    minW={"50%"}
                                    w={"100%"}
                                    keywordsExcFile={keywordsExcFile}
                                    setKeywordsExcFile={setKeywordsExcFile}
                                />
                            </Stack>
                        </Form>

                        <Flex
                            bg={"text.dark"}
                            justifyContent={["flex-end", null, null, null, "center"]}
                            py={3}
                            position={"fixed"}
                            bottom={0}
                            left={0}
                            width={"100%"}
                            pr={5}
                        >
                            {formik.dirty && !formik.isSubmitting ? (
                            <Popover placement={"top-start"}>
                                <PopoverTrigger>
                                <Button variant={"secondary"} mr={3}>
                                    Cancel
                                </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                bg={"text.dark"}
                                color={"white"}
                                _focus={{
                                    boxShadow: "none",
                                }}
                                >
                                <PopoverCloseButton />
                                <PopoverHeader fontWeight={"bold"} fontSize={"18px"}>
                                    Form Partially Filled
                                </PopoverHeader>
                                <PopoverBody>
                                    Are you sure you want to discard the data entered so
                                    far?
                                </PopoverBody>
                                <PopoverFooter d={"flex"} justifyContent={"flex-end"}>
                                    <Button
                                    onClick={() => navigate("/")}
                                    variant={"primary"}
                                    bg={"red.500"}
                                    color={"white"}
                                    size={"sm"}
                                    >
                                    Yes
                                    </Button>
                                </PopoverFooter>
                                </PopoverContent>
                            </Popover>
                            ) : (
                            <Button
                                as={RouterLink}
                                mr={3}
                                to={"/segments"}
                                variant={"secondary"}
                            >
                                Cancel
                            </Button>
                            )}
                            <Button
                                type={"submit"}
                                variant={"primary"}
                                disabled={!(
                                    formik.isValid &&
                                    formik.dirty &&
                                    (keywordsExcFile?.keywordsExc ? keywordsExcFile.keywordsExc.length !== 0 : false)
                                )}
                                isLoading={formik.isSubmitting}
                                onClick={() => {
                                    formik.submitForm()
                                }}
                            >
                                Save
                            </Button>
                        </Flex>
                        </>
                    );
                }}
            </Formik>
        </>
    )
}
