export default {
  baseStyle: {
    control: {
      borderColor: "muted",
      _hover: {
        bg: "currentColor",
        borderColor: "primary.dark",
      },
      _focus: {
        boxShadow: "none",
      },
      _checked: {
        bg: "text.dark",
        borderColor: "text.dark",
        _hover: {
          bg: "text.dark",
          borderColor: "primary.dark",
        },
        _before: {
          bg: "primary.dark",
          w: "50%",
          h: "50%",
        },
      },
    },
  },
};
