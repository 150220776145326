import { useState } from "react";
import {
  BoxProps,
  Button,
  Checkbox,
  Collapse,
  Flex,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CampaignCommunicationTypeChoices } from "../../../graphql/generated";
import { SelectField } from "../../../components/selectField/SelectField";
import { unCamelcase } from "../../../utils/displayGetters";
import { IoMdSend } from "react-icons/io";
import { useMutation } from "@apollo/client";
import {
  PROTECTED_GET_CAMPAIGN_INFO,
  SEND_CAMPAIGN_COMMUNICATION,
} from "../../../graphql/operations/campaignOperations/operations";
import { ToastNotificationBox } from "../../../components/toastNotificationBox/ToastNotificationBox";
import { useCampaignInfo } from "../index";
import { SectionBox } from "../../../components/sectionBox/SectionBox";

export const SendCommunications = (props: BoxProps) => {
  const [communicationType, setCommunicationType] =
    useState<CampaignCommunicationTypeChoices>();
  const [shouldNotifyUser, setShouldNotifyUser] = useState(false);
  const [sendCommunication, { loading }] = useMutation(
    SEND_CAMPAIGN_COMMUNICATION,
    {
      refetchQueries: [PROTECTED_GET_CAMPAIGN_INFO],
    }
  );
  const {
    campaign: { id, segments, creativeIds },
  } = useCampaignInfo();
  const [communicationError, setCommunicationError] = useState("");
  const toast = useToast();
  const toastId = "communication-toast";
  const onCommunicationTypeChange = ({
    value,
  }: {
    value: CampaignCommunicationTypeChoices;
  }) => {
    setCommunicationType(value);
  };

  const generateCommunicationOptions = () => {
    let options = Object.entries(CampaignCommunicationTypeChoices);
    if (!segments?.length) {
      options = options.filter(
        ([, value]) => value !== CampaignCommunicationTypeChoices.IdsGenerated
      );
    }
    if (!creativeIds?.length) {
      options = options.filter(
        ([, value]) => value !== CampaignCommunicationTypeChoices.CreativeIdsAudited
      );
    }
    return options.map(([key, value]) => ({
      label: unCamelcase(key),
      value: value,
    }));
  };

  return (
    <SectionBox sectionHeader={"Send Notifications"} {...props}>
      {communicationError && (
        <Text fontSize={"sm"} color={"error"} fontWeight={"bold"}>
          {communicationError}
        </Text>
      )}
      <SelectField
        placeholder={"Send Communication"}
        onChange={onCommunicationTypeChange}
        isMulti={false}
        options={generateCommunicationOptions()}
      />
      <Checkbox
        mt={3}
        defaultChecked={shouldNotifyUser}
        onChange={(e) => setShouldNotifyUser(e.target.checked)}
      >
        Notify campaign owner
      </Checkbox>
      <Collapse in={!!communicationType}>
        <Flex justifyContent={"flex-end"} mt={3}>
          <Popover placement={"left-start"}>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Button
                    variant={"primary"}
                    rightIcon={<Icon as={IoMdSend} width={6} height={6} />}
                    isLoading={loading}
                  >
                    Send
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  bg={"text.dark"}
                  color={"white"}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  <PopoverCloseButton />
                  <PopoverHeader fontWeight={"bold"} fontSize={"18px"}>
                    Confirm Notification
                  </PopoverHeader>
                  <PopoverBody>
                    Are you sure you want to send this notification?
                  </PopoverBody>
                  <PopoverFooter d={"flex"} justifyContent={"flex-end"}>
                    <Button
                      onClick={() => {
                        sendCommunication({
                          variables: {
                            communicationDetails: {
                              campaignId: id,
                              communicationType: communicationType,
                              notifyUser: shouldNotifyUser,
                            },
                          },
                        })
                          .then(() => {
                            setCommunicationError("")
                            toast({
                              id: toastId,
                              status: "info",
                              duration: 3000,
                              render: () => {
                                return (
                                  <ToastNotificationBox
                                    content={"Notification sent!"}
                                  />
                                );
                              },
                            });
                          })
                          .catch((err) =>
                            setCommunicationError(err.graphQLErrors[0].message)
                          );
                        onClose();
                      }}
                      variant={"primary"}
                      bg={"red.500"}
                      color={"white"}
                      size={"sm"}
                    >
                      Yes
                    </Button>
                  </PopoverFooter>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Flex>
      </Collapse>
    </SectionBox>
  );
};
