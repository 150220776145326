import { Link, SimpleGrid, Td, Tooltip, Tr } from "@chakra-ui/react";
import { Row, Table } from "@tanstack/react-table";

interface DomainSectionProps {
    table: Table<any>
    row: Row<any>
}

export const DomainSection = ({table, row}: DomainSectionProps) => {

    const urlArray: string[] = row.getValue("domains").split(",");
    return (
        <Tr>
            <Td
                colSpan={table.getVisibleFlatColumns().length}
            >
                <SimpleGrid columns={3} spacing={2}>
                    {urlArray.map((url, index) => (
                        <Tooltip
                            key={`domainString${index}`}
                            label={url}
                            openDelay={600}
                            placement={"auto"}
                            bg={"text.dark"}
                            p={2}
                        >
                            <Link
                                ml={12}
                                isTruncated
                                maxWidth={220}
                                href={`https://${url}`}
                                isExternal
                            >
                                {url}
                            </Link>
                        </Tooltip>
                    ))}
                </SimpleGrid>
            </Td>
        </Tr>
    )
}
