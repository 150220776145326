import { useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react"
import { createContext, useContext } from "react";
import { Navigate } from "react-router-dom";
import { ComponentLoading } from "../../components/loading/ComponentLoading";
import { PrecisionCategoriesOptionsType } from "../../graphql/generated";
import { GET_PRECISION_CATEGORIES_OPTIONS } from "../../graphql/operations/campaignOperations/operations";
import { SegmentForm } from "./segmentForm"

interface IPrecisionCategories {
    precisionCategoriesOptions: PrecisionCategoriesOptionsType[]
}

const precisionCategoriesContext = createContext({} as IPrecisionCategories)

export const usePrecisionCategoriesOptions = () => {
return useContext(precisionCategoriesContext)
}

export const SegmentSetupPage = () => {

    const { data, error, loading } = useQuery(
        GET_PRECISION_CATEGORIES_OPTIONS,
        {
          fetchPolicy: "cache-and-network",
        }
      );

      if (loading) {
        return <ComponentLoading text={"Loading campaign form..."} />;
      }

      if (error) {
        return <>ERROR</>;
      }

      const precisionCategoriesOptions: PrecisionCategoriesOptionsType[] = data.precisionCategoriesOptions;

      if (!precisionCategoriesOptions) {
        return <Navigate to={"/404"} />;
      }

    return (
        <precisionCategoriesContext.Provider value={{precisionCategoriesOptions: precisionCategoriesOptions}}>
            <Box mb={20}>
                <SegmentForm />
            </Box>
        </precisionCategoriesContext.Provider>
    )
}
