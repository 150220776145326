import { Grid, GridItem } from "@chakra-ui/react";
import { CategoryList } from "./CategoryList";
import { useLocation, useParams } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import { currentCategoryVar, insightsDatesVar } from "../../../lib/cache";
import { GET_CATEGORY_INSIGHTS } from "../../../graphql/operations/campaignOperations/operations";
import { ComponentLoading } from "../../../components/loading/ComponentLoading";
import { CategoryInsightEdge } from "../../../graphql/generated";
import { NoData } from "../NoData";
import { DetailsCharts } from "./detailsCharts";
import { DetailsHeader } from "./detailsHeader";

export const CampaignInsightDetails = () => {
  const { id: campaignId } = useParams();
  const { search } = useLocation();
  const categoryNameFromSearch = new URLSearchParams(search).get("cat");
  const dateRange = useReactiveVar(insightsDatesVar);
  const currentCategory = useReactiveVar(currentCategoryVar);
  const { data, loading, error } = useQuery(GET_CATEGORY_INSIGHTS, {
    variables: {
      campaignId,
      dateRange,
    },
    onCompleted: (data) => {
      const { edges } = data.insightsByCategoryList;
      /**
       * We want to deal with category name included in url search parameters
       */
      const categoryName = categoryNameFromSearch ?? currentCategory.category;
      if (edges.length) {
        if (categoryName) {
          const currentEdge = edges.find(
            ({ node: { category } }: CategoryInsightEdge) =>
              category === categoryName
          );
          if (currentEdge) {
            currentCategoryVar({
              id: currentEdge.node.id,
              category: currentEdge.node.category,
            });
            return "";
          }
        }
        currentCategoryVar({
          id: edges[0].node.id,
          category: edges[0].node.category,
        });
      }
    },
  });

  if (loading) {
    return (
      <ComponentLoading
        bg={"background"}
        text={"Getting categories..."}
        mt={2}
      />
    );
  }

  if (error) {
    return <div>Error</div>;
  }

  const edges: CategoryInsightEdge[] = data.insightsByCategoryList.edges;

  if (!edges.length) {
    return <NoData />;
  }

  return (
    <>
      <DetailsHeader />
      <Grid templateColumns={"repeat(8, 1fr)"} gap={4} h={"80vh"}>
        <GridItem colSpan={2} overflow={"scroll"}>
          <CategoryList edges={edges} />
        </GridItem>
        <GridItem colSpan={6} overflow={"scroll"}>
          <DetailsCharts />
        </GridItem>
      </Grid>
    </>
  );
};
