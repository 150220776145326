import { extendTheme } from "@chakra-ui/react";
import InputStyles from "./components/input";
import FormLabelStyles from "./components/formLabel";
import RadioStyles from "./components/radio";
import globalStyles from "./styles";
import colors from "./foundations/colors";
import fonts from "./foundations/fonts";
import ButtonStyles from "./components/button";
import LinkStyles from "./components/link";
import CheckboxStyles from "./components/checkbox";
import SwitchStyles from "./components/switch";
import MenuStyles from "./components/menu";
import ProgressStyles from "./components/progress";
import PopoverStyles from "./components/popover"

const overrides = {
  colors: colors,
  fonts: fonts,
  components: {
    Input: InputStyles,
    FormLabel: FormLabelStyles,
    Radio: RadioStyles,
    Button: ButtonStyles,
    Link: LinkStyles,
    Checkbox: CheckboxStyles,
    Switch: SwitchStyles,
    Menu: MenuStyles,
    Progress: ProgressStyles,
    Popover: PopoverStyles
  },
  styles: globalStyles,
};

const theme = extendTheme(overrides);
export type Theme = typeof theme;
export default theme;
