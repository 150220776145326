import { useQuery, useReactiveVar } from "@apollo/client"
import { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import { Navigate } from "react-router-dom";
import { ComponentLoading } from "../../components/loading/ComponentLoading"
import { GET_PRECISION_CATEGORIES_OPTIONS } from "../../graphql/operations/campaignOperations/operations"
import { userDetailsVar } from "../../lib/cache"
import { Header } from "./Header"
import { HITLBodyContent } from "./HITLBodyContent";

export enum ClassificationCategories {
    precision = "precision_iab_v3",
    brandSafety = "cat_brandsafety",
    entities = "illuma_ents",
    sentiment = "illuma_sent",
    emotion = "illuma_emotion"
}

export enum EntityCategories {
    date = "DATE",
    event = "EVENT",
    gpe = "GPE",
    language = "LANGUAGE",
    loc = "LOC",
    // money = "MONEY",
    norp = "NORP",
    org = "ORG",
    person = "PERSON",
    product = "PRODUCT",
    workOfArt = "WORK_OF_ART",
}

export interface IHITLFilters {
    urlSearch: string
    categoryFilter: {label: string, value: string}
    selectedUrl: string
    setSelectedUrl: Dispatch<SetStateAction<string>>
    urlList: string[]
    setUrlList: Dispatch<SetStateAction<string[]>>
    cursor: number
    setCursor: Dispatch<SetStateAction<number>>
    selectedIndex: number
    setSelectedIndex: Dispatch<SetStateAction<number>>
}

const hitlFiltersContext = createContext({} as IHITLFilters);

export const useHitlFilters = () => {
    return useContext(hitlFiltersContext);
  };

export const HumanInTheLoop = () => {

    const currentUser = useReactiveVar(userDetailsVar);
    const [urlSearch, setUrlSearch] = useState("");
    const [categoryFilter, setCategoryFilter] = useState({label: "All Categories", value: "ALL"});
    const [selectedUrl, setSelectedUrl] = useState("");
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [urlList, setUrlList] = useState<string[]>([]);
    const [cursor, setCursor] = useState<number>(0);

    const { data: precisionData, error: precisionError, loading: precisionLoading } = useQuery(
        GET_PRECISION_CATEGORIES_OPTIONS,
        {
            fetchPolicy: "cache-and-network",
        }
    );

    if (precisionLoading || !currentUser) {
        return <ComponentLoading text={"Loading page..."} />;
    }

    if (!currentUser?.isStaff) {
        if (
            !currentUser?.agency.hitlPermission?.isPermitted
        ) {
            return <Navigate to={"/campaigns"} />
        }
    }

    if (precisionError) {
        return <>Error getting URL and categories</>
    }

    return (
        <hitlFiltersContext.Provider
            value={{
                urlSearch: urlSearch,
                categoryFilter: categoryFilter,
                selectedUrl: selectedUrl,
                setSelectedUrl: setSelectedUrl,
                urlList: urlList,
                setUrlList: setUrlList,
                cursor: cursor,
                setCursor: setCursor,
                selectedIndex: selectedIndex,
                setSelectedIndex: setSelectedIndex
            }}
        >
            <Header
                precisionCategories={precisionData.precisionCategoriesOptions}
                urlSearch={urlSearch}
                setUrlSearch={setUrlSearch}
                categoryFilter={categoryFilter}
                setCategoryFilter={setCategoryFilter}
            />
            {/* <Outlet /> */}
            <HITLBodyContent />
        </hitlFiltersContext.Provider>
    )

}
