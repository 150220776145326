import { Box, Text, BoxProps } from "@chakra-ui/react";

interface ToastNotificationBoxProps extends BoxProps {
  heading?: string
  content: string
}

export const ToastNotificationBox = ({
  heading,
  content,
  ...rest
}: ToastNotificationBoxProps) => {
  return (
    <Box
      bg={"white"}
      borderColor={"primary.dark"}
      borderWidth={2}
      p={3}
      borderRadius={"lg"}
      {...rest}
    >
      {heading && (
        <Text fontWeight={"bold"} fontSize={"lg"}>
          {heading}
        </Text>
      )}
      <Text textAlign={"center"}>{content}</Text>
    </Box>
  );
};
