import { Icon } from "@chakra-ui/react";

export const ConversionsIcon = (props: Record<string, any>) => (
  <Icon
    viewBox={"0 0 22.217 18.309"}
    sx={{
      ".a": {
        strokeWidth: "0.4px",
      },
    }}
    {...props}
  >
    <g transform="translate(-141.345 -103)">
      <path
        className="a"
        d="M234.339,120.408a.408.408,0,1,0-.815,0c0,7.214-6.21,7.465-6.9,7.465h0a.356.356,0,0,0-.251.094c-.063.031-.094.094-.125.125a.465.465,0,0,0,0,.408.435.435,0,0,0,.376.22h.031c.721,0,6.9.251,6.9,7.465a.408.408,0,1,0,.815,0c0-7.182,6.179-7.465,6.9-7.465h0a.478.478,0,0,0,.376-.22.386.386,0,0,0-.031-.408.514.514,0,0,0-.345-.22h0C240.549,127.872,234.339,127.621,234.339,120.408Zm-.408,12.7a7.159,7.159,0,0,0-4.893-4.83,7.159,7.159,0,0,0,4.893-4.83,7.159,7.159,0,0,0,4.893,4.83A7.159,7.159,0,0,0,233.931,133.11Z"
        transform="translate(-78.335 -15.483)"
      />
      <path
        className="a"
        d="M146.156,112.609a3.716,3.716,0,0,1,3.764-4.077h.031a.423.423,0,0,0,.408-.439.415.415,0,0,0-.408-.408h0a3.716,3.716,0,0,1-3.764-4.077.408.408,0,0,0-.815,0,3.716,3.716,0,0,1-3.764,4.077h-.031a.409.409,0,0,0-.376.408.415.415,0,0,0,.408.408h0a3.7,3.7,0,0,1,3.764,4.077.415.415,0,0,0,.408.408A.362.362,0,0,0,146.156,112.609Zm-2.666-4.516a4.22,4.22,0,0,0,2.258-2.227,4.206,4.206,0,0,0,2.258,2.227,4.22,4.22,0,0,0-2.258,2.227A4.206,4.206,0,0,0,143.49,108.093Z"
        transform="translate(0.345)"
      />
    </g>
  </Icon>
);
