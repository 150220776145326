import { Box, HStack, IconButton, Text } from "@chakra-ui/react"
import { Dispatch, SetStateAction } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { revenueYearVar } from "../../../lib/cache"

interface YearSelectProps {
    year: number
    setYear: Dispatch<SetStateAction<number>>
}

export const YearSelect = ({year, setYear}: YearSelectProps) => {

    return (
        <Box>
            <HStack>
                <IconButton
                    variant={"pagination"}
                    aria-label={"Previous"}
                    icon={<IoIosArrowBack />}
                    onClick={() => {
                        setYear(year - 1)
                        revenueYearVar(year - 1)
                    }}
                    // disabled={currentPageIndex === 0}
                />
                <Text>
                    {year}
                </Text>
                <IconButton
                    variant={"pagination"}
                    aria-label={"Next"}
                    icon={<IoIosArrowForward />}
                    onClick={() => {
                        setYear(year + 1)
                        revenueYearVar(year + 1)
                    }}
                    // disabled={currentPageIndex === 0}
                />
            </HStack>
        </Box>
    )
}
