
import { QuestionGroupChoices } from "../../../graphql/generated"
import { CheckboxList } from "../boxes/CheckboxList"
// import { Confirm } from "../boxes/Confirm"

export const ActiveCampaign = () => {
    return (
        <>
            <CheckboxList title={"Active Campaign"} questionGroup={QuestionGroupChoices.ActiveCampaign}/>
        </>
    )
}
