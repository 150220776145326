import { QuestionGroupChoices } from "../../../graphql/generated";
import { CheckboxList } from "../boxes/CheckboxList"
import { SendCommunications } from "./SendCommunications";

export const PreCampaignChecks = () => {
    return (
        <>
            <SendCommunications />
            <CheckboxList mb={0} title={"Pre-Campaign"} questionGroup={QuestionGroupChoices.PreCampaignChecks}/>
        </>
    )
}
