import { useReactiveVar } from "@apollo/client"
import { Flex, Icon } from "@chakra-ui/react"
import { FaChartPie } from "react-icons/fa"
import { GoDashboard } from "react-icons/go"
import { HiOutlineClipboardList } from "react-icons/hi"
import { ImLoop } from "react-icons/im"
import { Navigate } from "react-router-dom"
import { ComponentLoading } from "../../components/loading/ComponentLoading"
import { MenuTile } from "../../components/menuTile/MenuTile"
import { userDetailsVar } from "../../lib/cache"

export const LandingPage = () => {

    const currentUser = useReactiveVar(userDetailsVar);

    if (!currentUser) {
        return <ComponentLoading />
    }

    if ((!currentUser?.isStaff && !currentUser?.agency.hitlPermission)) {
        return <Navigate to={"/campaigns"} />
    }

    return (
        <Flex
            width={"90%"}
            mx={"auto"}
            flexDir={"row"}
            flexWrap={"wrap"}
            gridGap={6}
            p={6}
        >
            <MenuTile
                pageName={"Campaigns"}
                icon={
                    <Icon
                        as={HiOutlineClipboardList}
                        w={20}
                        h={20}
                    />
                }
                pageUrl={"/campaigns"}
            />
            {
                currentUser.isStaff &&
                    <MenuTile
                        pageName={"Admin Dashboard"}
                        icon={
                            <Icon
                                as={GoDashboard}
                                w={20}
                                h={20}
                            />
                        }
                        pageUrl={"/campaigns/admin-dashboard"}
                        isAdmin={true}
                    />
            }
            {
                (currentUser.isStaff || currentUser.agency.hitlPermission) &&
                    <MenuTile
                        pageName={"Human in the Loop"}
                        icon={
                            <Icon
                                as={ImLoop}
                                w={20}
                                h={20}
                            />
                        }
                        pageUrl={"/human-in-the-loop"}
                    />
            }
            {
                (currentUser.isStaff || currentUser.agency.hitlPermission) &&
                    <MenuTile
                        pageName={"Segments"}
                        icon={
                            <Icon
                                as={FaChartPie}
                                w={20}
                                h={20}
                            />
                        }
                        pageUrl={"/segments"}
                    />
            }

        </Flex>
    )
}
