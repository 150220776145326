import {
  BoxProps,
  Grid,
  Text,
  HStack,
  GridItem,
  VStack,
} from "@chakra-ui/react";
import { CopyButton } from "../../components/copyButton/CopyButton";

interface Props {
  name: string;
  id: string;
  includeBorder: boolean;
}

export const SegmentField = ({
  name,
  id,
  includeBorder,
  ...rest
}: Props & BoxProps) => {
  return (
    <VStack
      px={10}
      py={2}
      mt={4}
      borderBottomColor={"muted"}
      borderBottomWidth={includeBorder ? "1px" : "0px"}
      {...rest}
    >
      <Grid templateColumns={"repeat(8, 1fr)"} alignItems={"center"} w={"100%"}>
        <GridItem colSpan={2}>
          <Text
            color={"primary.darker"}
            fontWeight={"bold"}
            fontSize={"sm"}
            mr={2}
          >
            ID
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <HStack spacing={0} bg={"gray.100"} borderRadius={"md"}>
            <Text
              fontFamily={"mono"}
              p={1}
              fontSize={["sm", null, null, "md", null]}
            >
              {id}
            </Text>
            <CopyButton textCopied={id} title={"ID"} />
          </HStack>
        </GridItem>
      </Grid>
      <Grid templateColumns={"repeat(8, 1fr)"} alignItems={"center"} w={"100%"}>
        <GridItem colSpan={2}>
          <Text
            color={"primary.darker"}
            fontWeight={"bold"}
            fontSize={"sm"}
            mr={2}
          >
            Name
          </Text>
        </GridItem>
        <GridItem colSpan={6}>
          <HStack spacing={0} bg={"gray.100"} borderRadius={"md"}>
            <Text
              wordBreak={"break-all"}
              fontFamily={"mono"}
              p={1}
              fontSize={["sm", null, null, "md", null]}
            >
              {name}
            </Text>
            <CopyButton textCopied={name} title={"Name"} />
          </HStack>
        </GridItem>
      </Grid>
    </VStack>
  );
};
