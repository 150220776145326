import { forwardRef, useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils, Modifier } from "react-day-picker";
import { CalendarIcon } from "./assets/CalendarIcon";
import "./date-picker.css";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";

export interface DatePickerProps {
  placeholder?: string;
  inputProps?: any;
  dateLimits?: {
    start: Date;
    end?: Date;
  };
  defaultExistingValue?: Date;
}

function parseDate(str: string, format: string, locale: any) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date: number | Date, format: string, locale: any) {
  return dateFnsFormat(date, format, { locale });
}

export const DatePicker = ({
  placeholder,
  inputProps,
  dateLimits,
  defaultExistingValue,
}: DatePickerProps) => {
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(defaultExistingValue && new Date(defaultExistingValue));
  const handleDayChange = (newDay: Date) => {
    setSelectedDay(newDay);
    const { form, field } = inputProps;
    form?.setFieldValue(field.name, newDay.toISOString().slice(0, 10));
    // form?.setFieldTouched(field.name, true, false);
  };
  const handleDayPickerHide = () => {
    const { form, field } = inputProps;
    form?.setFieldTouched(field.name, true, true);
  };
  let disabledDays: Modifier = {
    before: dateLimits?.start ?? new Date(),
  };
  if (dateLimits?.end) {
    disabledDays = { ...disabledDays, after: dateLimits.end };
  }

  return (
    <>
      <DayPickerInput
        value={selectedDay}
        onDayChange={handleDayChange}
        component={PickerInput}
        inputProps={inputProps}
        format={"do MMMM, yyyy"}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={placeholder}
        onDayPickerHide={handleDayPickerHide}
        dayPickerProps={{
          initialMonth: dateLimits?.start,
          fromMonth: dateLimits?.start,
          toMonth: dateLimits?.end,
          disabledDays: disabledDays,
        }}

      />
    </>
  );
};

const PickerInput = forwardRef<HTMLInputElement, any>((props, ref) => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents={"none"}>
        <CalendarIcon w={"21px"} h={"24px"} stroke={"text.dark"} />
      </InputLeftElement>
      <Input
        readOnly={true}
        {...props}
        ref={ref}
        _focus={{ bg: "primary.dark" }}
        _placeholder={{ color: "text.dark" }}
      />
    </InputGroup>
  );
});
