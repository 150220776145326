import {
    Box,
    Grid,
    GridItem,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Tag,
    TagCloseButton,
    TagLabel,
    useDisclosure,
    useOutsideClick,
} from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { AiFillCaretDown } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import { CheckboxOptionType, CheckboxTreeV3 } from "../checkboxTree/CheckboxTreeV3";

export interface DropdownCheckboxTreeProps {
    checkboxOptions: CheckboxOptionType[]
    selectedOptions: CheckboxOptionType[]
    setSelectedOptions: Dispatch<SetStateAction<CheckboxOptionType[]>>
    onPopoverClose?: () => void
    limitOptions?: number
    handleChildren?: boolean
    handleParents?: boolean
}

export const DropdownCheckboxTree = ({
    checkboxOptions,
    selectedOptions,
    setSelectedOptions,
    onPopoverClose,
    limitOptions,
    handleChildren=true,
    handleParents=true,
}: DropdownCheckboxTreeProps) => {

    const [input, setInput] = useState("");
    const [touched, setTouched] = useState(false);
    const [removeOptions, setRemoveOptions] = useState<CheckboxOptionType[]>([]);
    const { isOpen, onClose, onToggle } = useDisclosure();
    const widthRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    // const tagBoxRef = useRef<HTMLDivElement>(null);

    const filteredOptions = checkboxOptions.filter((option) => {
        if (limitOptions) {
            return option.parents.length < limitOptions
        } else {
            return true
        }
    });

    // Close popover when click outside
    useOutsideClick({
        ref: contentRef,
        handler: () => {
            onClose()
            // onPopoverClose && onPopoverClose();
        },
    })

    useEffect(() => {
        if (isOpen && inputRef.current) {
            inputRef.current.focus();
            setTouched(true);
        }

        if (!isOpen && touched) {
            onPopoverClose && onPopoverClose();
        }
    }, [isOpen]);

    // useEffect(() => {
    //     tagBoxRef.current?.scrollIntoView({ behavior: "smooth" });
    // }, [selectedOptions])

    const precisionCategoriesTags = prepareTags(selectedOptions);

    return (
        <>
            <Grid
                templateColumns={"repeat(24, 1fr)"}
                w={"100%"}
                maxH={120}
                // overflowY={"scroll"}
                borderColor={"primary.dark"}
                borderWidth={selectedOptions.length ? 1.5 : 0}
                borderRadius={10}
                alignItems={"center"}
            >
                <GridItem
                    colSpan={23}
                    maxH={120}
                    overflowY={"scroll"}
                >
                    {precisionCategoriesTags.map((option, ix) => {
                        return (
                            <Tag
                                key={ix}
                                size={"md"}
                                variant={"solid"}
                                backgroundColor={"primary.dark"}
                                _hover={{backgroundColor: "primary.light"}}
                                color={"text.dark"}
                                borderRadius={14}
                                m={1}
                                py={1}
                                px={2}
                            >
                                <TagLabel>
                                    {option.label}
                                </TagLabel>
                                <TagCloseButton
                                    onClick={() => {
                                        setRemoveOptions(oldItems => [...oldItems, option]);
                                    }}
                                />
                            </Tag>
                        )
                    })}
                    {/* <Box ref={tagBoxRef} /> */}
                </GridItem>
                <GridItem colSpan={1}>
                    {
                        selectedOptions.length ?
                            <IconButton
                                //py={1}
                                // backgroundColor={"yellow"}
                                visibility={(selectedOptions.length) ? "visible" : "hidden"}
                                _hover={{color: "primary.dark"}}
                                aria-label={"clear-search-query"}
                                variant={"unstyled"}
                                onClick={() => {
                                    // Remove all selected items
                                    setRemoveOptions(selectedOptions);
                                }}
                                icon={
                                    <Icon
                                        as={MdClear}
                                        w={[4, null, null, 4, null]}
                                        h={[4, null, null, 4, null]}
                                    />
                                }
                            /> : <></>
                    }
                </GridItem>
            </Grid>

            <Box ref={contentRef} width={"100%"}>
                <Popover
                    isOpen={isOpen}
                    onClose={() => {
                        onClose();
                        onPopoverClose && onPopoverClose();
                    }}
                    initialFocusRef={inputRef}
                    variant={"primary"}
                >
                    <PopoverTrigger>
                        <InputGroup ref={widthRef} onClick={onToggle}>
                            <Input
                                ref={inputRef}
                                fontSize={["sm", null, null, "md", null]}
                                placeholder={"Select Categories..."}
                                value={input}
                                _placeholder={{ color: "text.lighter" }}
                                borderColor={"primary.dark"}
                                onChange={(e) => setInput(e.target.value)}
                                mt={1}
                            />
                            <InputRightElement width={"fit-content"} pr={4}>
                                <IconButton
                                    visibility={(input || selectedOptions.length) ? "visible" : "hidden"}
                                    _hover={{color: "primary.dark"}}
                                    aria-label={"clear-search-query"}
                                    variant={"unstyled"}
                                    onClick={() => {
                                        setInput("");
                                    }}
                                    icon={
                                        <Icon
                                            as={MdClear}
                                            w={[4, null, null, 4, null]}
                                            h={[4, null, null, 4, null]}
                                        />
                                    }
                                />
                                <Icon
                                    as={AiFillCaretDown}
                                    w={[5, null, null, 5, null]}
                                    h={[5, null, null, 5, null]}
                                    _hover={{cursor: "pointer"}}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </PopoverTrigger>
                    <PopoverContent
                        // Removing animation from opening and closing
                        variants={{
                            exit: { scale: 1 },
                            enter: { scale: 1 }
                        }}
                        // Make width the same as the input
                        width={`${widthRef?.current?.offsetWidth}px`}
                        // overflowY={"scroll"}
                        minHeight={10}
                        maxHeight={350}
                        _focus={{
                            borderColor: "none"
                        }}
                        // Keep focus on search input when using popover
                        onClick={() => {
                            if (isOpen && inputRef.current) {
                                inputRef.current.focus();
                            }
                        }}
                    >
                        <PopoverBody>
                            <CheckboxTreeV3
                                options={filteredOptions}
                                setSelectedOptions={setSelectedOptions}
                                removeOptions={removeOptions}
                                setRemoveOptions={setRemoveOptions}
                                searchInput={input}
                                handleChildren={handleChildren}
                                handleParents={handleParents}
                            />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Box>
        </>
    )
}


const prepareTags = (selectedPrecisionOptions: CheckboxOptionType[]) => {
    const precisionTags: CheckboxOptionType[] = selectedPrecisionOptions.map(option => option);

    precisionTags.forEach((option) => {

        option.children.forEach((childOptionId) => {

            const indexOfChild = precisionTags.map(option => option.id).indexOf(parseInt(childOptionId));
            if (indexOfChild > -1) {
                precisionTags.splice(indexOfChild, 1);

            }

        })
    })

    return precisionTags;
  }


//   <Flex
//   ref={tagBoxRef}
//   flexWrap={"wrap"}
//   maxH={100}
//   overflowY={"scroll"}
//   borderColor={"primary.dark"}
//   borderWidth={selectedOptions.length ? 1.5 : 0}
//   borderRadius={10}
// >
//   {precisionCategoriesTags.map((option, ix) => {
//       return (
//           <Tag
//               key={ix}
//               size={"md"}
//               variant={"solid"}
//               backgroundColor={"primary.dark"}
//               _hover={{backgroundColor: "primary.light"}}
//               color={"text.dark"}
//               borderRadius={14}
//               m={1}
//               py={1}
//               px={2}
//           >
//               <TagLabel>
//                   {option.label}
//               </TagLabel>
//               <TagCloseButton
//                   onClick={() => {
//                       setRemoveOptions(oldItems => [...oldItems, option]);
//                   }}
//               />
//           </Tag>
//       )
//   })}
//   {
//       selectedOptions.length ?
//           <IconButton
//               visibility={(selectedOptions.length) ? "visible" : "hidden"}
//               _hover={{color: "primary.dark"}}
//               aria-label={"clear-search-query"}
//               variant={"unstyled"}
//               onClick={() => {
//                   setInput("");
//                   // Remove all selected items
//                   setRemoveOptions(selectedOptions);
//               }}
//               icon={
//                   <Icon
//                       as={MdClear}
//                       w={[4, null, null, 4, null]}
//                       h={[4, null, null, 4, null]}
//                   />
//               }
//           /> : <></>
//   }
//   {/* Using dummy div to scroll to bottom of tags*/}
//   <Box ref={tagBoxRef} />
// </Flex>
