import { gql } from "@apollo/client";
import {
  CAMPAIGN_INFO_DATA_FRAGMENT,
  CAMPAIGN_INSIGHT_VALUES_FRAGMENT,
  PROTECTED_CAMPAIGN_INFO_DATA_FRAGMENT,
} from "./fragments";

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($campaignDetails: CampaignCreateInput!, $inclusionLists: [Upload], $exclusionLists: [Upload]) {
    campaignCreate(campaignDetails: $campaignDetails, inclusionLists: $inclusionLists, exclusionLists: $exclusionLists) {
      id
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($id: ID!, $updateFields: CampaignUpdateFieldsInput!) {
    campaignUpdate(campaignId: $id, updateFields: $updateFields) {
      id
      name
      productCategories {
        id
        price {
          id
          dsp
          price
          product
        }
      }
      isApproved
      endDate
      estimatedIllumaBudget
      estimatedIllumaImpressions
      averageCpm
      dspInfo {
        id
        dspName
        dspMemberId
        isDirect
      }
    }
  }
`;

export const CREATE_PACKAGE = gql`
  mutation CreatePackage($packageDetails: PackageCreateInput!, $keywordExlusionList: Upload) {
    packageCreate(packageDetails: $packageDetails, keywordExlusionList: $keywordExlusionList) {
      uniqueId
    }
  }
`;

export const SEND_CAMPAIGN_COMMUNICATION = gql`
  mutation SendCommunication(
    $communicationDetails: CampaignCommunicationInput!
  ) {
    sendCommunication(communicationDetails: $communicationDetails) {
      response
    }
  }
`;

export const RESLOVE_CHECKLIST_QUESTION = gql`
  mutation ChecklistCheck($campaignId: ID!, $questionId: ID!) {
    campaignCheck(campaignId: $campaignId, questionId: $questionId) {
      checklist {
        questionId
        questionText
      }
    }
  }
`;

export const RESLOVE_CREATIVE_ID = gql`
  mutation CampaignCreativeId($campaignId: ID!, $creativeId: String!) {
    campaignCreativeId(campaignId: $campaignId, creativeId: $creativeId) {
      creativeIds {
        creativeId
        isAudited
      }
    }
  }
`;

export const RESOLVE_URL_CATEGORY = gql`
  mutation ReviewUrl(
    $url: String!,
    $precisionIabV3: String!,
    $reviewedClassification: String,
    $rejectedClassification: String
  ) {
    reviewUrl(
      url: $url,
      precisionIabV3: $precisionIabV3,
      reviewedClassification: $reviewedClassification,
      rejectedClassification: $rejectedClassification
    ) {
      id
      url
      reviewedClassification
      rejectedClassification
    }
  }
`;

export const PROTECTED_GET_CAMPAIGN_INFO = gql`
  ${CAMPAIGN_INFO_DATA_FRAGMENT}
  ${PROTECTED_CAMPAIGN_INFO_DATA_FRAGMENT}
  query ProtectedCampaignInfo($id: ID!) {
    campaignInfo(id: $id) {
      ...CampaignInfoData
      ...ProtectedCampaignInfoData
    }
  }
`;

export const GET_CAMPAIGN_INFO = gql`
  ${CAMPAIGN_INFO_DATA_FRAGMENT}
  query CampaignInfo($id: ID!) {
    campaignInfo(id: $id) {
      ...CampaignInfoData
    }
  }
`;

export const GET_CAMPAIGN_LIST_PAGINATED = gql`
  query CampaignListPaginated($search: String, $filter: CampaignStatusChoices, $first: Int, $offset: Int) {
    campaignListPaginated(search: $search, filter: $filter, first: $first, offset: $offset) {
      totalCount
      edgeCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          startDate
          endDate
          status
          availableMetrics
          creativeType
          insightsPermitted
          dspInfo {
            dspName
          }
          creator {
            agency {
              name
            }
          }
          markets {
            code
            name
          }
          segments {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PACKAGE_INFO = gql`
  query PackageInfo($id: ID!) {
    packageInfo(id: $id) {
      uniqueId
      id
      name
      active
      startDate
      endDate
      partner
      externalId
      keywordInclusion
      keywordExclusion
      siteInclusion
      siteExclusion
      expansionCategories {
        tier1
        tier2
        tier3
        tier4
      }
    }
  }
`

export const GET_PACKAGE_LIST_PAGINATED = gql`
  query PackageListPaginated($search: String, $isActive: Boolean, $first: Int, $offset: Int) {
    packageListPaginated(search: $search, isActive: $isActive, first: $first, offset: $offset) {
      totalCount
      edgeCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          uniqueId
          internalId
          externalId
          name
          startDate
          endDate
          partner
          active
        }
      }
    }
  }
`;

export const GET_CATEGORY_RANKINGS = gql`
  ${CAMPAIGN_INSIGHT_VALUES_FRAGMENT}
  query CategoryRankings(
    $campaignId: ID!
    $insightsMetric: InsightMetricsEnum!
    $dateRange: DateRangeInput
  ) {
    insightsCategoryRankings(
      campaignId: $campaignId
      insightsMetric: $insightsMetric
      dateRange: $dateRange
    ) {
      id
      top {
        category
        ...CampaignInsightValues
      }
      bottom {
        category
        ...CampaignInsightValues
      }
    }
  }
`;

export const GET_CATEGORY_DATE_INSIGHTS = gql`
  ${CAMPAIGN_INSIGHT_VALUES_FRAGMENT}
  query CampaignCategoryDateInsights(
    $campaignId: ID!
    $dateRange: DateRangeInput
  ) {
    insightsByCategoryList(campaignId: $campaignId, dateRange: $dateRange) {
      dateRange {
        startDate
        endDate
      }
      edges {
        node {
          id
          category
          insightFilters
          dates {
            date
            ...CampaignInsightValues
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_INSIGHTS = gql`
  ${CAMPAIGN_INSIGHT_VALUES_FRAGMENT}
  query CampaignCategoryInsights($campaignId: ID!, $dateRange: DateRangeInput) {
    insightsByCategoryList(campaignId: $campaignId, dateRange: $dateRange) {
      edges {
        node {
          id
          category
          ...CampaignInsightValues
        }
      }
    }
  }
`;

export const GET_DATE_INSIGHTS = gql`
${CAMPAIGN_INSIGHT_VALUES_FRAGMENT}
query CampaignDateInsights($campaignId: ID!, $dateRange: DateRangeInput){
  insightsByDateList(campaignId: $campaignId, dateRange: $dateRange){
    edges{
      node {
        date
        categories{
          category
          ...CampaignInsightValues
        }
      }
    }
  }
}

`

export const GET_SINGLE_CATEGORY_INSIGHTS_FROM_NODE = gql`
  ${CAMPAIGN_INSIGHT_VALUES_FRAGMENT}
  query SingleCategoryInsights($id: ID!) {
    node(id: $id) {
      ... on CategoryInsightNode {
        category
        insightFilters
        dates {
          date
          ...CampaignInsightValues
        }
        subCategories {
          subCategory
          ...CampaignInsightValues
        }
      }
    }
  }
`;

export const GET_MONTHLY_AGGREGATED_REVENUE = gql`
  query MonthlyAggregatedRevenue(
    $revenueType: RevenueType,
    $dspFilter: DSPChoices,
    $officeFilter: OfficeChoices,
    $isManaged: Boolean,
    $isNoId: Boolean,
    $isNoOffice: Boolean,
    $yearFilter: Int
  ) {
    monthlyAggregatedRevenue(
      revenueType: $revenueType,
      dspFilter: $dspFilter,
      officeFilter: $officeFilter,
      isManaged: $isManaged,
      isNoId: $isNoId,
      isNoOffice: $isNoOffice,
      yearFilter: $yearFilter
    ) {
      month
      revenue
    }
  }
`;

export const GET_DSP_REVENUE = gql`
  query RevenueByDsp($officeFilter: OfficeChoices, $yearFilter: Int) {
    revenueByDsp(officeFilter: $officeFilter yearFilter: $yearFilter) {
      dsp
      revenue
    }
  }
`;

export const GET_MONTHLY_DSP_REVENUE = gql`
  query MonthlyDspRevenue($dspFilter: DSPChoices, $officeFilter: OfficeChoices, $yearFilter: Int) {
    monthlyDspRevenue(dspFilter: $dspFilter, officeFilter: $officeFilter yearFilter: $yearFilter) {
      dsp
      dspRevenue {
        month
        revenue
      }
    }
  }
`

export const GET_TARGET_REVENUE = gql`
  query TargetRevenue($yearFilter: Int!) {
    targetRevenue(yearFilter: $yearFilter) {
      januaryUs
      januaryRow
      februaryUs
      februaryRow
      marchUs
      marchRow
      aprilUs
      aprilRow
      mayUs
      mayRow
      juneUs
      juneRow
      julyUs
      julyRow
      augustUs
      augustRow
      septemberUs
      septemberRow
      octoberUs
      octoberRow
      novemberUs
      novemberRow
      decemberUs
      decemberRow
    }
  }
`

export const GET_FULL_ACTUAL_REVENUE_PAGINATED = gql`
  query FullActualRevenuePaginated($dspFilter: DSPChoices, $officeFilter: OfficeChoices, $yearFilter: Int, $first: Int, $offset: Int) {
    fullRevenue: fullActualRevenuePaginated(dspFilter: $dspFilter, officeFilter: $officeFilter, yearFilter: $yearFilter, first: $first, offset: $offset) {
      totalCount
      edges {
        node {
          campaign {
            id
            name
            office
            brand{
              name
            }
            accountManager {
              firstName
              lastName
            }
            salesPerson {
              firstName
              lastName
            }
            dspInfo {
              dspName
            }
          }
          revenue
          date
        }
      }
    }
  }
`;


export const GET_FULL_FORECAST_REVENUE_PAGINATED = gql`
  query FullForecastRevenuePaginated($dspFilter: DSPChoices, $officeFilter: OfficeChoices, $yearFilter: Int, $first: Int, $offset: Int) {
    fullRevenue: fullForecastRevenuePaginated(dspFilter: $dspFilter, officeFilter: $officeFilter, yearFilter: $yearFilter, first: $first, offset: $offset) {
      totalCount
      edges {
        node {
          campaign {
            id
            name
            office
            brand{
              name
            }
            accountManager {
              firstName
              lastName
            }
            salesPerson {
              firstName
              lastName
            }
            dspInfo {
              dspName
            }
          }
          revenue
          month
          year
        }
      }
    }
  }
`;

export const GET_FULL_SALESFORCE_REVENUE_PAGINATED = gql`
  query FullSalesforceRevenuePaginated($regionFilter: OfficeChoices, $yearFilter: Int, $first: Int, $offset: Int) {
    fullRevenue: fullSalesforceRevenuePaginated(regionFilter: $regionFilter, yearFilter: $yearFilter, first: $first, offset: $offset) {
      totalCount
      edges {
        node {
          salesforceCampaign {
            id
            opportunityName
            opportunityOwner
            region
            stage
            accountName
          }
          revenue
          date
        }
      }
    }
  }
`;

export const GET_ROLE_USERS = gql`
  query UsersRoleGroup($role: UserRoleChoices) {
    usersRoleGroup(role: $role) {
      id
      firstName
      lastName
      isStaff
      role
    }
  }
`
export const GET_BRAND_LIST = gql`
  query BrandList {
    brandList {
      id
      name
    }
  }
`;

export const GET_PRECISION_CATEGORIES_LIST = gql`
  query PrecisionCategoriesList {
    precisionCategoriesList {
      value
      label
      subItems {
        value
        label
        subItems {
          value
          label
          subItems {
            value
            label
          }
        }
      }
    }
  }
`;

export const GET_PRECISION_CATEGORIES_OPTIONS = gql`
  query PrecisionCategoriesOptions {
    precisionCategoriesOptions {
      id
      label
      children
      parents
    }
  }
`;

export const GET_RANDOM_URL = gql`
  query RandomUrl($urlSource: String, $urlSearch: String, $categorySearch: String) {
    randomUrl(urlSource: $urlSource, urlSearch: $urlSearch, categorySearch: $categorySearch) {
      id
      url
      urlHash
      timestamp
      langDetect
      numOfWords
      urlSource
      precisionIabV3
      classificationResult
    }
  }
`;

export const GET_REVIEWED_URL = gql`
  query ReviewedUrl($url: String!) {
    reviewedUrl(url: $url) {
      url
      createdAt
      precisionIabV3
      reviewedClassification
      rejectedClassification
      reviewedBy {
        firstName
        lastName
      }
    }
  }
`;
