import { makeVar, ReactiveVar } from "@apollo/client";

const getCleanValueForStorage = (value: any) => {
  return typeof value === "string" ? value : JSON.stringify(value);
};

const makeVarPersisted = (initialValue: any, config: Record<string, any>) => {
  const rv: ReactiveVar<any> = makeVar(initialValue);

  const reactiveVarFn = function (newValue: any = null) {
    if (newValue !== null) {
      try {
        config?.storage.setItem(
          config.storageKey,
          getCleanValueForStorage(newValue)
        );

        return rv(newValue);
      } catch {
        // ignore
      }
    } else {
      return rv;
    }
  };

  const restore = async () => {
    try {
      const previousValue = await config.storage.getItem(config.storageKey);
      if (previousValue) {
        rv(
          typeof initialValue === "string"
            ? previousValue
            : JSON.parse(previousValue)
        );
      }
    } catch {
      // ignore
    }
  };

  reactiveVarFn.onNextChange = rv.onNextChange;

  return [reactiveVarFn, restore];
};

export default makeVarPersisted;
