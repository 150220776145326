import { useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { useHitlFilters } from ".";
import { ComponentLoading } from "../../components/loading/ComponentLoading";
import { GET_URL_LIST } from "../../rest/rest";

export const HITLBodyContent = () => {

    const hitlFilters = useHitlFilters();
    const { data, error, loading } = useQuery(
        GET_URL_LIST,
        {
            context: {clientName: "hitlEndpoint"},
            variables: {
                search: hitlFilters.urlSearch,
                category: hitlFilters.categoryFilter.value === "ALL" ? "" : hitlFilters.categoryFilter.value,
            },
            // fetchPolicy: "cache-and-network",
            onCompleted: () => {
                hitlFilters.setUrlList(data.urlList.urls);
                hitlFilters.setCursor(data.urlList.cursor);
            }
        }
    );

    if (loading) {
        return <ComponentLoading text={"Loading URLs..."}/>
    };

    if (error) {
        return <>Error</>
    };

    return (
        <Box
            width={"80%"}
            mx={"auto"}
        >
            <Outlet />
        </Box>
    )
}
