import { Box, BoxProps } from "@chakra-ui/react";

interface Props {
  message?: string;
}

export const NoData = ({ message, ...rest }: Props & BoxProps) => {
  return (
    <Box
      textAlign={"center"}
      p={3}
      fontSize={"18px"}
      color={"text.light"}
      {...rest}
    >
      {message ?? "No data - Expand or change the filter parameters."}
    </Box>
  );
};
