import { BoxProps, Flex, Grid, GridItem, Tag, TagLabel, Text } from "@chakra-ui/react";
import { useSegmentInfo } from ".";
import { SectionBox } from "../../components/sectionBox/SectionBox";
import { DetailField } from "../../components/detailField/DetailField";

export const TargetDetailsSummary = (props: BoxProps) => {
    const { segment } = useSegmentInfo();

    return (
        <SectionBox {...props}>
            <Flex direction={"column"}>
                <Grid
                    templateColumns="repeat(8, 1fr)"
                    gap={6}
                    mb={4}
                    alignItems={"start"}
                >
                    <GridItem colSpan={3}>
                        <Text
                            color={"primary.darker"}
                            fontWeight={"bold"}
                            fontSize={"sm"}
                            mr={2}
                        >
                            Targetting Details
                        </Text>
                    </GridItem>
                    <GridItem colSpan={5}>
                        <Tag
                            size={"sm"}
                            bg={segment.active ? "primary.light" : "inactive"}
                            colorScheme="teal"
                            borderRadius={"full"}
                        >
                            <TagLabel>
                                {segment.active ? "active": "inactive"}
                            </TagLabel>
                        </Tag>
                    </GridItem>
                </Grid>
                {segment.externalId &&
                    <DetailField
                        label={"ID"}
                        value={segment.externalId}
                        isCopyField={true}
                    />
                }
                <DetailField
                    label={"Name"}
                    value={segment.name}
                    isCopyField={true}
                />

            </Flex>
        </SectionBox>
    )
}
