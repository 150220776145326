export default {
  global: (props: any) => ({
    "html, body": {
      color: props.colorMode === "dark" ? "white" : "text.dark",
      bg: "background",
    },
    '&::-webkit-scrollbar': {
        width: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: "inactive",
        borderRadius: "lg"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#A2B1C3"
    }
  }),
};
