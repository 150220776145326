import { useLazyQuery } from "@apollo/client";
import { Box, Button, Center } from "@chakra-ui/react";
import { useState } from "react";
import { useHitlFilters } from "..";
import { ActionLabel } from "../../../components/actionLabel/ActionLabel";
import { GET_URL_LIST } from "../../../rest/rest";
import { UrlCard } from "./UrlCard";

export const UrlList = () => {

    const hitlFilters = useHitlFilters();
    const [urlLimit, setUrlLimit] = useState(10);
    const [buttonLoading, setButtonLoading] = useState(false);
    const partialListOfUrls: string[] = hitlFilters.urlList.slice(0, urlLimit);

    const [ getMoreUrls, {data} ] = useLazyQuery(
        GET_URL_LIST,
        {
            context: {clientName: "hitlEndpoint"},
            // fetchPolicy: "cache-and-network",
            onCompleted: () => {
                hitlFilters.setUrlList((prev) => [...prev, ...data.urlList.urls]);
                hitlFilters.setCursor(data.urlList.cursor);
                setButtonLoading(false);
            }
        }
    );

    return (
        <>
            {hitlFilters.urlList.length > 10 &&
                <Box>
                    <ActionLabel label={`Results: ${hitlFilters.urlList.length} urls found.`} />
                </Box>
            }
            {partialListOfUrls.map((url, ix) =>
                <UrlCard
                    key={ix}
                    url={url}
                    urlIndex={ix}
                />
            )}
            <Center hidden={(hitlFilters.cursor === 0 && urlLimit >= hitlFilters.urlList.length)}>
                <Button
                    isLoading={buttonLoading}
                    onClick={() => {
                        setButtonLoading(true)

                        if (urlLimit >= hitlFilters.urlList.length) {
                            getMoreUrls({
                                variables: {
                                    search: hitlFilters.urlSearch,
                                    category: hitlFilters.categoryFilter.value === "ALL" ? "" : hitlFilters.categoryFilter.value,
                                    cursor: hitlFilters.cursor
                                }
                            });
                            setUrlLimit(prev => prev + 15);
                        } else {
                            setUrlLimit(prev => prev + 15);
                            setButtonLoading(false);
                        }

                    }}
                    variant={"primary"}
                >
                    Load more URLs...
                </Button>
            </Center>
        </>
    )
}
