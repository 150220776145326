import { useCampaignInfo } from "./index";
import { Box, BoxProps, Progress, Text } from "@chakra-ui/react";
import { SegmentField } from "./SegmentField";
import { DspChoices, SegmentsSegmentSegmentTypeChoices } from "../../graphql/generated";

export const SegmentsSummary = (props: BoxProps) => {
  const { campaign, shouldPoll } = useCampaignInfo();
  let component;

  // OLD: Only show segments when dsp is Xandr or isDeal is true
  // Deprecating use of isDeal
  // const campaignSegmentsFiltered = campaign.segments.filter((segment) => {
  //   if (campaign.dspInfo.dspName === DspChoices.Xandr || segment.isDeal) {
  //     return true
  //   } else {
  //     return false
  //   }
  // })

  // Conditions to show segments shown below
  const campaignSegmentsFiltered = campaign.segments.filter((segment) => {
    if (!campaign.dspInfo.isDirect) {
      // When campaign is not direct show only deals
      if (segment.segmentType === SegmentsSegmentSegmentTypeChoices.Deal) {
        return true;
      }
    } else {
      if (campaign.dspInfo.dspName === DspChoices.Xandr) {
        // When campaign is direct and Xandr only segment type should be segment
        if (segment.segmentType === SegmentsSegmentSegmentTypeChoices.Segment) {
          return true;
        }
      }
      if (campaign.dspInfo.dspName === DspChoices.Ttd) {
        // When campaign is direct and TTD only segment type should be category
        if (segment.segmentType === SegmentsSegmentSegmentTypeChoices.Category) {
          return true;
        }
      }
    }
  })
  if (campaignSegmentsFiltered.length) {
    component = (
      <Box {...props}>
        {campaignSegmentsFiltered.map(
          ({ id, name }: { name: string; id: string }, ix: number) => (
            <SegmentField
              key={`${name}-=${ix}`}
              id={id}
              name={name}
              includeBorder={ix < campaignSegmentsFiltered.length - 1}
            />
          )
        )}
      </Box>
    );
  } else {
    component = (
      <Box px={10} py={4} {...props}>
        <Text fontSize={"25px"} fontWeight={"medium"}>
          Thank you for submitting your request.
        </Text>
        {shouldPoll ? (
          <>
            <Text>Generating illuma IDs</Text>
            <Progress isIndeterminate size={"md"} colorScheme={"teal"} />
          </>
        ) : (
          <Text>
            Your illuma IDs will be available soon. You will be notified via
            email when they have been generated.
          </Text>
        )}
      </Box>
    );
  }

  return component;
};
