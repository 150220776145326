export default {
  variants: {
    accountMenu: {
      list: {
        bg: "text.dark",
        color: "white",
      },
      item: {
        fontWeight: "medium",
        _focus: {
          bg: "transparent",
        },
        _hover: {
          bg: "transparent",
          color: "primary.light",
        },
      },
    },
    filterMenu: {
      list: {
        color: "text.dark",
        borderRadius: "lg",
        borderWidth: "2px",
        borderColor: "primary.dark",
      },
      groupTitle: {
        color: "text.light",
        fontSize: "11px",
      },
      divider: {
        borderColor: "primary.dark",
        borderWidth: "2px",
      },
      item: {
        _checked: {
          bg: "primary.lighter",
        },
        _focus: {
          bg: "primary.light",
        },
        _active: {
          bg: "primary.lighter",
        },
      },
    },
  },
};
