import { useQuery } from "@apollo/client";
import { Box, Flex, Switch, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useCampaignInfo } from "..";
import { ComponentLoading } from "../../../components/loading/ComponentLoading";
import { SectionBox } from "../../../components/sectionBox/SectionBox";
import { SelectField } from "../../../components/selectField/SelectField";
import { AgencyProductPriceType, CampaignProductCategoryType, CampaignTierChoices, DspChoices, OfficeChoices, UserRoleChoices } from "../../../graphql/generated";
import { GET_BRAND_LIST, GET_ROLE_USERS } from "../../../graphql/operations/campaignOperations/operations";
import { formatTextDisplay, getDSPName } from "../../../utils/displayGetters";
import { DetailField } from "../../../components/detailField/DetailField";
import { BrandSelect } from "./BrandSelect";
import { PersonSelect } from "./PersonSelect";
import { SavePopover } from "./SavePopover";

export const CampaignDetails = () => {

    const { campaign: {accountManager, brand, dspInfo, office, productCategories, salesPerson, tier} } = useCampaignInfo();
    const [tierSelect, setTierSelect] = useState<CampaignTierChoices>(tier);
    const [officeSelect, setOfficeSelect] = useState<OfficeChoices>(office);
    const [productPrices, setProductPrices] = useState<Record<string, string>>({});
    const [brandSelect, setBrandSelect] = useState<{label: string, value: string} | undefined>(
        brand && {label: brand.name, value: brand.id}
    );
    const [isDspDirect, setIsDspDirect] = useState<boolean>(dspInfo.isDirect);
    const [accountManagerSelect, setAccountManagerSelect] = useState<Record<string, string>>(
        accountManager && {label: `${accountManager.lastName}, ${accountManager.firstName}`, value: accountManager.id}
    );
    const [salesPersonSelect, setSalesPersonSelect] = useState<Record<string, string>>(
        salesPerson && {label: `${salesPerson.lastName}, ${salesPerson.firstName}`, value: salesPerson.id}
    );

    const { data: dataBrand, loading: loadingBrand, error: errorBrand } = useQuery(GET_BRAND_LIST,
        {fetchPolicy: "cache-and-network"}
    );
    const { data: dataAccount, error: errorAccount, loading: loadingAccount } = useQuery(GET_ROLE_USERS,
        {
            fetchPolicy: "cache-and-network",
            variables: {role: UserRoleChoices.AccountManagement}
        }
    );
    const { data: dataSales, error: errorSales, loading: loadingSales } = useQuery(GET_ROLE_USERS,
        {
            fetchPolicy: "cache-and-network",
            variables: {role: UserRoleChoices.Sales}
        }
    );
    const { data: dataSenior, error: errorSenior, loading: loadingSenior } = useQuery(GET_ROLE_USERS,
        {
            fetchPolicy: "cache-and-network",
            variables: {role: UserRoleChoices.SeniorManagement}
        }
    );

    if (loadingBrand || loadingAccount || loadingSales || loadingSenior) {
        return (
            <SectionBox>
                <ComponentLoading text={"Loading campaign details..."} />
            </SectionBox>
        );
    }

    if (errorBrand || errorAccount || errorSales || errorSenior) {
        return <>Error</>
    }

    return(
        <SectionBox sectionHeader={"Campaign Details"}>
            <Text mb={2}>Price</Text>
            {productCategories.map(
                ({ id: productId, productCategory, priceList, price }) => {
                return (
                    <Box key={`product-pricing-${productCategory}`} mb={4} ml={4}>
                        <DetailField
                            label={`${formatTextDisplay(productCategory)} Price`}
                            value={price ? formatPriceDisplay(price) : "Unset"}
                            mb={1}
                        />
                        <SelectField
                            placeholder={"Set Price"}
                            defaultValue={generatePriceObjectType(price)}
                            onChange={({ value: newPriceId }) => {
                                setProductPrices({
                                    ...productPrices,
                                    [productId]: newPriceId,
                                });
                            }}
                            isMulti={false}
                            options={getPriceListOptions(priceList)}
                            hideSelectedOptions={true}
                        />
                    </Box>
                );
                }
            )}
            <Text my={5}>Office</Text>
            <Box ml={4}>
                <SelectField
                    placeholder={"Set Office"}
                    onChange={({value}) => {
                        setOfficeSelect(value)
                    }}
                    defaultValue={office ? {label: office, value: office} : undefined}
                    isMulti={false}
                    options={Object.values(OfficeChoices).map(
                        (value) => ({label: value, value: value})
                    )}
                    hideSelectedOptions={true}
                />
            </Box>
            <Text my={5}>Campaign Tier</Text>
            <Box ml={4}>
                <SelectField
                    placeholder={"Set Tier"}
                    onChange={({value}) => {
                        setTierSelect(value)
                    }}
                    defaultValue={tier ? {label: formatTextDisplay(tier), value: tier} : undefined}
                    isMulti={false}
                    options={Object.values(CampaignTierChoices).map(
                        (value) => ({label: formatTextDisplay(value), value: value})
                    )}
                    hideSelectedOptions={true}
                />
            </Box>
            <BrandSelect
                brandList={dataBrand.brandList}
                brandSelect={brandSelect}
                setBrandSelect={setBrandSelect}
            />
            <PersonSelect
                dataAccount={dataAccount.usersRoleGroup}
                dataSales={dataSales.usersRoleGroup}
                dataSenior={dataSenior.usersRoleGroup}
                accountManager={accountManager}
                setAccountManagerSelect={setAccountManagerSelect}
                salesPerson={salesPerson}
                setSalesPersonSelect={setSalesPersonSelect}
            />
            {
                dspInfo.dspName === DspChoices.Ttd &&
                    <Box my={5}>
                        <Flex
                            // justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Text mr={10}>Direct Integration</Text>
                            <Switch
                                // {...field}
                                size={"md"}
                                defaultChecked={isDspDirect}
                                onChange={() => {
                                    setIsDspDirect(prev => !prev)
                                }}
                                isChecked={isDspDirect}
                            />
                        </Flex>
                    </Box>
            }
            <SavePopover
                officeSelect={officeSelect}
                tierSelect={tierSelect}
                brandSelect={brandSelect}
                accountManagerSelect={accountManagerSelect}
                salesPersonSelect={salesPersonSelect}
                productPrices={productPrices}
                isPriceSelected={isPriceSelected(productPrices, productCategories)}
                isDirect={isDspDirect}
            />
        </SectionBox>
    )
}

const getPriceListOptions = (
    priceList: AgencyProductPriceType[]
): { label: string; value: string }[] => {
    return priceList.map(
        (price) =>
        generatePriceObjectType(price) ?? ({} as { label: string; value: string })
    );
};

const generatePriceObjectType = (price?: AgencyProductPriceType) => {
    if (price) {
        return {
            label: formatPriceDisplay(price),
            value: price.id
        };
    } else return undefined;
};

const formatPriceDisplay = (price: AgencyProductPriceType | undefined) => {
    if (price) {
        return `${formatTextDisplay(price.product)} @ ${getDSPName(price.dsp)} | $${price.price}`;
    } else {
        return "";
    }
};

const isPriceSelected = (
    productPrices: Record<string, string>,
    productCategories: CampaignProductCategoryType[],
) => {
    const actualIds: string[] = []
    const selectedIds = Object.values(productPrices);
    let isSelected = false;

    // This caused a bug as it was assuming all prices had id
    // after only checking the first product

    // if (productCategories[0].price) {
    //     productCategories.forEach(({price}) => {
    //         actualIds.push(price.id)
    //     })
    // }

    productCategories.forEach(({price}) => {
        price && actualIds.push(price.id)
    })

    if (selectedIds.length !== 0) {
        selectedIds.forEach((id) => {
            if (actualIds.indexOf(id) === -1) {
                isSelected = true;
            }
        })
    }

    return isSelected;
}
