import { Box, Text, Link, Flex, Image, Button } from "@chakra-ui/react";
import { LoginForm } from "./loginForm";
import { IllumaLogo } from "../../assets/icons/IllumaLogo";
import { useAuth } from "../../lib/auth";
import { Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export const LoginPage = () => {
  const { signIn, isSignedIn } = useAuth();
  const location = useLocation();
  const pageState = location.state as { destination: string };

  if (isSignedIn) {
    return <Navigate to={pageState?.destination ?? "/"} replace={true} />;
  }
  return (
    <Box px={[0, null, 5, null, 10]} py={[0, null, 5, 8, 10]}>
      <Helmet titleTemplate={"%s | IllumaLand"}>
        <title>Login</title>
      </Helmet>
      <Flex mb={10} fill={"black"} flexDirection={"column"}>
        <IllumaLogo width={"100%"} height={"37.55px"} />
        <Text
          pl={"40px"}
          textAlign={"center"}
          color={"muted"}
          fontSize={"18px"}
          fontWeight={"medium"}
        >
          Reports
        </Text>
      </Flex>
      <Box>
        <Image
          src={"images/login_page_bg.svg"}
          position={"absolute"}
          visibility={["hidden", null, "visible", null, null]}
          w={"calc(100% - 60px)"}
          zIndex={0}
        />
        <Box
          bg={"white"}
          borderRadius={"md"}
          px={10}
          pt={10}
          pb={8}
          maxW={["100%", null, "50%", null, "40%", "30%"]}
          mx={"auto"}
        >
          <Text
            textAlign={"center"}
            color={"secondary"}
            fontWeight={"medium"}
            fontSize={"48px"}
          >
            Login
          </Text>
          <Text
            fontWeight={"medium"}
            textAlign={"center"}
            mt={3}
            fontSize={"16px"}
          >
            Don&apos;t have an account?{" "}
            <Button
              as={Link}
              variant={"link"}
              fontSize={"16px"}
              href={"https://weareilluma.com/contact"}
            >
              Contact support
            </Button>
          </Text>
          <LoginForm onSubmit={signIn} />
        </Box>
      </Box>

      <Text
        fontWeight={"medium"}
        textAlign={"center"}
        mt={12}
        fontSize={"14px"}
      >
        Need help?{" "}
        <Button
          as={Link}
          variant={"link"}
          href={"https://weareilluma.com/contact"}
          fontSize={"14px"}
        >
          Contact support
        </Button>
      </Text>
    </Box>
  );
};
