import { useEffect } from "react";
import { version as currentVersion } from "./lib/version";
import {
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useDisclosure,
  ModalFooter,
  Button,
} from "@chakra-ui/react";

export const CacheBuster = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    // We include a random search string with the fetch query so that the browser always fetches
    // the json file
    fetch(`/version.json?i=${new Date()}`)
      .then((res) => {
        return res.json();
      })
      .then(({ version: latestVersion }) => {
        if (parseFloat(currentVersion) !== parseFloat(latestVersion)) {
          console.log(
            "There is an update to the app. Please reload your browser."
          );
          onOpen();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentVersion]);
  return <CacheReloadModal onClose={onClose} isOpen={isOpen} />;
};

const CacheReloadModal = ({
  isOpen,
  onClose,
}: Pick<ModalProps, "isOpen" | "onClose">) => {
  const handleUpdateClick = () => {
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      // Clearing service worker caches.
      caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
    window.location.reload();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update available!</ModalHeader>
        <ModalBody>
          <Text>
            A new version of illumaland is available. Do you want to update?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleUpdateClick} variant={"primary"}>
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
