import { Box, useTheme } from "@chakra-ui/react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  PieController,
  TooltipItem
} from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { DspRevenueType } from "../../../../graphql/generated";

interface DspRevenuePieChartProps {
  revenueByDspData: DspRevenueType[]
}

export const DspRevenuePieChart = ({revenueByDspData}: DspRevenuePieChartProps) => {

  const chakraTheme = useTheme();
  const [dspList, dspRevenue] = preparePieChartData(revenueByDspData)

  // Set colors
  const colors: string[] = Object.values(chakraTheme.colors.distinctDataColors);
  const totalRevenue = dspRevenue.reduce((prev, curr) => prev + curr, 0);

  ChartJS.register(
    ArcElement,
    ChartDataLabels,
    Legend,
    PieController,
    Tooltip,
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "white",
        display: (context: Context) => {
          const value = context.dataset.data[context.dataIndex];
          if (typeof value !== "number") {
            return false
          }
          if (value && getPercentage(value, totalRevenue) < 10) {
            return false
          }
          return true
        },
        labels: {
          value: {
            formatter: (value: number, context: Context) => {
              if (context.chart.data.labels) {
                const percentage = getPercentage(value, totalRevenue);
                return `${context.chart.data.labels[context.dataIndex]} (${percentage}%)`
              }
              return
            },
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<"pie">) => {
            const value = context.dataset.data[context.dataIndex];
            const percentage = getPercentage(value, totalRevenue)
            return ` ${context.formattedValue} (${percentage}%)`;
          },
          title: (context: TooltipItem<"pie">[]) => {
            return context[0].label
          }
        }
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          padding: 20
        }
      },
    },
  };

  const pieChartData = {
    labels: dspList,
    datasets: [
      {
        label: 'revenue',
        data: dspRevenue,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <Box height={400} mb={4} mx={20}>
        <Chart
          type={"pie"}
          options={options}
          data={pieChartData}
        />
      </Box>
    </>
  )
}

const preparePieChartData = (dspRevenueData: DspRevenueType[]): [string[], number[]] => {
  const filteredDsp = dspRevenueData.filter((dspRevenue) => dspRevenue.revenue);
  const dspLabels = filteredDsp.map((dspRevenue) => dspRevenue.dsp);
  const dspRevenue = filteredDsp.map((dspRevenue) => dspRevenue.revenue);

  return [dspLabels, dspRevenue];
}

const getPercentage = (value: number, total: number) => {
  const percentage = (value / total) * 100;
  // Rounded to 2 decimal places
  const percentageRounded = Math.round((percentage + Number.EPSILON) * 100) / 100
  return percentageRounded
}
