import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { flexRender, Table as TableType } from "@tanstack/react-table";
import { Fragment } from "react";
import { DomainSection } from "../../pages/adminDashboardPage/segmentRotation/ExpandedDomains";

interface CustomTableProps {
    table: TableType<any>
    expanded?: "domains"
}

export const CustomTable = ({table, expanded}: CustomTableProps) => {

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map((headerGroup, ix) => (
                    <Tr key={`tableRowHeader${ix}`}>
                        {headerGroup.headers.map((header, index) => (
                            <Th
                                key={`tableHeader${index}`}
                                colSpan={header.colSpan}
                                fontSize={"sm"}
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                )}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row, ix) => {
                    return (
                        <Fragment key={ix}>
                            <Tr>
                                {row.getVisibleCells().map((cell, index) => (
                                    <Td key={`tableData${index}`}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>

                            {/* Expanded section are individually added here. Add another option to
                            "expanded" prop to use another expansion component */}

                            {row.getIsExpanded() && expanded === "domains" && (
                                <DomainSection table={table} row={row} />
                            )}

                        </Fragment>
                    )
                })}
            </Tbody>
        </Table>
    )
}
