import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Header } from "../../header/Header";
import { Footer } from "../../footer/Footer";
import { Box, Collapse, Flex } from "@chakra-ui/react";
import { InsightsHeader } from "../../../pages/campaignInsightsPage/InsightsHeader";

interface AppLayoutProps {
  pageTitle?: string;
  showInsightsHeader?: boolean;
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  pageTitle = "Insights",
  showInsightsHeader = false,
}) => {
  return (
    <Flex flexDirection={"column"} h={"100vh"} w={"100%"}>
      <Helmet titleTemplate={"%s | IllumaLand"}>
        <title>{pageTitle}</title>
      </Helmet>
      <Header pageTitle={pageTitle}>
        <Collapse in={showInsightsHeader} unmountOnExit>
          <InsightsHeader mt={2} />
        </Collapse>
      </Header>
      <Box
        flexGrow={1}
        // h={"90vh"}
        maxW={"80rem"}
        mx={"auto"}
        w={"100%"}
        px={[2, null, null, null, 0]}
        // overflow={"hidden"}
        mb={10}
      >
        <Box w={"100%"}>{children}</Box>
      </Box>
      <Footer />
    </Flex>
  );
};
