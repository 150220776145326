import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { AuthProvider } from "./lib/auth";
import { LoginPage } from "./pages/loginPage";
import { Navigate, Route, Routes } from "react-router-dom";
import { CampaignHomePage } from "./pages/campaignHomePage";
import { RequireAuthWrapper } from "./pages/RequireAuthWrapper";
import { CampaignSetupPage } from "./pages/campaignSetupPage";
import { HelmetProvider } from "react-helmet-async";
import { CampaignSummaryPage } from "./pages/campaignSummaryPage";
import { AppLayout } from "./components/containers/appLayout/AppLayout";
import { NotFound } from "./pages/NotFound";
import { ResetPassword } from "./pages/ResetPassword";
import { ResetPasswordDone } from "./pages/ResetPasswordDone";
import { CampaignInsightsOverview } from "./pages/campaignInsightsPage/campaignInsightsOverview";
import { CampaignInsightDetails } from "./pages/campaignInsightsPage/campaignInsightsDetails";
import { CampaignInsightsPage } from "./pages/campaignInsightsPage";
import { version } from "./lib/version";
import { useEffect } from "react";
import { CacheBuster } from "./CacheBuster";
import { CampaignSummaryHandleDeprecation } from "./pages/campaignSummaryPage/CampaignSummaryHandleDeprecation";
import { TermsOfService } from "./pages/TermsOfService";
import { AdminCampaignPage } from "./pages/adminCampaignPage";
import { Approval } from "./pages/adminCampaignPage/approval/Approval";
import { PreCampaignChecks } from "./pages/adminCampaignPage/preCampaignChecks/PreCampaignChecks";
import { ActiveCampaign } from "./pages/adminCampaignPage/activeCampaign/ActiveCampaign";
import { CampaignAdminOverview } from "./pages/adminCampaignPage/campaignAdminOverview/CampaignAdminOverview";
import { AdminDashboardPage } from "./pages/adminDashboardPage";
import { SpecificSegmentRotation } from "./pages/adminCampaignPage/specificSegmentRotation/SpecificSegmentRotation";
import { Revenue } from "./pages/adminDashboardPage/revenue";
import { AnnualRevenue } from "./pages/adminDashboardPage/revenue/annualRevenue";
import { DspRevenue } from "./pages/adminDashboardPage/revenue/dspRevenue";
import { MonthlyDspRevenue } from "./pages/adminDashboardPage/revenue/monthlyDspRevenue";
import { HumanInTheLoop } from "./pages/humanInTheLoopPage";
import { LandingPage } from "./pages/landingPage";
import { UrlReview } from "./pages/humanInTheLoopPage/urlReview/index";
import { UrlList } from "./pages/humanInTheLoopPage/urlList";
import { PastReview } from "./pages/humanInTheLoopPage/pastReview";
import { DownloadJson } from "./pages/DownloadJson";
import { SegmentHomePage } from "./pages/segmentHomePage";
import { SegmentSummaryPage } from "./pages/segmentSummaryPage";
import { SegmentSetupPage } from "./pages/segmentSetupPage";

export const App = () => {
  useEffect(() => {
    console.log(`illumaland version ${version}`);
  });
  return (
    <HelmetProvider>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <CacheBuster />
          <Routes>
            <Route path={"/"}>
              <Route
                path={""}
                element={
                  <RequireAuthWrapper>
                    <AppLayout pageTitle={""}>
                      <LandingPage />
                    </AppLayout>
                  </RequireAuthWrapper>
                }
              />
              <Route
                path={"campaigns"}
                element={<Navigate to={"/campaigns"} replace={true} />}
              />
              <Route
                path={"campaign-summary"}
                element={<CampaignSummaryHandleDeprecation />}
              />
              <Route
                path={"human-in-the-loop"}
                element={
                  <RequireAuthWrapper>
                    <AppLayout pageTitle={"Human in the Loop"}>
                      <HumanInTheLoop />
                    </AppLayout>
                  </RequireAuthWrapper>
                }
              >
                <Route
                  path={""}
                  element={<Navigate to={"url-list"} replace={true} />}
                />
                <Route
                  path={"url-list"}
                  element={
                    <RequireAuthWrapper>
                        <UrlList />
                    </RequireAuthWrapper>
                  }
                />
                <Route
                  path={"review"}
                  element={
                    <RequireAuthWrapper>
                        <UrlReview />
                    </RequireAuthWrapper>
                  }
                />
                <Route
                  path={"past-review"}
                  element={
                    <RequireAuthWrapper>
                        <PastReview />
                    </RequireAuthWrapper>
                  }
                />
              </Route>
              <Route path={"campaigns"}>
                <Route
                  path={""}
                  element={
                    <RequireAuthWrapper>
                      <AppLayout pageTitle={"Campaigns"}>
                        <CampaignHomePage />
                      </AppLayout>
                    </RequireAuthWrapper>
                  }
                />
                <Route
                  path={"new"}
                  element={
                    <RequireAuthWrapper>
                      <AppLayout pageTitle={"New Campaign"}>
                        <CampaignSetupPage />
                      </AppLayout>
                    </RequireAuthWrapper>
                  }
                />
                <Route
                  path={"admin-dashboard"}
                  element={
                    <RequireAuthWrapper>
                      <AppLayout pageTitle={"Admin"}>
                        <AdminDashboardPage />
                      </AppLayout>
                    </RequireAuthWrapper>
                  }
                >
                  <Route
                    path={""}
                    element={<Navigate to={"revenue-forecast"} replace={true} />}
                  />
                  {/* <Route
                    path={"segment-rotation"}
                    element={
                      <RequireAuthWrapper>
                        <SegmentRotation />
                      </RequireAuthWrapper>
                    }
                  /> */}
                  <Route
                    path={"revenue-forecast"}
                    element={
                      <RequireAuthWrapper>
                        <Revenue>
                          <AnnualRevenue />
                        </Revenue>
                      </RequireAuthWrapper>
                    }
                  />
                  <Route
                    path={"dsp-revenue"}
                    element={
                      <RequireAuthWrapper>
                        <Revenue>
                          <DspRevenue />
                        </Revenue>
                      </RequireAuthWrapper>
                    }
                  />
                  <Route
                    path={"monthly-dsp-revenue"}
                    element={
                      <RequireAuthWrapper>
                        <Revenue>
                          <MonthlyDspRevenue />
                        </Revenue>
                      </RequireAuthWrapper>
                    }
                  />
                </Route>
                <Route path={":id"}>
                  <Route
                    path={""}
                    element={
                      <RequireAuthWrapper>
                        <AppLayout pageTitle={"Summary"}>
                          <CampaignSummaryPage />
                        </AppLayout>
                      </RequireAuthWrapper>
                    }
                  />
                  <Route
                    path={"admin-campaign"}
                    element={
                      <RequireAuthWrapper>
                        <AppLayout pageTitle={"Admin"}>
                          <AdminCampaignPage />
                        </AppLayout>
                      </RequireAuthWrapper>
                    }
                  >
                    <Route
                      path={""}
                      element={<Navigate to={"campaign-admin-overview"} replace={true} />}
                    />
                    <Route
                      path={"campaign-admin-overview"}
                      element={
                        <RequireAuthWrapper>
                          <CampaignAdminOverview />
                        </RequireAuthWrapper>
                      }
                    />
                    <Route
                      path={"approval"}
                      element={
                        <RequireAuthWrapper>
                          <Approval />
                        </RequireAuthWrapper>
                      }
                    />
                    <Route
                      path={"pre-campaign-checks"}
                      element={
                        <RequireAuthWrapper>
                          <PreCampaignChecks />
                        </RequireAuthWrapper>
                      }
                    />
                    <Route
                      path={"active-campaign"}
                      element={
                        <RequireAuthWrapper>
                          <ActiveCampaign />
                        </RequireAuthWrapper>
                      }
                    />
                    <Route
                      path={"specific-segment-rotation"}
                      element={
                        <RequireAuthWrapper>
                          <SpecificSegmentRotation />
                        </RequireAuthWrapper>
                      }
                    />
                  </Route>
                  <Route
                    path={"insights"}
                    element={
                      <RequireAuthWrapper>
                        <CampaignInsightsPage />
                      </RequireAuthWrapper>
                    }
                  >
                    <Route
                      path={""}
                      element={<Navigate to={"overview"} replace={true} />}
                    />
                    <Route
                      path={"overview"}
                      element={
                        <RequireAuthWrapper>
                          <CampaignInsightsOverview />{" "}
                        </RequireAuthWrapper>
                      }
                    />
                    <Route
                      path={"details"}
                      element={
                        <RequireAuthWrapper>
                          <CampaignInsightDetails />
                        </RequireAuthWrapper>
                      }
                    />
                  </Route>
                </Route>
              </Route>
              <Route path={"segments"}>
                <Route
                  path={""}
                  element={
                    <RequireAuthWrapper>
                      <AppLayout pageTitle={"Segments"}>
                        <SegmentHomePage />
                      </AppLayout>
                    </RequireAuthWrapper>
                  }
                />
                <Route
                  path={"new"}
                  element={
                    <RequireAuthWrapper>
                      <AppLayout pageTitle={"New Segment"}>
                        <SegmentSetupPage />
                      </AppLayout>
                    </RequireAuthWrapper>
                  }
                />
                <Route path={":id"}>
                  <Route
                    path={""}
                    element={
                      <RequireAuthWrapper>
                        <AppLayout pageTitle={"Summary"}>
                          <SegmentSummaryPage />
                        </AppLayout>
                      </RequireAuthWrapper>
                    }
                  />
                </Route>
              </Route>
            </Route>
            <Route path={"login"} element={<LoginPage />} />
            <Route path={"password-reset"}>
              <Route
                path={""}
                element={
                  <AppLayout pageTitle={"Password Reset"}>
                    <ResetPassword />
                  </AppLayout>
                }
              />
              <Route
                path={"done"}
                element={
                  <AppLayout pageTitle={"Done"}>
                    <ResetPasswordDone />
                  </AppLayout>
                }
              />
            </Route>
            <Route
              path={"404"}
              element={
                <AppLayout pageTitle={"404 Not Found"}>
                  <NotFound />
                </AppLayout>
              }
            />
            <Route
              path={"terms-of-service"}
              element={
                <AppLayout pageTitle={"Illuma Technology Terms of Service"}>
                  <TermsOfService />
                </AppLayout>
              }
            />
            <Route
              path={"download-json"}
              element={
                <AppLayout pageTitle={""}>
                  <DownloadJson />
                </AppLayout>
              }
            />
            <Route
              path={"*"}
              element={<Navigate to={"/404"} replace={true} />}
            />
          </Routes>
        </ChakraProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};
