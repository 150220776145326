export default {
  background: "#EAF0F2",
  primary: {
    lighter: "#C2E6E95A",
    light: "#C2E6E9",
    dark: "#66C2C9",
    darker: "#009AA7",
  },
  secondary: "#126BFA",
  accent: "#731CD9",
  accentLight: "#AD89D6",
  error: "#D81111",
  muted: "#93A5B7",
  text: {
    dark: "#1A2938",
    light: "#4D657E",
  },
  link: "#4D657E",
  inactive: "#CAD3DD",
  submitGreen: "#22D081",
  lightGreen: "#E8FAF2",
  cancelRed: "#FF5772",

  dataColors: {
    l0: "#EAF0F2", // Background color to represent missing data
    l1: "#C2E6E9",
    l2: "#a8e0e5",
    l3: "#91dbe1",
    l4: "#75d5dd",
    l5: "#51cfda",
    l6: "#02c9d6",
    l7: "#07AFBA"
  },
  distinctDataColors: {
    red: "#FF5B63",
    green: "#19A979",
    blue: "#5899DA",
    orange: "#F58231",
    purple: "#945ECF",
    magenta: "#BF399E",
    lime: "#CFD95A",
    pink: "#BF399E",
    teal: "#C8FAF1",
    yellow: "#F7F1A8",
    lavender: "#BDABDA",
    brown: "#AB8B7B",
    beige: "#BDD081",
    maroon: "#924A5B",
    mint: "#D3F7E0",
    olive: "#8E9C61",
    apricot: "#EE6868",
    cyan: "#13A4B4",
    navy: "#2F6497",
    grey: "#6C8893",
    gold: "#E7D27C",
  }
};

// Raquel Original
// dataColors: {
//   l0: "#EAF0F2", // Background color to represent missing data
//     l1: "#C2E6E9",
//     l2: "#66C2C9",
//     l3: "#41ACDB",
//     l4: "#126BFA",
//     l5: "#134DAC",
//     l6: "#1A2938",
// },

// Pink Original Certainly Not
// l1: "#fde8ff",
//   l2: "#fbcaf7",
//   l3: "#fbaaeb",
//   l4: "#fc87da",
//   l5: "#fe5cc5",
//   l6: "#ff08ac",
