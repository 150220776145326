import {
  Box,
  BoxProps,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Tag,
  TagLabel,
  Text
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { AiFillEdit } from "react-icons/ai";
import { formatTextDisplay } from "../../utils/displayGetters";

export const DetailFieldListBox = ({
  label,
  value,
  format = false,
  openModal,
  setModalOption,
  useTag = false,
  ...rest
}: {
  label: string;
  value?: any;
  format?: boolean;
  openModal?: () => void;
  setModalOption?: Dispatch<SetStateAction<string>>;
  useTag?: boolean
} & BoxProps) => {
  return (
    <Grid
      templateColumns="repeat(8, 1fr)"
      gap={6}
      mb={4}
      alignItems={"start"}
      {...rest}
    >
      <GridItem colSpan={3}>
        <Text
          mr={1}
          color={"primary.darker"}
          fontWeight={"bold"}
          fontSize={"sm"}
        >
          {label}
        </Text>
      </GridItem>
      <GridItem colSpan={5}>
        {value && value.constructor === Array ? (
            <Box
                px={2}
                pt={1}
                maxHeight={200}
                overflowY={"scroll"}
                borderColor={"primary.dark"}
                borderWidth={"2px"}
                borderRadius={"lg"}
            >
                {value.map((v, ix) => {
                  if (useTag) {
                    return (
                      <Tag
                        key={ix}
                        size={"sm"}
                        bg={"primary.light"}
                        colorScheme="teal"
                        borderRadius={"full"}
                        mx={1}
                      >
                        <TagLabel>
                            {format ? formatTextDisplay(v) : v}
                        </TagLabel>
                      </Tag>
                    )
                  } else {
                    return (
                      <Text key={v + ix} mr={1} pb={1}>
                          {format ? formatTextDisplay(v) : v}
                      {/* <span
                          style={{
                          visibility: ix !== value.length - 1 ? "visible" : "hidden",
                          }}
                      >
                          ,
                      </span> */}
                      </Text>
                    )
                  }
                })}
            </Box>
        ) : (
          <HStack spacing={"5"}>
            <Text wordBreak={"break-all"}>
              {format ? formatTextDisplay(value) : value}
            </Text>
            {
              (openModal && setModalOption) &&
              <IconButton
                aria-label={"Edit"}
                m={0}
                variant={"unStyled"}
                icon={<AiFillEdit />}
                color={"primary.darker"}
                size={"sm"}
                onClick={() => {
                  setModalOption(label)
                  openModal()
                }}
              />
            }
          </ HStack>
        )}
      </GridItem>
    </Grid>
  );
};
