import { useNavigate } from "react-router-dom";
import { useAuth } from "../../lib/auth";
import { useReactiveVar } from "@apollo/client";
import { userDetailsVar } from "../../lib/cache";
import { Collapse } from "@chakra-ui/react";
import { AccountMenu } from "../accountMenu/AccountMenu";

export const AccountArea = () => {

  const navigate = useNavigate();
  const { signOut } = useAuth();

  const signOutAndGoToLogin = () => {
    signOut();
    navigate("/login");
  };

  const userDetails = useReactiveVar(userDetailsVar);

  const accountMenuItems = [
    { label: "Sign out", action: () => signOutAndGoToLogin() }
  ];

  return (
    <Collapse in={!!userDetails}>

      <AccountMenu
        items={accountMenuItems}
        isStaff={userDetails?.isStaff as boolean}
        userName={`${userDetails?.firstName ?? "Illuma"} ${userDetails?.lastName ?? "User"
          }`}
        px={2} py={2}
      />
    </Collapse>
  );
};

/** While In Beta **/
// const getFeedbackEmailLink = () => {
//   const feedbackMail = "illumaland@weareilluma.com";
//   const feedbackSubject = "illumaland Feedback";
//
//   return `mailto:${encodeURI(feedbackMail)}?subject=${encodeURI(
//     feedbackSubject
//   )}`;
// };
