import { useEffect, useState } from "react";
import { SelectField } from "../../../components/selectField/SelectField";
import { Box, Button, HStack } from "@chakra-ui/react";
import { InsightsDateRangePresetsEnum } from "../../../graphql/generated";
import { formatTextDisplay } from "../../../utils/displayGetters";
import { insightsDatesVar } from "../../../lib/cache";
import { gql, useApolloClient, useReactiveVar } from "@apollo/client";
import { DatePicker } from "../../../components/datePicker/DatePicker";
import { Field, FieldProps, Form, Formik } from "formik";
import * as yup from "yup";
import { useParams } from "react-router-dom";

export const InsightsDateRangeSelector = () => {
  const dateRange = useReactiveVar(insightsDatesVar);

  const [showCustomDate, setShowCustomDate] = useState(false);
  const defaultDateRange = {
    label: formatTextDisplay(dateRange.preset ?? ""),
    value: dateRange.preset ?? "",
  };
  useEffect(() => {
    if (!dateRange.preset) {
      insightsDatesVar({ preset: InsightsDateRangePresetsEnum.Last_14Days });
    }
  }, []);

  const showDefaultDate = (defaultDate: {label: string, value: string}) => {
    if(defaultDate.label !== "undefined") {
      return defaultDate;
    } else {
      return {label: "Last 14 Days", value: "LAST_14_DAYS"};
    }
  }
  return (
    <HStack>
      <Box minW={"12rem"}>
        <SelectField
          options={insightDateRangeOptions}
          defaultValue={showDefaultDate(defaultDateRange)}
          onChange={({ value }) => {
            if (value !== "CUSTOM") {
              insightsDatesVar({ preset: value });
              setShowCustomDate(false);
            } else {
              setShowCustomDate(true);
            }
          }}
        />
      </Box>
      {showCustomDate && <InsightsCustomDateRangeSelector />}
    </HStack>
  );
};

const insightDateRangeOptions = [
  ...Object.values(InsightsDateRangePresetsEnum).map((value: string) => ({
    label: formatTextDisplay(value),
    value,
  })),
  { label: "Custom Range", value: "CUSTOM" },
];

const InsightsCustomDateRangeSelector = () => {
  const client = useApolloClient();
  const { id: campaignId } = useParams();
  const campaignDates = client.readFragment({
    id: `CampaignType:${campaignId}`,
    fragment: gql`
      fragment CampaignDates on CampaignType {
        startDate
        endDate
      }
    `,
  });
  const schema = yup.object({
    startDate: yup.date().required(),
    endDate: yup.date().required(),
  });

  return (
    <Formik
      initialValues={{
        startDate: "",
        endDate: "",
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        insightsDatesVar(values);
      }}
    >
      {({ isValid, touched, dirty, values }) => {
        const startDateLimits = {
          start: new Date(campaignDates.startDate),
          end: new Date(values.endDate || campaignDates.endDate),
        };
        const endDateLimits = {
          start: new Date(values.startDate || campaignDates.startDate),
          end: new Date(campaignDates.endDate),
        };
        return (
          <Form>
            <HStack>
              <HStack maxW={"24rem"}>
                <Field name={"startDate"}>
                  {({ field, form }: FieldProps) => {
                    return (
                      <DatePicker
                        inputProps={{ field, form }}
                        placeholder={"From"}
                        dateLimits={startDateLimits}
                      />
                    );
                  }}
                </Field>
                <Field name={"endDate"}>
                  {({ field, form }: FieldProps) => {
                    return (
                      <DatePicker
                        inputProps={{ field, form }}
                        placeholder={"To"}
                        dateLimits={endDateLimits}
                      />
                    );
                  }}
                </Field>
              </HStack>
              {isValid && !!touched && dirty && (
                <Button
                  size={"sm"}
                  variant={"primary"}
                  type={"submit"}
                  flexGrow={1}
                >
                  Ok
                </Button>
              )}
            </HStack>
          </Form>
        );
      }}
    </Formik>
  );
};
