import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      token
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query GetUserDetails {
    me {
      id
      firstName
      lastName
      isStaff
      role
      agency {
        hitlPermission {
          isPermitted
          urlSource
        }
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($userEmail: String!) {
    passwordReset(userEmail: $userEmail) {
      response
    }
  }
`;
