import { Icon } from "@chakra-ui/react";

export const ClicksIcon = (props: Record<string, any>) => (
  <Icon
    viewBox={"0 0 20.956 20.954"}
    sx={{
      ".b": {
        clipPath: "url(#a)",
      },
    }}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <rect className="a" width="20.847" height="20.847" />
      </clipPath>
    </defs>
    <g transform="translate(106 216)">
      <g transform="translate(-105.893 -215.893)">
        <g className="b" transform="translate(0.002 0)">
          <path
            className="a"
            d="M76.33-41.779l-12.893-5.6a.616.616,0,0,0-.682.13.617.617,0,0,0-.13.682l5.6,12.893a.617.617,0,0,0,.566.371l.056,0a.617.617,0,0,0,.546-.474l1.3-5.539,5.539-1.3a.617.617,0,0,0,.474-.546.617.617,0,0,0-.369-.621Zm-6.3,1.349a.617.617,0,0,0-.46.46l-.959,4.1-4.233-9.749,9.749,4.232Zm0,0"
            transform="translate(-55.746 54.254)"
          />
        </g>
      </g>
      <path
        className="a"
        d="M74.617-211.064a.617.617,0,0,0,.617-.617v-3.7a.617.617,0,0,0-.617-.617.617.617,0,0,0-.617.617v3.7a.617.617,0,0,0,.617.617Zm0,0"
        transform="translate(-172.595)"
      />
      <path
        className="a"
        d="M-50.48,27.822-53.1,30.439a.617.617,0,0,0,0,.873.617.617,0,0,0,.873,0l2.618-2.618a.617.617,0,0,0,0-.873.617.617,0,0,0-.873,0Zm0,0"
        transform="translate(-50.553 -233.618)"
      />
      <path
        className="a"
        d="M138.693-159.607l2.618-2.618a.617.617,0,0,0,0-.873.617.617,0,0,0-.873,0l-2.618,2.618a.617.617,0,0,0,0,.873.617.617,0,0,0,.873,0Zm0,0"
        transform="translate(-233.617 -50.553)"
      />
      <path
        className="a"
        d="M-52.225-163.1a.617.617,0,0,0-.873,0,.617.617,0,0,0,0,.873l2.618,2.618a.617.617,0,0,0,.873,0,.617.617,0,0,0,0-.873Zm0,0"
        transform="translate(-50.553 -50.553)"
      />
      <path
        className="a"
        d="M-101.064-35.383a.617.617,0,0,0-.617-.617h-3.7a.617.617,0,0,0-.617.617.617.617,0,0,0,.617.617h3.7a.617.617,0,0,0,.617-.617Zm0,0"
        transform="translate(0 -172.595)"
      />
    </g>
  </Icon>
);
