import * as React from "react";
import { useAuth } from "../lib/auth";
import { Navigate, useLocation } from "react-router-dom";

export const RequireAuthWrapper: React.FC = ({ children }) => {
  const { isSignedIn } = useAuth();
  const location = useLocation();

  if (!isSignedIn) {
    return <Navigate to={"/login"} state={{ destination: location }} />;
  } else {
    return <>{children}</>;
  }
};
