
import { FormDataClass } from "../../../utils/formsSchema/FormDataClass";
import { DataTypes } from "json-schema-yup-transformer/dist/schema";


export class SegmentFormDataClass extends FormDataClass {
    constructor(staffCreator = false) {
        super();
        this.fields = {
            ...this.getBasicDetails(),
            ...(staffCreator ? this.getUserDetailsFields() : {}),
        };
    }

    getUserDetailsFields() {
        return {
            packageOwnerEmail: {
                type: DataTypes.STRING,
                format: "email",
                inputFormat: "email",
                label: "Segment Owner Email Address",
                placeholder: "Type the segment owners email address",
                isRequired: true,
                default: "",
                errors: {
                    required: "This field is required.",
                    format: {
                    email: "Please enter a valid email address.",
                    },
                },
            },
        };
        }

    getBasicDetails() {
        return {
        name: {
            type: DataTypes.STRING,
            label: "Segment Name",
            placeholder: "Type the name of the segment",
            isRequired: true,
            default: "",
            errors: {
            required: "The segment name is required.",
            },
        },
        startDate: {
            type: DataTypes.STRING,
            format: "date",
            label: "Start Date",
            isRequired: true,
            default: "",
            placeholder: "Choose",
            errors: {
            required: "Please specify a start date for the segment.",
            },
        },
        endDate: {
            type: [DataTypes.STRING, DataTypes.NULL],
            format: "date",
            label: "End Date",
            isRequired: false,
            default: null,
            placeholder: "Choose",
          },
          expansionCategories: {
            type: [DataTypes.ARRAY, DataTypes.NULL],
            items: {
              type: DataTypes.NUMBER,
              // enum: Object.values(InsightCategoryChoices),
            },
            isRequired: false,
            label: "Precision Categories",
            placeholder: "Type or select precision categories",
            default: [],
            minItems: 0,
            errors: {
              required: "Please select at least one precision category.",
            },
          },
        };
    }

}
