import { HStack, Tag, TagLabel, Text } from "@chakra-ui/react";
import { getStatusColor } from "../../utils/displayGetters";
import { useCampaignInfo } from "./index";

export const CampaignStatusDisplay = () => {
  const { campaign } = useCampaignInfo();
  return (
    <HStack px={10} pt={2}>
      <Text fontWeight={"bold"} fontSize={"sm"} color={"primary.darker"}>
        Campaign Status
      </Text>
      <Tag
        size={"sm"}
        bg={getStatusColor(campaign.status)}
        colorScheme="teal"
        borderRadius={"full"}
      >
        <TagLabel>{campaign.status.toLowerCase()}</TagLabel>
      </Tag>
    </HStack>
  );
};
