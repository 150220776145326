
import { useReactiveVar } from "@apollo/client";
import {
    Box,
    BoxProps,
} from "@chakra-ui/react";
import { Field, FieldProps, FormikProps, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { usePrecisionCategoriesOptions } from "..";
import { CheckboxOptionType } from "../../../components/checkboxTree/CheckboxTreeV3";
import { DatePicker } from "../../../components/datePicker/DatePicker";
import { DropdownCheckboxTree } from "../../../components/dropdownCheckboxTree/DropdownCheckboxTree";
import { FormFieldLabel } from "../../../components/formFieldLabel/FormFieldLabel";
import { FormInputField } from "../../../components/formInputField/FormInputField";
import { FormSubSectionHeader } from "../../../components/formSubSectionHeader/FormSubSectionHeader";
import { AccountsAgencyHitlPermissionPartnerChoices } from "../../../graphql/generated";
import { userDetailsVar } from "../../../lib/cache";
import { IFormDataClassField } from "../../../utils/formsSchema/FormDataClass";

interface Props {
    fields: Record<string, IFormDataClassField>;
    formik: FormikProps<FormikValues>;
    defaultValues: Record<string, any>;
}

export const SegmentDetails = ({
    formik,
    fields,
    defaultValues,
    ...boxProps
}: Props & BoxProps) => {

    const {
      name,
      startDate,
      endDate,
      expansionCategories,
    } = fields;

    const currentUser = useReactiveVar(userDetailsVar);
    const precisionCategoriesList = usePrecisionCategoriesOptions();
    const [selectedExpansionOptions, setSelectedExpansionOptions] = useState<CheckboxOptionType[]>([]);

    useEffect(() => {
      const expansionIds = selectedExpansionOptions.map((option) => option.id);
      formik.setFieldValue(
        "expansionCategories",
        expansionIds
      );
    }, [selectedExpansionOptions])

    return (
      <Box p={8} bg={"white"} borderRadius={"md"} {...boxProps}>
        <FormSubSectionHeader label={"Segment Details"}/>

        <Field name={"name"}>
          {({ field, form }: FieldProps) => (
            <FormInputField
              mt={0}
              inputFieldProps={field}
              fieldType={"text"}
              isRequired={name.isRequired}
              label={name.label}
              isInvalid={!!(form.errors.name && !!form.touched.name)}
              placeholder={name.placeholder}
              errorMessage={form.errors?.name?.toString()}
            />
          )}
        </Field>

        {
          (
            currentUser?.isStaff ||
            currentUser?.agency.hitlPermission.partner === AccountsAgencyHitlPermissionPartnerChoices.NewsUk
          ) &&
            <Field name={"precisionCategories"}>
              {({ field, form }: FieldProps) => {

                const onMenuClose = () => {
                    form.setFieldTouched(field.name, true, true);
                };

                return (
                  <FormFieldLabel
                    label={expansionCategories.label}
                    isRequired={expansionCategories.isRequired}
                    isInvalid={
                    !!(
                        form.errors["expansionCategories"] && !!form.touched["expansionCategories"]
                    )
                    }
                    errorMessage={form.errors?.expansionCategories?.toString()}
                    // toolTipComponent={<PrecisionCategoriesToolTip />}
                  >
                    <DropdownCheckboxTree
                      checkboxOptions={precisionCategoriesList.precisionCategoriesOptions}
                      selectedOptions={selectedExpansionOptions}
                      setSelectedOptions={setSelectedExpansionOptions}
                      onPopoverClose={onMenuClose}
                      limitOptions={4} // Upto tier 4
                      handleChildren={true}
                      handleParents={true}
                    />
                  </FormFieldLabel>
                );
              }}
            </Field>
        }

        <Field name={"startDate"}>
          {({ field, form }: FieldProps) => {
            return (
              <FormFieldLabel
                label={startDate.label}
                isRequired={startDate.isRequired}
                errorMessage={form.errors?.startDate?.toString()}
                isInvalid={
                  !!(form.errors["startDate"] && !!form.touched["startDate"])
                }
              >
                <DatePicker
                  inputProps={{ field, form }}
                  placeholder={startDate.placeholder}
                  dateLimits={{ start: new Date() }}
                />
              </FormFieldLabel>
            );
          }}
        </Field>

        <Field name={"endDate"}>
          {({ field, form }: FieldProps) => {
            return (
              <FormFieldLabel
                toolTip={"Leave end date empty if segment will always be on."}
                label={endDate.label}
                isRequired={endDate.isRequired}
                errorMessage={form.errors?.endDate?.toString()}
                isInvalid={
                  !!(form.errors["endDate"] && !!form.touched["endDate"])
                }
              >
                <DatePicker
                  inputProps={{ field, form }}
                  placeholder={endDate.placeholder}
                  dateLimits={{ start: new Date() }}
                />
              </FormFieldLabel>
            );
          }}
        </Field>


      </Box>
    );
  };
