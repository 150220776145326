import { Button, Flex, FlexProps, IconButton } from "@chakra-ui/react"
import { Dispatch, SetStateAction } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

interface PaginationBarProps extends FlexProps {
    currentPageIndex: number,
    totalPageCount: number,
    setPage: Dispatch<SetStateAction<number>>
}

export const PaginationBar = ({
    currentPageIndex,
    totalPageCount,
    setPage,
    ...rest
}: PaginationBarProps) => {

    const displayPageNumber = currentPageIndex + 1;
    return (
        <Flex {...rest}>
            <IconButton
                variant={"pagination"}
                aria-label={"Back"}
                icon={<IoIosArrowBack />}
                onClick={() => setPage(currentPageIndex - 1)}
                disabled={currentPageIndex === 0}
            />
            <Button
                variant={"pagination"}
                hidden={currentPageIndex <= 1}
                onClick={() => setPage(0)}
            >
                1
            </Button>
            <Button
                variant={"pagination"}
                hidden={currentPageIndex <= 2}
                onClick={() => setPage(currentPageIndex - 2)}
            >
                ...
            </Button>
            <Button
                variant={"pagination"}
                hidden={currentPageIndex === 0}
                onClick={() => setPage(currentPageIndex - 1)}
            >
                {displayPageNumber - 1}
            </Button>
            <Button
                variant={"pagination"}
                color={"primary.darker"}
                onClick={() => setPage(currentPageIndex)}
            >
                {displayPageNumber}
            </Button>
            <Button
                variant={"pagination"}
                hidden={currentPageIndex === totalPageCount - 1}
                onClick={() => setPage(currentPageIndex + 1)}
            >
                {displayPageNumber + 1}
            </Button>
            <Button
                variant={"pagination"}
                hidden={currentPageIndex >= totalPageCount - 3}
                onClick={() => setPage(currentPageIndex + 2)}
            >
                ...
            </Button>
            <Button
                variant={"pagination"}
                hidden={currentPageIndex >= totalPageCount - 2}
                onClick={() => setPage(totalPageCount -1)}
            >
                {totalPageCount}
            </Button>
            <IconButton
                variant={"pagination"}
                aria-label={"Next"}
                icon={<IoIosArrowForward />}
                onClick={() => setPage(currentPageIndex + 1)}
                disabled={currentPageIndex === totalPageCount - 1}
            />
        </Flex>
    )
}
