export type CountryOptionType = {
  label: string;
  value: string;
};

export type CountryGroupType = {
  label: string;
  options: CountryOptionType[];
};
export const getCountries = (format: "values" | "normal" = "normal") => {
  const groups: CountryGroupType[] = [
    {
      label: "",
      options: [
        {
          label: "United Kingdom",
          value: "GB",
        },
        { label: "United States", value: "US" },
        { label: "Spain", value: "ES" },
        { label: "Canada", value: "CA" },
      ],
    },
  ];

  const extractValues = (objectList: CountryOptionType[]) => {
    return objectList.reduce(
      (accumulator, { value }) => [...accumulator, value],
      []
    );
  };

  const countries: CountryOptionType[] = [
    { label: "Afghanistan", value: "AF" },
    {
      label: "Albania",
      value: "AL",
    },
    { label: "Algeria", value: "DZ" },
    {
      label: "Andorra",
      value: "AD",
    },
    { label: "Angola", value: "AO" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    {
      label: "Armenia",
      value: "AM",
    },
    { label: "Australia", value: "AU" },
    {
      label: "Austria",
      value: "AT",
    },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    {
      label: "Bahrain",
      value: "BH",
    },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    {
      label: "Belarus",
      value: "BY",
    },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    {
      label: "Benin",
      value: "BJ",
    },
    {
      label: "Bhutan",
      value: "BT",
    },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    {
      label: "Botswana",
      value: "BW",
    },
    {
      label: "Brazil",
      value: "BR",
    },
    {
      label: "Brunei",
      value: "BN",
    },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    {
      label: "Burundi",
      value: "BI",
    },

    { label: "Cambodia", value: "KH" },
    {
      label: "Cameroon",
      value: "CM",
    },

    { label: "Cape Verde", value: "CV" },

    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    {
      label: "Chile",
      value: "CL",
    },
    { label: "China", value: "CN" },
    {
      label: "Colombia",
      value: "CO",
    },
    { label: "Comoros", value: "KM" },
    {
      label: "Congo",
      value: "CG",
    },
    { label: "Democratic Republic of the Congo", value: "CD" },
    { label: "Costa Rica", value: "CR" },
    { label: "Côte d'Ivoire", value: "CI" },
    {
      label: "Croatia",
      value: "HR",
    },
    { label: "Cuba", value: "CU" },
    {
      label: "Cyprus",
      value: "CY",
    },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    {
      label: "Djibouti",
      value: "DJ",
    },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    {
      label: "Ecuador",
      value: "EC",
    },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    {
      label: "Equatorial Guinea",
      value: "GQ",
    },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },

    { label: "Ethiopia", value: "ET" },
    { label: "Fiji", value: "FJ" },
    {
      label: "Finland",
      value: "FI",
    },
    { label: "France", value: "FR" },
    {
      label: "Gabon",
      value: "GA",
    },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    {
      label: "Germany",
      value: "DE",
    },
    { label: "Ghana", value: "GH" },
    {
      label: "Greece",
      value: "GR",
    },
    { label: "Greenland", value: "GL" },
    { label: "Grenada", value: "GD" },
    { label: "Guatemala", value: "GT" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    {
      label: "Guyana",
      value: "GY",
    },
    { label: "Haiti", value: "HT" },
    { label: "Honduras", value: "HN" },
    {
      label: "Hong Kong",
      value: "HK",
    },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    {
      label: "India",
      value: "IN",
    },
    { label: "Indonesia", value: "ID" },
    {
      label: "Iran",
      value: "IR",
    },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },

    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    {
      label: "Jamaica",
      value: "JM",
    },
    { label: "Japan", value: "JP" },
    {
      label: "Jordan",
      value: "JO",
    },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    {
      label: "Kiribati",
      value: "KI",
    },
    { label: "North Korea", value: "KP" },
    {
      label: "South Korea",
      value: "KR",
    },
    { label: "Kuwait", value: "KW" },
    {
      label: "Kyrgyzstan",
      value: "KG",
    },
    {
      label: "Latvia",
      value: "LV",
    },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    {
      label: "Liberia",
      value: "LR",
    },
    { label: "Libya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    {
      label: "Lithuania",
      value: "LT",
    },
    { label: "Luxembourg", value: "LU" },
    { label: "Macedonia", value: "MK" },
    {
      label: "Madagascar",
      value: "MG",
    },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    {
      label: "Maldives",
      value: "MV",
    },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    {
      label: "Marshall Islands",
      value: "MH",
    },
    { label: "Mauritania", value: "MR" },
    {
      label: "Mauritius",
      value: "MU",
    },
    {
      label: "Mexico",
      value: "MX",
    },
    { label: "Micronesia", value: "FM" },
    {
      label: "Republic of Moldova",
      value: "MD",
    },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    {
      label: "Montenegro",
      value: "ME",
    },
    { label: "Morocco", value: "MA" },
    {
      label: "Mozambique",
      value: "MZ",
    },
    { label: "Myanmar", value: "MM" },
    { label: "Namibia", value: "NA" },
    {
      label: "Nauru",
      value: "NR",
    },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },

    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    {
      label: "Niger",
      value: "NE",
    },
    { label: "Nigeria", value: "NG" },

    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    {
      label: "Pakistan",
      value: "PK",
    },
    { label: "Palau", value: "PW" },
    { label: "Palestine, State of", value: "PS" },
    {
      label: "Panama",
      value: "PA",
    },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    {
      label: "Peru",
      value: "PE",
    },
    { label: "Philippines", value: "PH" },
    {
      label: "Poland",
      value: "PL",
    },
    { label: "Portugal", value: "PT" },
    { label: "Puerto Rico", value: "PR" },
    {
      label: "Qatar",
      value: "QA",
    },
    { label: "Romania", value: "RO" },
    {
      label: "Russia",
      value: "RU",
    },
    { label: "Rwanda", value: "RW" },

    {
      label: "Saint Kitts and Nevis",
      value: "KN",
    },
    { label: "Saint Lucia", value: "LC" },
    {
      label: "Saint Vincent and the Grenadines",
      value: "VC",
    },
    { label: "Samoa", value: "WS" },
    {
      label: "San Marino",
      value: "SM",
    },
    { label: "Sao Tome and Principe", value: "ST" },
    {
      label: "Saudi Arabia",
      value: "SA",
    },
    { label: "Senegal", value: "SN" },
    { label: "Serbia", value: "RS" },
    {
      label: "Seychelles",
      value: "SC",
    },
    { label: "Sierra Leone", value: "SL" },
    {
      label: "Singapore",
      value: "SG",
    },
    {
      label: "Slovakia",
      value: "SK",
    },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    {
      label: "Somalia",
      value: "SO",
    },
    { label: "South Africa", value: "ZA" },
    { label: "South Sudan", value: "SS" },

    {
      label: "Sri Lanka",
      value: "LK",
    },
    { label: "Sudan", value: "SD" },
    { label: "Suriname", value: "SR" },
    { label: "Sweden", value: "SE" },
    {
      label: "Switzerland",
      value: "CH",
    },
    { label: "Syria", value: "SY" },
    {
      label: "Taiwan",
      value: "TW",
    },
    { label: "Tajikistan", value: "TJ" },
    {
      label: "Tanzania",
      value: "TZ",
    },
    { label: "Thailand", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    {
      label: "Togo",
      value: "TG",
    },
    { label: "Tonga", value: "TO" },
    {
      label: "Trinidad and Tobago",
      value: "TT",
    },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    {
      label: "Turkmenistan",
      value: "TM",
    },
    {
      label: "Tuvalu",
      value: "TV",
    },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    {
      label: "United Arab Emirates",
      value: "AE",
    },

    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    {
      label: "Vanuatu",
      value: "VU",
    },
    { label: "Venezuela", value: "VE" },
    {
      label: "Vietnam",
      value: "VN",
    },
    { label: "Virgin Islands (British)", value: "VG" },
    {
      label: "Virgin Islands (U.S.)",
      value: "VI",
    },
    { label: "Wallis and Futuna", value: "WF" },
    {
      label: "Western Sahara",
      value: "EH",
    },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    {
      label: "Zimbabwe",
      value: "ZW",
    },
  ];

  const countryOptions = [...groups, ...countries];

  if (format === "values") {
    // Return a list of country codes
    let result: string[] = [];
    countryOptions.forEach((country) => {
      if (Object.prototype.hasOwnProperty.call(country, "options")) {
        result = [
          ...result,
          ...extractValues((country as CountryGroupType).options),
        ];
      } else {
        result = [...result, (country as CountryOptionType).value];
      }
    });
    return result;
  } else {
    // Return the default {countryLabel: countryCode}
    return countryOptions;
  }
};
