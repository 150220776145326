import { Box, HStack, Icon, Spacer, Text } from "@chakra-ui/react";
import { RiCheckLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

interface SidebarItemProps {
    title: string
    url?: string
    isComplete?: boolean
    isSubItem?: boolean
    removeTopBorder?: boolean
}

export const SidebarItem = ({
    title,
    url,
    isComplete = false,
    isSubItem = false,
    removeTopBorder = false,
}: SidebarItemProps) => {

    const isCurrentCategory: boolean = url === location.pathname.split("/").pop();
    const navigate = useNavigate();

    return (
        <Box
            borderTopColor={removeTopBorder ? "transparent" : "muted"}
            borderTopWidth={"1px"}
            bg={isCurrentCategory ? "primary.dark" : "transparent"}
            color={"text.dark"}
            px={6}
            py={4}
            cursor={"pointer"}
            _hover={{
                bg: "primary.dark",
                color: "text.dark",
            }}
            onClick={() => {
                url && navigate(url);
            }}
        >
            <HStack>
                <Text
                    fontSize={"14px"}
                    fontWeight={isSubItem ? "semibold" : "bold"}
                    pl={isSubItem ? 4 : 0}
                    color={"text.dark"}
                >
                    {title}
                </Text>
                <Spacer />
                {isComplete && <Icon as={RiCheckLine} h={5} w={5}/>}
            </HStack>
        </Box>
    )
}
