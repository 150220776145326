import { Box, BoxProps, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { SectionTitle } from "../SectionTitle";
import { formatNumberDisplay } from "../../../utils/displayGetters";
import { Link } from "react-router-dom";
import { useMetricSuffix } from "../hooks";

interface Props {
  categories: { category: string; metric: number }[];
  title: string;
  rankingType?: string;
}

export const CategoryRankBox = ({
  categories,
  title,
  rankingType,
  ...rest
}: Props & BoxProps) => {
  const suffix = useMetricSuffix();
  return (
    <Flex direction={"column"} {...rest}>
      <SectionTitle title={title} />
      <Box bg={"white"} borderRadius={"lg"}>
        {categories.map(({ category, metric }, ix) => (
          <Link to={`../details?cat=${category}`} key={`${category}=${ix}`}>
            <Grid
              templateColumns={"repeat(8, 1fr)"}
              borderBottomWidth={"1px"}
              borderBottomColor={
                ix < categories.length - 1 ? "muted" : "transparent"
              }
              px={4}
              py={2}
              fontWeight={"bold"}
              color={"text.dark"}
            >
              <GridItem colSpan={1}>
                <Text fontSize={"18px"}>{ix + 1}</Text>
              </GridItem>
              <GridItem colSpan={6} _hover={{ color: "link" }}>
                <Text fontSize={"14px"}>{category}</Text>
              </GridItem>
              <GridItem colSpan={1} color={getRankingTextColor(rankingType)}>
                <Text fontSize={"14px"}>
                  {formatNumberDisplay(metric, suffix)}
                </Text>
              </GridItem>
            </Grid>
          </Link>
        ))}
      </Box>
    </Flex>
  );
};

const getRankingTextColor = (rankingType?: string) => {
  switch (rankingType) {
    case "top":
      return "green.400";

    case "bottom":
      return "red.400";

    default:
      return "inherit";
  }
};
