export const getDateRange = (startDate: string, endDate: string) => {
  const dateList = [];
  const dateFlag = new Date(startDate);
  let strDate = startDate;

  while (strDate < endDate) {
    strDate = dateFlag.toISOString().slice(0, 10);
    dateList.push(strDate);
    dateFlag.setDate(dateFlag.getDate() + 1);
  }
  return Array.from(new Set(dateList));
};
