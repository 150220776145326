import { Box, BoxProps } from "@chakra-ui/react"
import { Dispatch, SetStateAction } from "react";
import { FileWithPath } from "react-dropzone";
import { FileUpload } from "../../../components/fileUpload/FileUpload";
import { FormFieldLabel } from "../../../components/formFieldLabel/FormFieldLabel"
import { FormSubSectionHeader } from "../../../components/formSubSectionHeader/FormSubSectionHeader"

export type UploadFileType = {
    keywordsExc: FileWithPath[]
}

interface KeywordsFileProps {
    keywordsExcFile: UploadFileType;
    setKeywordsExcFile: Dispatch<SetStateAction<UploadFileType>>;
}

export const KeywordsFile = ({
    keywordsExcFile,
    setKeywordsExcFile,
    ...boxProps
}: KeywordsFileProps & BoxProps) => {

    const acceptedFileTypes =
        "text/plain, application/gzip";

    const setFiles = (name: string, files: FileWithPath[]) => {
        setKeywordsExcFile(prevFiles => ({ ...prevFiles, [name]: files }))
    }

    return (
        <Box p={8} bg={"white"} borderRadius={"md"} {...boxProps}>
            <FormSubSectionHeader label={"Upload Keywords"} />
            <FormFieldLabel
                mt={0}
                label={"Keyword exclusion list"}
                subLabel={"Import only .txt"}
                isRequired={true}
                toolTip={"Each line in the .txt file will be considered a keyword."}
            >
              <FileUpload
                name={"keywordsExc"}
                acceptedFileTypes={acceptedFileTypes}
                maxFiles={1}
                setFiles={setFiles}
              />
            </FormFieldLabel>
        </Box>
    )

}
