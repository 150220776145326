import {
    Box,
    FormControl,
    FormErrorMessage,
    Grid,
    GridItem,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Tag,
    TagCloseButton,
    TagLabel
} from "@chakra-ui/react"
import { Dispatch, SetStateAction, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdClear } from "react-icons/md";

interface MultiTextInputProps {
    selectedInputs: string[],
    setSelectedInputs: Dispatch<SetStateAction<string[]>>,
    placeholder?: string
}

export const MultiTextInput = ({
    selectedInputs,
    setSelectedInputs,
    placeholder
}: MultiTextInputProps) => {

    const [isFocus, setIsFocus] = useState(false);
    const [textInput, setTextInput] = useState("");

    return (
        <FormControl isInvalid={(!isFocus && textInput.length > 0)}>
            <Box
                borderRadius={"lg"}
                borderWidth={isFocus ? 2 : 1}
                borderColor={
                    isFocus ? "primary.dark" : ((!isFocus && textInput.length > 0) ? "red" : "muted")
                }
            >
                <Grid
                    templateColumns={"repeat(24, 1fr)"}
                    w={"100%"}
                >
                    <GridItem colSpan={23}>
                        {selectedInputs.map((item, ix) => {
                            return (
                                <Tag
                                    key={ix}
                                    size={"sm"}
                                    variant={"solid"}
                                    backgroundColor={"primary.dark"}
                                    _hover={{backgroundColor: "primary.light"}}
                                    color={"text.dark"}
                                    borderRadius={14}
                                    m={1}
                                    py={1}
                                    px={2}
                                >
                                    <TagLabel isTruncated={false}>
                                        {item}
                                    </TagLabel>
                                    <TagCloseButton
                                        onClick={() => {
                                            const newInputs = [...selectedInputs]
                                            newInputs.splice(ix, 1);
                                            setSelectedInputs(newInputs)
                                        }}
                                    />
                                </Tag>
                            )
                        })}
                        <InputGroup>
                            <Input
                                onFocus={() => setIsFocus(true)}
                                // Delay added to blut to prevent error message coming straight away
                                // when clicking the add button
                                onBlur={() => setTimeout(() => setIsFocus(false), 100)}
                                border={"none"}
                                _hover={{border: "none"}}
                                _focus={{border: "none"}}
                                _invalid={{border: "none"}}
                                placeholder={placeholder ? placeholder : "Input"}
                                onChange={(event) => {
                                    setTextInput(event.target.value);
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    const newInputs = [...selectedInputs];
                                    newInputs.push(textInput);
                                    setSelectedInputs(newInputs);
                                    setTextInput("");
                                  }
                                }}
                                value={textInput}
                            />
                            <InputRightElement>
                            <IconButton
                                visibility={textInput ? "visible" : "hidden"}
                                _hover={{color: "primary.dark"}}
                                aria-label={"clear-search-query"}
                                variant={"unstyled"}
                                onClick={() => {
                                    const newInputs = [...selectedInputs];
                                    newInputs.push(textInput);
                                    setSelectedInputs(newInputs);
                                    setTextInput("");
                                }}
                                icon={
                                    <Icon
                                        as={IoMdAdd}
                                        w={[4, null, null, 4, null]}
                                        h={[4, null, null, 4, null]}
                                    />
                                }
                            />
                            </InputRightElement>
                        </InputGroup>
                    </GridItem>
                    <GridItem colSpan={1} alignSelf={"center"}>
                        <IconButton
                            visibility={(selectedInputs.length || textInput) ? "visible" : "hidden"}
                            _hover={{color: "primary.dark"}}
                            aria-label={"clear-search-query"}
                            variant={"unstyled"}
                            onClick={() => {
                                // Remove all selected inputs
                                setSelectedInputs([]);
                                setTextInput("");
                            }}
                            icon={
                                <Icon
                                    as={MdClear}
                                    w={[4, null, null, 4, null]}
                                    h={[4, null, null, 4, null]}
                                />
                            }
                        />
                    </GridItem>
                </Grid>
            </Box>
            <FormErrorMessage>
                Please click the add button to confirm input.
            </FormErrorMessage>
        </FormControl>
    )
}
