export const illumaProductInfo = [
    {title: "illuma Reacts", description: "Expand campaign reach for your display and video campaigns using live engagement signals"},
    {title: "illuma Precision (Web)", description: "Our technology detects high-performing pages within your chosen context, fluidly adapting as these change throughout the campaign"},
    {title: "illuma Precision (CTV)", description: "Run high quality CTV campaigns within specific contexts, delivering scale without losing relevance"},
    {title: "illuma Compass", description: "Activate powerful web and CTV cross-platform campaigns, intelligently scaling CTV inventory using web signals"},
]

export const dspMemberInfo = [
    {title: "DV360: Partner ID", description: ""},
    {title: "TTD (Category): Partner ID", description: ""},
    {title: "TTD (Deal): Default Partner Seat ID", description: ""},
    {title: "Xandr: Member ID", description: ""},
]
