import { BoxProps, Button, Flex, HStack, Icon } from "@chakra-ui/react";
import { Link, useLocation, useParams } from "react-router-dom";
import { HiOutlineClipboardList } from "react-icons/hi";
import { InsightsDateRangeSelector } from "./campaignInsightsOverview/InsightsDateRangeSelector";

export const InsightsHeader = (props: BoxProps) => {
  const { pathname } = useLocation();
  const { id: campaignId } = useParams();
  const [currentView] = pathname.split("/").slice(-1);

  return (
    <Flex
      bg={"white"}
      w={"100%"}
      py={3}
      justifyContent={"space-between"}
      {...props}
    >
      <InsightsDateRangeSelector />
      <HStack>
        <Link to={`/campaigns/${campaignId}`}>
          <Button
            variant={"tabInactive"}
            size={"sm"}
            fontSize={"14px"}
            borderRadius={"md"}
            leftIcon={<Icon as={HiOutlineClipboardList} w={5} h={5} />}
          >
            Go to Summary
          </Button>
        </Link>
        <Link to={"overview"}>
          <Button
            variant={currentView === "overview" ? "tabActive" : "tabInactive"}
          >
            Overview
          </Button>
        </Link>
        <Link to={"details"}>
          <Button
            variant={currentView === "details" ? "tabActive" : "tabInactive"}
          >
            Details
          </Button>
        </Link>
      </HStack>
    </Flex>
  );
};
