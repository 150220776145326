import { Navigate, Outlet, useParams } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import { insightsMetricVar, userDetailsVar } from "../../lib/cache";
import { CampaignType, InsightMetricsEnum } from "../../graphql/generated";
import { ComponentLoading } from "../../components/loading/ComponentLoading";
import { GET_CAMPAIGN_INFO } from "../../graphql/operations/campaignOperations/operations";
import { AppLayout } from "../../components/containers/appLayout/AppLayout";
import { createContext, useContext } from "react";

interface ICampaignSummary {
  campaign: CampaignType;
}

const campaignContextInsights = createContext({} as ICampaignSummary);

export const useCampaignInfoInsights = () => {
  return useContext(campaignContextInsights);
};

export const CampaignInsightsPage = () => {
  const { id: campaignId } = useParams();
  const currentMetric = useReactiveVar(insightsMetricVar);
  const currentUser = useReactiveVar(userDetailsVar);

  const { data, loading, error } = useQuery(GET_CAMPAIGN_INFO, {
    variables: { id: campaignId },
    skip: !campaignId,
    onCompleted: (data) => {
      if (!data.campaignInfo.availableMetrics.includes(currentMetric)) {
        insightsMetricVar(
          data.campaignInfo.availableMetrics[0] as InsightMetricsEnum
        );
      }
    },
  });
  if (loading || currentUser?.isStaff === undefined) {
    return (
      <AppLayout pageTitle={"Insights"} showInsightsHeader>
        <ComponentLoading text={"Loading insights..."} mt={2} />
      </AppLayout>
    );
  }
  if (error || !data) {
    return (
      <AppLayout pageTitle={"Insights"} showInsightsHeader>
        <div>There seems to be an error.</div>
      </AppLayout>
    );
  }

  const campaignHasInsights = !!data.campaignInfo.availableMetrics.length;
  const isInsightsPermitted = data.campaignInfo.insightsPermitted;
  const campaignInfo: CampaignType = data.campaignInfo;

  if (!campaignHasInsights || !isInsightsPermitted && !currentUser?.isStaff) {
    return <Navigate to={"/campaigns"} />;
  }

  return (
    <campaignContextInsights.Provider value={{ campaign: campaignInfo }}>
      <AppLayout pageTitle={"Insights"} showInsightsHeader>
        <Outlet />
      </AppLayout>
    </campaignContextInsights.Provider>
  );
};
