import { useQuery } from "@apollo/client";
import { useState } from "react";
import { ComponentLoading } from "../../../../components/loading/ComponentLoading";
import { SectionBox } from "../../../../components/sectionBox/SectionBox";
import { GET_MONTHLY_DSP_REVENUE } from "../../../../graphql/operations/campaignOperations/operations";
import { revenueDspVar, revenueOfficeVar, revenueYearVar } from "../../../../lib/cache";
import { ChartBox } from "../ChartBox"
import { MonthlyDspRevenueLineChart } from "./MonthlyDspRevenueLineChart";

export const MonthlyDspRevenue = () => {

    const [year, setYear] = useState<number>(revenueYearVar);
    const [officeSelect, setOfficeSelect] = useState(revenueOfficeVar);
    const [dspSelect, setDspSelect] = useState(revenueDspVar);

    const { data, loading, error } = useQuery(GET_MONTHLY_DSP_REVENUE, {
        variables: {
            officeFilter: officeSelect.value === "ALL" ? undefined : officeSelect.value,
            yearFilter: year,
            dspFilter: dspSelect.value === "ALL" ? undefined : dspSelect.value
        },
        fetchPolicy: "cache-and-network",
    });

    if (loading) {
        return (
            <SectionBox>
                <ComponentLoading text={"Loading Chart..."} p={5}/>
            </SectionBox>
        )
    }

    if (error) {
        return (
          <SectionBox>
            There seems to be a problem
          </SectionBox>
        );
    }

    return (
        <ChartBox
            chartBoxHeader={"Monthly DSP Revenue"}
            dspFilter={{dspSelect: dspSelect, setDspSelect: setDspSelect}}
            officeFilter={{officeSelect: officeSelect, setOfficeSelect: setOfficeSelect}}
            yearFilter={{year: year, setYear: setYear}}
            isDownloadable={true}
        >
            <MonthlyDspRevenueLineChart
                monthlyDspRevenueData={data.monthlyDspRevenue}
                officeSelect={officeSelect}
                year={year}
            />
        </ChartBox>
    )
}
