import { useQuery, useReactiveVar } from "@apollo/client";
import { Button, Center, HStack, Link as ChakraLink,Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CampaignList } from "../../components/containers/campaignList/CampaignList";
import { ComponentLoading } from "../../components/loading/ComponentLoading";
import { PaginationBar } from "../../components/paginationBar/PaginationBar";
import { SegmentCard } from "../../components/segmentCard/SegmentCard";
import { PackageTypeEdge } from "../../graphql/generated"
import { GET_PACKAGE_LIST_PAGINATED } from "../../graphql/operations/campaignOperations/operations";
import { useAuth } from "../../lib/auth";
import { segmentSearchFilterVar, SegmentStatusFilterType, segmentStatusFilterVar } from "../../lib/cache";

export const SegmentHomeBody = () => {

    const [pageNumber, setPageNumber] = useState(0);
    const pageSize = 10;
    const statusFilter = useReactiveVar(segmentStatusFilterVar);
    const searchFilter = useReactiveVar(segmentSearchFilterVar);

    const navigate = useNavigate();
    const { signOut } = useAuth();
    const signOutAndGoToLogin = () => {
      signOut();
      navigate("/login");
    };

    useEffect(() => {
      setPageNumber(0)
    }, [searchFilter])

    const { data, loading, error } = useQuery(GET_PACKAGE_LIST_PAGINATED, {
      variables: {
        isActive: handleStatus(statusFilter),
        search: searchFilter.trim(),
        first: pageSize,
        offset: (pageNumber) * pageSize
      },
      fetchPolicy: "cache-and-network",
    });

    if (loading) {
      return <ComponentLoading text={"Getting segments..."} />;
    }

    if (error) {
      return (
        <>
          <div>There seems to be a problem. Please sign out and and sign back in.</div>
          <ChakraLink onClick={signOutAndGoToLogin}>Click here to sign out</ChakraLink>
        </>
      );
    }

    const packageList = data.packageListPaginated.edges;
    const totalPageCount = Math.ceil(data.packageListPaginated.totalCount/pageSize);

    return (
        <>
            {packageList.length === 0 && statusFilter === "ALL" && !searchFilter ? (
                <HStack>
                    <Text fontWeight={"medium"} fontSize={"18px"} color={"link"}>
                        {"You haven't created any Campaigns yet. Create a"}
                    </Text>
                    <Link to={"new"}>
                        <Button variant={"primary"} size={"sm"}>
                            New Segment
                        </Button>
                    </Link>
                </HStack>
            ) : (
                <>
                    <CampaignList mt={5}>
                        {packageList.map(
                            (edge: PackageTypeEdge) => (
                                // <Text key={edge.node.id}>
                                //     {edge.node.name}
                                // </Text>
                                <SegmentCard
                                    key={edge.node.id}
                                    {...edge.node}
                                />
                            )
                        )}
                    </CampaignList>
                    {(statusFilter !== "ALL" || searchFilter) &&
                        packageList.length === 0 && (
                        <Text
                            fontSize={"18px"}
                            color={"muted"}
                            textAlign={"center"}
                            mt={4}
                        >
                            No segments match your current parameters...
                        </Text>
                    )}
                </>
            )}
            {packageList.length !== 0 && (
                <Center>
                    <PaginationBar
                        currentPageIndex={pageNumber}
                        totalPageCount={totalPageCount}
                        setPage={setPageNumber}
                        mt={4}
                        gridGap={4}
                    />
                </Center>
            )}
        </>
    )
}

const handleStatus = (status: SegmentStatusFilterType) => {
    switch(status) {
        case "ACTIVE":
            return true;
        case "INACTIVE":
            return false;
        default:
            return null;
    }

}
