import { useEffect, useState, createContext, useContext } from "react";
import * as React from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  // createHttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { cache, userDetailsVar } from "./cache";
import { setContext } from "@apollo/client/link/context";
import {
  GET_USER_DETAILS,
  LOGIN_USER,
} from "../graphql/operations/userOperations";
import { createUploadLink } from "apollo-upload-client"
import { FullSizeIconLoading } from "../components/loading/FullSizeIconLoading";
import { RestLink } from "apollo-link-rest";

interface IAuth {
  isSignedIn: boolean;
  signIn: (email: string, password: string, remember?: boolean) => Promise<any>;
  client: undefined | ApolloClient<NormalizedCacheObject>;
  signOut: () => void;
  authToken: string | null;
}

const authContext = createContext<IAuth>({} as IAuth);

function useProvideAuth() {

  const [authToken, setAuthToken] = useState(sessionStorage.getItem("@illuma_at"));
  const isSignedIn = !!authToken;
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {
      await setClient(createApolloClient());
    }

    init().catch((err) => err);
  }, [authToken]);

  useEffect(() => {
    const localAuthToken = localStorage.getItem("@illuma_at");
    if(localAuthToken) {
      setAuthToken(localAuthToken);
    }
    async function initUser() {
      await client?.query({ query: GET_USER_DETAILS }).then(({ data }) => {
        userDetailsVar(data.me);
      });
    }

    initUser().catch((err) => err);
  }, [client]);

  const createApolloClient: () => ApolloClient<NormalizedCacheObject> = () => {
    const authLink = setContext((_, { headers }) => {

      return {
        headers: {
          ...headers,
          authorization: authToken ? `JWT ${authToken}` : "",
        },
      };
    });

    // const httpLink = createHttpLink({
    //   uri: process.env.REACT_APP_API_URI,
    //   credentials: "include",
    // });

    // We use an upload link instead of http link because we require file uploading
    const uploadLink = (createUploadLink({
      uri: process.env.REACT_APP_API_URI,
      credentials: "include",
    }) as unknown) as ApolloLink

    const restLink = new RestLink({ uri: process.env.REACT_APP_REST_API_URI });
    // "https://hitl.illumaland.com/" prod endpoint
    // "https://dev-hitl.illuma-tech.com/" dev endpoint

    return new ApolloClient({
      // link: authLink.concat(uploadLink),
      link: ApolloLink.split(
        operation => operation.getContext().clientName === "hitlEndpoint",
        restLink, //if above
        authLink.concat(uploadLink)
      ),
      cache,
      connectToDevTools: true,//process.env.NODE_ENV === "development",
      resolvers: {},
    });
  };

  const signIn = async (email: string, password: string, remember = false) => {
    if (!client) return;

    const { data, errors } = await client.mutate({
      mutation: LOGIN_USER,
      variables: { email, password },
    });

    // ToDo: Handle Remember
    if (remember) {
      localStorage.setItem("@illuma_at", data.userLogin.token);
    } else {
      sessionStorage.setItem("@illuma_at", data.userLogin.token);
    }

    if (data?.userLogin?.token) {
      setAuthToken(data.userLogin.token)
      return Promise.resolve();
    } else {
      return Promise.reject(errors);
    }
  };

  const signOut = () => {
    setAuthToken("");
    userDetailsVar(null);
    client?.clearStore();
    localStorage.clear();
    sessionStorage.clear();
  };

  return {
    isSignedIn,
    client,
    authToken,
    signIn,
    signOut,
  };
}

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvideAuth();

  if (!auth.client) {
    return <FullSizeIconLoading />;
  }

  return (
    <authContext.Provider value={auth}>
      <ApolloProvider client={auth.client}>{children}</ApolloProvider>
    </authContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(authContext);
};
