import { InMemoryCache, makeVar } from "@apollo/client";
import makeVarPersisted from "./makeVarPersisted";
import { LocalStorageWrapper } from "apollo3-cache-persist";
import {
  CampaignStatusChoices,
  DateRangeInput,
  InsightMetricsEnum,
  InsightsDateRangePresetsEnum,
  UserType,
} from "../graphql/generated";
import { dspFilterOptionType, officeFilterOptionType } from "../pages/adminDashboardPage/revenue/ChartBox";

export const cache = new InMemoryCache({
  possibleTypes: {
    InsightNodeInterface: [
      "CategoryInsightNode",
      "DateInsightNode",
      "CategoryRankingNode",
      "SubCategoryInsightNode",
    ],
  },
  typePolicies: {
    CategoryInsightConnection: {
      merge: false,
    },
  },
});

/** Reactive Vars */
// ToDo: redux-cookie-persist for storing cache instead of local storage
export const persistedAuthToken = makeVarPersisted("", {
  storage: new LocalStorageWrapper(window.localStorage),
  storageKey: "@illuma_at",
});

// To redirect last visited page, is it necessary?
// export const lastPageVisited = makeVarPersisted("", {
//   storage: new LocalStorageWrapper(window.localStorage),
//   storageKey: "@last_page_visited",
// });

export const userDetailsVar = makeVar<UserType | null>(null);
export type CampaignStatusFilterType = CampaignStatusChoices | "ALL";
export const campaignStatusFilterVar = makeVar<CampaignStatusFilterType>("ALL");
export type SegmentStatusFilterType = "ALL" | "ACTIVE" | "INACTIVE";
export const segmentStatusFilterVar = makeVar<SegmentStatusFilterType>("ALL");
export const insightsMetricVar = makeVar<InsightMetricsEnum>(
  InsightMetricsEnum.Impressions
);
export const campaignSearchFilterVar = makeVar<string>("");
export const segmentSearchFilterVar = makeVar<string>("");
export const insightsDatesVar = makeVar<Partial<DateRangeInput>>({
  preset: InsightsDateRangePresetsEnum.Last_14Days,
});
type CurrentCategoryType = { id: string; category: string };
export const currentCategoryVar = makeVar<CurrentCategoryType>(
  {} as CurrentCategoryType
);
type CategoryOrderingType = { orderBy: string; ordering: string };
export const categoryOrderingVar = makeVar<CategoryOrderingType>({
  ordering: "ASC",
  orderBy: "ALPHABETICALLY",
} as CategoryOrderingType);
export const overviewChartVar = makeVar<string>("BUBBLE");
// Revenue chart filters
export const revenueYearVar = makeVar<number>(new Date().getFullYear());
export const revenueOfficeVar = makeVar<officeFilterOptionType>(
  {label: "All Offices", value: "ALL"}
);
export const revenueDspVar = makeVar<dspFilterOptionType>(
  {label: "All DSPs", value: "ALL"}
);
