import { Flex, Text, VStack } from "@chakra-ui/react"

export type TooltipLabelTypes = string | {title: string, description: string}[]

interface TooltipLabelProps {
  label: TooltipLabelTypes
}

export const TooltipLabel = ({label}: TooltipLabelProps) => {
    if (Array.isArray(label)) {
      return(
        <>
          {label.map((section, ix: number) => {
            return(
              <Flex key={ix} flexDir={"column"} pb={4}>
                <VStack align={"left"} spacing={"2"}>
                    <Text as={"b"}>{section.title}</Text>
                    <Text>{section.description}</Text>
                </VStack>
              </Flex>
            )
          })}
        </>
      )
    } else {
      return(
        <Text>{label}</Text>
      )
    }
  }
