import { Input } from "@chakra-ui/react";
import {
  FormFieldLabel,
  FormFieldLabelProps,
} from "../formFieldLabel/FormFieldLabel";

export interface FormInputFieldProps extends FormFieldLabelProps {
  placeholder?: string;
  /**
   * Is this field required?
   */
  fieldType: "text" | "email" | "password";
  /**
   * Is the form valid (usually after submission checks)
   */
  inputFieldProps?: Record<string, any>;
  disabled?: boolean;
}

export const FormInputField = ({
  placeholder,
  fieldType,
  label,
  inputFieldProps,
  disabled = false,
  ...fieldLabelProps
}: FormInputFieldProps) => {
  placeholder = placeholder ? placeholder : label;
  return (
    <FormFieldLabel {...fieldLabelProps} label={label}>
      <Input
        {...inputFieldProps}
        data-testid={"input-field"}
        type={fieldType}
        placeholder={placeholder}
        isDisabled={disabled}
      />
    </FormFieldLabel>
  );
};
