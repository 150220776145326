import {
  CampaignProductCategoryType,
  CountryType,
  ProductCategoryChoices,
} from "../../graphql/generated";
import { BoxProps, Flex, HStack, Text, useDisclosure } from "@chakra-ui/react";
// import { SmartCategoriesIcon } from "../../assets/icons/SmartCategoriesIcon";
import { ReactsIcon } from "../../assets/icons/ReactsIcon";
import { CompassIcon } from "../../assets/icons/CompassIcon";
import { DetailField } from "../../components/detailField/DetailField";
import { DetailFieldListBox } from "../../components/detailFieldListBox/DetailFieldListBox";
import {
  formatDateDisplay,
  formatNumberDisplay,
  formatPrecisionLabel,
  getDSPName,
} from "../../utils/displayGetters";
import { SectionBox } from "../../components/sectionBox/SectionBox";
import { useCampaignInfo } from "./index";
import { CheckedField } from "./CheckedField";
import { EditModal } from "./EditModal";
import { useState } from "react";

export const BasicDetailsSummary = (props: BoxProps) => {
  const { campaign } = useCampaignInfo();
  const markets = campaign.markets.map((m: CountryType) => m.name);
  const smartCategories = campaign.productCategories?.filter(
    (p) => p.productCategory === ProductCategoryChoices.Smart
  );
  const products = campaign.productCategories.map(
    (p: CampaignProductCategoryType) => p.productCategory
  );
  const precisionCategories = campaign.productCategories?.filter(
    (p) => p.productCategory === ProductCategoryChoices.Precision
  );

  const precisionCategoriesCtv = campaign.productCategories?.filter(
    (p) => p.productCategory === ProductCategoryChoices.PrecisionCtv
  );

  const iconProps = { w: 28, h: 20 };
  const iconMap = {
    [ProductCategoryChoices.Smart]: <Text>[illuma Precision]</Text>, //<SmartCategoriesIcon {...iconProps} />,
    [ProductCategoryChoices.SmartCtv]: <Text>[illuma Precision]</Text>, //<SmartCategoriesIcon {...iconProps} />,
    [ProductCategoryChoices.Reacts]: <ReactsIcon {...iconProps} />,
    [ProductCategoryChoices.Compass]: <CompassIcon {...iconProps} />,
    [ProductCategoryChoices.Precision]: <>[illuma Precision]</>,
    [ProductCategoryChoices.PrecisionCtv]: <>[illuma Precision]</>,
  };

  // Modal options. To use modal for a DetailField pass in onOpen and setModalOption
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ modalOption, setModalOption ] = useState<string>("");

  return (
    <SectionBox sectionHeader={"Details"} {...props}>
      <Flex direction={"column"}>
        <DetailField label={"Name"} value={campaign.name} />
        {products && (
          <HStack>
            {products.map((p: ProductCategoryChoices, ix) => (
              <span key={ix}>{iconMap[p]}</span>
            ))}
          </HStack>
        )}
        <DetailField label={"Advertiser"} value={campaign.advertiserName} />
        <DetailField
          label={"Creative Type"}
          value={campaign.creativeType}
          format={true}
        />
        <DetailField label={"Markets"} value={markets} />
        <DetailField
          label={"DSP"}
          value={getDSPName(campaign.dspInfo.dspName ?? "")}
        />
        <DetailField
          label={"DSP Member ID"}
          value={campaign.dspInfo.dspMemberId}
        />
        <DetailField
          label={"Start Date"}
          value={formatDateDisplay(campaign.startDate)}
        />
        <DetailField
          label={"End Date"}
          value={campaign.endDate ? formatDateDisplay(campaign.endDate) : "TBD"}
          openModal={onOpen}
          setModalOption={setModalOption}
        />
        {smartCategories &&
          smartCategories[0] &&
          smartCategories[0].insightCategories && (
            <DetailField
              label={"Precision Categories"}
              value={smartCategories[0].insightCategories}
              format={true}
            />
          )
        }
        {precisionCategories &&
          precisionCategories[0] &&
          precisionCategories[0].precisionCategories && (
            <DetailFieldListBox
              label={"Precision Categories"}
              value={formatPrecisionLabel(precisionCategories[0].precisionCategories)}
              format={false}
            />
          )
        }
        {precisionCategoriesCtv &&
          precisionCategoriesCtv[0] &&
          precisionCategoriesCtv[0].precisionCategories && (
            <DetailField
              label={"Precision CTV Categories"}
              value={formatPrecisionLabel(precisionCategoriesCtv[0].precisionCategories)}
              format={true}
            />
          )
        }
        <DetailField
          label={"Estimated Illuma Budget"}
          value={campaign.estimatedIllumaBudget}
          openModal={onOpen}
          setModalOption={setModalOption}
        />
        <DetailField
          label={"Estimated Illuma Impressions"}
          value={formatNumberDisplay(campaign.estimatedIllumaImpressions ?? 0)}
          openModal={onOpen}
          setModalOption={setModalOption}
        />
        <DetailField
          label={"Average CPM"}
          value={campaign.averageCpm}
          openModal={onOpen}
          setModalOption={setModalOption}
        />
        <CheckedField
          label={"Inclusion List Provided"}
          value={!!campaign.inclusionLists.length}
        />
        <CheckedField
          label={"Exclusion List Provided"}
          value={!!campaign.exclusionLists.length}
        />
      </Flex>
      <EditModal
        isOpen={isOpen}
        onClose={onClose}
        modalOption={modalOption}
      />
    </SectionBox>
  );
};
