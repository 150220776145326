import { Box, BoxProps, Collapse, HStack, Icon, Spacer, Text } from "@chakra-ui/react"
import { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

interface SidebarGroupProps extends BoxProps {
    title: string
    removeTopBorder?: boolean
}

export const SidebarGroup = ({title, removeTopBorder=false, children}: SidebarGroupProps) => {
    const [show, setShow] = useState(true);

    return (
        <>
            <Box
                borderTopColor={removeTopBorder ? "transparent" : "muted"}
                borderTopWidth={"1px"}
                color={"text.dark"}
                px={6}
                py={4}
                cursor={"pointer"}
                _hover={{
                    bg: "primary.dark",
                    color: "text.dark",
                }}
                onClick={() => {
                    setShow(!show)
                }}
            >
                <HStack>
                    <Text
                        fontSize={"14px"}
                        fontWeight={"bold"}
                        pl={0}
                        color={"text.dark"}
                    >
                        {title}
                    </Text>
                    <Spacer />
                    {show ?
                        <Icon as={RiArrowDropUpLine} h={6} w={6}/> :
                        <Icon as={RiArrowDropDownLine} h={6} w={6}/>
                    }
                </HStack>
            </Box>
            <Collapse in={show}>
                {children}
            </Collapse>
        </>
    )
}
