import { Icon } from "@chakra-ui/react";

export const IllumaLogo = (props: Record<string, any>) => (
  <Icon
    viewBox={"0 0 164.172 37.549"}
    {...props}
    sx={{
      ".a": {
        fill: "#67c2c9",
      },
      ".b": {
        fill: "#c2e0e2",
      },
      ".c": "#192839",
    }}
  >
    <g transform="translate(0 1.793)">
      <path
        className="a"
        d="M16.981,36.462A16.981,16.981,0,1,1,33.962,19.481,17,17,0,0,1,16.981,36.462Zm0-30.375A13.394,13.394,0,1,0,30.375,19.481,13.409,13.409,0,0,0,16.981,6.087Z"
        transform="translate(0 -2.5)"
      />
    </g>
    <g transform="translate(27.291)">
      <path
        className="b"
        d="M56.816,37.549A18.774,18.774,0,1,1,75.591,18.775,18.8,18.8,0,0,1,56.816,37.549Zm0-30.375a11.6,11.6,0,1,0,11.6,11.6A11.614,11.614,0,0,0,56.816,7.174Z"
        transform="translate(-38.042)"
      />
    </g>
    <g transform="translate(70.893 6.359)">
      <path
        className="c"
        d="M98.821,11.558a2.734,2.734,0,0,1,2.763-2.694,2.7,2.7,0,0,1,2.728,2.694,2.676,2.676,0,0,1-2.728,2.659A2.706,2.706,0,0,1,98.821,11.558Zm.207,22.206V15.633h3.142c1.658,0,1.9.552,1.9,3.315V33.764Z"
        transform="translate(-98.821 -8.864)"
      />
      <path
        className="c"
        d="M111.626,34.049V9.874H114.7c1.692,0,1.969.415,1.969,2.832V34.049Z"
        transform="translate(-102.44 -9.149)"
      />
      <path
        className="c"
        d="M124.143,34.049V9.874h3.073c1.692,0,1.969.415,1.969,2.832V34.049Z"
        transform="translate(-105.977 -9.149)"
      />
      <path
        className="c"
        d="M148.1,32.009V18.3h5.043V36.43h-3.419c-.829,0-1.313-.276-1.519-1.693a11.308,11.308,0,0,1-6.216,1.934c-3.039,0-5.284-1.174-5.284-4.9V18.3h5.042V30.49c0,1.969.967,2.418,2.556,2.418A10.739,10.739,0,0,0,148.1,32.009Z"
        transform="translate(-109.528 -11.53)"
      />
      <path
        className="c"
        d="M170.144,20.09a11.09,11.09,0,0,1,6.389-2.176c1.865,0,3.557.552,4.317,2.314a11.311,11.311,0,0,1,6.7-2.314c2.555,0,4.731,1.174,4.731,5.076V36.321h-5.042V24.268c0-1.726-.587-2.314-2.106-2.314a10.8,10.8,0,0,0-3.868.864v13.5h-5.042V24.268c0-1.726-.588-2.314-2.107-2.314a11.8,11.8,0,0,0-3.868.829V36.321h-5.042V18.19h3.419C169.489,18.19,169.972,18.57,170.144,20.09Z"
        transform="translate(-117.582 -11.422)"
      />
      <path
        className="c"
        d="M217.823,34.525a6.584,6.584,0,0,1-5.249,2c-3.385,0-5.87-1.865-5.87-5.456,0-3.8,3.039-5.664,7.217-5.664a13.937,13.937,0,0,1,3.626.415V24.1c0-1.658-.9-2.521-3.315-2.521a8.236,8.236,0,0,0-4.7,1.415h-1.415V19.433a15.631,15.631,0,0,1,6.907-1.519c5.007,0,7.563,1.658,7.563,5.836V36.321h-2.106C218.895,36.321,218.169,35.976,217.823,34.525Zm-.276-6.009a15.11,15.11,0,0,0-2.59-.207c-2.037,0-3.349.725-3.349,2.486s1.14,2.555,2.832,2.555a4.63,4.63,0,0,0,3.108-1.381Z"
        transform="translate(-129.31 -11.422)"
      />
    </g>
  </Icon>
);
