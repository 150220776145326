import { useMutation } from "@apollo/client";
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { useCampaignInfo } from "..";
import { SelectField } from "../../../components/selectField/SelectField";
import { BrandType } from "../../../graphql/generated";
import { PROTECTED_GET_CAMPAIGN_INFO, UPDATE_CAMPAIGN } from "../../../graphql/operations/campaignOperations/operations";

interface BrandSelectProps {
    brandList: BrandType[]
    brandSelect: {label: string, value: string} | undefined //Record<string, string> | undefined
    setBrandSelect: Dispatch<SetStateAction<{label: string, value: string} | undefined>>
}

export const BrandSelect = ({brandList, brandSelect, setBrandSelect}: BrandSelectProps) => {

    const { campaign: { id, brand } } = useCampaignInfo();
    const [touched, setTouched] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [updateCampaign, { loading: loadingMutation }] = useMutation(UPDATE_CAMPAIGN, {
        refetchQueries: [PROTECTED_GET_CAMPAIGN_INFO],
    });

    if (loadingMutation) {
        return (
            <></>
        )
    }

    return (
        <>
            <Box>
                <Text my={5}>Brand</Text>
                <Flex>
                    <Box ml={4} width={"100%"}>
                        <SelectField
                            placeholder={"Set Brand"}
                            onChange={(option) => {
                                setBrandSelect(option)
                            }}
                            defaultValue={brandSelect ? brandSelect : undefined}
                            isMulti={false}
                            options={getBrandOptions(brandList)}
                            hideSelectedOptions={true}
                        />
                    </Box>
                    <IconButton
                        ml={"auto"}
                        mr={0}
                        bg={"white"}
                        _hover={{ bg: "white" }}
                        aria-label={"Add Brand"}
                        variant={"unStyled"}
                        icon={<Icon as={IoMdAdd} w={5} h={5} />}
                        onClick={() => {
                            onOpen()
                        }}
                    />
                </Flex>
            </Box>

            <Modal
                isOpen={isOpen}
                onClose={() => {
                    setTouched(false);
                    brand ? setBrandSelect({label: brand.name, value: brand.id}) : setBrandSelect(undefined);
                    onClose()
                }}
            >
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Create new Brand</ModalHeader>
                <ModalBody>
                    <Text mb={4}>Create a new Brand and assign it to this campaign.</Text>
                    <FormControl
                        isInvalid={!(isBrandValid(brandSelect, brandList))[0] && touched}
                    >
                        <FormLabel>Brand</FormLabel>
                        <Input
                            placeholder={"Set Brand"}
                            onChange={(e) => {
                                setTouched(true);
                                setBrandSelect({label: e.target.value, value: ""})
                            }}
                        />
                        <FormErrorMessage>
                            {isBrandValid(brandSelect, brandList)[1]}
                        </FormErrorMessage>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                <Button
                        mr={4}
                        variant={"tabActive"}
                        onClick={() => {
                            setTouched(false);
                            brand ? setBrandSelect({label: brand.name, value: brand.id}) : setBrandSelect(undefined);
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        isDisabled={!isBrandValid(brandSelect, brandList)[0]}
                        variant={"tabActive"}
                        onClick={() => {
                            updateCampaign({
                                variables: {
                                    id: id,
                                    updateFields: {
                                        brand: brandSelect?.label,
                                    },
                                },
                            })
                            onClose();
                        }}
                    >
                        Save
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

const getBrandOptions = (
    brands: BrandType[]
): {label: string, value: string}[] => {
    return brands.map((brand) =>
            ({label: brand.name, value: brand.id})
    );
}

const isBrandValid = (
    brandSelect: {label: string, value: string} | undefined,
    brandList: BrandType[],
): [boolean, string] => {

    if (!brandSelect || !brandSelect.label) {
        return [false, "Cannot be empty."];
    }

    const result = brandList.findIndex(item => item.name.toLowerCase() === brandSelect.label.toLowerCase());
    if (result !== -1) {
        return [false, "This brand already exists."];
    }

    return [true, "No errors"];
};
