export default {
  baseStyle: {
    fontWeight: "bold",
    fontSize: "1rem",
    fontFamily: "Open Sans",
    color: "black",
    borderRadius: "full",
    _focus: {
      boxShadow: "none",
    },
    _active: {
      transform: "scale(0.98)",
    },
  },

  variants: {
    primary: {
      bg: "primary.dark",
      boxShadow: "none",
      fontSize: "14px",
      px: 6,
      py: 2,
      _hover: {
        bg: "primary.light",
        _disabled: {
          bg: "primary.dark",
        },
      },
    },
    secondary: {
      bg: "transparent",
      borderColor: "white",
      color: "white",
      borderWidth: "2px",
      fontSize: "14px",
      px: 6,
      py: 2,
      _hover: {
        color: "primary.light",
        borderColor: "primary.light",
      },
    },
    link: {
      bg: "transparent",
      color: "link",
      textDecoration: "underline",
      transition: "color 0.25s ease-in-out",
      p: 0,
      _hover: {
        color: "primary.darker",
      },
    },
    darkSolid: {
      bg: "black",
      color: "white",
      _hover: {
        _disabled: {
          bg: "black",
          opacity: "0.3",
        },
      },
    },
    tabActive: {
      bg: "primary.dark",
      borderRadius: "md",
      px: 4,
      py: 2,
      fontWeight: 200,
      fontSize: "18px",
    },
    tabInactive: {
      bg: "transparent",
      px: 4,
      py: 2,
      fontWeight: 200,
      fontSize: "18px",
      borderRadius: "md",
      _active: {
        bg: "primary.light",
      },
      _hover: {
        bg: "primary.lighter",
      },
    },
    unStyled: {
      bg: "transparent",
      px: 2,
      borderRadius: "md",
      _hover: {
        bg: "primary.lighter",
      },
      _active: {
        bg: "primary.light",
      },
    },
    accountMenu: {
      py: 2,
      h: "100%",
      borderRadius: "lg",
      _hover: {
        bg: "primary.lighter",
      },
      _active: {
        bg: "primary.light",
      },
      _focus: {
        boxShadow: "none",
      },
      _expanded: {
        bg: "primary.lighter",
      },
    },
    filterMenu: {
      bg: "white",
      borderRadius: "lg",
      borderWidth: "2px",
      borderColor: "white",
      _active: {
        borderColor: "primary.light",
      },
      _hover: {
        borderColor: "primary.lighter",
      },
      _expanded: {
        borderColor: "primary.dark",
      },
    },
    pagination: {
      bg: "transparent",
      borderRadius: "md",
      _hover:{
        stroke: "primary.darker",
        color: "primary.darker",
      },
      _active: {
        bg: "transparent",
      },
    },
  },
};
