import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Text,
  Box,
  HStack,
  Icon,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import * as React from "react";
import { AiOutlineQuestion } from "react-icons/ai";
import { TooltipLabel, TooltipLabelTypes } from "../tooltipLabel/TooltipLabel";

export interface FormFieldLabelProps extends FormControlProps {
  label: string;
  errorMessage?: string;
  subLabel?: string;
  toolTip?: TooltipLabelTypes;
  toolTipComponent?: React.ReactNode
  forceSpacer?: boolean
}

export const FormFieldLabel: React.FC<FormFieldLabelProps> = ({
  children,
  label,
  subLabel,
  toolTip,
  errorMessage,
  toolTipComponent,
  forceSpacer=false,
  ...rest
}) => {
  return (
    <FormControl display={"flex"} flexDirection={"column"} mt={4} {...rest}>
      <Box mb={1}>
        <HStack>
          <FormLabel data-testid={"input-label"} m={0}>
            {label}
          </FormLabel>
          <Spacer />
          {toolTip && (
            <Tooltip
              label={<TooltipLabel label={toolTip} />}
              placement={"left-start"}
              bg={"text.dark"}
              p={2}
            >
              <span>
                <Icon
                  bg={"text.dark"}
                  borderRadius={"full"}
                  p={"2px"}
                  as={AiOutlineQuestion}
                  color={"primary.light"}
                />
              </span>
            </Tooltip>
          )}
          {
            toolTipComponent && (
              <>
                {toolTipComponent}
              </>
            )
          }
        </HStack>
        {subLabel && <Text fontSize={"12px"}>{subLabel}</Text>}
      </Box>
      {/* Spacer below helps fix switch bug */}
      {forceSpacer && <Spacer/>}
      {children}
      <FormErrorMessage fontSize={"13px"} m={0} color={"error"}>
        <Text color={"error"}>{errorMessage}</Text>
      </FormErrorMessage>
    </FormControl>
  );
};
