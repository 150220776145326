import {
    CategoryScale,
    Chart as ChartJS,
    ChartType,
    ChartTypeRegistry,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Chart } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MonthlyDspRevenueType, MonthlyRevenueType } from "../../../../graphql/generated";
import { officeFilterOptionType } from "../ChartBox"
import { getMonthsArray } from "../../../../utils/getMonths";
import { useTheme } from "@chakra-ui/react";

interface MonthlyDspRevenueLineChartProps {
    monthlyDspRevenueData: MonthlyDspRevenueType[],
    officeSelect: officeFilterOptionType
    year: number
}

export const MonthlyDspRevenueLineChart = ({monthlyDspRevenueData}: MonthlyDspRevenueLineChartProps) => {
    const chakraTheme = useTheme();

    ChartJS.register(
        ChartDataLabels,
        CategoryScale,
        LinearScale,
        LineController,
        LineElement,
        PointElement,
        Title,
        Tooltip,
        Legend,
    );

    const options = {
        responsive: true,
        plugins: {
          datalabels: {
            display: false
          }
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: false,
          },
        },
    };

    const distinctColors: string[] = Object.values(chakraTheme.colors.distinctDataColors)

    const prepareDatasets = () => {
        const datasets: lineDatasetsType[] = [];
        // Filter out DSPs when no revenue values exist
        const filtered = monthlyDspRevenueData.filter((dspRev: MonthlyDspRevenueType) => {
            return !dspRev.dspRevenue.every((dsp) => (dsp.revenue === null));
        });
        filtered.forEach((dspRev: MonthlyDspRevenueType, ix: number) => {
            datasets.push({
                type: "line" as ChartType,
                label: dspRev.dsp,
                data: prepareMonthlyData(dspRev.dspRevenue),
                borderColor: distinctColors[ix],
                backgroundColor: distinctColors[ix],
                borderWidth: 2,
            })
        });
        return datasets;
    };

    const chartData = {
        labels: getMonthsArray(),
        datasets: prepareDatasets()
      };

    return (
        <>
            <Chart
                type={"line"}
                options={options}
                data={chartData}
            />
        </>
    )
}

const prepareMonthlyData = (data: MonthlyRevenueType[]) => {
    return data.map((monthData) => {
      return monthData.revenue
    })
}

interface lineDatasetsType {
    type: keyof ChartTypeRegistry;
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    borderWidth: number;
}
