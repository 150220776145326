import { Button, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <VStack spacing={5}>
      <Text fontWeight={"bold"}>
        {"We can't quite find what you are looking for"}
      </Text>
      <Link to={"/"}>
        <Button variant={"primary"}>{"Let's go home"}</Button>
      </Link>
    </VStack>
  );
};
