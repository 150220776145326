import Select, {
  components as selectComponents,
  GroupTypeBase,
  OptionTypeBase,
  Props as SelectProps,
  SelectComponentsConfig,
  StylesConfig,
  Theme,
} from "react-select";
import {
  chakra,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Divider,
  CloseButton,
  Center,
  Portal,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  useTheme,
  useColorModeValue,
  Icon,
  RecursiveCSSObject,
  CSSWithMultiValues,
} from "@chakra-ui/react";
import { AiFillCaretDown } from "react-icons/ai";

interface ItemProps extends CSSWithMultiValues {
  _disabled: CSSWithMultiValues;
  _focus: CSSWithMultiValues;
}

const chakraStyles: SelectProps["styles"] = {
  input: (provided) => ({
    ...provided,
    color: "inherit",
    lineHeight: 1,
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: "0.125rem 1rem",
  }),
};

const chakraComponents: SelectProps["components"] = {
  // Control components
  Control: ({ children, innerRef, innerProps, isDisabled, isFocused }) => {
    const inputStyles = useMultiStyleConfig("Input", {});
    return (
      <StylesProvider value={inputStyles}>
        <Flex
          className={"select-control"}
          ref={innerRef}
          sx={{
            ...inputStyles.field,
            p: 0,
            overflow: "hidden",
            h: "auto",
            minH: 10,
            borderColor: "primary.dark",
            // fontSize: ["xs", null, null, "md", null]
          }}
          {...innerProps}
          {...(isFocused && { "data-focus": true })}
          {...(isDisabled && { disabled: true })}
        >
          {children}
        </Flex>
      </StylesProvider>
    );
  },
  MultiValueContainer: ({
    children,
    innerRef,
    innerProps,
    data: { isFixed },
  }) => (
    <Tag
      ref={innerRef}
      m={"0.125rem"}
      py={1}
      borderRadius={"full"}
      variant={isFixed ? "solid" : "subtle"}
      {...innerProps}
    >
      {children}
    </Tag>
  ),
  MultiValueLabel: ({ children, innerRef, innerProps, ...props }) => {

    if (props.selectProps.shortenPrecisionLabel) {
      const index = children.lastIndexOf(">");
      if (index > -1) {
        return (
          <TagLabel ref={innerRef} {...innerProps}>
            {children.slice(index + 2)}
          </TagLabel>
        )
      }
    }

    return (
      <TagLabel ref={innerRef} {...innerProps}>
        {children}
      </TagLabel>
    )
  },
  MultiValueRemove: ({ children, innerRef, innerProps, data: { isFixed } }) => {
    if (isFixed) {
      return null;
    }

    return (
      <TagCloseButton ref={innerRef} {...innerProps}>
        {children}
      </TagCloseButton>
    );
  },
  IndicatorSeparator: ({ innerProps }) => (
    <Divider {...innerProps} orientation="vertical" opacity="0" />
  ),
  ClearIndicator: ({ innerProps }) => (
    <CloseButton
      {...innerProps}
      size="sm"
      mx={2}
      color={"text.dark"}
      _hover={{
        bg: "transparent",
        color: "primary.dark",
      }}
    />
  ),
  DropdownIndicator: ({ innerProps }) => {
    const { addon } = useStyles();

    return (
      <Center
        {...innerProps}
        sx={{
          ...addon,
          h: "100%",
          borderRadius: 0,
          borderWidth: 0,
          borderColor: "transparent",
          cursor: "pointer",
          bg: "transparent",
        }}
      >
        <Icon as={AiFillCaretDown} h={5} w={5} />
      </Center>
    );
  },
  // Menu components
  MenuPortal: ({ children }) => <Portal>{children}</Portal>,
  Menu: ({ children, ...props }) => {
    const menuStyles = useMultiStyleConfig("Menu", {});
    return (
      <selectComponents.Menu {...props}>
        <StylesProvider value={menuStyles}>{children}</StylesProvider>
      </selectComponents.Menu>
    );
  },

  MenuList: ({ innerRef, children, maxHeight }) => {
    const { list } = useStyles();
    return (
      <chakra.div
        sx={{
          ...list,
          maxH: `${maxHeight}px`,
          overflowY: "auto",
          border: "none",
          // fontSize: ["xs", null, null, "md", null]
        }}
        ref={innerRef}
      >
        {children}
      </chakra.div>
    );
  },
  GroupHeading: ({ innerProps, children }) => {
    const { groupTitle } = useStyles();
    return (
      <chakra.div sx={groupTitle} {...innerProps}>
        {children}
      </chakra.div>
    );
  },
  Option: ({ innerRef, innerProps, children, isFocused, isDisabled }) => {
    const { item } = useStyles();
    return (
      <chakra.div
        role="button"
        sx={{
          ...item,
          w: "100%",
          textAlign: "left",
          bg: isFocused ? "primary.light" : "transparent",
          ...(isDisabled && (item as RecursiveCSSObject<ItemProps>)._disabled),
        }}
        ref={innerRef}
        {...innerProps}
        {...(isDisabled && { disabled: true })}
      >
        {children}
      </chakra.div>
    );
  },
};

interface SelectFieldProps {
  onChange?: (option: any) => void;
  onMenuClose?: () => void;
  defaultExistingValue?: { label: string; value: string }[];
  shortenPrecisionLabel?: boolean;
}

export function SelectField<
  OptionType extends OptionTypeBase = { label: string; value: string },
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>({
  name = "",
  styles = {},
  components = {},
  theme,
  isMulti,
  onChange,
  onMenuClose,
  defaultExistingValue,
  shortenPrecisionLabel,
  ...props
}: SelectProps<OptionType, IsMulti, GroupType> & SelectFieldProps) {
  const chakraTheme = useTheme();
  const placeholderColor = useColorModeValue(
    chakraTheme.colors.muted,
    chakraTheme.colors.whiteAlpha[400]
  );

  return (
    <Select
      data-testid={"select"}
      defaultValue={defaultExistingValue}
      name={name}
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      components={{
        ...(chakraComponents as SelectComponentsConfig<
          OptionType,
          IsMulti,
          GroupType
        >),
        ...components,
      }}
      styles={{
        ...(chakraStyles as StylesConfig<OptionType, IsMulti, GroupType>),
        ...styles,

        multiValue: (base, props) => ({
          ...base,
          backgroundColor: chakraTheme.colors.primary.dark,
          borderRadius: chakraTheme.radii.full,
          marginRight: "0.5rem",
          "&:hover": {
            backgroundColor: chakraTheme.colors.primary.light,
          },
          ...(styles.multiValue && styles?.multiValue(base, props)),
        }),
        multiValueRemove: (base, props) => ({
          ...base,
          borderRadius: chakraTheme.radii.full,
          color: chakraTheme.colors.text,
          "&:hover": {
            color: chakraTheme.colors.muted,
            backgroundColor: "transparent",
          },
          ...(styles.multiValueRemove && styles?.multiValueRemove(base, props)),
        }),
        menu: (base, props) => {
          return {
            ...base,
            borderColor: chakraTheme.colors.primary.dark,
            borderRadius: chakraTheme.radii.lg,
            borderWidth: "2px",
            borderStyle: "solid",
            boxShadow: "none",
            zIndex: 999,
            overflowX: "hidden",
            ...(styles.menu && styles.menu({}, props)),
          };
        },
        noOptionsMessage: (base) => ({
          ...base,
          width: "100%",
          textAlign: "start",
        }),
      }}
      theme={(baseTheme) => ({
        ...baseTheme,
        //
        colors: {
          ...baseTheme.colors,
          neutral50: placeholderColor, // placeholder text color
          neutral40: placeholderColor, // noOptionsMessage color
          ...(theme as Theme)?.colors,
        },
        spacing: {
          ...baseTheme.spacing,
          ...(theme as Theme)?.spacing,
        },
      })}
      onChange={onChange}
      onMenuClose={onMenuClose}
      shortenPrecisionLabel={shortenPrecisionLabel}
      {...props}
    />
  );
}
