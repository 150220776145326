import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Flex,
  Icon,
  Wrap,
  IconButton,
} from "@chakra-ui/react";
import { useDropzone, FileWithPath } from "react-dropzone";
import { FiPaperclip } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { AiTwotoneFile } from "react-icons/ai";

export interface FileUploadProps {
  acceptedFileTypes: string;
  name: string;
  maxFiles?: number;
  setFiles: (name: string, files: FileWithPath[]) => void
}

export const FileUpload = ({
  name,
  setFiles,
  acceptedFileTypes,
  maxFiles,
}: FileUploadProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([])

  const [inputMounted, setInputMounted] = useState<boolean>(true);
  maxFiles = maxFiles ?? 4

  useEffect(() => {

    if (!inputMounted) {
      setInputMounted(true);
    }
  }, [inputMounted]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = [...acceptedFiles, ...uploadedFiles]
      // Loop through to check for duplicates. We first sort the files by name
      // then we filter all the files with duplicate names out.
      const newFilesUnique = newFiles.sort((a, b) => {
        if (a.name < b.name) { return -1 }
        else if (a.name > b.name) { return 1 }
        else { return 0 }
      }).filter((current, index, array) => index === 0 || current.name !== array[index - 1].name).slice(0, maxFiles)

      setUploadedFiles(newFilesUnique);
      setFiles(name, newFilesUnique)
    },
    [uploadedFiles]
  );

  const removeFile = (file: FileWithPath) => () => {
    const newFiles = uploadedFiles.concat();
    newFiles.splice(newFiles.indexOf(file), 1);
    setUploadedFiles(newFiles);
    setFiles(name, newFiles)
    setInputMounted(false);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: acceptedFileTypes,
      maxFiles: maxFiles,
    });

  const getBgColor = () => {
    if (isDragAccept) {
      return "green.200";
    } else if (isDragReject) {
      return "error";
    } else {
      return "primary.lighter";
    }
  };

  const getOtherColor = () => {
    if (isDragAccept) {
      return "green.600";
    } else if (isDragReject) {
      return "red.600";
    } else {
      return "primary.dark";
    }
  };

  return (
    <>
      {!!uploadedFiles.length && (
        <Box>
          {uploadedFiles.map((file: FileWithPath, ix) => (
            <Flex key={`${file.path}-${ix}`} justifyContent={"space-between"}>
              <Flex align={"center"}>
                <Icon as={AiTwotoneFile} color={"primary.dark"} mr={2} />
                <Text textDecoration={"underline"} fontWeight={"bold"}>
                  {file.path}
                </Text>
              </Flex>
              <IconButton
                bg={"transparent"}
                _hover={{ bg: "primary.light" }}
                aria-label={"Remove file"}
                onClick={removeFile(file)}
                icon={<IoMdClose />}
              />
            </Flex>
          ))}
        </Box>
      )}
      <Box
        bg={getBgColor()}
        py={10}
        px={4}
        borderRadius={"md"}
        borderStyle={"dashed"}
        borderWidth={"1px"}
        borderColor={getOtherColor()}
      >
        <Box {...getRootProps({ style: { width: "100%", height: "100%" } })}>
          {inputMounted && (
            <Box>
              <input {...getInputProps()} />

              <Wrap spacing={2} align={"center"} justify={"center"}>
                <Text fontWeight={"bold"}>Drag and drop or</Text>
                <Button
                  variant={"primary"}
                  bg={getOtherColor()}
                  leftIcon={<Icon as={FiPaperclip} width={5} height={5} />}
                >
                  Browse Files
                </Button>
              </Wrap>
            </Box>
          )}
        </Box>
      </Box>

    </>
  );
};
