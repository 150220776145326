import { useMutation, useReactiveVar } from "@apollo/client"
import {
    BoxProps,
    Button,
    Checkbox,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text,
    useDisclosure
} from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { CampaignChecklistType, QuestionGroupChoices } from "../../../graphql/generated"
import { PROTECTED_GET_CAMPAIGN_INFO, RESLOVE_CHECKLIST_QUESTION } from "../../../graphql/operations/campaignOperations/operations"
import { SectionBox } from "../../../components/sectionBox/SectionBox"
import dateFnsFormat from "date-fns/format";
import { useState } from "react"
import { ComponentLoading } from "../../../components/loading/ComponentLoading"
import { userDetailsVar } from "../../../lib/cache"
import { useCampaignInfo } from ".."

interface CheckListProps {
    title: string
    questionGroup: QuestionGroupChoices
}

export const CheckboxList = ({title, questionGroup, ...rest}: CheckListProps & BoxProps) => {

    const {campaign: {checklist}} = useCampaignInfo();
    const checklistArray: CampaignChecklistType[] = checklist.filter((option: CampaignChecklistType) => (
        option.questionGroup === questionGroup
    ));

    return (
        <SectionBox sectionHeader={`${title} Checklist`} {...rest}>
            {checklistArray.map(({questionId, questionText, questionGroup, isResolved, checkedBy, resolvedAt}) => {
                return (
                    <CheckPoint
                        questionId={questionId}
                        questionText={questionText}
                        questionGroup={questionGroup}
                        isResolved={isResolved}
                        checkedBy={checkedBy}
                        resolvedAt={resolvedAt}
                        key={questionId}
                    />
                )
            })}
        </SectionBox>
    )
}

const CheckPoint = ({questionId, questionText, isResolved, checkedBy, resolvedAt}: CampaignChecklistType) => {

    const [isChecked, setIsChecked] = useState(isResolved);
    const [initials, setInitials] = useState(checkedBy?.firstName[0] + checkedBy?.lastName[0]);
    const [dateString, setDateString] = useState(dateFnsFormat((new Date(resolvedAt)), "dd/MM/yy 'at' HH:mm"));
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {id} = useParams();

    const userDetails = useReactiveVar(userDetailsVar);

    const [resolveQuestion, { loading }] = useMutation(RESLOVE_CHECKLIST_QUESTION, {
        refetchQueries: [PROTECTED_GET_CAMPAIGN_INFO],
    });


    if (loading) {
        return <ComponentLoading text={"Updating Checklist"}/>
    }

    return (
        <>
            <HStack key={questionId} my={4}>
                <Checkbox
                    size={"md"}
                    isChecked={isChecked}
                    isDisabled={isChecked}
                    onChange={() => {
                        onOpen()
                    }}
                    maxW={"80%"}
                >
                    {questionText}
                </Checkbox>
                <Spacer />
                {isChecked &&
                    <Text
                        as={"i"}
                        color={"muted"}
                    >
                        {initials}: {dateString}
                    </Text>
                }
            </HStack>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                onOverlayClick={()=> {
                    setIsChecked(false);
                }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirmation</ModalHeader>
                    <ModalBody>
                        Are you sure &quot;{questionText}&quot; is complete?
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={2}
                            variant={"tabActive"}
                            onClick={() => {
                                setIsChecked(false);
                                onClose()
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            variant={"tabActive"}
                            onClick={() => {
                                setIsChecked(true);
                                resolveQuestion({ variables: { campaignId: id, questionId: questionId } });
                                userDetails && setInitials(userDetails.firstName[0] + userDetails.lastName[0]);
                                setDateString(dateFnsFormat((new Date()), "dd/MM/yy 'at' HH:mm"));
                                onClose();
                            }}
                        >
                            Complete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
