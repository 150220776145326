import { useQuery, useReactiveVar } from "@apollo/client";
import { Button, Center, HStack, Link as ChakraLink,Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CampaignCard } from "../../components/campaignCard/CampaignCard";
import { CampaignList } from "../../components/containers/campaignList/CampaignList";
import { ComponentLoading } from "../../components/loading/ComponentLoading";
import { PaginationBar } from "../../components/paginationBar/PaginationBar";
import { CampaignTypeEdge } from "../../graphql/generated"
import { GET_CAMPAIGN_LIST_PAGINATED } from "../../graphql/operations/campaignOperations/operations";
import { useAuth } from "../../lib/auth";
import { campaignSearchFilterVar, campaignStatusFilterVar } from "../../lib/cache";

export const CampaignHomeBody = () => {

    const [pageNumber, setPageNumber] = useState(0);
    const pageSize = 10;
    const statusFilter = useReactiveVar(campaignStatusFilterVar);
    const searchFilter = useReactiveVar(campaignSearchFilterVar);

    const navigate = useNavigate();
    const { signOut } = useAuth();
    const signOutAndGoToLogin = () => {
      signOut();
      navigate("/login");
    };

    useEffect(() => {
      setPageNumber(0)
    }, [searchFilter])

    const { data, loading, error } = useQuery(GET_CAMPAIGN_LIST_PAGINATED, {
      variables: {
        filter: statusFilter === "ALL" ? undefined : statusFilter,
        search: searchFilter.trim(),
        first: pageSize,
        offset: (pageNumber) * pageSize
      },
      fetchPolicy: "cache-and-network",
    });

    if (loading) {
      return <ComponentLoading text={"Getting campaigns..."} />;
    }

    if (error) {
      return (
        <>
          <div>There seems to be a problem. Please sign out and and sign back in.</div>
          <ChakraLink onClick={signOutAndGoToLogin}>Click here to sign out</ChakraLink>
        </>
      );
    }

    const campaignList = data.campaignListPaginated.edges;
    const totalPageCount = Math.ceil(data.campaignListPaginated.totalCount/pageSize);

    return (
        <>
            {campaignList.length === 0 && statusFilter === "ALL" && !searchFilter ? (
                <HStack>
                    <Text fontWeight={"medium"} fontSize={"18px"} color={"link"}>
                        {"You haven't created any Campaigns yet. Create a"}
                    </Text>
                    <Link to={"new"}>
                        <Button variant={"primary"} size={"sm"}>
                            New Campaign
                        </Button>
                    </Link>
                </HStack>
            ) : (
                <>
                    <CampaignList mt={5}>
                        {campaignList.map(
                        (edge: CampaignTypeEdge) => (
                            <CampaignCard
                                key={edge.node.id}
                                {...edge.node}
                            />
                        )
                        )}
                    </CampaignList>
                    {(statusFilter !== "ALL" || searchFilter) &&
                        campaignList.length === 0 && (
                        <Text
                            fontSize={"18px"}
                            color={"muted"}
                            textAlign={"center"}
                            mt={4}
                        >
                            No campaigns match your current parameters...
                        </Text>
                    )}
                </>
            )}
            {campaignList.length !== 0 && (
                <Center>
                    <PaginationBar
                        currentPageIndex={pageNumber}
                        totalPageCount={totalPageCount}
                        setPage={setPageNumber}
                        mt={4}
                        gridGap={4}
                    />
                </Center>
            )}
        </>
    )
}
