import { Link, Text, VStack } from "@chakra-ui/react";

export const ResetPasswordDone = () => {
  return (
    <VStack
      spacing={5}
      minW={["100%", null, null, "30rem", null]}
      bg={"white"}
      p={6}
      borderRadius={"lg"}
    >
      <Text fontWeight={"bold"} fontSize={24}>
        We have sent you a password reset email.
      </Text>
      <Text>Please follow the link in the email to reset your password.</Text>
      <Link href={"/login"} variant={"buttonLinkPrimary"}>
        Login
      </Link>
    </VStack>
  );
};
