import { useReactiveVar } from "@apollo/client";
import { Box, BoxProps, HStack, Image, Spacer, Text } from "@chakra-ui/react";
import { useCampaignInfoInsights } from ".";
import { insightsMetricVar } from "../../lib/cache";
import { getMetricDisplay } from "../../utils/displayGetters";
interface Props {
    chartName: string,
}

export const HiddenLogo = ({ chartName }: Props & BoxProps) => {
    const insightsMetric = getMetricDisplay(useReactiveVar(insightsMetricVar), true);
    const {campaign: { name }} = useCampaignInfoInsights();

    return(
        <Box
            py={2}
            id={`hiddenLogo${chartName}`}
            display={"none"}
        >
            <HStack pl={2} pr={8}>
                <Text color={"muted"} fontSize={"16px"}>
                    {`${name} - ${insightsMetric}`}
                </Text>
                <Spacer />
                <Image src="/images/illuma-final.png" height={6}/>
            </HStack>
        </Box>
    )
}
