import {
  HStack,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  Grid,
  Box,
  GridItem,
  Button,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { formatDateDisplay, getDSPName, getStatusColor } from "../../utils/displayGetters";
import { GoGraph } from "react-icons/go";
import { HiOutlineClipboardList } from "react-icons/hi";
import { CampaignStatusChoices, CampaignType, CountryType } from "../../graphql/generated";
import { useReactiveVar } from "@apollo/client";
import { userDetailsVar } from "../../lib/cache";
import { RiAdminLine } from "react-icons/ri";

export const CampaignCard = ({
  name,
  status = CampaignStatusChoices.Submitted,
  startDate,
  endDate,
  id,
  availableMetrics,
  insightsPermitted,
  creativeType,
  dspInfo,
  creator,
  markets
}: Partial<CampaignType>) => {
  const currentUser = useReactiveVar(userDetailsVar);

  const getMarketsString = (inputMarkets: CountryType[]) => {
    const marketNames = inputMarkets.map((item) => item.code);
    return marketNames.join(", ")
  }

  return (
    <Tooltip
      label={name}
      placement={"top-start"}
      openDelay={800}
      bg={"text.dark"}
      p={2}
    >
      <Box
        bg={"white"}
        borderRadius={"lg"}
        px={[2, null, null, 6, null]}
        py={[2, null, null, 4, null]}
        transition={"all 0.3s ease-in-out"}
        overflow={"hidden"} // ToDo: Remove this
      >
        <Grid templateColumns={"repeat(8, 1fr)"} w={"100%"}>
          <GridItem colSpan={5} maxW={"95%"}>
            <Link to={`${id}`}>
              <Text
                fontWeight={"bold"}
                fontSize={["md", null, null, "lg", null]}
                isTruncated
                _hover={{
                  color: "primary.dark",
                }}
                _active={{
                  color: "primary.darker",
                }}
                sx={{
                  span: {
                    color: "text.light",
                    fontSize: ["xs", null, null, "sm", null],
                    verticalAlign: "middle",
                    mr: 2,
                  },
                }}
              >
                <span>{creativeType}</span>
                {name}
              </Text>
            </Link>
            <HStack spacing={2} gridRow={1}>
              <Text fontSize={["xs", null, null, "md", null]}>
                {formatDateDisplay(startDate)}
              </Text>
              <span>&ndash;</span>{" "}
              <Text fontSize={["xs", null, null, "md", null]}>
                {endDate ? formatDateDisplay(endDate) : "TBD"}
              </Text>
              <Tag
                size={"sm"}
                bg={getStatusColor(status)}
                borderRadius={"full"}
                px={3}
              >
                <TagLabel>{status.toLowerCase()}</TagLabel>
              </Tag>
              {currentUser?.isStaff &&
                <HStack color={"muted"} fontSize={"sm"}>
                  <Text>{getDSPName(dspInfo?.dspName ?? "")}</Text>
                  <Text>{creator?.agency.name}</Text>
                  <Text>{markets ? getMarketsString(markets) : ""}</Text>
                </HStack>
              }
            </HStack>
          </GridItem>
          <GridItem colSpan={3}>
            <HStack align={"center"} justify={"flex-end"}>
            <Link to={`${id}/admin-campaign`}>
                <IconButton
                  aria-label={"link to admin dashboard"}
                  icon={<RiAdminLine />}
                  variant={"unStyled"}
                  display={["flex", null, "none", null, null]}
                />
                <Button
                  variant={"unStyled"}
                  fontSize={["sm", null, null, "md", null]}
                  display={["none", null, "flex", null, null]}
                  leftIcon={
                    <Icon
                      as={RiAdminLine}
                      w={[4, null, null, 5, null]}
                      h={[4, null, null, 5, null]}
                    />
                  }
                  hidden={!currentUser?.isStaff}
                >
                  Admin
                </Button>
              </Link>
              <Link to={`${id}`}>
                <IconButton
                  aria-label={"link to campaign summary"}
                  icon={<HiOutlineClipboardList />}
                  variant={"unStyled"}
                  display={["flex", null, "none", null, null]}
                />
                <Button
                  variant={"unStyled"}
                  fontSize={["sm", null, null, "md", null]}
                  display={["none", null, "flex", null, null]}
                  leftIcon={
                    <Icon
                      as={HiOutlineClipboardList}
                      w={[4, null, null, 5, null]}
                      h={[4, null, null, 5, null]}
                    />
                  }
                >
                  Summary
                </Button>
              </Link>
              <Link to={`${id}/insights`}>
                <IconButton
                  aria-label={"link to insights page"}
                  icon={<GoGraph />}
                  variant={"unStyled"}
                  display={["flex", null, "none", null, null]}
                />
                <Button
                  variant={"unStyled"}
                  display={["none", null, "flex", null, null]}
                  leftIcon={
                    <Icon
                      as={GoGraph}
                      w={[4, null, null, 5, null]}
                      h={[4, null, null, 5, null]}
                    />
                  }
                  disabled={!availableMetrics?.length || !insightsPermitted && !currentUser?.isStaff}
                >
                  Insights
                </Button>
              </Link>
            </HStack>
          </GridItem>
        </Grid>
      </Box>
    </Tooltip>
  );
};
