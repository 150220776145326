import { Text, VStack } from "@chakra-ui/react";
export const TermsOfService = () => {
    return (
        <VStack align={"left"} textJustify={"left"} bg={"white"} p={4} borderRadius={"md"}>
            <Text >
                Illuma generates insights and analytics using AI technology to automatically
                sort webpages and URLs into subject matter categories. While we strive for accuracy
                in such labeling, the use of AI tools and the inherent subjectivity in
                categorization means there may be errors or ambiguity in the designated labels.
                All third-party URLs and trademarks remain owned by the applicable website operator
                or other rights holder.
            </Text>
            <Text >
                By clicking to accept these terms, you hereby acknowledge and agree,
                on behalf of the advertising agency that you are making this request for
                (the “Agency”), that:
            </Text>
            <Text >
                (i) you are properly authorised by the Agency to
                make this request to Illuma Technology Limited (“us”).
            </Text>
            <Text >
                (ii) the data that
                you are requesting from us (“Data”) relates only to adverts run by the
                Agency for the Agency’s own clients via one or more Demand Side Platform
                (DSP) (“Adverts”).
            </Text>
            <Text >
                (iii) the Agency is authorised to act on behalf of each
                such client and the Agency’s receipt of the Data is in compliance with and will not
                breach any of the Agency’s other agreements relating to the Adverts and/or the DSP(s).
            </Text>
            <Text >
                (iv) the Adverts appeared only on websites which are on the Agency’s own whitelist of
                approved websites (“Websites”) and have been paid for by the Agency.
            </Text>
            <Text >
                (v) the Agency has provided consent to the relevant DSP(s) via which the Adverts were served for details of
                the Adverts and Websites to be supplied to us, and the Agency has otherwise obtained, and
                shall be deemed to have granted to us, all rights necessary to allow us to receive and use details
                of the Adverts and Websites.
            </Text>
            <Text>
                (vi) the DSP(s) with whom you contract have warranted to
                you that they have the necessary rights to provide data regarding the Adverts and Websites
                to you; and (vii) in obtaining the Data, Illuma endeavours to comply with the preferences
                of the relevant Websites, for example as set out in robots.txt files and noindex attributes,
                and to acquire permitted information in a manner that will not interfere with the operation of web servers.
            </Text>
        </VStack>
    );
};
