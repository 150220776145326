import { useReactiveVar } from "@apollo/client";
import {
  BoxProps,
  Flex,
  Icon,
  IconButton
} from "@chakra-ui/react";
import { MutableRefObject } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import { BsImage } from "react-icons/bs";
import { insightsMetricVar } from "../../lib/cache";
import { getMetricDisplay } from "../../utils/displayGetters";


interface Props {
  reference: MutableRefObject<null>;
  name: string,
}

export const DownloadAsImage = ({ reference, name }: Props & BoxProps) => {
  const insightsMetric = getMetricDisplay(useReactiveVar(insightsMetricVar), true);
  return (
    <Flex direction={"row-reverse"}>
        <IconButton
            bg={"white"}
            aria-label={"Download"}
            variant={"unStyled"}
            color={"muted"}
            icon={<Icon as={BsImage} w={6} h={6} />}
            onClick={() => {
                  exportComponentAsPNG(reference, {
                    fileName: name + insightsMetric,
                    html2CanvasOptions:{
                      scale:2,
                      onclone: (clonedDoc) => {
                        const logo = clonedDoc.getElementById("hiddenLogo" + name);
                        if(logo !== null ) {
                          logo.style.display = "block";
                        }
                      }
                    }
                  })
            }}
        />
    </Flex>
  );
};
