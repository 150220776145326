import { useQuery } from "@apollo/client";
import { Box, Button, Grid, GridItem, HStack, Icon, IconButton, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { IoReaderOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useHitlFilters } from "..";
import { GET_REVIEWED_URL } from "../../../graphql/operations/campaignOperations/operations";
import { BiPencil } from "react-icons/bi";

interface UrlCardProps {
    url: string
    urlIndex: number
}

export const UrlCard = ({url, urlIndex}: UrlCardProps) => {

    const hitlFilters = useHitlFilters();
    const navigate = useNavigate();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const { loading, error } = useQuery(
        GET_REVIEWED_URL,
        {
            variables: {
                url: url,
            },
            fetchPolicy: "cache-and-network",
        }
    );

    useEffect(() => {
        if (error) {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    }, [error])

    return (
        <Box
            bg={"white"}
            borderRadius={"lg"}
            px={[2, null, null, 6, null]}
            py={[2, null, null, 4, null]}
            transition={"all 0.3s ease-in-out"}
            overflow={"hidden"} // ToDo: Remove this
            my={4}
        >
            <Grid
                templateColumns={"repeat(8, 1fr)"}
                w={"100%"}
                onClick={() => {
                    hitlFilters.setSelectedUrl(url);
                    hitlFilters.setSelectedIndex(urlIndex);
                }}
                _hover={{
                    cursor: "pointer"
                }}
            >
                <GridItem
                    colSpan={7}
                    maxW={"100%"}
                    px={2}
                    onClick={() => {
                        navigate("/human-in-the-loop/review");
                    }}
                >
                    <Text
                        fontWeight={"bold"}
                        fontSize={["md", null, null, "md", null]}
                        // isTruncated
                        _hover={{
                            color: "primary.dark",
                        }}
                        _active={{
                            color: "primary.darker",
                        }}
                        py={2}
                        sx={{
                        span: {
                            color: "text.light",
                            fontSize: ["md", null, null, "md", null],
                            verticalAlign: "middle",
                            mr: 2,
                        },
                        }}
                    >
                        <span>URL:</span>
                        {url}
                    </Text>
                </GridItem>
                <GridItem colSpan={1}>
                    <HStack align={"center"} justify={"flex-end"} height={"100%"}>
                        <Link to={"/human-in-the-loop/review"}>
                            <IconButton
                                aria-label={"link to correction"}
                                icon={<BiPencil />}
                                variant={"unStyled"}
                                display={["flex", null, "none", null, null]}
                                isDisabled={isButtonDisabled}
                                isLoading={loading}
                            />
                            <Button
                                variant={"unStyled"}
                                fontSize={["sm", null, null, "md", null]}
                                display={["none", null, "flex", null, null]}
                                leftIcon={
                                    <Icon
                                        as={BiPencil}
                                        w={[4, null, null, 5, null]}
                                        h={[4, null, null, 5, null]}
                                    />
                                }

                            >
                                Review
                            </Button>
                        </Link>
                        <Link to={"/human-in-the-loop/past-review"}>
                            <IconButton
                                aria-label={"link to correction"}
                                icon={<IoReaderOutline />}
                                variant={"unStyled"}
                                display={["flex", null, "none", null, null]}
                                isDisabled={isButtonDisabled}
                                isLoading={loading}
                            />
                            <Button
                                variant={"unStyled"}
                                fontSize={["sm", null, null, "md", null]}
                                display={["none", null, "flex", null, null]}
                                leftIcon={
                                    <Icon
                                        as={IoReaderOutline}
                                        w={[4, null, null, 5, null]}
                                        h={[4, null, null, 5, null]}
                                    />
                                }
                                isDisabled={isButtonDisabled}
                                isLoading={loading}
                                // hidden={!currentUser?.isStaff}
                            >
                                Correction
                            </Button>
                        </Link>
                    </HStack>
                </GridItem>
            </Grid>
        </Box>
    )

}
