import { BoxProps } from "@chakra-ui/react";
import { getDSPName, unCamelcase } from "../../utils/displayGetters";
import { DetailField } from "../../components/detailField/DetailField";
import { useCampaignInfo } from "./index";
import { SectionBox } from "../../components/sectionBox/SectionBox";

export const DSPDetailsSummary = (props: BoxProps) => {
  const { campaign } = useCampaignInfo();
  return (
    <SectionBox
      sectionHeader={`${getDSPName(campaign.dspInfo.dspName ?? "")} Details`}
      {...props}
    >
      {Object.entries(JSON.parse(campaign.dspSpecific))
        .filter(([, value]) => !!value)
        .map(([key, value]) => (
          <DetailField key={key} label={unCamelcase(key)} value={value} />
        ))}
    </SectionBox>
  );
};
