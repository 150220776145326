import {
  DataTypes,
  JSONSchema7Extended,
  SchemaKeywords,
} from "json-schema-yup-transformer/dist/schema";
import convertToYup from "json-schema-yup-transformer";

export interface IFormDataClassField extends JSONSchema7Extended {
  type: DataTypes | DataTypes[];
  label: string;
  /**
   * Leaving this here as another way to declare an object type whose keys are
   * dependent on the keys in an enum
   *
   * errors?: { [key in keyof typeof SchemaKeywords]: string };
   * */
  errors?: Partial<Record<SchemaKeywords, any>>;
  isRequired: boolean;
  placeholder?: string;
}

export class FormDataClass {
  fields?: Record<string, IFormDataClassField>;
  /**
   * Config is where configuration options for generating a yup schema lives.
   * Should mostly be used for defining field validation errors. Errors should
   * be added to the field itself.
   */
  config?: Record<string, any>;

  /**
   * Exclude fields that are not part of the JSON schema definitions when generating
   * JSON schema for class.
   */
  readonly exclusionList: string[] = [
    "label",
    "isRequired",
    "placeholder",
    "errors",
  ];

  constructor(exclusionListExtras: string[] = []) {
    if (new.target === FormDataClass) {
      throw new TypeError(
        `Cannot construct ${new.target.name} instances directly.`
      );
    }
    this.exclusionList = [...this.exclusionList, ...exclusionListExtras];
  }

  getDefaultValues(): Record<string, any> {
    if (this.fields === undefined) {
      throw new TypeError("Fields have not been defined!");
    }
    return Object.keys(this.fields).reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue]: this.fields?.[currentValue].default,
      }),
      {}
    );
  }

  getJsonSchema(): JSONSchema7Extended {
    if (this.fields === undefined) {
      throw new TypeError("Fields have not been defined for this form class!");
    } else {
      return {
        type: "object",
        $schema: "http://json-schema.org/draft-07/schema#",
        $id: this.constructor.name.toLowerCase(),
        properties: {
          ...this._spreadFields(this.fields),
        },
        required: [...this._getRequiredFields(this.fields)],
      };
    }
  }

  getYupSchema() {
    return convertToYup(this.getJsonSchema(), {
      ...this.config,
      errors: this._getErrors(),
    });
  }

  _getErrors(): Record<string, any> {
    if (this.fields === undefined) {
      throw new TypeError("Fields have not been defined for this form class!");
    } else {
      return Object.entries(this.fields)
        .filter(([, { errors }]) => errors !== undefined)
        .reduce(
          (accumulator, [key, { errors }]) => ({
            ...accumulator,
            [key]: errors,
          }),
          {}
        );
    }
  }

  _getRequiredFields(fields: Record<string, IFormDataClassField>) {
    return Object.keys(fields).filter((key) => fields[key].isRequired);
  }

  _spreadFields(fields: Record<string, any>): Record<string, any> {
    return Object.entries(fields).reduce(
      (accumulator, [key, item]) => ({
        ...accumulator,
        [key]: this._excludeKeys(item),
      }),
      {}
    );
  }

  _excludeKeys(item: Record<string, IFormDataClassField>) {
    return Object.keys(item)
      .filter((key) => !this.exclusionList.includes(key))
      .reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue]: item[currentValue],
        }),
        {}
      );
  }
}
