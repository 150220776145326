import { OwnerDetails } from "./OwnerDetails"


export const CampaignAdminOverview = () => {
    return (
        <>
            <OwnerDetails />
        </>
    )
}
