import { useEffect } from "react";
import {
  Button,
  ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { FilterIcon } from "../../../assets/icons/FilterIcon";
import { AiFillCaretDown } from "react-icons/ai";
import { FaCircle } from "react-icons/fa";
import { InsightMetricsEnum } from "../../../graphql/generated";
import { useReactiveVar } from "@apollo/client";
import { categoryOrderingVar } from "../../../lib/cache";
import { getMetricDisplay } from "../../../utils/displayGetters";

type FilterMenuOption = { label: string; value: string };

interface Props {
  availableMetrics: InsightMetricsEnum[];
}

export const CategoryListFilter = ({
  availableMetrics,
  ...rest
}: Props & ButtonProps) => {
  const { orderBy, ordering } = useReactiveVar(categoryOrderingVar);
  // Filter Menu Groups
  const orderByOptions = ["ALPHABETICALLY", ...availableMetrics];
  const orderByGroup: FilterMenuOption[] = orderByOptions.map(
    (metric: string): FilterMenuOption => ({
      label: getMetricDisplay(metric),
      value: metric,
    })
  );
  const orderingGroup: FilterMenuOption[] = [
    {
      label: "ascending",
      value: "ASC",
    },
    {
      label: "descending",
      value: "DESC",
    },
  ];

  useEffect(() => {
    if (!ordering) {
      categoryOrderingVar({ orderBy, ordering: orderingGroup[0].value });
    }
    if (!orderBy || !orderByOptions.includes(orderBy)) {
      categoryOrderingVar({ orderBy: orderByGroup[0].value, ordering });
    }
  }, []);
  return (
    <Menu
      placement={"bottom-end"}
      variant={"filterMenu"}
      closeOnSelect={false}
      autoSelect={false}
    >
      <MenuButton
        as={Button}
        variant={"filterMenu"}
        rightIcon={<Icon as={AiFillCaretDown} w={4} h={4} />}
        {...rest}
      >
        <FilterIcon w={6} h={6} />
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          type={"radio"}
          title={"ORDER BY"}
          value={orderBy}
          onChange={(value: string) =>
            categoryOrderingVar({ ordering, orderBy: value })
          }
        >
          {orderByGroup.map(({ label, value }) => (
            <MenuItemOption
              value={value}
              key={label}
              icon={<Icon w={3} h={3} as={FaCircle} fill={"primary.dark"} />}
            >
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        <MenuDivider />
        <MenuOptionGroup
          type={"radio"}
          title={"ORDER"}
          value={ordering}
          onChange={(value: string) =>
            categoryOrderingVar({ ordering: value, orderBy })
          }
        >
          {orderingGroup.map(({ label, value }) => (
            <MenuItemOption
              value={value}
              key={label}
              icon={<Icon w={3} h={3} as={FaCircle} fill={"primary.dark"} />}
            >
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
