export default {
  baseStyle: {
    control: {
      borderRadius: "md",
      _checked: {
        bg: "text.dark",
        borderColor: "text.dark",
        _hover: {
          bg: "text.dark",
          borderColor: "primary.dark",
        },
      },
      _indeterminate: {
        bg: "text.dark",
        borderColor: "text.dark",
        _hover: {
          bg: "text.dark",
          borderColor: "primary.dark",
        },
      },
      _focus: {
        boxShadow: "none",
      },
    },
    icon: {
      color: "primary.dark",
    },
  },
};
