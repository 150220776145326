import { useQuery } from "@apollo/client"
import { Flex, Text, useTheme } from "@chakra-ui/react"
import { useState } from "react"
import { ComponentLoading } from "../../../../components/loading/ComponentLoading"
import { SectionBox } from "../../../../components/sectionBox/SectionBox"
import { DspChoices, DspRevenueType } from "../../../../graphql/generated"
import { GET_DSP_REVENUE } from "../../../../graphql/operations/campaignOperations/operations"
import { revenueOfficeVar, revenueYearVar } from "../../../../lib/cache"
import { ChartBox } from "../ChartBox"
import { Highlights } from "../Highlights"
import { DspRevenuePieChart } from "./DspRevenuePieChart"

export const DspRevenue = () => {

    const dataColors = useTheme().colors.distinctDataColors;
    const [year, setYear] = useState<number>(revenueYearVar);
    const [officeSelect, setOfficeSelect] = useState(revenueOfficeVar);

    const { data, loading, error } = useQuery(GET_DSP_REVENUE, {
        variables: {
          yearFilter: year,
          officeFilter: officeSelect.value === "ALL" ? undefined : officeSelect.value,
        },
        fetchPolicy: "cache-and-network",
    });

    if (loading) {
        return (
            <SectionBox>
                <ComponentLoading text={"Loading Pie Chart..."} p={5}/>
            </SectionBox>
        )
    }

    if (error) {
        return (
          <SectionBox>
            There seems to be a problem
          </SectionBox>
        );
    }

    const dspSplit = getDspSplit(data.revenueByDsp);

    // Check if all revenue values are null
    if (!dspSplit.xandr.revenue && !dspSplit.ecd.revenue && !dspSplit.managed.revenue) {
        return(
            <ChartBox
                chartBoxHeader={"DSP REVENUE PIE CHART"}
                officeFilter={{officeSelect: officeSelect, setOfficeSelect: setOfficeSelect}}
                yearFilter={{year: year, setYear: setYear}}
            >
                <Text mx={8}>
                    No data available
                </Text>
            </ChartBox>
        )
    }

    const prepareHighlights = () => {
        const highlights = [];

        let sum = 0
        data.revenueByDsp.forEach((month: DspRevenueType) => sum += month.revenue);

        //const dspSplit = getDspSplit(data.revenueByDsp);
        if (dspSplit.xandr.revenue || dspSplit.managed.revenue || dspSplit.ecd.revenue) {
            highlights.push({
                label: `${officeSelect.label} revenue for ${year}`,
                value: `$${sum.toLocaleString()}`,
                color: dataColors.red
            })
        }
        if (dspSplit.xandr.revenue) {
            highlights.push({
                label: `Xandr revenue for ${year}`,
                value: `$${dspSplit.xandr.revenue?.toLocaleString()} (${Math.round(dspSplit.xandr.percentage)}%)`,
                color: "black"
            })
        }
        if (dspSplit.managed.revenue) {
            highlights.push({
                label: `Managed service revenue for ${year}`,
                value: `$${dspSplit.managed.revenue?.toLocaleString()} (${Math.round(dspSplit.managed.percentage)}%)`,
                color: "black"
            })
        }
        if (dspSplit.ecd.revenue) {
            highlights.push({
                label: `ECD revenue for ${year}`,
                value: `$${dspSplit.ecd.revenue.toLocaleString()} (${Math.round(dspSplit.ecd.percentage)}%)`,
                color: "black"
            })
        }
        return highlights

    }

    return (
        <ChartBox
            chartBoxHeader={"DSP REVENUE PIE CHART"}
            officeFilter={{officeSelect: officeSelect, setOfficeSelect: setOfficeSelect}}
            yearFilter={{year: year, setYear: setYear}}
            isDownloadable={true}
        >
            <Flex>
                <DspRevenuePieChart
                    revenueByDspData={data.revenueByDsp}
                />
                <Highlights highlightsList={prepareHighlights()}/>
            </Flex>
        </ChartBox>
    )
}

const getDspSplit = (revenueByDsp: DspRevenueType[]) => {

    let xandrRevenue = 0;
    revenueByDsp.filter((dsp) => dsp.dsp === DspChoices.Xandr).
        forEach((month: DspRevenueType) => xandrRevenue += month.revenue);

    let managedRevenue = 0;
    revenueByDsp.filter((dsp) => dsp.dsp === "MANAGED").
        forEach((month: DspRevenueType) => managedRevenue += month.revenue);

    let ecdRevenue = 0;
    revenueByDsp.filter((dsp) => dsp.dsp !== DspChoices.Xandr && dsp.dsp !== "MANAGED").
        forEach((month: DspRevenueType) => ecdRevenue += month.revenue);

    return {
        xandr: {
            revenue: xandrRevenue,
            percentage: (xandrRevenue/(xandrRevenue + managedRevenue + ecdRevenue))*100
        },
        managed: {
            revenue: managedRevenue,
            percentage: (managedRevenue/(xandrRevenue + managedRevenue + ecdRevenue))*100
        },
        ecd: {
            revenue: ecdRevenue,
            percentage: (ecdRevenue/(xandrRevenue + managedRevenue + ecdRevenue))*100
        }
    }
}
