import { Navigate, useParams } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_PACKAGE_INFO } from "../../graphql/operations/campaignOperations/operations";
import { ComponentLoading } from "../../components/loading/ComponentLoading";
import { PackageType } from "../../graphql/generated";
import { Box, Flex, Grid, GridItem, HStack, Icon, Text } from "@chakra-ui/react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { createContext } from "react";
import { useContext } from "react";
import { DetailsSummary } from "./DetailsSummary";
import { TargetDetailsSummary } from "./TargetDetailsSummary";
import { userDetailsVar } from "../../lib/cache";


interface IPackageSummary {
    segment: PackageType;
  }

const segmentContext = createContext({} as IPackageSummary);

export const useSegmentInfo = () => {
    return useContext(segmentContext);
  };

export const SegmentSummaryPage = () => {

    const currentUser = useReactiveVar(userDetailsVar);
    const { id: segmentId } = useParams();

    if (!segmentId) {
        return <Navigate to={"/404"} />;
      }

    const { data, loading, error } = useQuery(GET_PACKAGE_INFO, {
        variables: {
            id: segmentId
        },
        fetchPolicy: "cache-and-network",
    });

    if (!currentUser) {
        return <ComponentLoading text={"Loading page..."} />;
    }

    if (!(currentUser?.isStaff || currentUser?.agency.hitlPermission?.isPermitted)) {
        return <Navigate to={"/"} />

    }

    if (loading && !data) {
        return <ComponentLoading text={"Loading your segment information..."} />;
    }

    if (error) {
        return <Navigate to={"/404"} />;
    }

    const segmentInfo: PackageType = data.packageInfo;

    return (
        <segmentContext.Provider value={{ segment: segmentInfo }}>
            <Flex mb={8} justifyContent={"space-between"}>
                <Box
                    as={"button"}
                    onClick={() => history.back()}
                >
                    <HStack
                        spacing={1}
                        _hover={{
                        stroke: "primary.darker",
                        color: "primary.darker",
                        }}
                        align={"center"}
                    >
                        <Icon
                        as={IoIosArrowRoundBack}
                        width={6}
                        height={6}
                        strokeWidth={10}
                        />
                        <Text fontSize={"sm"}>
                        Back
                        {/* {currentUser?.isStaff ? "Back to Campaigns" : "Back to Your Campaigns"} */}
                        </Text>
                    </HStack>
                </Box>
            </Flex>
            <Grid gap={6} templateColumns={"repeat(8, 1fr)"}>
                <GridItem colSpan={4}>
                        <DetailsSummary />
                </GridItem>
                <GridItem colSpan={4}>
                    <TargetDetailsSummary />
                </GridItem>
            </Grid>
        </segmentContext.Provider>
    )
}
