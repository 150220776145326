import { BoxProps } from "@chakra-ui/react";
import { DetailField } from "../../../components/detailField/DetailField";
import { SectionBox } from "../../../components/sectionBox/SectionBox";
import { useCampaignInfo } from "../index";

export const OwnerDetails = (props: BoxProps) => {

    const {campaign: { creator }} = useCampaignInfo();

    return (
        <SectionBox sectionHeader={"Owner Details"} {...props}>
          <DetailField
            label={"Name"}
            value={`${creator.firstName} ${creator.lastName}`}
          />
          <DetailField label={"Email"} value={creator.email} />
          <DetailField label={"Agency"} value={creator.agency.name} />
        </SectionBox>
      );
}
