import { useReactiveVar } from "@apollo/client"
import { Flex } from "@chakra-ui/react"
import { Navigate, Outlet } from "react-router-dom"
import { ContentHeader } from "../../components/contentHeader/ContentHeader"
import { ComponentLoading } from "../../components/loading/ComponentLoading"
import { SidebarGroup } from "../../components/sidebar/SidebarGroup"
import { SidebarItem } from "../../components/sidebar/SidebarItem"
import { SidebarV2 } from "../../components/sidebar/SidebarV2"
import { AccountsUserRoleChoices } from "../../graphql/generated"
import { userDetailsVar } from "../../lib/cache"

export const allowedRevenueUsers = [
    AccountsUserRoleChoices.SeniorManagement,
    AccountsUserRoleChoices.Tech,
    AccountsUserRoleChoices.Sales
];

export const AdminDashboardPage = () => {

    const currentUser = useReactiveVar(userDetailsVar);

    if(currentUser?.isStaff === undefined) {
        return <ComponentLoading />
    }

    if(!currentUser.isStaff) {
        return <Navigate to={"/campaigns"} />
    }

    return (
        <>
            <ContentHeader />
            <Flex>
                <SidebarV2>
                    {/* <SidebarItem
                        title={"Segment Rotation"}
                        url={"segment-rotation"}
                    /> */}
                    {allowedRevenueUsers.includes(currentUser.role) &&
                        <SidebarGroup
                            title={"Revenue"}
                            removeTopBorder={true}
                        >
                            <SidebarItem
                                isSubItem={true}
                                title={"1. Monthly Revenue"}
                                url={"revenue-forecast"}
                            />
                            <SidebarItem
                                isSubItem={true}
                                title={"2. DSP Revenue"}
                                url={"dsp-revenue"}
                            />
                            <SidebarItem
                                isSubItem={true}
                                title={"3. Monthly DSP Revenue"}
                                url={"monthly-dsp-revenue"}
                            />
                        </SidebarGroup>
                    }
                </SidebarV2>
                <Flex
                    id={"contentArea"}
                    flexDirection={"column"}
                    w={"full"}
                    mx={2}
                    overflowY={"scroll"}
                    maxH={"100vh"}
                >
                    <Outlet />
                </Flex>
            </Flex>
        </>
    )
}
