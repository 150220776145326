import { CreativeIdField } from "./CreativeIdField";
import { useCampaignInfo } from "./index";
import { Box, BoxProps, Button, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { CreativeIdModal } from "./CreativeIdModal";
import { GoPlus } from "react-icons/go";
import { DspChoices } from "../../graphql/generated";

export const CreativeIdsSummary = (props: BoxProps) => {
  const [creativeId, setCreativeId] = useState("");
  const { campaign } = useCampaignInfo();
  const { isOpen, onOpen, onClose } = useDisclosure();


  return campaign.dspInfo.dspName === DspChoices.Roku ? (
    <>
      <Box bg={"white"}  mt={4} borderRadius={"lg"} {...props}>
        <Text
          fontWeight={"bold"}
          fontSize={"sm"}
          color={"primary.darker"}
          px={10} pt={4}
        >
          Creative IDs
        </Text>
        <Box>
          {campaign.creativeIds.map(
            (
              {
                creativeId,
                isAudited,
              }: {
                creativeId: string;
                isAudited: boolean;
              },
              ix: number
            ) => (
              <CreativeIdField
                key={`${creativeId}-=${ix}`}
                creativeId={creativeId}
                isAudited={isAudited}
                includeBorder={ix < campaign.creativeIds.length - 0}
                setCreativeId={setCreativeId}
                onOpen={onOpen}
              />
            )
          )}
        </Box>
        <Box
          px={8}
          py={2}
        >
          <Button
            variant={"unStyled"}
            leftIcon={<Icon color={"primary.darker"} as={GoPlus} />}
            _hover={{ bg: "white" }}
            onClick={() => {
              setCreativeId("")
              onOpen()
            }}
          >
            <Text color={"primary.darker"} fontWeight={"bold"} fontSize={"sm"}>
              Add Creative ID
            </Text>
          </Button>
        </Box>
      </Box>
      <CreativeIdModal
        isOpen={isOpen}
        onClose={onClose}
        creativeId={creativeId}
      />
    </>
  ) : (
    <></>
  );
};
