import * as React from "react";
import { BoxProps, VStack } from "@chakra-ui/react";

export const CampaignList: React.FC<BoxProps> = ({ children, ...boxProps }) => {
  return (
    <VStack
      align={"stretch"}
      spacing={[2, null, null, 4, null]}
      w={"100%"} {...boxProps}>
      {children}
    </VStack>
  );
};
