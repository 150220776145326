import {
  Box,
  BoxProps,
  Checkbox,
  Radio,
  RadioGroup,
  Stack,
  Wrap,
  Collapse,
  Switch,
} from "@chakra-ui/react";
import { Field, FieldProps, FormikProps, FormikValues } from "formik";
import { ChangeEvent, Dispatch, useEffect, useState } from "react";
import {
  CampaignCreativeTypeChoices,
  DspChoices,
  // InsightCategoryChoices,
  ProductCategoryChoices,
} from "../../../../graphql/generated";
import {
  // formatTextDisplay,
  getDSPName,
} from "../../../../utils/displayGetters";
import { FormInputField } from "../../../../components/formInputField/FormInputField";
import { SelectField } from "../../../../components/selectField/SelectField";
import { FormFieldLabel } from "../../../../components/formFieldLabel/FormFieldLabel";
import {
  CountryOptionType,
  getCountries,
} from "../../../../utils/getCountries";
import { DatePicker } from "../../../../components/datePicker/DatePicker";
import { IFormDataClassField } from "../../../../utils/formsSchema/FormDataClass";
import { FormSubSectionHeader } from "../../../../components/formSubSectionHeader/FormSubSectionHeader";
import { dspMemberInfo, illumaProductInfo } from "../../TooltipInfo";
import { useReactiveVar } from "@apollo/client";
import { userDetailsVar } from "../../../../lib/cache";
import { DropdownCheckboxTree } from "../../../../components/dropdownCheckboxTree/DropdownCheckboxTree";
import { usePrecisionCategoriesOptions } from "../..";
import { CheckboxOptionType } from "../../../../components/checkboxTree/CheckboxTreeV3";
// import { PrecisionCategoriesToolTip } from "../../PrecisionCategoriesToolTip";
// import { SmartCategoriesIcon } from "../../../../assets/icons/SmartCategoriesIcon";
// import { ReactsIcon } from "../../../../assets/icons/ReactsIcon";

interface Props {
  fields: Record<string, IFormDataClassField>;
  formik: FormikProps<FormikValues>;
  setDspState: Dispatch<string>;
  currentDsp: string;
  defaultValues: Record<string, any>;
}

export const BasicDetails = ({
  formik,
  fields,
  setDspState,
  currentDsp,
  defaultValues,
  ...boxProps
}: Props & BoxProps) => {
  const {
    name,
    dsp,
    markets,
    startDate,
    endDate,
    illumaCore,
    // illumaSmart,
    // illumaSmartCtv,
    illumaCompass,
    illumaPrecision,
    illumaPrecisionCtv,
    creativeType,
    isManaged
  } = fields;
  const precisionCategories = usePrecisionCategoriesOptions();
  const currentUser = useReactiveVar(userDetailsVar);
  // const [showInsightCategoryBox, setShowInsightCategoryBox] =
  //   useState<boolean>(false);
  // const [showInsightCategoryBoxCtv, setShowInsightCategoryBoxCtv] =
  //   useState<boolean>(false);
  const [showPrecisionCategoryBox, setShowPrecisionCategoryBox] =
    useState<boolean>(false);
  const [showPrecisionCategoryBoxCtv, setShowPrecisionCategoryBoxCtv] =
    useState<boolean>(false);
  const [isManagedSwitch, setIsManagedSwitch] = useState<boolean>(false);
  const [selectedPrecisionOptions, setSelectedPrecisionOptions] = useState<CheckboxOptionType[]>([]);
  const [selectedPrecisionCtvOptions, setSelectedPrecisionCtvOptions] = useState<CheckboxOptionType[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<{cat: ProductCategoryChoices, checked: boolean}[]>(
    [{cat: ProductCategoryChoices.Reacts, checked: true}]
  );
  const { setFieldTouched, setFieldValue, values, touched, errors, resetForm } = formik;

  useEffect(() => {
    const { dspSpecific, kpis, ...previousFormValues } = values;

    resetForm({
      values: {
        ...previousFormValues,
        kpis: defaultValues.kpis,
        dspSpecific: defaultValues.dspSpecific,
      },
    });
  }, [currentDsp]);

  useEffect(() => {
    setFieldValue("dspSpecific", defaultValues.dspSpecific);
    setFieldValue("kpis", defaultValues.kpis);
    setFieldValue("isManaged", false);
    setIsManagedSwitch(false);
  }, [defaultValues.dsp]);

  useEffect(() => {
    // Adding children to mutation
    // const precisionCategoryIds = selectPrecisionChildCategories(selectedPrecisionOptions, true);
    // const precisionCtvCategoryIds = selectPrecisionChildCategories(selectedPrecisionCtvOptions);

    if (selectedProducts.findIndex(p => p.cat === ProductCategoryChoices.Precision && p.checked === true) >= 1) {
      const precisionIds = selectedPrecisionOptions.map((option) => option.id);
      setFieldValue(
        "illumaPrecision",
        // precisionIds.length === 0 ? null :
        precisionIds
      );
    }

    if (selectedProducts.findIndex(p => p.cat === ProductCategoryChoices.PrecisionCtv && p.checked === true) >= 1) {
      const precisionCtvIds = selectedPrecisionCtvOptions.map((option) => option.id);
      setFieldValue(
        "illumaPrecisionCtv",
        // precisionCtvIds.length === 0 ? null :
        precisionCtvIds
      );
    }

  }, [selectedPrecisionOptions, selectedPrecisionCtvOptions])

  useEffect(() => {
    if (selectedProducts.every((product) => product.checked === false)) {
      setFieldValue("productSelected", false, true);
    } else {
      setFieldValue("productSelected", true, true);
    }
  }, [selectedProducts])

  const handleDspChanged = (
    { field }: Pick<FieldProps, "field">,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const newDsp = e.target.value;
    setFieldValue(field.name, newDsp, true);
    setDspState(newDsp);
  };

  // Old version can be found at bottom
  const handleProductSelectedV2 = (cat: ProductCategoryChoices, checked: boolean) => {

    if (selectedProducts.some((product) => product.cat === cat)) {
      // Find and replace
      const index = selectedProducts.findIndex((product) => product.cat === cat);
      const replacer = [...selectedProducts];
      replacer[index] = {cat: cat, checked: checked};
      setSelectedProducts(replacer);
    } else {
      // Does't already exist
      setSelectedProducts(prev => [...prev, {cat: cat, checked: checked}]);
    }

    if (cat === ProductCategoryChoices.Reacts) {
      setFieldValue("illumaCore", checked, true);
      setFieldTouched("illumaCore", true, true);
    } else if (cat === ProductCategoryChoices.Compass) {
      setFieldValue("illumaCompass", checked, true);
      setFieldTouched("illumaCompass", true, true);
    }

  }

  return (
    <Box p={8} bg={"white"} borderRadius={"md"} {...boxProps}>
      <FormSubSectionHeader label={"Basic Details"}/>
      <Field name={"dsp"}>
        {({ field, form }: FieldProps) => {
          const { value, ...rest } = field;
          return (
            <FormFieldLabel
              mt={0}
              isRequired={dsp.isRequired}
              label={dsp.label}
              isInvalid={!!(form.errors["dsp"] && !!form.touched["dsp"])}
            >
              <RadioGroup {...rest} value={value}>
                <Wrap spacing={"3%"} p={2}>
                  {Object.values(DspChoices).map((k, ix) => {

                    // Hiding Mediamath option, no longer exists
                    if (k === DspChoices.Mediamath) {
                      return
                    }

                    return (
                      <Radio
                        key={`${k}${ix}`}
                        value={k}
                        minW={"23%"}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          // onChange(e);
                          handleDspChanged({ field }, e);
                        }}
                      >
                        {getDSPName(k)}
                      </Radio>
                    );
                  })}
                </Wrap>
              </RadioGroup>
            </FormFieldLabel>
          );
        }}
      </Field>
      <Collapse in={(currentDsp === DspChoices.Xandr && currentUser?.isStaff)}>
        <Field name={"isManaged"}>
          {({ field }: FieldProps) => (
            <FormFieldLabel
              flexDirection={"row"}
              label={isManaged.label}
              isRequired={isManaged.isRequired}
              mb={3}
            >
              <Switch
                {...field}
                defaultChecked={isManaged.default as boolean}
                onChange={() => {
                  setFieldValue("isManaged", !isManagedSwitch);
                  setIsManagedSwitch(!isManagedSwitch);
                }}
                isChecked={isManagedSwitch}
                ml={3}
              />
            </FormFieldLabel>
          )}
        </Field>
      </Collapse>
      <Field name={"name"}>
        {({ field, form }: FieldProps) => (
          <FormInputField
            inputFieldProps={field}
            fieldType={"text"}
            isRequired={name.isRequired}
            label={name.label}
            isInvalid={!!(form.errors.name && !!form.touched.name)}
            placeholder={name.placeholder}
            errorMessage={form.errors?.name?.toString()}
          />
        )}
      </Field>
      <FormFieldLabel
        label={"illuma Product"}
        isRequired={true}
        isInvalid={
          !!(
            errors?.productSelected &&
            (touched.illumaCore || touched.illumaSmart)
          )
        }
        errorMessage={errors?.productSelected?.toString()}
        toolTip={illumaProductInfo}
      >
        <Stack
          spacing={[5, 10, null, null, null]}
          direction={["column", "row", null, null, null]}
        >
        {/* <Flex
          flexWrap={"wrap"}
          gridGap={5}
        > */}
          <Field
            name={"illumaCore"}
            as={Checkbox}
            defaultChecked={illumaCore.default}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleProductSelectedV2(ProductCategoryChoices.Reacts, e.target.checked);
            }}
          >
            {illumaCore.label}
            {/*<ReactsIcon w={28} h={20} />*/}
          </Field>

          {/* HIDING OLD SMART CATEGORIES */}
          {/* <Field>
            {({ form }: FieldProps) => (
              <Checkbox
                defaultChecked={showInsightCategoryBox}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setShowInsightCategoryBox(e.target.checked);
                  if (!e.target.checked) {
                    form.setFieldValue("illumaSmart", null, false);
                  } else {
                    form.setFieldValue("illumaSmart", [], true);
                    // form.setFieldTouched("illumaSmart", true, true);
                  }
                  handleProductSelectedV2(ProductCategoryChoices.Smart, e.target.checked);
                }}
              >
                <SmartCategoriesIcon w={28} h={20} />
                {illumaSmart.label}
              </Checkbox>
            )}
          </Field> */}
          {/* <Field>
            {({ form }: FieldProps) => (
              <Checkbox
                defaultChecked={showInsightCategoryBoxCtv}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setShowInsightCategoryBoxCtv(e.target.checked);
                  if (!e.target.checked) {
                    form.setFieldValue("illumaSmartCtv", null, false);
                  } else {
                    form.setFieldValue("illumaSmartCtv", [], true);
                    // form.setFieldTouched("illumaSmartCtv", true, true);
                  }
                  handleProductSelectedV2(ProductCategoryChoices.SmartCtv, e.target.checked);
                }}
              >
                <SmartCategoriesIcon w={28} h={20} />
                {illumaSmartCtv.label}
              </Checkbox>
            )}
          </Field> */}
          <Field>
            {({ form }: FieldProps) => (
              <Checkbox
                defaultChecked={showPrecisionCategoryBox}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setShowPrecisionCategoryBox(e.target.checked);
                  if (!e.target.checked) {
                    form.setFieldValue("illumaPrecision", null, false);
                  } else {
                    form.setFieldValue("illumaPrecision", [], true);
                    // form.setFieldTouched("illumaPrecision", true, true);
                  }
                  handleProductSelectedV2(ProductCategoryChoices.Precision, e.target.checked);
                  // setSelectedPrecisionOptions([]);
                }}
              >
                {/*<SmartCategoriesIcon w={28} h={20} />*/}
                {illumaPrecision.label}
              </Checkbox>
            )}
          </Field>
          <Field>
            {({ form }: FieldProps) => (
              <Checkbox
                defaultChecked={showPrecisionCategoryBoxCtv}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setShowPrecisionCategoryBoxCtv(e.target.checked);
                  if (!e.target.checked) {
                    form.setFieldValue("illumaPrecisionCtv", null, false);
                  } else {
                    form.setFieldValue("illumaPrecisionCtv", [], true);
                  }
                  handleProductSelectedV2(ProductCategoryChoices.PrecisionCtv, e.target.checked);
                  // setSelectedPrecisionCtvOptions([]);
                }}
              >
                {/*<SmartCategoriesIcon w={28} h={20} />*/}
                {illumaPrecisionCtv.label}
              </Checkbox>
            )}
          </Field>
          <Field
            name={"illumaCompass"}
            as={Checkbox}
            defaultChecked={illumaCompass.default}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleProductSelectedV2(ProductCategoryChoices.Compass, e.target.checked);
            }}
          >
            {illumaCompass.label}
            {/*<ReactsIcon w={28} h={20} />*/}
          </Field>
        {/* </Flex> */}
        </Stack>
      </FormFieldLabel>


      {/* <Collapse in={showInsightCategoryBox} unmountOnExit={true}>
        <Field name={"illumaSmart"}>
          {({ field, form }: FieldProps) => {
            const onChange = (option: { label: string; value: string }[]) => {
              form.setFieldValue(
                field.name,
                option.map((item) => item.value)
              );
            };

            const onMenuClose = () => {
              form.setFieldTouched(field.name, true, true);
            };
            return (
              <FormFieldLabel
                label={illumaSmart.label}
                isRequired={true}
                isInvalid={
                  !!(
                    form.errors["illumaSmart"] && !!form.touched["illumaSmart"]
                  )
                }
                errorMessage={form.errors?.illumaSmart?.toString()}
              >
                <SelectField
                  name={"illumaSmart"}
                  onChange={onChange}
                  onMenuClose={onMenuClose}
                  isMulti={true}
                  options={Object.values(InsightCategoryChoices).map((cat) => ({
                    label: formatTextDisplay(cat),
                    value: cat,
                  }))}
                  placeholder={illumaSmart.placeholder}
                />
              </FormFieldLabel>
            );
          }}
        </Field>
      </Collapse>

      <Collapse in={showInsightCategoryBoxCtv} unmountOnExit={true}>
        <Field name={"illumaSmartCtv"}>
          {({ field, form }: FieldProps) => {
            const onChange = (option: { label: string; value: string }[]) => {
              form.setFieldValue(
                field.name,
                option.map((item) => item.value)
              );
            };

            const onMenuClose = () => {
              form.setFieldTouched(field.name, true, true);
            };
            return (
              <FormFieldLabel
                label={illumaSmartCtv.label}
                isRequired={true}
                isInvalid={
                  !!(
                    form.errors["illumaSmartCtv"] && !!form.touched["illumaSmartCtv"]
                  )
                }
                errorMessage={form.errors?.illumaSmartCtv?.toString()}
              >
                <SelectField
                  name={"illumaSmartCtv"}
                  onChange={onChange}
                  onMenuClose={onMenuClose}
                  isMulti={true}
                  options={Object.values(InsightCategoryChoices).map((cat) => ({
                    label: formatTextDisplay(cat),
                    value: cat,
                  }))}
                  placeholder={illumaSmartCtv.placeholder}
                />
              </FormFieldLabel>
            );
          }}
        </Field>
      </Collapse> */}

      <Collapse in={showPrecisionCategoryBox} unmountOnExit={true}>
        {/* New precison categories dropdown */}
        <Field name={"illumaPrecision"}>
          {({ field, form }: FieldProps) => {

            const onMenuClose = () => {
              form.setFieldTouched(field.name, true, true);
            };
            return (
              <FormFieldLabel
                label={illumaPrecision.label}
                isRequired={true}
                isInvalid={
                  !!(
                    form.errors["illumaPrecision"] && !!form.touched["illumaPrecision"]
                  )
                }
                errorMessage={form.errors?.illumaPrecision?.toString()}
                // toolTipComponent={<PrecisionCategoriesToolTip />}
              >
                <DropdownCheckboxTree
                  checkboxOptions={precisionCategories.precisionCategoriesOptions}
                  selectedOptions={selectedPrecisionOptions}
                  setSelectedOptions={setSelectedPrecisionOptions}
                  onPopoverClose={onMenuClose}
                  limitOptions={2} // Limiting options to tier 2
                  handleChildren={true}
                  handleParents={true}
                />
              </FormFieldLabel>
            );
          }}
        </Field>
      </Collapse>

      <Collapse in={showPrecisionCategoryBoxCtv} unmountOnExit={true}>
        {/* New precison categories dropdown */}
        <Field name={"illumaPrecisionCtv"}>
          {({ field, form }: FieldProps) => {

            const onMenuClose = () => {
              form.setFieldTouched(field.name, true, true);
            };

            return (
              <FormFieldLabel
                label={illumaPrecisionCtv.label}
                isRequired={true}
                isInvalid={
                  !!(
                    form.errors["illumaPrecisionCtv"] && !!form.touched["illumaPrecisionCtv"]
                  )
                }
                errorMessage={form.errors?.illumaPrecisionCtv?.toString()}
                // toolTipComponent={<PrecisionCategoriesToolTip />}
              >
                <DropdownCheckboxTree
                  checkboxOptions={precisionCategories.precisionCategoriesOptions}
                  selectedOptions={selectedPrecisionCtvOptions}
                  setSelectedOptions={setSelectedPrecisionCtvOptions}
                  onPopoverClose={onMenuClose}
                  limitOptions={1} // Limiting options to tier 1
                />
              </FormFieldLabel>
            );
          }}
        </Field>
      </Collapse>

      <Field name={"markets"}>
        {({ field, form }: FieldProps) => {
          const onChange = (option: { label: string; value: string }[]) => {
            form.setFieldValue(
              field.name,
              option.map((item) => item.value)
            );
          };

          const onMenuClose = () => {
            form.setFieldTouched(field.name, true, true);
          };
          return (
            <FormFieldLabel
              label={markets.label}
              isRequired={markets.isRequired}
              isInvalid={
                !!(form.errors["markets"] && !!form.touched["markets"])
              }
              errorMessage={form.errors?.markets?.toString()}
            >
              <SelectField
                name={"markets"}
                onChange={onChange}
                onMenuClose={onMenuClose}
                isMulti={true}
                options={getCountries("normal") as CountryOptionType[]}
                placeholder={markets.placeholder}
              />
            </FormFieldLabel>
          );
        }}
      </Field>
      <Field name={"creativeType"}>
        {({ form, field }: FieldProps) => {
          const onChange = (option: { label: string; value: string }) => {
            form.setFieldValue(field.name, option.value);
          };

          const onMenuClose = () => {
            form.setFieldTouched(field.name, true, true);
          };
          return (
            <FormFieldLabel
              label={creativeType.label}
              isRequired={creativeType.isRequired}
              isInvalid={
                !!(
                  form.errors["creativeType"] && !!form.touched["creativeType"]
                )
              }
              errorMessage={form.errors?.creativeType?.toString()}
            >
              <SelectField
                isMulti={false}
                onChange={onChange}
                onMenuClose={onMenuClose}
                placeholder={creativeType.placeholder}
                options={Object.entries(CampaignCreativeTypeChoices).map(
                  ([label, value]) => ({
                    label,
                    value,
                  })
                )}
              />
            </FormFieldLabel>
          );
        }}
      </Field>
      <Stack
        maxW={["100%", null, "100%", null, null]}
        spacing={6}
        align={"baseline"}
        direction={["column", null, "row", null, null]}
      >
        <Field name={"startDate"}>
          {({ field, form }: FieldProps) => {
            return (
              <FormFieldLabel
                label={startDate.label}
                isRequired={startDate.isRequired}
                errorMessage={form.errors?.startDate?.toString()}
                isInvalid={
                  !!(form.errors["startDate"] && !!form.touched["startDate"])
                }
              >
                <DatePicker
                  inputProps={{ field, form }}
                  placeholder={startDate.placeholder}
                  dateLimits={{ start: new Date() }}
                />
              </FormFieldLabel>
            );
          }}
        </Field>
        <Field name={"endDate"}>
          {({ field, form }: FieldProps) => {
            return (
              <FormFieldLabel
                label={endDate.label}
                isRequired={endDate.isRequired}
                isInvalid={
                  !!(form.errors["endDate"] && !!form.touched["endDate"])
                }
                errorMessage={form.errors?.endDate?.toString()}
              >
                <DatePicker
                  inputProps={{ field, form }}
                  placeholder={endDate.placeholder}
                  dateLimits={{ start: new Date() }}
                />
              </FormFieldLabel>
            );
          }}
        </Field>
      </Stack>
      <Stack
        direction={["column", null, "row", null, null]}
        spacing={6}
        align={"baseline"}
      >
        {["dspMemberId", "advertiserName"].map((f, ix) => (
          <Field name={f} key={`${f}${ix}`}>
            {({ form, field }: FieldProps) => (
              <FormInputField
                inputFieldProps={field}
                fieldType={"text"}
                isRequired={fields[f].isRequired}
                label={fields[f].label}
                isInvalid={!!(form.errors[f] && form.touched[f])}
                placeholder={fields[f].placeholder}
                errorMessage={form.errors?.[f]?.toString()}
                toolTip={f==="dspMemberId" ? dspMemberInfo : undefined}
              />
            )}
          </Field>
        ))}
      </Stack>
    </Box>
  );
};

// Function used to add children to mutation
// const selectPrecisionChildCategories = (
//   selectedPrecisionOptions: CheckboxOptionType[],
//   // onlyTier1=false,
// ) => {

//   const precisionCategoryIds: number[] = [];

//   // Pushing child ids if it does not already exist in selected
//   selectedPrecisionOptions.forEach((option) => {
//     // precisionCategoryIds.push(option.id);
//     precisionCategoryIds.indexOf(option.id) === -1 && precisionCategoryIds.push(option.id);

//     if (option.children) {
//       option.children.forEach((idString) => {
//         precisionCategoryIds.indexOf(parseInt(idString)) === -1 && precisionCategoryIds.push(parseInt(idString))
//       })
//     }
//   });

//   return precisionCategoryIds;
// }


// const handleProductSelected = (checked: boolean, cat: string) => {
//   if (cat === "core") {
//     setFieldValue("illumaCore", checked, true);
//   }
//   if (cat === "compass") {
//     setFieldValue("illumaCompass", checked, true);
//   }
//   if (checked) {
//     setFieldValue("productSelected", true, true);
//   } else {
//     // Old method
//     // if (cat === "core" && !showInsightCategoryBox) {
//     //   setFieldValue("productSelected", false, true);
//     // } else if (cat === "smart" && !values.illumaCore) {
//     //   setFieldValue("productSelected", false, true);
//     // }

//     const productArray: boolean[] = [];
//     cat !== "core" && productArray.push(values.illumaCore);
//     cat !== "smart" && productArray.push(values.illumaSmart ? true : false);
//     cat !== "smartCtv" && productArray.push(values.illumaSmartCtv ? true : false);
//     cat !== "compass" && productArray.push(values.illumaCompass);
//     cat !== "precision" && productArray.push(values.illumaPrecision ? true : false);

//     const isAllFalse = productArray.every(product => product === false);
//     if (isAllFalse) {
//       setFieldValue("productSelected", false, true);
//     }
//   }
// };
